import React, {Fragment, useContext, useEffect} from "react";
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { useNetwork, useSwitchNetwork } from 'wagmi';
import { cancel_icon_w_gradient } from '../../assets';
import { useMediaQuery } from 'usehooks-ts';
import { StoreContext } from "../../utils/store";

function WrongNetwork({ open, isOpen }) {
    const navigate = useNavigate()
    const Store = useContext(StoreContext);
    const {setDepositWithdrawToken} = Store;
    const isMobile = useMediaQuery('(max-width: 640px)');
    const { chain } = useNetwork()
    const { chains, error, isLoading, isSuccess, pendingChainId, switchNetwork } = useSwitchNetwork()

    useEffect(()=>{
        if(chain.id !== 42161){
            setDepositWithdrawToken('USDbC'); 
        }
    },[isSuccess])

  return (
    <Transition appear show={isOpen} as={Fragment}>
    <Dialog as='div' className='relative z-[1000]' onClose={() => open(false)}>
        <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
        >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto bg-[rgba(0,0,0,0.90)] flex'>
            <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
            >
                <Dialog.Panel className={`modal-dialog transition-all transform ${isMobile ? 'mx-2' : ""} sm:w-[681px] modal flex max-[480px]:w-4/5`}>
                    <div className='mx-auto overflow-hidden'>
                        <button className="absolute top-2 right-2 z-20" onClick={() => open(false)}>
                            <img src={cancel_icon_w_gradient} alt="" />
                        </button>
                        <div className='flex items-center justify-center pt-5 mt-8 text-6xl font-["Tourney"] font-extrabold gradient-text text-center'>
                            WRONG NETWORK
                        </div>
                        <div 
                        className="rounded-md flex flex-col items-center border-1 wn-box my-12 mx-4 overflow-hidden"
                        >
                            <p className="flex flex-col justify-center text-lg font-normal text-[#E0DDFF] dark:text-gray-400 text-center py-5 mt-4 barlow tracking-wide">
                                <span>(Pepe&apos;s not mad, just disappointed</span> 
                                <span>in your network choice.)</span>
                            </p>
                            <button
                                className="m-2 mt-8 drop-shadow-3xl flex justify-center items-center flex-row w-[200px] h-[40px] border-2 border-sky-500 rounded-lg barlow connect-wallet-btn hover:bg-gradient-to-r from-bluetint to-purpletint"
                                onClick={() => switchNetwork?.(42161)}
                            >
                                <span className='gradient-text-b font-bold'>Change Network</span>
                            </button>
                            <p className="text-red">{error&&error.message}</p>
                            <div className="m-4 mb-8">
                            <p
                              className="inline-flex flex-col items-center text-center manrope font-medium text-gray-500 dark:text-white"
                            >
                             <span className='text-base font-semibold'>New to Arbitrum?</span><span><a className='underline text-xs hover:text-[#E0DDFF]/60' href='https://arbitrum.io/bridge-tutorial/' target="_blank" rel="noreferrer">How to bridge or add Arbitrum to your Wallet</a></span>
                            </p>
                          </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Transition.Child>
        </div>
    </Dialog>
</Transition>
  );
}

export default WrongNetwork;
