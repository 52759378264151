import { ethers, BigNumber } from 'ethers';
import { useAccount, useNetwork, useSigner } from 'wagmi';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { blueETH, blueToken, cancel_icon_w_gradient, smartPepe } from '../../assets';
import { StoreContext } from '../../utils/store';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { getDollarValue } from '../../services/service';
import {
  coingecko_api,
  pepePool,
  WETH,
  balancer_pool_tokens,
  chain_ids,
  arbitriumContracts,
  baseContracts
} from '../../constants';
import { Tooltip } from '@mui/material';
import { pepeLock } from '../../utils/pepeLock';
import { pepeTokenABI } from '../../utils/pepeTokenABI';
import { BalancerSDK, BalancerSdkConfig, Network } from '@balancer-labs/sdk';
import { useDebounce } from 'usehooks-ts';
import { pepeEsPegLockABI } from '../../utils/pepeEsPegLockABI';
const config = {
  network: Network.ARBITRUM,
  rpcUrl: `https://arb-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API}`,
};
const balancer = new BalancerSDK(config);

const LockModal = ({ open, isOpen, promptType, isEsPeg, data }) => {
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const { chain } = useNetwork();

  const Store = useContext(StoreContext);
  const { setStoreState, userLockingState, setRefreshLocking, reloadData,setLockModal, setPromptType } = Store;
  const { enqueueSnackbar } = useSnackbar();


  const {
    esPegTokenAddress,
    pepeEsPegLockUpContractAddress,
    pepeLockingContractAddress,
    pegContractAddress,
    poolId,
  } = chain?.id == chain_ids[0]? arbitriumContracts : baseContracts //// @dev chain Id index 0 is arbitrium

  const pepeTokenContract = useMemo(
    () => data || new ethers.Contract(pegContractAddress, pepeTokenABI, signer),
    [signer]
  );
  const esPegTokenContract = useMemo(() => new ethers.Contract(esPegTokenAddress, pepeTokenABI, signer), [signer]);
  const wethContract = useMemo(() => new ethers.Contract(WETH, pepeTokenABI, signer), [signer]); //both are erc20 abis
  const lockContract = useMemo(() => new ethers.Contract(pepeLockingContractAddress, pepeLock, signer), [signer]);
  const esPegLockUpContract = useMemo(
    () => new ethers.Contract(pepeEsPegLockUpContractAddress, pepeEsPegLockABI, signer),
    [signer]
  );

  const mainTokenContract = useMemo(
    () => (isEsPeg ? esPegTokenContract : pepeTokenContract),
    [esPegTokenContract, isEsPeg, pepeTokenContract]
  );
  const mainLockingContract = useMemo(
    () => (isEsPeg ? esPegLockUpContract : lockContract),
    [esPegLockUpContract, lockContract, isEsPeg]
  );
  const mainLockContractAddress = isEsPeg ? pepeEsPegLockUpContractAddress : pepeLockingContractAddress;

  const [isComplete, setIsComplete] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  // const [isSelected, setIsSelected] = useState(false);
  const [formattedInput, setFormattedAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [weth_amount, setWETHAmount] = useState(0);
  const [pegApproved, setPegApproved] = useState(false);
  const [wethApproved, setWethApproved] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [peg_input_event, setPEGInputEvent] = useState(null);
  const [weth_input_event, setWETHInputEvent] = useState(null);
  const [wethPrice, setWethPrice] = useState(0);
  const [pegPrice, setPEGPrice] = useState(0);
  const [wethAllowance, setWethAllowance] = useState(BigNumber.from(0));
  const [pegAllowance, setPegAllowance] = useState(BigNumber.from(0));
  const floatRegexp = useMemo(() => /^[+-]?\d*(?:[.,]\d*)?$/, []);
  const debouncedValue_peg = useDebounce(peg_input_event, 2000);
  const debouncedValue_weth = useDebounce(weth_input_event, 2000);
  const [hash, setHash] = useState('');
  const [userPegOrEsPegBalance, setUserPegOrEsPegBalance] = useState(BigNumber.from(0));
  const [userWethBalance, setWethBalance] = useState(BigNumber.from(0));
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = useCallback(
    async (e, selectedToken) => {
      const value = e?.target?.value?.toString() || selectedToken === 'PEG' ? amount : weth_amount;
      console.log({ value }, 'Called HandleChange');
      const valid = floatRegexp.test(value?.replace(/,/g, ''));
      if (!valid) e?.preventDefault();

      if (valid) {
        const formatted = value?.replace(/,/g, '');
        console.log('Formatted: ', formatted, value);
        if (selectedToken === 'PEG') {
          setAmount(value.replace(/,/g, '') || '');
          setFormattedAmount(formatted);
        } else {
          setWETHAmount(value.replace(/,/g, '') || '');
        }
        console.log(value.replace(/,/g, '') || '', { formatted });

        if (formatted > 0) {
          const tkn_address = selectedToken === 'WETH' ? balancer_pool_tokens[0] : balancer_pool_tokens[1]; //index 0=weth,index 1=peg
          //make calculation for the amount of weth/peg needed
          // console.log(formatted, tkn_address);
          await calculateBalance(formatted, tkn_address);
        }
      }
      if (value === '') {
        setAmount('');
        setFormattedAmount('');
      }
    },
    [amount, weth_amount, floatRegexp]
  );

  useEffect(() => {
    handleChange(peg_input_event, 'PEG');
  }, [debouncedValue_peg]);

  useEffect(() => {
    handleChange(weth_input_event, 'WETH');
  }, [debouncedValue_weth]);

  const getDollarValue_ = useCallback(async () => {
    try {
      let weth_id = 'weth';
      let peg_id = '';
      const newAmount = {
        peg: amount,
        weth: weth_amount,
      };
      let prices = await getDollarValue(weth_id, newAmount);
      setPEGPrice(prices[0]); //!TODO: change this to the actual price
      setWethPrice(prices[1]);
    } catch (error) {
      console.log(error);
    }
  }, [amount, weth_amount]);

  const calculateBalance = useCallback(
    async (amount_, token) => {
      try {
        setCalculating(true);
        const pool = await balancer.pools.find(poolId);

        const amountOfToken = typeof amount_ != 'object' ? ethers.utils.parseUnits(amount_.toString(), 18) : amount_;
        const { amounts } = await pool.calcProportionalAmounts(token, amountOfToken);
        setCalculating(false);
        if (BigNumber.from(amounts[0]).gt(userPegOrEsPegBalance)) {
          enqueueSnackbar(`Insufficient ${isEsPeg ? 'EsPEG' : 'PEG'} balance`, {
            variant: 'error',
          });
        }
        if (BigNumber.from(amounts[1]).gt(userWethBalance)) {
          enqueueSnackbar(`Insufficient wETH balance`, {
            variant: 'error',
          });
        }
        setAmount(ethers.utils.formatUnits(amounts[0]), 18);
        setWETHAmount(ethers.utils.formatUnits(amounts[1]), 18);
      } catch (err) {
        console.log(err);
      }
    },
    [isEsPeg, userPegOrEsPegBalance, userWethBalance]
  );

  const getUserPegAndWethAllowance = useCallback(async () => {
    try {
      const pegAllowance = await mainTokenContract.allowance(address, mainLockContractAddress);
      const wethAllowance = await wethContract.allowance(address, mainLockContractAddress);
      setPegAllowance(pegAllowance);
      setWethAllowance(wethAllowance);
      if (amount && weth_amount) {
        const parsedPegAmount = ethers.utils.parseUnits(amount.toString(), 18);
        const parsedWethAmount = ethers.utils.parseUnits(weth_amount.toString(), 18);
        // console.log('parsed peg amount: ', parsedPegAmount);
        // console.log('parsed weth amount: ', parsedWethAmount);
        if (pegAllowance.gte(parsedPegAmount) && wethAllowance.gte(parsedWethAmount)) {
          setIsApproved(true);
          return;
        } else {
          setIsApproved(false);
        }

        if (pegAllowance.gte(parsedPegAmount)) {
          setPegApproved(true);
        } else {
          setPegApproved(false);
        }

        if (wethAllowance.gte(parsedWethAmount)) {
          setWethApproved(true);
        } else {
          setWethApproved(false);
        }
      } else {
        setIsApproved(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [mainTokenContract, address, mainLockContractAddress, wethContract, amount, weth_amount]);

  const approvePeg = useCallback(async () => {
    //show approving peg
    enqueueSnackbar('PEG approval, confirm in wallet', {
      variant: 'success',
    });
    try {
      await mainTokenContract.approve(mainLockContractAddress, ethers.constants.MaxUint256);
      enqueueSnackbar('PEG approved', {
        variant: 'success',
      });
      //if after approving peg, the user has enough weth allowance, then set isApproved to true
      const pegAllowance_ = await mainTokenContract.allowance(address, mainLockContractAddress);
      const wethAllowance_ = await wethContract.allowance(address, mainLockContractAddress);
      setWethAllowance(wethAllowance_);
      setPegAllowance(pegAllowance_);
      if (wethAllowance_.gte(ethers.utils.parseUnits(weth_amount.toString(), 18))) {
        setIsApproved(true);
        return;
      } else {
        enqueueSnackbar(
          `PEG approved but you do not have enough wETH allowance. wETH allowance = ${ethers.utils.formatUnits(
            wethAllowance_,
            18
          )}`,
          {
            variant: 'warning',
          }
        );
      }
      setPegApproved(true);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('PEG approval failed', {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainTokenContract, mainLockContractAddress, address, wethContract, weth_amount]);

  const approveWeth = useCallback(async () => {
    //show approving weth
    enqueueSnackbar('Weth approval, confirm in wallet', {
      variant: 'success',
    });
    try {
      await wethContract.approve(mainLockContractAddress, ethers.constants.MaxUint256);
      enqueueSnackbar('Weth approved', {
        variant: 'success',
      });
      //if after approving weth, the user has enough peg allowance, then set isApproved to true
      const pegAllowance_ = await mainTokenContract.allowance(address, mainLockContractAddress);
      const wethAllowance_ = await wethContract.allowance(address, mainLockContractAddress);
      setWethAllowance(wethAllowance_);
      setPegAllowance(pegAllowance_);
      if (pegAllowance_.gte(ethers.utils.parseUnits(amount.toString(), 18))) {
        setIsApproved(true);
        return;
      } else {
        enqueueSnackbar(
          `Weth approved but you do not have enough PEG allowance. PEG allowance = ${ethers.utils.formatUnits(
            pegAllowance_
          )}`,
          {
            variant: 'warning',
          }
        );
      }
      setWethApproved(true);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Weth approval failed', {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wethContract, mainLockContractAddress, mainTokenContract, address, amount]);

  const lock = useCallback(async () => {
    //show locking
    enqueueSnackbar('Locking, confirm in wallet', {
      variant: 'success',
    });
    let estimateGas;
    try {
      estimateGas = await mainLockingContract.estimateGas.lock(
        ethers.utils.parseUnits(weth_amount.toString(), 18), //weth
        ethers.utils.parseUnits(amount.toString(), 18), //peg
        1 //minblp out
      );

      estimateGas = estimateGas.toNumber() + 1e4;
    } catch (e) {
      //!Transaction will fail best to seize operation?
      estimateGas = 3e6;
    }
    try {
      const tx = await mainLockingContract.lock(
        ethers.utils.parseUnits(weth_amount.toString(), 18), //weth
        ethers.utils.parseUnits(amount.toString(), 18), //peg
        1, //minblp out
        { gasLimit: estimateGas }
      );
      const reciept = await tx.wait();
      setHash(reciept.transactionHash);
      setIsComplete(true);
      setRefreshLocking(true);
      //getUserBalance();
      setStoreState('reloadData', !reloadData);
      getUserWETHBalance();
      getUserPegAndWethAllowance();
      enqueueSnackbar('Locked Successfully', {
        variant: 'success',
      });
      // open(false);
      setIsComplete(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Locking failed', {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, weth_amount, mainLockingContract]);

  useEffect(() => {
    getDollarValue_();
    getUserPegAndWethAllowance();
  }, [getDollarValue_, getUserPegAndWethAllowance]);

  const ethersCalculate = (data, places) => {
    try {
      return parseFloat(ethers.utils.formatUnits(data, 18)).toFixed(places);
    } catch (error) {
      return '0.00';
    }
  };

  const getUserPegOrEsPegBalance = useCallback(async () => {
    try {
      console.log('getting user balance');
      const balance = await mainTokenContract.balanceOf(address);
      setUserPegOrEsPegBalance(balance);
    } catch (error) {
      console.log('get balance', error);
    }
  }, [mainTokenContract, address]);

  const getUserWETHBalance = useCallback(async () => {
    try {
      const wethBalance = await wethContract.balanceOf(address);
      setWethBalance(wethBalance);
    } catch (error) {
      console.log('get weth balance', error);
    }
  }, [wethContract, address]);

  const handleCustomAmountChange = async (percentage, selectedToken) => {
    // console.log('user balance: ', ethers.utils.formatUnits(userPegOrEsPegBalance, 18));

    if (selectedToken === 'PEG') {
      const percentageAmount = userPegOrEsPegBalance.mul(percentage).div(1e4);

      if (percentageAmount.gt(BigNumber.from(0))) {
        const tkn_address = balancer_pool_tokens[1];
        await calculateBalance(percentageAmount, tkn_address);
      } else {
        setAmount(0);
      }
    } else {
      // console.log('user weth balance: ', ethers.utils.formatUnits(userWethBalance, 18));
      const percentageAmount = userWethBalance.mul(percentage).div(1e4);
      if (percentageAmount.gt(BigNumber.from(0))) {
        const tkn_address = balancer_pool_tokens[0];
        await calculateBalance(percentageAmount, tkn_address);
      } else {
        setWETHAmount(0);
      }
    }
  };

  useEffect(() => {
    getUserPegOrEsPegBalance();
    getUserWETHBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isOpen]);

  useEffect(() => {
    setStoreState('reloadData', !reloadData);
    setAmount(0);
    setWETHAmount(0);
    setIsComplete(false);
    setIsApproved(false);
  }, [isOpen]);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const formattedDate = (timestamp) => {
    let formattedDate = '';

    if (timestamp) {
      const date = new Date(timestamp * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      formattedDate = `${year}-${month}-${day}`;

      return formattedDate
    }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={() => open(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-deep-blue/[0.89] flex justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom={`${isComplete ? 'opacity-100' : 'opacity-0'} scale-95`}
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`h-[90vh] sm:h-auto modal-dialog transition-all transform ${
                  isComplete ? 'w-[739px]' : promptType ? 'w-[542px]' : 'w-[520px]'
                } stake-modal flex max-[480px]:w-4/5 relative`}
              >
                <div className="overflow-y-auto pt[100%] sm:pt-0 sm:overflow-none selection:flex flex-col justify-around w-full mx-auto">
                  <div className="flex items-end justify-end mt-4 mr-4">
                    <button onClick={() => open(false)}>
                      <img src={cancel_icon_w_gradient} alt="cancel icon" width="15px" />
                    </button>
                  </div>
                  {isComplete ? (
                    <>
                      <div className='flex items-center justify-center text-7xl font-["Tourney"] font-extrabold gradient-text text-center mt-8'>
                        YEAH!
                      </div>
                      <div className="mt-8 mb-40 text-center text-white barlow">
                        <p className="text-3xl">You have successfully locked</p>
                        <p className='text-[43px] font-["Tourney"] font-extrabold gradient-text'>
                          {typeof amount === 'object'
                            ? Number.isInteger(parseFloat(ethers.utils.formatUnits(amount, 18)))
                              ? parseFloat(ethers.utils.formatUnits(amount, 18))
                              : parseFloat(ethers.utils.formatUnits(amount, 18)).toFixed(4)
                            : Number.isInteger(parseFloat(amount))
                            ? parseFloat(amount)
                            : parseFloat(amount).toFixed(4)}{' '}
                          {isEsPeg ? 'esPEG' : 'PEG'}
                        </p>
                        <p className="text-3xl">to your frenly dealer</p>
                        <a className="text-xs underline" target="__blank" href={'https://arbiscan.io/tx/' + hash}>
                          See tx
                        </a>
                      </div>
                      <img src={smartPepe} alt="" className="absolute bottom-0 left-0" />
                    </>
                  ) : (
                    <>
                      <div className='flex items-center justify-center text-4xl font-["Tourney"] font-extrabold gradient-text text-center mb-4'>
                        {promptType ? 'ADD TO POSITION' : isEsPeg ? 'LOCKING esPEG-wETH' : 'LOCKING PEG-wETH'}
                      </div>
                      {promptType ? (
                        <div className="bg-white/[0.05] rounded-md barlow text-white text-center w-[90%] mx-auto mb-5 p-2">
                          <p className="text-lg font-semibold">Your Existing Position</p>
                          <p className="mb-2 text-sm text-[#E0DDFF]/30">
                            Locked Liquidity until {formattedDate(userLockingState?.unlockTimestamp) || 'Date Error'}
                          </p>
                          <div className="flex justify-evenly">
                            <div>
                              <p className="uppercase">PEG</p>
                              <p className="text-[#E0DDFF]/30">
                                {/* {ethers?.utils?.formatUnits(userLockingState?.pegLocked, 18) || 'Ethers 1'} */}
                                {ethersCalculate(userLockingState.pegLocked, 4)}
                              </p>
                            </div>
                            <div>
                              <p className="">wETH</p>
                              <p className="text-[#E0DDFF]/30">
                                {/* {parseFloat(ethers.utils.formatUnits(userLockingState?.wethLocked, 18))?.toFixed(
                                      4
                                    ) || 'Error 1'} */}
                                {ethersCalculate(userLockingState.wethLocked, 4)}
                              </p>
                            </div>
                            <div>
                              <p className="uppercase">TOTAL $</p>
                              <p className="text-[#E0DDFF]/30">
                                $
                                {parseFloat(userLockingState?.wethUsd + userLockingState?.pegUsd)?.toFixed(2) ||
                                  'Error 2'}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <p className="flex self-start justify-start text-xs text-[#E0DDFF]/30 mx-auto w-4/5 mb-1">
                        Wallet Balance: {ethersCalculate(userPegOrEsPegBalance, 4)} {isEsPeg ? 'esPEG' : 'PEG'}
                      </p>
                      <div className=" p-[1px] bg-gradient-to-r from-[rgb(0,183,240)] to-[rgba(72,25,190)] mx-auto w-4/5 rounded-xl">
                        <div className="flex flex-col items-center justify-between gap-4 px-4 py-5 mx-auto text-white bg-deep-blue/[0.93] stake-modal-shadow rounded-xl barlow sm:flex-row sm:gap-0">
                          <p className="text-[#E0DDFF] text-sm flex items-center">
                            <img src={blueToken} alt="" className="w-[35px] h-[35px] mr-4" />
                            <span>80%</span>
                          </p>
                          <div className="flex flex-col items-center sm:items-end gap-1 sm:gap-0 w-[70%] justify-evenly">
                            {calculating ? (
                              <p>Calculating...</p>
                            ) : (
                              <div className="flex items-center w-auto gap-[10px] justify-evenly flex-wrap sm:flex-nowrap">
                                <input
                                  className="dwf-funds-input-dark !mr-0 pr-2"
                                  onChange={(e) => {
                                    setPEGInputEvent(e);
                                    floatRegexp.test(e.target.value.replace(/,/g, '')) && setAmount(e.target.value);
                                  }}
                                  placeholder="0.00"
                                  value={amount}
                                  // value={parseFloat(amount).toFixed(4)}
                                />{' '}
                                <button
                                  onClick={(e) => {
                                    handleCustomAmountChange(6900, 'PEG');
                                  }}
                                  className="bg-white/10 opacity-80 rounded-md mr-2 p-1.5"
                                >
                                  69%
                                </button>
                                <button
                                  onClick={(e) => {
                                    handleCustomAmountChange(1e4, 'PEG');
                                  }}
                                  className="bg-white/10 opacity-80 rounded-md p-1.5"
                                >
                                  100%
                                </button>
                              </div>
                            )}
                            <p className="text-xs text-[#E0DDFF]/30">${pegPrice}</p>
                          </div>
                        </div>
                      </div>
                      <p className="flex self-center justify-center text-4xl text-white">+</p>
                      <p className="flex self-start justify-start text-xs text-[#E0DDFF]/30 mx-auto w-4/5 mb-1">
                        Wallet Balance: {ethersCalculate(userWethBalance, 4)} wETH
                      </p>
                      <div className=" p-[1px] bg-gradient-to-r from-[rgb(0,183,240)] to-[rgba(72,25,190)] mx-auto w-4/5 rounded-xl">
                        <div className="flex flex-col items-center justify-between gap-4 px-4 py-5 mx-auto text-white bg-deep-blue/[0.93] stake-modal-shadow rounded-xl barlow sm:flex-row sm:gap-0">
                          <p className="text-[#E0DDFF] text-sm flex items-center">
                            <img src={blueETH} alt="" className="w-[35px] h-[35px] mr-4" />
                            <span>20%</span>
                          </p>
                          <div className="flex flex-col items-center sm:items-end gap-1 sm:gap-0 w-[70%] justify-evenly">
                            {calculating ? (
                              <p>Calculating...</p>
                            ) : (
                              <div className="flex items-center w-auto gap-[10px] justify-evenly flex-wrap sm:flex-nowrap">
                                <Tooltip
                                  title={`Weth Balance: ${ethers.utils.formatUnits(userWethBalance, 18)}`}
                                  placement="bottom"
                                  arrow
                                  open={weth_amount == 0 && isFocused}
                                >
                                  <input
                                    className="dwf-funds-input-dark !mr-0 pr-2"
                                    onChange={(e) => {
                                      setWETHInputEvent(e);
                                      setWETHAmount(e.target.value);
                                    }}
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    placeholder="0.00"
                                    value={weth_amount}
                                    name="weth-input"
                                  />
                                </Tooltip>
                                <button
                                  onClick={(e) => {
                                    handleCustomAmountChange(6900, 'WETH');
                                  }}
                                  className="bg-white/10 opacity-80 rounded-md mr-2 p-1.5"
                                >
                                  69%
                                </button>
                                <button
                                  onClick={(e) => {
                                    handleCustomAmountChange(1e4, 'WETH');
                                  }}
                                  className="bg-white/10 opacity-80 rounded-md p-1.5"
                                >
                                  100%
                                </button>
                              </div>
                            )}
                            <p className="text-xs text-[#E0DDFF]/30">${wethPrice}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center my-4 text-sm text-center text-white sm:flex-row">
                        <p>You are adding: </p>
                        <p>
                          &nbsp;
                          {(amount && parseFloat(amount).toFixed(4)) || 0.0}
                          &nbsp;
                          {isEsPeg ? 'esPEG' : 'PEG'}
                          &nbsp; + &nbsp;
                          {parseFloat(weth_amount).toFixed(6) || 0.0}
                          &nbsp; wETH
                        </p>
                      </div>
                      <div className="flex flex-col justify-center my-4 text-sm text-center text-white sm:flex-row">
                        <p className="text-xs text-[#E0DDFF]/30">This won't change your locking period!</p>
                      </div>
                      <div className="flex justify-center my-4">
                      {
                        
                        isApproved ? (
                          promptType ? (
                            isEsPeg ? ("") : (
                              <button className="inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[170px] text-xs inter mx-4"
                              onClick={
                                () => lock()
                                // () => {
                                // setLockModal(true);
                                // setPromptType(true);}
                              }
                              >
                            {' '}
                            Add to Position
                          </button>
                            )
                          ):(
                            <button
                            onClick={() => lock()}
                            className="inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[256px] text-xs inter mx-4"
                          >
                            {' '}
                            {isEsPeg ? 'Lock for 1.5 months' : 'Lock for 6 months'}
                          </button>
                          )
                          
                        ) : (
                          <>
                            <button
                              onClick={() => approvePeg()}                            
                              disabled={pegApproved || weth_amount == 0 || amount == 0}
                              className={`inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[135px] text-xs inter mx-4 ${
                                pegApproved || weth_amount == 0 || amount == 0
                                  ? 'opacity-50 cursor-not-allowed'
                                  : 'opacity-100'
                              }`}
                            >
                              Approve &nbsp;
                              {isEsPeg ? 'esPEG' : 'PEG'}
                            </button>
                              )
                            ):(
                              <button
                              onClick={() => lock()}
                              className="inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[256px] text-xs inter mx-4"
                            >
                              {' '}
                              {isEsPeg ? 'Lock for 1.5 months' : 'Lock for 6 months'}
                            </button>
                          </>
                        )
                      
                      }
                      </div>
                      {/* <div className="flex justify-center my-4">
                        {
                          isEsPeg ? ("") : (
                            <button className="inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[170px] text-xs inter mx-4"
                            onClick={() => {
                              setLockModal(true);
                              setPromptType(true);}}
                            >
                          {' '}
                          Add to Position
                        </button>
                          )
                        }
                        
                      </div> */}
                    </>
                  )}
                  
                    
                    {
                      isEsPeg ?  (
                      <p className={`text-white text-xs p-8 pt-0 ${isComplete ? 'hidden' : ''}`}>
                      *By locking and adding your liquidity to Pepe's Game, it will be committed for the next 6 weeks.
                      You're free to add more liquidity anytime, but please note that withdrawing added liquidity is only
                      possible after the 6 weeks have passed. As your contribution becomes part of our existing LP in
                      Balancer, you may notice varying amounts of esPEG/wETH based on price action, and Impermanent Loss is
                      a possibility, as with any LP position. Rest assured, the current APY will be updated in real-time,
                      reflecting any changes influenced by game fees, trade fees, and total lockers.
                      </p>) : 
                      
                      (
                        <p className={`text-white text-xs p-8 pt-0 ${isComplete ? 'hidden' : ''}`}>
                        *By locking and adding your liquidity to Pepe's Game, it will be committed for the next 24 weeks.
                        You're free to add more liquidity anytime, but please note that withdrawing added liquidity is only
                        possible after the 24 weeks have passed. As your contribution becomes part of our existing LP in
                        Balancer, you may notice varying amounts of esPEG/wETH based on price action, and Impermanent Loss is
                        a possibility, as with any LP position. Rest assured, the current APY will be updated in real-time,
                        reflecting any changes influenced by game fees, trade fees, and total lockers.
                        </p>
                      )
                    }
                  
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default LockModal;
