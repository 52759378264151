import React, { useEffect, useState } from 'react';
import { copyicon, editicon, finalmobilelogo, twitter, discord, whitepaper, medium, gitbook, socialIconVector, information_white } from '../assets';
import PepeMobileFooter from '../components/PepeMobileFooter';
import Navbar from '../components/Navbar/Navbar';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useAccount, useSigner, useProvider } from 'wagmi';
import '../styles/referral.css';
import { api, referralContract, addreferURL, referral_API } from '../constants';
import { dollarFormatter } from '../utils/dollarFormatter';
import { ethers } from 'ethers'
import { referralABI } from '../utils/referralABI';
import { useDebounce } from 'usehooks-ts';
import { cancel_icon_w_gradient } from '../assets';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';

const ReferralPage = () => {
  const referralURL = window.location.origin;
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [totalUserVolume, setTotalUserVolume] = useState(0);
  const [referralsReward, setUserReward] = useState(0)
  const [isEditingReferrer, setIsEditingReferrer] = useState(false);
  const [referrerText, setReferrerText] = useState('Fill in the code of your referrer');
  const [loading, setLoading] = useState(false)
  const { address } = useAccount();
  const provider = useProvider();
  const { data: signer } = useSigner()
  const [_event, setInputEvent] = useState(null);
  const debouncedValue = useDebounce(_event, 2000)
  const navigate = useNavigate();

  const [referralCode, setReferralCode] = useState('')
  const formatter = new Intl.NumberFormat('en-US');

  const { enqueueSnackbar } = useSnackbar();


  //Method to check if user is already having a referral code 
  const getReferralCode = async () => {
    if (address) {
      try {
        const response = await axios.get(`${api.offChainBet.getReferralCodeEndpoint}?address=${address}`)
        const refResponse = await axios.get(`${api.offChainBet.getMyReferrerApiEndpoint}?address=${address}`)
        const data = response.data.refcode[0].refcode; //unsure of returned data type, assuming json
        const refData = refResponse.data.refcode ? refResponse.data?.refcode[0]?.refcode : ''
        setReferralCode(data);
        setReferrerText(refData);
        return data;
      } catch (e) {
        console.error('Failed to check for referralCode: ', e);
        throw new Error('Failed to check for referralCode');
      }
    }
  };

  //Method to get all referral stats
  const getReferralStatus = async () => {
    try {
      const response = await axios.get(`${api.offChainBet.getReferStatsApiEndpoint}?address=${address}`);
      const data = response.data; //unsure of returned data type, assuming json
      // console.log('Referral stats s::', data)
      setTotalUserVolume(data.uservolume === null ? 0 : data.uservolume)
      setTotalReferrals(data.referred)
      setTotalVolume(data.totalvolume === null ? 0 : data.totalvolume)
      // setTotalRewards()
      return data;
    } catch (e) {
      console.error('Failed to check for referralCode: ', e);
      throw new Error('Failed to check for referralCode');
    }
  };

  const getRewards = async () => {
    const provid = new ethers.providers.JsonRpcProvider('https://arb1.arbitrum.io/rpc');
    const contract_ = new ethers.Contract(referralContract, referralABI, provid);
    const claimableAmount = await contract_['getClaimableUsdc(address)'](address);
    setUserReward(ethers.utils.formatUnits(claimableAmount, 6))
  }

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(`${referralURL}?ref=${referralCode}`);
      enqueueSnackbar('Referral copied.', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar('An error occurred while copying the referral link.', { variant: 'error' });
    }
  };

  //claim reward
  const claimReward = async () => {
    if (referralsReward > 0) {
      const contract = new ethers.Contract(referralContract, referralABI, signer);
      let gasEstimate
      try {
        gasEstimate = await contract.estimateGas.claimAll();
        gasEstimate = gasEstimate.toNumber() + 1e4;
      } catch (error) {
        gasEstimate = 3e6;
      }

      try {

        const transfer = await contract.claimAll({ gasLimit: gasEstimate });
        const tx = await transfer.wait();
        // console.log(tx.transactionHash)
      } catch (error) {
        throw (error)
      }
    } else {
      enqueueSnackbar('Sorry you have no rewards to claim at the moment.', { variant: 'error' });

    }
  }

  useEffect(() => {
    handleReferrerTextChange(_event)
  }, [debouncedValue])

  useEffect(() => {
    getReferralStatus()
    getReferralCode()

  }, [address])

  useEffect(() => {
    getRewards()
  }, [provider])



  const referrals = [
    {
      date: 'April 27th, 2023',
      amount: 125,
      transactionAddress: '0x89220d9e960b9738100ee9fBB6F8e1F891110BE6',
    },
    {
      date: 'May 14th, 2023',
      amount: 125,
      transactionAddress: '0x86620d9e960b9738100ee9fBB6F8e1F891110BE5',
    },
    {
      date: 'January 3rd, 2023',
      amount: 125,
      transactionAddress: '0x19020d9e960b9738100ee9fBB6F8e1F891110BE9',
    },
  ];

  // Method to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional smooth scrolling animation
    });
  }

  // Method to handle edit referrer button
  const handleEditReferrerButton = () => {
    if (!isEditingReferrer) {
      setReferrerText('')
    }
    setIsEditingReferrer(!isEditingReferrer);
  };

  const handleReferrerTextChange = async (e) => {
    //setReferrerText(e.target.value);
    if (referralCode === e.target.value) {
      setReferrerText('Fill in the code of your referrer');
      enqueueSnackbar('You cannot refer yourself', {
        variant: 'warning',
        preventDuplicate: true,
      });
      return;
    }
    if (referrerText && e) {
      //check if user pasted a url or some random text
      let regex = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm
      if (referrerText.length > 32 || regex.test(referrerText)) {
        enqueueSnackbar('Please input only the referrer code', {
          variant: 'error',
        });
        return
      }
      setLoading(true)
      //make request to lambda funtion to check if code is valid
      try {
        // const response = await axios.get(`${addreferURL}refCode=${e.target.value}&referee=${address}`);
        const response = await axios.get(`${api.offChainBet.addRefererApiEndpoint}?refCode=${e.target.value}&referee=${address}`);
        const data = response.data; //unsure of returned data type, assuming json
        if (data.status == "success") {
          enqueueSnackbar('Referrer linked successfully', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(data?.message, {
            variant: 'error',
          });
        }
      } catch (e) {
        console.error('Failed to check for referralCode: ', e);
        throw new Error('Failed to check for referralCode');
      } finally {
        setLoading(false)
      }
    }
  };

  return (
    <div className="bg-[rgba(9,3,29,1)] text-white min-h-screen lg:pb-0">
      <Navbar />
      <img src={finalmobilelogo} alt="" className="pointer-events-none block mx-auto sm:hidden" />
      <div className="sm:pt-20">
        <div className="relative p-4 w-[90%] mx-auto bg-[rgba(255,255,255,0.05)] border-2 border-solid border-[rgba(10,130,182,0.7)] rounded-2xl lg:pb-20">
          <img
            src={finalmobilelogo}
            alt=""
            className="pointer-events-none hidden mx-auto sm:block w-[25rem] absolute top-[-7rem] left-0 right-0"
          />
          <span className='w-[14px] sm:w-auto absolute top-3 right-3 text-white cursor-pointer' >
            <img src={cancel_icon_w_gradient} alt='' onClick={() => navigate("/slot")} />
          </span>
          <div className='flex flex-col text-center leading-[50px] sm:flex-row font-["Tourney"] gradient-text font-bold text-[1.75rem] sm:text-[3.125rem] justify-center items-center sm:pt-[5rem] mb-4'>
            <span>BRING YOUR OWN DEGEN</span>
          </div>

          <div className="flex flex-col sm:flex-row text-center justify-center barlow text-[11px] sm:text-[16px] mb-8 sm:mb-12">
            <span>Share your referral code with a fellow degenerate and get rewarded for every spin they make</span>
          </div>

          <div className="w-full sm:w-[88%] sm:max-w-[61rem] sm:mx-auto barlow">
            <div className="w-full sm:max-w-[61rem] mx-auto bg-[rgba(9,3,29,0.4)] rounded-2xl px-2 sm:px-4 py-8 mb-8">
              <div className="sm:flex sm:justify-center sm:items-center sm:gap-8">
                <div className="font-light text-[#E0DDFF] mb-2 mx-auto sm:m-0 text-center">
                  <span>YOUR PENDING REWARDS</span>
                  <Tooltip
                    title={"Nothing to see yet! Actual rewards will be calculated and updated at the end of the referral campaign."}
                    placement="bottom"
                    arrow={true}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          color: 'white',
                          backgroundColor: '#43334F',
                          paddingTop: '16px',
                          paddingBottom: '16px',
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          borderRadius: '4px',
                          fontSize: '14px',
                          fontFamily: 'Barlow',
                          fontStyle: 'normal',
                          fontWeight: '400px',
                          // width: '229.025px',
                          // height: '139.793p',
                        },
                      },
                      arrow: {
                        sx: {
                          color: '#43334F',
                        },
                      },
                    }}
                  >
                    <img
                      src={information_white}
                      alt="information icon"
                      className="inline-block font-barlow ml-3"
                    />
                  </Tooltip>
                </div>
                <div className='font-["Tourney"] colored-text font-extrabold gradient-text-blueish text-[1.875rem] mb-4 mx-auto sm:m-0 text-center'>
                  {dollarFormatter(referralsReward)}
                </div>
                <button onClick={claimReward} className="stake-btn-shadow text-[#18ADFA] rounded-md w-[8.25rem] h-7 text-xs mx-auto sm:m-0 flex justify-center items-center">
                  Claim
                </button>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-8 mb-8 sm:mb-12">
              <div className="sm:flex-1 bg-[rgba(255,255,255,0.05)] border-border-blue stake-bg-shadow rounded-2xl h-36 flex items-center justify-center">
                <div className="text-center">
                  <div className="text-[#E0DDFF] text-lg lg:text-xl font-light sm:mb-2">YOUR REFERRALS</div>
                  <div className="text-[rgba(224,221,255,0.7)] text-3xl lg:text-[2rem] xl:text-[2.5rem] font-semibold">
                    {totalReferrals}
                  </div>
                </div>
              </div>

              <div className="sm:flex-1 bg-[rgba(255,255,255,0.05)] border-border-blue stake-bg-shadow rounded-2xl h-36 flex items-center justify-center">
                <div className="text-center">
                  <div className="text-[#E0DDFF] text-lg lg:text-xl font-light sm:mb-2">TOTAL VOLUME</div>
                  <div className="text-[rgba(224,221,255,0.7)] text-3xl lg:text-[2rem] xl:text-[2.5rem] font-semibold">
                    {dollarFormatter(totalVolume)}
                  </div>
                </div>
              </div>

              <div className="sm:flex-1 bg-[rgba(255,255,255,0.05)] border-border-blue stake-bg-shadow rounded-2xl h-36 flex items-center justify-center">
                <div className="text-center">
                  <div className="text-[#E0DDFF] text-lg lg:text-xl font-light sm:mb-2">YOUR REFERRED VOLUME</div>
                  <div className="text-[rgba(224,221,255,0.7)] text-3xl lg:text-[2rem] xl:text-[2.5rem] font-semibold">
                    {dollarFormatter(totalUserVolume)}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-9 mb-8 sm:mb-12">

              <div className="sm:flex-1 bg-[rgba(255,255,255,0.05)] rounded-2xl h-28 flex items-center justify-center px-4 sm:px-0">
                <div className="text-center">
                  <div className="text-[#E0DDFF] text-base lg:text-base font-light">YOUR REFERRAL LINK</div>
                  <div className="text-[rgba(224,221,255,0.7)] text-[17px] lg:text-[17px] flex-col sm:flex-row font-medium flex items-center mx-2 justify-center gap-1">
                    <a
                      href={`#`}
                      onClick={copyToClipboard}
                      className="underline lg:no-underline lg:hover:underline lg:hover:cursor-pointer break-all"
                    >
                      {`${referralURL}?ref=${referralCode}`}
                    </a>
                    <button className="lg:cursor-pointer py-2 px-1" onClick={copyToClipboard}>
                      <img src={copyicon} alt="" />
                    </button>
                  </div>
                </div>
              </div>

              <div className="sm:flex-1 bg-[rgba(255,255,255,0.05)] rounded-2xl h-28 flex items-center justify-center px-4 sm:px-0">
                <div className="text-center">
                  <div className="text-[#E0DDFF] text-base lg:text-base font-light">WHO BROUGHT YOU?</div>
                  <div className="text-[rgba(224,221,255,0.7)] text-[17px] lg:text-[17px] font-medium flex items-center justify-center gap-2">
                    {loading ? (
                      <span
                        href={`slot?ref=${referralCode}`}
                        target="_blank"
                        rel="noreferrer"
                        className="underline lg:no-underline lg:hover:underline lg:hover:cursor-pointer"
                      >
                        checking...
                      </span>
                    ) :
                      isEditingReferrer ? (
                        <input
                          autoFocus
                          type="text"
                          value={referrerText}
                          className='bg-transparent border-0'
                          onChange={e => { setInputEvent(e); setReferrerText(e.target.value) }}
                          onBlur={handleEditReferrerButton}
                        />
                      ) : (
                        <span
                          href={`slot?ref=${referralCode}`}
                          target="_blank"
                          rel="noreferrer"
                          className="underline lg:no-underline lg:hover:underline lg:hover:cursor-pointer"
                        >
                          {referrerText}
                        </span>
                      )}

                    <button className="lg:cursor-pointer py-2 px-1" onClick={handleEditReferrerButton}>
                      <img width={16} src={editicon} alt="" />
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative p-4 w-[90%] mx-auto sm:hidden">
        <div className='flex justify-end'>
          <button onClick={scrollToTop} className="uppercase w-[58.96px] h-[58.96px] rounded-full flex items-center justify-center bg-[rgba(217, 217, 217, 0.1)] border-2 border-solid border-[rgba(10,130,182,0.7)] up-btn">
            Up
          </button>
        </div>
      </div>

      <div className="relative p-4 w-[90%] mx-auto bg-[rgba(20, 23, 24, 0.2)] py-[20px] mt-[150px] hidden sm:block">
        <div className='flex justify-end'>
          <div className='mr-[40px] flex'>
            <a className='mr-3' href='https://twitter.com/PepesGame' target='_blank'>
              <img src={twitter} />
            </a>
            <a className='mr-3' href='https://discord.com/invite/pepesgame' target='_blank'>
              <img src={discord} />
            </a>
            <a className='mr-3' href='https://medium.com/@pepesgame1' target='_blank'>
              <img src={medium} />
            </a>
            <a className='mr-3' href='https://pepes-game.gitbook.io/pepes-docs/' target='_blank'>
              <img src={gitbook} />
            </a>
            {/* <a className='mr-3' href='https://tinyurl.com/PG-Litepaper' target='_blank'>
              <img src={whitepaper} />
            </a> */}
            {/* <a className='mr-2' href='#' target='_blank'>
              <img src={socialIconVector} />
            </a> */}
          </div>

          <div className='flex'>
            {/* <a className='mr-[10px] text-sm text-[#ffffffb3]'>Privacy Policy</a> */}
            {/* <a className='mr-[10px] text-sm text-[#ffffffb3]'>Terms of Service</a> */}
            <a className='text-sm text-[#ffffffb3]'>Pepe's Game © 2023</a>
          </div>
        </div>
      </div>

      <PepeMobileFooter classes="pt-7" />
    </div>
  );
};

export default ReferralPage;
