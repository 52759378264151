import { Dialog, Transition } from '@headlessui/react';
import React, {Fragment, useContext} from "react";
import { useNavigate } from 'react-router-dom';
import info from "../../assets/images/information.svg"
import credits from "../../assets/images/B2 Attraction Button.svg"
import USDC from "../../assets/images/B2 Attraction Button (1).svg"
import { StoreContext } from '../../utils/store';

function ConnectSlot({ open, isOpen }) {
  const navigate = useNavigate();
  const store = useContext(StoreContext)
  const {setDepositWithdrawModal} = store

    //Method to close connect Slot Modal
    const closeModal = () => {
        open(false);
        navigate('/slot');
      };
  return (
  <>
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-[1000]' onClose={() => open(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className='fixed inset-0 overflow-y-auto bg-[rgba(0,0,0,0.75)] flex'>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 scale-95'
                    enterTo='opacity-100 scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 scale-100'
                    leaveTo='opacity-0 scale-95'
                >
                    <Dialog.Panel className='modal-dialog transition-all transform w-[681px] modal flex h-[377px] max-[480px]:w-4/5'>
                        <div className='mx-auto'>
                            <div className='flex items-center justify-center pt-5 text-6xl font-["Tourney"] font-extrabold gradient-text text-center'>
                                ZERO CREDITS
                            </div>
                            <p className="text-2xl font-normal text-white dark:text-gray-400 text-center py-5 barlow tracking-wide">
                                Deposit USDC to play
                            </p>
                            <div className='w-full flex justify-center text-white'>
                                <button 
                                className=''
                                onClick={() => {window.open('https://app.uniswap.org/#/tokens/arbitrum/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '_blank');}}
                                >
                                    <img src={USDC}/>
                                </button>
                                <button 
                                className=''
                                onClick={() => setDepositWithdrawModal(true)}
                                >
                                    <img src={credits} className='h-[74px]' alt="Add Credits"/>
                                </button>
                                {/* <button className="border-none cursor-pointer appearance-none bg-[inherit] refresh-btn">
                                    <img className="h-6 w-6" src={info} alt="Info" />
                                </button> */}
                            </div>
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition>
  </>
  )
};

export default ConnectSlot;
