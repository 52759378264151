import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useContext } from 'react';
import { cancel_icon_w_gradient, pepeFlex, smartPepe } from '../../assets';
import { StoreContext } from '../../utils/store';

const ClaimConfirmation = ({ isOpen, open }) => {
  const Store = useContext(StoreContext);
   const { lockingReward, esPegUnlockHash } = Store;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1000]" onClose={() => open(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-80" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-deep-blue/[0.89] flex justify-center items-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="modal-dialog transition-all transform w-[739px] h-[600px] stake-modal flex max-[480px]:w-4/5 relative">
              {/* main container */}
              <div className="flex flex-col justify-around w-full mx-auto py-2">
                <div className="flex items-end justify-end mr-4 -mt-5">
                  <button onClick={() => open(false)}>
                    <img src={cancel_icon_w_gradient} alt="cancel icon" width="15px" />
                  </button>
                </div>
                <div className='flex items-center justify-center text-7xl font-["Tourney"] font-extrabold gradient-text text-center'>
                  YEAH!
                </div>
                <div className="mb-40 text-center text-white barlow">
                  <p className="text-3xl">You have successfully claimed</p>
                  <p className='text-[43px] font-["Tourney"] font-extrabold gradient-text'>{`${lockingReward} PEG`}</p>
                  <p className="text-3xl">to your frenly dealer</p>
                  <a className="text-xs underline" target="__blank" href={'https://arbiscan.io/tx/' + esPegUnlockHash}>
                    See tx
                  </a>
                </div>
                <img src={smartPepe} className="absolute bottom-0 left-0" />
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ClaimConfirmation;
