import React from 'react';
import {ClaimAirdrop} from '../components/Claim';
import { useMediaQuery } from 'usehooks-ts';
import { main } from '../assets';
import Navbar from '../components/Navbar/Navbar';


const ClaimAirdropPLS=()=>{

    const query = useMediaQuery('(max-width: 640px)');

    return (
        <>
        <div
        className="items-center md:container md:mx-auto flex flex-col gap-24"
        style={{
          background: query ? '#09031D' : `url(${main}) no-repeat fixed`,
          backgroundSize: 'cover',
          display: 'flex',
          maxWidth: '1550',
          minWidth: '1330',
          maxHeight: '980',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
            <Navbar/>
            <ClaimAirdrop isPLS={true} />
      </div>
            
        </>
    )
}

export default ClaimAirdropPLS