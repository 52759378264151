import React, { useContext, useState, useReducer, useRef } from "react";
import "../styles/jackpotmode.css"
import PepeFooter from "../components/Pepe-footer/pepefooter";
import Settings from "../components/Settings/Settings";
import TopBar from "../components/top-nav/top-bar";
import Wrapper from "../components/Wrapper";
import { pepe_top } from '../assets';
import PlayButton from "../components/PlayButton/PlayButton";
import EvilSideBet from "../components/EvilSideBet/EvilSideBet";
import Jackpot from "../components/Jackpot/Jackpot";
import { StoreContext } from '../utils/store';
import { betReducer, INITIAL_STATE } from '../reducers/betReducer' // Importing betReducer and bet initial values
import { ACTION_TYPES } from '../actions/betActionTypes';
import { pepe_coin_jackpot } from '../assets';

function JackpotMode() {
    const [state, dispatch] = useReducer(betReducer, INITIAL_STATE)
    const vector = require("../assets/images/Vector.png")
    const Store = useContext(StoreContext)
    const { doRandomizeTradeVars, tradeVars, currency, time, leverage } = Store;

    const [currencySlots, setCurrencySlots] = useState(currency[0])
    const [timeSlots, setTimeSlots] = useState(time[0])
    const [leverageSlots, setLeverageSlots] = useState(leverage[0])
    const [rolling, setRolling] = useState(false)
    let slotRef = [useRef(null), useRef(null), useRef(null)];


    //Method to run bet, to trigger roolling and maintain state
    const runBet = () => {
        setRolling(true);

        doRandomizeTradeVars()

        // looping through all 3 slots to start rolling
        slotRef.forEach((slot, i) => {
            // this will trigger rolling effect
            if (i + 1 === 1) {
                triggerSlotRotation(slot.current, currency, tradeVars.currency);
                setCurrencySlots(tradeVars.currency);
            } else if (i + 1 === 2) {
                triggerSlotRotation(slot.current, leverage, tradeVars.leverage);
                setLeverageSlots(tradeVars.leverage);
            } else {
                triggerSlotRotation(slot.current, time, tradeVars.time);
                setTimeSlots(tradeVars.time);
            }
        });
    }

    //Method that will create a rolling effect
    const triggerSlotRotation = (ref, data, selectedValue) => {
        function setTop(top) {
            ref.style.top = `${top}px`;
        }
        let options = ref.children;
        let selectedOption = data.indexOf(selectedValue)
        let choosenOption = options[selectedOption];


        ref.classList.add('restart-slot') //For restarting slot position 
        ref.classList.remove('stop');  // Removing stop styling
        ref.classList.add('loop'); // Adding slot loop animation

        //Running loop animation for 7 seconds
        setTimeout(() => {
            ref.classList.remove('loop');
            ref.classList.add('stop');
            ref.classList.remove('restart-slot')

            setTradeVars();
            setTop(-choosenOption.offsetTop + 38);

            setRolling(false)
        }, 7000);
    };

    //Method to set tradevars values
    const setTradeVars = () => {
        dispatch({
            type: ACTION_TYPES.UPDATE_TRADEVARS,
            payload: {
                leverage: tradeVars.leverage,
                currency: tradeVars.currency,
                time: tradeVars.time,
                amount: tradeVars.amount,
                longShort: tradeVars.longShort
            }
        })
    }

    return (
        <Wrapper>
            <div className='overlay'></div>
            <TopBar classes='relative flex justify-end w-full h-[50px] top-bar top-bar-jackpot' red={true} />

            <div className='absolute -left-10 z-[1] logo'>
                <img src={pepe_top} className="w-auto h-52 object-contain" alt="Logo" />
            </div>
            <div className='absolute bg-sparkle bg-contain bg-no-repeat mix-blend-color-dodge w-[750px] h-3/4 top-0 z-[100] right-0 -scale-x-100 sparkle-1'></div>
            <div className='absolute bg-sparkle bg-contain bg-no-repeat mix-blend-color-dodge w-[750px] h-3/4 z-[200] left-0 top-0 sparkle-2'></div>
            <div className="absolute bg-local bg-no-repeat bg-contain z-50 w-20 h-[100px] first"></div>
            <div className="absolute bg-local bg-no-repeat bg-contain z-50 w-20 h-[100px] second"></div>
            <div className="absolute bg-local bg-no-repeat bg-contain z-50 w-20 h-[100px] third"></div>
            <div className="absolute bg-local bg-no-repeat bg-contain z-50 w-20 h-[100px] fourth"></div>

            <div className="flex flex-col items-end justify-end h-full w-[80%] slot-cover">
                <div className="bg-contain bg-center basis-2/3 relative z-10 bg-no-repeat w-full flex justify-center h-2/3 max-w-full bg-slotmachine">

                    <div className="flex flex-col mt-3 justify-start md:w-[50%] lg:w-[30%]">
                        <Jackpot amount="$1,388,000" />

                        <div className='slot-values'>


                            <div className="roller">
                                <section>
                                    <div id='currency' className="container slot1" ref={slotRef[0]}>
                                        {currency.map((value, i) => (
                                            <div key={i}>
                                                <span className={`${state.currency === value && rolling === false ? 'selectedSlot' : ''}`}>{value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            </div>

                            <div className="roller">
                                <section>
                                    <div id='leverage' className="container slot2" ref={slotRef[1]}>
                                        {leverage.map((value, i) => (
                                            <div key={i}>
                                                <span className={`${state.leverage === value && rolling === false ? 'selectedSlot' : ''}`}>{value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            </div>

                            <div className="roller">
                                <section>
                                    <div id='time' className="container slot3" ref={slotRef[2]}>
                                        {time.map((value, i) => (
                                            <div key={i}>
                                                <span className={`${state.time === value && rolling === false ? 'selectedSlot' : ''}`}>{value}s</span>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="flex slot-options-cover slot-option-jackpot relative z-10 justify-center self-center bg-glow bg-cover bg-no-repeat bg-center items-end lg:w-[50%] pb-[30px] pr-[80px] pl-[80px] h-[35%]">
                    <div className='jackpot-info'>
                        <div className="jackpot-info-section credit-section">
                            <div className="flex flex-wrap h-[38px] w-[60px] mx-5 text-white slot-option">
                                <span className="text-[10px] h-3.5 manrope text-pepe-blue slot-option-tile slot-option-title-jackpot">Your Credits</span>
                                <span className="manrope tracking-wide slot-option-value">$1.259</span>
                            </div>
                        </div>
                        <div className="jackpot-info-section text-section">
                            <p id='jackpot-info-text'>
                                Will you crack my boss’ seedphrase? spit in your hands and smash that f* button ...
                            </p>
                        </div>
                        <div className="jackpot-info-section btn-section">
                            <PlayButton text="PLAY" action={runBet} disabled={rolling} classes={'jackpot-btn'} />
                        </div>
                    </div>
                </div>
            </div>
            <Settings classes={'jackpot-settings'} red={true} />
            <div className='absolute bottom-0 left-0 pepefooter'>
                <img src={pepe_coin_jackpot} className="w-[300px] h-auto" alt="Pepe" />
            </div>
        </Wrapper >);
};

export default JackpotMode;
