import React from 'react';

const UPMobile = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling animation
    });
  };
  const scrollToElement = () => {
    const element = document.getElementById("top");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  

  return (
    <span onClick={() => {document.getElementById("top") ? (scrollToElement()) : (scrollToTop())}} className='cursor-pointer'>
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        // onClick={scrollToTop}
      >
        <circle
          cx="29.5202"
          cy="29.4939"
          r="28.6797"
          fill="#D9D9D9"
          fill-opacity="0.1"
          stroke="url(#paint0_linear_868_52942)"
          stroke-width="1.6"
        />
        <path
          d="M27.1326 22.9985H28.8434V32.3531C28.8434 33.3189 28.6158 34.1812 28.1605 34.94C27.7098 35.6943 27.0728 36.2899 26.2496 36.7268C25.4263 37.1591 24.4605 37.3752 23.3521 37.3752C22.2438 37.3752 21.278 37.1591 20.4547 36.7268C19.6315 36.2899 18.9922 35.6943 18.5369 34.94C18.0862 34.1812 17.8608 33.3189 17.8608 32.3531V22.9985H19.5717V32.2151C19.5717 32.9049 19.7235 33.5189 20.027 34.057C20.3305 34.5905 20.7629 35.0113 21.3239 35.3195C21.8896 35.623 22.5657 35.7748 23.3521 35.7748C24.1386 35.7748 24.8146 35.623 25.3803 35.3195C25.946 35.0113 26.3783 34.5905 26.6773 34.057C26.9808 33.5189 27.1326 32.9049 27.1326 32.2151V22.9985Z"
          fill="url(#paint1_linear_868_52942)"
        />
        <path
          d="M32.26 37.1269V22.9985H37.0338C38.1422 22.9985 39.0482 23.1986 39.7519 23.5987C40.4601 23.9942 40.9844 24.53 41.3248 25.2061C41.6651 25.8822 41.8352 26.6364 41.8352 27.4688C41.8352 28.3013 41.6651 29.0578 41.3248 29.7385C40.989 30.4191 40.4693 30.9618 39.7657 31.3666C39.062 31.7667 38.1606 31.9667 37.0614 31.9667H33.6397V30.449H37.0062C37.7651 30.449 38.3744 30.318 38.8344 30.0558C39.2943 29.7937 39.6277 29.4395 39.8347 28.9934C40.0462 28.5427 40.152 28.0345 40.152 27.4688C40.152 26.9032 40.0462 26.3973 39.8347 25.9511C39.6277 25.505 39.292 25.1555 38.8275 24.9026C38.3629 24.645 37.7467 24.5162 36.9786 24.5162H33.9708V37.1269H32.26Z"
          fill="url(#paint2_linear_868_52942)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_868_52942"
            x1="16.0621"
            y1="-23.1485"
            x2="45.8527"
            y2="72.8959"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.338542" stop-color="#00B7F0" />
            <stop offset="1" stop-color="#4819BE" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_868_52942"
            x1="14.6297"
            y1="20.9013"
            x2="77.8906"
            y2="19.0967"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#00B7F0" />
            <stop offset="1" stop-color="#4819BE" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_868_52942"
            x1="14.6297"
            y1="20.9013"
            x2="77.8906"
            y2="19.0967"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#00B7F0" />
            <stop offset="1" stop-color="#4819BE" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  );
};

export default UPMobile;
