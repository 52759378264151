import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useContext, useEffect, useReducer, useState } from 'react';
import { cancel_icon_w_gradient, pepe_top, smartPepeBlurred } from '../../assets';
import { StoreContext } from '../../utils/store';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi';
import axios from 'axios';
import { useStorage } from '../../utils/StorageContext';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'usehooks-ts';
import { api, autoSpin_API } from '../../constants';

const AutoSpinResult = ({ open, isOpen, numOfRounds, amountForAutoSpin, betToken }) => {
  const Store = useContext(StoreContext);
  const { setAutoSpinResult, autoSpinVars, setAutoSpinVars } = Store;
  const { autoSpinData, setAutoSpinData, removeAutoSpinData, setIsRunning } = useStorage();
  const navigate = useNavigate();
  const { address } = useAccount();
  const location = useLocation();

  const isMobile = useMediaQuery('(max-width: 640px)');

  const initialState = {
    btcLong: 0,
    btcShort: 0,
    ethShort: 0,
    ethLong: 0,
    num20: 0,
    num30: 0,
    num40: 0,
    tradesWon: 0,
    tradesLost: 0,
    totalAmountWon: 0,
  };
  const autoSpinResponseReducer = (state, action) => {
    switch (action.type) {
      case 'FETCH_AUTOSPIN_SUCCESS':
        return { ...action.payload };
      default:
        return state;
    }
  };
  // const [results, dispatch] = useReducer(autoSpinResponseReducer, initialState);
  const [results, setResults] = useState(initialState)

  useEffect(() => {
    if (!address) return;
    const getAutospinResults = async () => {
      try {
        // const limit = autoSpinData.rounds - autoSpinData.roundsLeft;
        // const { data } = await axios.get(
        //   `${autoSpin_API}/getautospinresults?address=${address}&id=${autoSpinData.autospin_id}`
        // );
        const { data } = await axios.get(
          `${api.offChainBet.getAutoSpinDataApiEndpoint}/getautospinresults?address=${address}&id=${autoSpinData.autospin_id}`
        );

        setResults({
          btcLong: data.data?.btcLongBets.data,
          btcShort: data.data?.btcShortBets.data,
          ethShort: data.data?.ethShortBets.data,
          ethLong: data.data?.ethLongBets.data,
          numberofRunTimes: data.data?.numberofRunTimes,
          num20: data.data?.numberofRunTimes?.[0]?.data,
          num30: data.data?.numberofRunTimes?.[1]?.data,
          num40: data.data?.numberofRunTimes?.[2]?.data,
          tradesWon: data.data?.tradesWon.data,
          tradesLost: data.data?.tradesLost.data,
          totalAmountWon: data.data?.totalAmountWon.data,
          totalPoints: data.data?.totalPoints.data
        })
        console.log('auto spin result:: ', data.data)
        console.log('Autospin results: ', data.data?.numberOfRunTimes);

        // dispatch({
        //   type: 'FETCH_AUTOSPIN_SUCCESS',
        //   payload: {
        //     btcLong: data?.btcLongBets.data,
        //     btcShort: data?.btcShortBets.data,
        //     ethShort: data?.ethShortBets.data,
        //     ethLong: data?.ethLongBets.data,
        //     num20: data?.numberofRunTimes[0].data,
        //     num30: data?.numberofRunTimes[1].data,
        //     num40: data?.numberofRunTimes[2].data,
        //     tradesWon: data?.tradesWon.data,
        //     tradesLost: data?.tradesLost.data,
        //     totalAmountWon: data?.totalAmountWon.data,
        //   },
        // });
      } catch (error) {
        console.log({ error });
      }
    };
    // setTimeout(() => {
    getAutospinResults();
    // }, 3000)

  }, [address]);

  const handleReAutoSpin = async () => {
    setIsRunning(false)
    if (autoSpinData.isAuto) {
      console.log('calling')
      const { isAuto, amount, rounds } = autoSpinData;
      setAutoSpinData({ isAuto, amount, rounds, roundsLeft: rounds, autospin_id: (await nanoid(10)) + `_1_${rounds}`, isPlay: true, isStop: false, isStopAt: 0 });
      setAutoSpinVars(autoSpinData.isAuto, autoSpinData.amount, autoSpinData.rounds, autoSpinData.rounds);
      setAutoSpinResult(false);
      if (location != '/slot') {
        navigate('/slot');
      }
      window.location.reload();
    }
  };

  const handleCancelClick = () => {
    setAutoSpinResult(false);
    removeAutoSpinData();
    setAutoSpinVars(false, 0, 0, 0);
    setAutoSpinData({});
    setIsRunning(false)

    if (location != '/slot') {
      navigate('/slot');
    }
    // open(false);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto overflow-x-hidden pb-[114px] pt-[214px] sm:py-0 bg-[rgba(0,0,0,0.75)] flex">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={"transition-all transform w-[120%] sm:w-[700px] autospin-bg flex flex-col sm:scale-[0.8] sm:h-auto sm:mx-0 sm:mt-0"} style={{ margin: `${isMobile ? "auto -10px" : "auto"}` }}>
                <div className="absolute -top-[214px] sm:-top-28 flex justify-center z-[100] pointer-events-none w-full">
                  <img src={pepe_top} className="object-contain w-auto h-64 " alt="Logo" />
                </div>
                <div className="coins bg-no-repeat bg-contain h-full w-[500px] absolute pointer-events-none z-[999] sm:z-20 -top-[95px] sm:-top-5 right-[-312px] sm:right-[-233px]" />

                <div className="flex justify-end w-full h-12 pr-4 pt-3">
                  <button className="cursor cursor-pointer w-6 h-6">
                    <img src={cancel_icon_w_gradient} alt="cancelIcon" onClick={handleCancelClick} />
                  </button>
                </div>
                <div className={`mx-auto w-[90%] sm:w-[70%] sm:min-w-[460px] relative ${isMobile}`}>
                  <div className='flex items-center justify-center pt-12 text-4xl font-["Tourney"] font-medium text-white text-center'>
                    Well done, degen!
                  </div>
                  {/* <p className="text-xl font-normal text-white dark:text-gray-400 text-center barlow tracking-wide">
                    Let's tally up the scores
                  </p> */}
                  <div className="flex flex-col justify-center text-white mx-auto result-btn-bg rounded-xl mt-4 py-[70px] gap-[30px] ">
                    {/* <div className="self-center p-4 font-extrabold text-center rounded-xl w-full autospin-header">
                      <span className="text-5xl sm:text-7xl font-extrabold tracking-widest autospin-text barlow">
                        +12,555.43
                        <span className="text-3xl">%</span>
                      </span>
                    </div> */}

                    {/* old design */}
                    {/* <div className=" text-lg rounded-b-xl flex flex-col py-4">
                      <p className=" uppercase text-lg text-center mt-2">
                        Autospin: ${amountForAutoSpin} x {numOfRounds} rounds
                      </p>
                      <div className="flex justify-between px-8 mb-2 sm:mb-0">
                        <p>
                          <span className="text-[#83828C] mr-2 uppercase">Eth Long</span>
                          <span>{results?.ethLong}</span>
                        </p>

                        <p>
                          <span className="text-[#83828C] uppercase mr-2">Trades won</span>
                          <span>{results?.tradesWon}</span>
                        </p>
                      </div>
                      <div className="flex justify-between px-8 flex-wrap mb-2 sm:mb-0">
                        <p>
                          <span className="text-[#83828C] mr-2 uppercase">Eth Short</span>
                          <span>{results?.ethShort}</span>
                        </p>

                        <p>
                          <span className="uppercase mr-2 text-[#83828C]">Trades lost</span>
                          <span>{results?.tradesLost}</span>
                        </p>
                      </div>
                      <div className="flex justify-between px-8 mb-2 flex-wrap">
                        <p>
                          <span className="text-[#83828C] mr-2 uppercase">Btc Long</span>
                          <span>{results?.btcLong}</span>
                        </p>

                        <p>
                          <span className="uppercase mr-2 text-[#83828C]">Ratio W/L</span>
                          <span>
                            {results?.tradesWon}/{results?.tradesLost}
                          </span>
                        </p>
                      </div>
                      <div className="flex justify-between px-8 mb-2 flex-wrap">
                        <p>

                        </p>

                        <p>
                          <span className="uppercase mr-2 text-[#83828C]">Bet Token</span>
                          <span>
                            {betToken}
                          </span>
                        </p>
                      </div>
                      <div className="flex justify-between px-8 flex-wrap">
                        <p>
                          <span className="text-[#83828C] mr-2 uppercase">Btc Short</span>
                          <span>{results?.btcShort}</span>
                        </p>

                        <p>
                          <span className="uppercase mr-2">Total</span>
                          <span>+${parseFloat(results?.totalAmountWon).toFixed(3)}</span>
                        </p>
                      </div>
                      <div className="flex justify-start px-8 mb-0 sm:mb-2"></div>

                      {/* <div className="flex flex-col px-8 mt-4">
                        <p>
                          <span className="text-[#83828C] mr-2">20s</span>
                          <span>{results?.num20 !== undefined ? results?.num20 : 0}</span>
                        </p>
                        <p>
                          <span className="text-[#83828C] mr-2">30s</span>
                          <span>{results?.num20 !== undefined ? results?.num30 : 0}</span>
                        </p>
                        <p>
                          <span className="text-[#83828C] mr-2">40s</span>
                          <span>{results?.num20 !== undefined ? results?.num40 : 0}</span>
                        </p>
                      </div> 

                      <div className="flex flex-col px-8 mt-4">
                        {results?.numberofRunTimes?.map((entry) => (
                          <p key={entry.runTime}>
                            <span className="text-[#83828C] mr-2">{entry.runTime}s</span>
                            <span>{entry?.data}</span>
                          </p>
                        ))}
                      </div>

                        </div> */}

                    {/* new design */}
                    <>
                      <div className="text-center text-xl barlow ">
                        CONGRATS <br />
                        YOU WON <span>{results?.tradesWon}/{numOfRounds}</span> ROUNDS
                      </div>
                      <div className='flex flex-col justify-center items-center gap-3'>
                        <span className=' text-7xl font-["Tourney"] font-medium'>+${results?.totalAmountWon > 0 ? parseFloat(results?.totalAmountWon).toFixed(2) : "0.00"}</span>
                        <span className='text-[#18ADFA] text-lg barlow '>YOUR POINTS +{results?.totalPoints}</span>
                      </div>
                    </>
                  </div>
                  <button className="flex mx-auto w-[276px] my-8 respin-button barlow" onClick={handleReAutoSpin}>
                    <span>Re-Autospin</span>
                  </button>
                </div>
                <img src={smartPepeBlurred} className={`absolute bottom-0 left-[2px] w-[150px] pointer-events-none ${isMobile && "hidden"}`} />
                <button
                  className="absolute bottom-[-50px] left-2/5 mx-auto flex restart-button barlow"
                  onClick={handleCancelClick}
                >
                  <span>Close</span>
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AutoSpinResult;
