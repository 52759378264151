import React, { useContext } from 'react';

import { cancel_icon_w_gradient as CancelIcon, twitter, discord, whitepaper, pls_airdrop, pills, pepe_pills } from '../assets';
import pills_base from '../assets/images/pills_base.svg'
import XIcon from '../assets/icons/XIcon.svg'
import { Link, useNavigate } from 'react-router-dom';
import { StoreContext } from '../utils/store';
import { CreditsContext } from '../utils/creditsContext';
import { useAccount, useNetwork } from 'wagmi';


import LeaderBoard from './../pages/leaderboard';
import TwitterIcon from './TwitterIcon';
import DiscordIcon from './DiscordIcon';
import MediumIcon from './MediumIcon';
import GitbookIcon from './GitbookIcon';
import { useStorage } from '../utils/StorageContext';



export const SideMenu = () => {
  const store = useContext(StoreContext);
  const creditsContext = useContext(CreditsContext);
  const { setSideMenu, setShowHowToPlay, setQuestsModal } = store;
  const { questChips } = creditsContext;
  const formatter = new Intl.NumberFormat('en-US');
  const { isConnected } = useAccount();
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const navigate = useNavigate();

  const toDashboard = () => {
    const dashboardLink = "https://dashboard.pepesgame.com/";
    window.open(dashboardLink, "_blank");
  }

  return (
    <>
      <ul
        id="menu"
        className="fixed top-0 left-0 pb-16 bg-deep-blue z-[10001] h-full w-full sm:w-80 manrope text-lg font-medium overflow-auto"
      >
        <li className="flex justify-end py-4 pr-4">
          <span
            // add closing logic here
            onClick={() => setSideMenu(false)}
            className="text-6xl text-white cursor-pointer gradient-text"
          >
            <img src={CancelIcon} alt="" />
          </span>
        </li>

        {isConnected && (
          <li className="barlow pl-12 pb-4 text-[12px] font-bold text-white lg:hidden">
            <span className="flex items-center justify-start px-5 py-0.5 rounded-lg bg-[#FFFFFF1A] w-fit">
              <span>{formatter.format(questChips)}</span>
              <div className="w-1" />
              <img className="w-5 h-auto" src={arbsOrBase === 'base' ? pills_base : pills} alt="" />
            </span>
          </li>
        )}

        <li className="barlow pl-12 text-[12px] font-bold text-white">GAME</li>
        <li
          className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
          onClick={() => {
            navigate('/slot');
            return setSideMenu(false);
          }}
        >
          <span className="text-white duration-300 opacity-100 gradient-text-b">Play</span>
        </li>
        <li
          className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
          onClick={() => {
            navigate('/recent-plays');
            return setSideMenu(false);
          }}
        >
          <span className="text-white duration-300 opacity-100 gradient-text-b">History</span>
        </li>

        <li
          className="lg:block py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint hover:cursor-pointer"
          onClick={() => {
            setSideMenu(false);
            toDashboard();;
          }}
        >
          <span className="text-white duration-300 opacity-100 gradient-text-b">Dashboard</span>
          <span className="font-normal ml-[10px] text-[13px] leading-[21px] tracking-[-0.02em] text-[#ECE9F3] px-[6px] py-1 bg-gradient-to-br from-[rgba(85,52,126,0.8)] to-[rgba(34,23,65,0.9)] rounded">
            NEW!
          </span>
        </li>

        <li
          className="lg:block py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint hover:cursor-pointer"
          onClick={() => {
            setSideMenu(false);
            return setQuestsModal(true);
          }}
        >
          {/* change span tag to link tag and remove `coming-soon` class when link is available. link should open in new tab for good UX */}
          <span className="text-white duration-300 opacity-100 gradient-text-b">Pepe&apos;s Pills</span>
          {/* <span className="font-normal ml-[10px] text-[13px] leading-[21px] tracking-[-0.02em] text-[#ECE9F3] px-[6px] py-1 bg-gradient-to-br from-[rgba(85,52,126,0.8)] to-[rgba(34,23,65,0.9)] rounded">
            NEW!
          </span> */}
        </li>

        <li
          className="lg:block py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint hover:cursor-pointer"
          onClick={() => {
            setSideMenu(false);
            return setShowHowToPlay(true);
          }}
        >
          {/* change span tag to link tag and remove `coming-soon` class when link is available. link should open in new tab for good UX */}
          <span className="text-white duration-300 opacity-100 gradient-text-b">How to Play</span>
        </li>

        {/* new section */}
        <li className="barlow pl-12 text-[12px] font-bold text-white mt-8">CAMPAIGNS</li>
        <li
          className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
          onClick={() => {
            navigate('/degen-dashboard');
            return setSideMenu(false);
          }}
        >
          <span className="text-white duration-300 opacity-100 gradient-text-b">Degens of the Week</span>
          {/* <span className="font-normal ml-[10px] text-[13px] leading-[21px] tracking-[-0.02em] text-[#ECE9F3] px-[6px] py-1 bg-gradient-to-br from-[rgba(85,52,126,0.8)] to-[rgba(34,23,65,0.9)] rounded">
            NEW!
          </span> */}
        </li>
        {/* <li
          className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
          onClick={() => {
            navigate('/referrals');
            return setSideMenu(false);
          }}
        >
          <span className="text-white duration-300 opacity-100 gradient-text-b">Bring Your Own Degen</span>
        </li> */}


        {/* new section */}
        <li className="barlow pl-12 text-[12px] font-bold text-white mt-8">EARN</li>

        <li
              className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
              onClick={() => {
                navigate('/stake', { state: 'stake' });
                return setSideMenu(false);
              }}
            >
              <span className="text-white duration-300 opacity-100 gradient-text-b">Stake</span>
        </li>

        {chain?.id == 42161 &&
          <>
            
            <li
              className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
              onClick={() => {
                navigate('/lock', { state: 'lock' });
                return setSideMenu(false);
              }}
            >
              <span className="text-white duration-300 opacity-100 gradient-text-b">Lock / LP</span>
            </li>
            <li
              className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
              onClick={() => {
                navigate('/claim', { state: 'claim' });
                return setSideMenu(false);
              }}
            >
              <span className="text-white duration-300 opacity-100 gradient-text-b ">Claim Airdrops</span>
            </li>
            <li
              className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
              onClick={() => {
                navigate('/claimpls');
                return setSideMenu(false);
              }}
            >
              <span className="flex justify-start items-center gap-2">
                <img src={pls_airdrop} alt="" className="w-4 h-auto" />
                <span className="text-white duration-300 opacity-100 gradient-text-b">PLS Asset Airdrops</span>
              </span>
            </li>
          </>
        }

        <li
          className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
          onClick={() => {
            if (arbsOrBase === 'base') {
              window.open(
                'https://app.uniswap.org/#/swap?chain=base',
                '_blank'
              );
            }else{
              window.open(
                'https://app.balancer.fi/#/arbitrum/swap/eth/0x4fc2a3fb655847b7b72e19eaa2f10fdb5c2addbe',
                '_blank'
              );
            }
           
            return setSideMenu(false);
          }}
        >
          <span className="text-white duration-300 opacity-100 gradient-text-b">Buy PEG</span>
        </li>

        {/* new section */}
        <li className="barlow pl-12 text-[12px] font-bold text-white mt-8">INFO</li>
        <li
          className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
          onClick={() => {
            window.open('https://tinyurl.com/PG-Litepaper', '_blank');
            return setSideMenu(false);
          }}
        >
          <span className="text-white duration-300 opacity-100 gradient-text-b">Lite Paper</span>
        </li>
        <li
          className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
          onClick={() => {
            window.open('https://pepes-game.gitbook.io/pepes-docs', '_blank');
            return setSideMenu(false);
          }}
        >
          <span className="text-white duration-300 opacity-100 gradient-text-b">Gitbook</span>
        </li>
        <li
          className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
          onClick={() => {
            window.open('https://docs.google.com/document/d/e/2PACX-1vTmZSQA6MgkmAyM9P7cJrraa_FLqWTKmBjPIkfycIub8kKFqP_rH6KaO8dRbBUYu5GkGyG063jSWfPt/pub', '_blank');
            return setSideMenu(false);
          }}
        >
          <span className="text-white duration-300 opacity-100 gradient-text-b">Terms Of Service</span>
        </li>
        <li
          className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
          onClick={() => {
            window.open('https://docs.google.com/document/d/e/2PACX-1vSkFH0xvDpaWriy2PxPkcVpfVV08lvlFpVIpqey7NzmlE5iK8dFCBDJZjLPmsk2Fq6ktB13O_Kc9toy/pub', '_blank');
            return setSideMenu(false);
          }}
        >
          <span className="text-white duration-300 opacity-100 gradient-text-b">Privacy Policy</span>
        </li>
        <li
          className="py-2 pl-12 hover:bg-gradient-to-r from-bluetint to-purpletint cursor-pointer"
          onClick={() => {
            window.open('https://docs.google.com/document/d/e/2PACX-1vSsRy5PEbd_cay0rqcdthJld2oYAKwvIfWYfLFD3ROGnPQPQ5xGjUEfVaWsnhrlEU2RGeBiWqUd5bdf/pub', '_blank');
            return setSideMenu(false);
          }}
        >
          <span className="text-white duration-300 opacity-100 gradient-text-b">Disclaimer</span>
        </li>

        <ul className="flex justify-start items-center gap-[18px] mt-6 ml-12">
          <li>
            <span
              className="hover:cursor-pointer"
              onClick={() => {
                window.open('https://twitter.com/PepesGame', '_blank');
                return setSideMenu(false);
              }}
            >
              <img src={XIcon} alt="twitter icon" className="w-[22px] h-[16px]" />
              {/* <TwitterIcon /> */}
            </span>
          </li>
          <li>
            <span
              className="hover:cursor-pointer"
              onClick={() => {
                window.open('https://discord.gg/pepesgame', '_blank');
                return setSideMenu(false);
              }}
            >
              {/* <img src={discord} alt="discord icon" className="w-[30px] h-[30px]" /> */}
              <DiscordIcon />
            </span>
          </li>
          <li>
            <span
              className="hover:cursor-pointer"
              onClick={() => {
                window.open('https://medium.com/@pepesgame1', '_blank');
                return setSideMenu(false);
              }}
            >
              {/* <img src={whitepaper} className="w-[30px] h-[30px]" alt="whitepaper icon" /> */}
              <MediumIcon />
            </span>
          </li>
          <li>
            <span
              className="hover:cursor-pointer"
              onClick={() => {
                window.open('https://pepes-game.gitbook.io/pepes-docs/', '_blank');
                return setSideMenu(false);
              }}
            >
              {/* <img src={whitepaper} className="w-[30px] h-[30px]" alt="whitepaper icon" /> */}
              <GitbookIcon />
            </span>
          </li>
        </ul>
      </ul>
    </>
  );
};
