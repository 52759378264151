import React from 'react';
import './GradientBox.css'

const GradientBox = ({ classes = 'relative', children = <></>, roundedClass='rounded-md' }) => {
  return (
    <div className={`z-10 ${classes}`}>
      <div className={`relative ${roundedClass} bg-[#0A0420] gradient-box-container`}>{children}</div>
    </div>
  );
};

export default GradientBox;
