import React, { useEffect, useLayoutEffect, useRef, useState, useContext } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useDispatch, useSelector } from 'react-redux';
import { setGameDataPractice, setNewPricePractice } from '../../features/chartDataPractice/chartDataPracticeSlice';
import { useNavigate } from 'react-router-dom';
import start from '../../assets/icons/start.svg';
import clock from '../../assets/icons/clockChart.svg';
import finish from '../../assets/icons/finish.svg';
import upIcon from '../../assets/images/up-icon.png';
import downIcon from '../../assets/images/up-icon.png';
import tagUpLabel from '../../assets/images/tag-up-label.png';
import tagDownLabel from '../../assets/images/tag-down-label.png';
import tagUpLabelMobile from '../../assets/images/tag-up-label.png';
import tagDownLabelMobile from '../../assets/images/tag-down-label.png';
import { useSocketContext } from '../../utils/SocketProvider';
import { useStorage } from '../../utils/StorageContext';
import rocket_down from '../../assets/icons/rocket_down.png';
import rocket_up from '../../assets/icons/rocket_up.png';
import rocket_icon_up from '../../assets/images/rocket-move-up.png';
import rocket_icon_down from '../../assets/images/rocket-move-down.png';
import rocket_icon_equal from '../../assets/images/rocket-move-equal.png';
import rocket_icon_mini from '../../assets/images/rocket-move-equal-mini.png';
import rocket_icon_mini_blue from '../../assets/images/rocket-move-equal-mini_blue.png'
import rocket_line from '../../assets/icons/rocket_line.png';
import { getNewPrice } from '../../services/service';
import { StoreContext } from '../../utils/store';
import axios from 'axios';
import { useMediaQuery } from 'usehooks-ts';

import { Connection, PublicKey } from '@solana/web3.js'
import { getPythClusterApiUrl, getPythProgramKeyForCluster, PythCluster, PriceStatus, PythConnection } from '@pythnetwork/client'

const PYTHNET_CLUSTER_NAME = 'pythnet'
const connection = new Connection(getPythClusterApiUrl(PYTHNET_CLUSTER_NAME))
const pythPublicKey = getPythProgramKeyForCluster(PYTHNET_CLUSTER_NAME)
const ETHUSD = 'JBu1AL4obBcCMqKBBxhpWCNUt136ijcuMZLFvTP7iWdB';
const BTCUSD = 'GVXRSBjFk6e6J3NbVPXohDJetcTjaeeuykUpbQF8UoMU';

const feeds = [new PublicKey(ETHUSD), new PublicKey(BTCUSD)]
const pythConnection = new PythConnection(connection, pythPublicKey, 'confirmed', feeds)

let data;
// let hoverType;
var range;
let valueClose;
let max;
let min;
let rangeUpDown;
let refresh;
let startPrice;

let dataGame = '';

let valueLabel = tagUpLabel;
let valueLabelMobile = tagUpLabelMobile;
let valueIcon = upIcon;
let valueColor = 0x18adfa;

const ROOM_NAME = 'PRACTICE1M';
const GAME_ID = 'e05d1d6a-916bSulemana Awal-41e2-a6be-ab7b431cbbed';

// const dataObject = {
//   currency: 'BTCUSDT',
// };

const CurrentPhase = {
  PREPARE: 'PREPARE',
  BETTING: 'BETTING',
  IN_GAMING: 'IN_GAMING',
  WAITING_NEXT_GAME: 'WAITING_NEXT_GAME',
};

const getHistoricPrices = async (currency) => {
  const formatValue = (el) => {
    return {
      date: Number(el?.time)*1000,
      value: Number(el?.price),
    };
  };

  const resp = await axios.get(`https://api.coinbase.com/v2/prices/${currency}-USD/historic?days=3`);
  data = resp?.data?.data.prices.map((el) => formatValue(el)) ?? [];
  // console.log(data)
};

let startTime, startBettingTime, endBettingTime, endTime2;

function Chart3(props) {
  const { arbsOrBase } = useStorage();
  const isMobile = useMediaQuery('(max-width: 640px)');
  const [loading, setLoading] = useState(true);
  const [chartRunTime, setChartRunTime] = useState()
  //const dispatch = useDispatch();
  const rootRef = useRef(null);
  const series1Ref = useRef(null);
  const xAxisRef = useRef(null);
  const yAxisRef = useRef(null);
  let temp_array = [];
  //const [temp_array, setTempArray] = useState([])
  //const { socket } = useSocketContext();
  const navigate = useNavigate();
  const [locationKeys, setLocationKeys] = useState([]);

  let newSocket = null;

  const [newprice, setNewPrice] = useState(0.0);

  const Store = useContext(StoreContext);

  const { startStoreTime, setStartingPrice, currentPrice, currentPricea, setPrice, lambdaResponseData, tradeVars } = Store;


  //const bettingInfoData = useSelector((state) => state.dataChartPractice.bettingInfo);



  const getPriceFeedWithWebSocket = () => {
    pythConnection.stop()
    //const currency = tradeVars.currency?`Crypto.${tradeVars.currency}/USD`:`Crypto.ETH/USD`
    const currency = `Crypto.${tradeVars.currency}/USD`

    pythConnection.onPriceChangeVerbose((productAccount, priceAccount) => {
      // The arguments to the callback include solana account information / the update slot if you need it.
      const product = productAccount.accountInfo.data.product
      const price = priceAccount.accountInfo.data
      //console.log(currency)
      if (product.symbol == currency) {
        if (price.price) {
          // tslint:disable-next-line:no-console
          // console.log({currency,temp_array})

          temp_array = [...temp_array, price]


        } else {
          // tslint:disable-next-line:no-console
          console.log(`${product.symbol}: price currently unavailable. status is ${PriceStatus[price.status]}`)
        }
      }

    })

    // tslint:disable-next-line:no-console
    console.log('Reading from Pyth price feed...')
    pythConnection.start()
  }



  useLayoutEffect(() => {
    const formatValue = (el) => {
      return {
        date: new Date(el?.timeStamp).getTime(),
        value: el?.price,
      };
    };

    setChartRunTime(new Date().getTime())
    getHistoricPrices(tradeVars.currency)

  }, []);

  useLayoutEffect(() => {
    let root = am5.Root.new('chartdiv', {
      useSafeResolution: false,
    });
    getPriceFeedWithWebSocket();
    root.setThemes([am5themes_Animated.new(root)]);

    root._logo.dispose();

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        //panX: true,
        panY: true,
        zoomX: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
      })
    );

    let easing = am5.ease.sine;
    chart.zoomOutButton.set('forceHidden', true);

    // Create axes
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.5,
        // @ts-ignore
        minZoomCount: data?.length >= 400 ? 60 : 20,
        maxZoomCount: 900,
        start: 0.4,
        extraMax: 0.1, // this adds some space in front
        extraMin: -0.1, // this removes some space form th beginning so that the line would not be cut off
        baseInterval: {
          timeUnit: 'second',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 100,
        }),
        // tooltip: am5.Tooltip.new(root, {}),
        tooltip: null,
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxPrecision: 3,
        // minZoomCount: data?.length >= 400 ? 100 : 50,
        // maxZoomCount: 900,
        // strictMinMax:true,
        // zoomY:true,
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
          minGridDistance: 50,
          pan: 'zoom',
        }),
      })
    );

    let yRenderer = yAxis.get('renderer');
    yRenderer.grid.template.setAll({
      stroke: am5.color(0xEEEEEE),
      strokeWidth: 1,
      opacity: 0.5
    });

    yRenderer.labels.template.setAll({
      fill: am5.color(0xEEEEEE),
      fontSize: isMobile ? '10px' : '12px',
      fontWeight: '500',
      paddingRight: isMobile ? 5 : 10,
      opacity: 0.5
    });

    let xRenderer = xAxis.get('renderer');
    xRenderer.grid.template.setAll({
      stroke: am5.color(0xEEEEEE),
      strokeWidth: 1,
      opacity: 0.5
    });

    xRenderer.labels.template.setAll({
      fill: am5.color(0xEEEEEE),
      fontSize: isMobile ? '8px' : '12px',
      fontWeight: '500',
      paddingTop: isMobile ? 22 : 25,
    });

    xRenderer.labels.template.set('visible', false);

    // Add series
    let series = chart?.series?.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        // locationX: 0,
        fill: am5.color(0xEEEEEE),
        stroke: am5.color(0xEEEEEE),
        opacity: 0.5,
        // tooltip: am5.Tooltip.new(root, {
        //   pointerOrientation: 'horizontal',
        //   labelText: '{valueY}',
        // }),
        tooltip: null,
        // tension: 0.8
      })
    );
    // @ts-ignore
    if (data && data?.length > 0) {
      // @ts-ignore
      series.data.setAll(data);
    }

    series.fills.template.set(
      'fillGradient',
      am5.LinearGradient.new(root, {
        stops: [
          {
            color: am5.color(0xffffff),
            opacity: 0.3,
          },
          {
            color: am5.color(0xffffff),
            opacity: 0,
          },
        ],
      })
    );

    series.bullets.push(function (root, series, dataItem) {
      let lastDataItem = series.dataItems[series.dataItems.length - 1];
      if (lastDataItem?.uid === dataItem?.uid) {
        let container = am5.Container.new(root, {
          // radius: 5,
          // fill: series.get("fill"),
          // stroke: root.interfaceColors.get("background"),
          // strokeWidth: 2,
          // tooltipText: "{valueY}",
          // showTooltipOn: "always",
          // pointerOrientation: "horizontal",
          // tooltip: am5.Tooltip.new(root, {})
        });

        let rocket = container.children.push(
          am5.Picture.new(root, {
            layer: 0,
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            scale: 0.6,
            src: arbsOrBase == 'base' ? rocket_icon_mini_blue : rocket_icon_mini,
            width: isMobile ? 90 : 130,
            height: isMobile ? 90 : 130,
          })
        );

        return am5.Bullet.new(root, {
          locationX: undefined,
          sprite: rocket,
          stateAnimationDuration: 5,
          stateAnimationEasing: am5.ease.inOut(am5.ease.sine)
        });
      }
    });

    // series.bullets.push(function (root, series, dataItem) {
    //   let lastDataItem = series.dataItems[series.dataItems.length - 1];
    //   if (lastDataItem?.uid === dataItem?.uid) {
    //     return am5.Bullet.new(root, {
    //       locationX: undefined,
    //       // sprite: container,
    //       sprite: am5.Picture.new(root, {
    //         width: 34,
    //         height: 34,
    //         layer: 2,
    //         // x: am5.percent(30),
    //         // y: am5.percent(50),
    //         // centerX: am5.percent(50),
    //         // centerY: am5.percent(50),
    //         // src: isGrown() ? rocket_down : rocket_down,
    //         src: rocket_down,
    //       }),
    //     });
    //   }
    // });

    function createRange(value, endValue, color) {
      var rangeDataItem = yAxis.makeDataItem({
        value: value,
        endValue: endValue,
      });

      // if (!isMobile) {
      //   rangeDataItem.set(
      //     'bullet',
      //     am5xy.AxisBullet.new(root, {
      //       sprite: am5.Picture.new(root, {
      //         layer: 0,
      //         centerX: am5.percent(15),
      //         centerY: am5.percent(50),
      //         src: rocket_down,
      //       }),
      //     })
      //   );
      // }

      range = yAxis.createAxisRange(rangeDataItem);

      range.get('label').setAll({
        fill: am5.color(0xffffff),
        layer: 1,
        text: value,
        fontSize: isMobile ? '10px' : '14px',
        fontWeight: 500,
        background: am5.Rectangle.new(root, {
          fill: color,
        }),
      });

      range.get('grid').setAll({
        stroke: color,
        strokeOpacity: 1,
        location: 1,
        strokeDasharray: [3.3],
      });
    }
    // @ts-ignore
    if (data && data?.length > 0) {
      // @ts-ignore
      createRange(data[data?.length - 1].value, undefined, am5.color('#18ADFA'));
    }

    var rangeX;
    function createRangeX(value, endValue, color, image, topY) {
      var rangeDataItem = xAxis.makeDataItem({
        // @ts-ignore
        value: value,
        // @ts-ignore
        endValue: endValue,
      });

      rangeX = xAxis.createAxisRange(rangeDataItem);

      rangeDataItem.set(
        'bullet',
        am5xy.AxisBullet.new(root, {
          sprite: am5.Picture.new(root, {
            centerX: am5.percent(50),
            centerY: topY,
            src: image,
          }),
        })
      );

      if (endValue) {
        rangeX.get('axisFill').setAll({
          fill: color,
          fillOpacity: 0.2,
          visible: true,
        });

        rangeX.get('label').setAll({
          fill: am5.color(0xffffff),
          text: value + '-' + endValue,
          location: 1,
          background: am5.RoundedRectangle.new(root, {
            fill: color,
          }),
        });
      } else {
        rangeX.get('label')?.setAll({
          fill: am5.color(0xffffff),
          width: 20,
          height: 20,
          // borderRadius: "50%",
          background: am5.RoundedRectangle.new(root, {
            fill: color,
          }),
        });
      }

      rangeX.get('grid')?.setAll({
        stroke: color,
        strokeOpacity: 1,
        location: 1,
      });
    }

    // Add cursor
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
      })
    );

    cursor.lineX.setAll({
      // stroke: am5.color(0x555555),
      stroke: am5.color(0xEEEEEE),
      strokeDasharray: [5],
      opacity: 0.5
    });

    cursor.lineY.setAll({
      stroke: am5.color(0xEEEEEE),
      strokeDasharray: [5],
      opacity: 0.5
    });

    series.events.once('datavalidated', function (ev) {
      //pre-zooming to last date
      var startDate = data[0].date;
      // @ts-ignore
      if (data?.length >= 130) {
        // @ts-ignore
        startDate = data[data?.length - 130].date;
      }

      ev?.target.get('xAxis').zoomToDates(
        new Date(startDate),
        // @ts-ignore
        new Date(data[data?.length - 1].date + 36000)
      );
    });

    var seriesPoint;
    function createSeriesPoint(name, field) {
      seriesPoint = chart?.series?.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          // dx: -4,
          // locationX: 0,
          fill: am5.color(valueColor),
          // stroke: am5.color(valueColor),
          valueYField: field,
          valueXField: 'date',
        })
      );

      seriesPoint?.data.setAll(dataPoint);

      seriesPoint.bullets.push(function (root, series, dataItem) {
        if (dataItem.dataContext.bullet) {
          let tooltip = am5.Tooltip.new(root, {
            pointerOrientation: 'right',
            // height: 20,
            // paddingTop: 3,
            // paddingBottom: 3,
            animationDuration: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingLeft: 0,
            paddingBottom: 0,
            dy: 3,
            dx: 2,
            getStrokeFromSprite: true,
            autoTextColor: false,
            html: `<img src=${isMobile ? valueLabelMobile : valueLabel} alt=''/>`,
          });

          tooltip.get('background').setAll({
            fill: am5.color(valueColor),
            fillOpacity: 0,
            strokeOpacity: 0,
          });

          tooltip.label.setAll({
            fill: am5.color(0xffffff),
            html: `<span 
            style=\"
            display: flex; 
            align-items: center; 
            height: ${isMobile ? '18px' : '30px'}; 
            font-weight: 500;
            font-size:  ${isMobile ? '9px' : '12px'};
            color: #FFFFFF;
            margin-left: ${isMobile ? '3px' : '10px'};
            \">{valueY} <img style=\"
            margin-left: ${isMobile ? '1px' : '3px'};
            width: ${isMobile ? '7px' : 'auto'}
            \" src=${valueIcon} alt=''/></span>`,
          });

          let container = am5.Container.new(root, {
            fill: am5.color(0x191919),
            tooltipText: '{valueY}',
            showTooltipOn: 'always',
            tooltip: tooltip,
          });

          let circle0 = container.children.push(
            am5.Circle.new(root, {
              radius: 2,
              fill: am5.color(valueColor),
            })
          );

          return am5.Bullet.new(root, {
            locationX: undefined,
            sprite: container,
          });
        }
      });

      seriesPoint?.strokes.template.setAll({
        strokeWidth: 1,
        stroke: am5.color(valueColor),
      });
    }

    let endTime;

    function addData(el) {
      let lastDataItem = series.dataItems[series.dataItems.length - 1];
      let lastValue = lastDataItem && lastDataItem.get('valueY');
      let newValue = +Number(el?.price ?? 0);

      let time = new Date().getTime();

      if (endTime && endTime + 2000 <= time) {
        removeFc();
      }

      // series.data.removeIndex(0);
      series.data.push({
        date: time,
        value: newValue,
      });

      if (valueClose) {
        if (lastValue <= Number(valueClose ?? 0) && newValue > Number(valueClose ?? 0) && lambdaResponseData.isLong) {
          valueLabel = tagUpLabel;
          valueLabelMobile = tagUpLabelMobile;
          valueIcon = upIcon;
          valueColor = 0x18adfa;

          seriesPoint?.data.setAll(dataPoint);

          seriesPoint?.strokes.template.setAll({
            strokeWidth: 1,
            stroke: am5.color(valueColor),
          });
        }

        if (lastValue <= Number(valueClose ?? 0) && newValue > Number(valueClose ?? 0) && !lambdaResponseData.isLong) {
          valueLabel = tagUpLabel;
          valueLabelMobile = tagUpLabelMobile;
          valueIcon = upIcon;
          valueColor = 0x9747ff;

          seriesPoint?.data.setAll(dataPoint);

          seriesPoint?.strokes.template.setAll({
            strokeWidth: 1,
            stroke: am5.color(valueColor),
          });
        }
        // else{
        //   valueLabel = tagUpLabel;
        //   valueLabelMobile = tagUpLabelMobile;
        //   valueIcon = upIcon;
        //   valueColor = 0x9747FF;

        //   seriesPoint?.data.setAll(dataPoint);

        //   seriesPoint?.strokes.template.setAll({
        //     strokeWidth: 1,
        //     stroke: am5.color(valueColor),
        //   });
        // }

        if (lastValue >= Number(valueClose ?? 0) && newValue < Number(valueClose ?? 0) && !lambdaResponseData.isLong) {
          valueLabel = tagDownLabel;
          valueLabelMobile = tagDownLabelMobile;
          valueIcon = downIcon;
          valueColor = 0x18adfa;

          seriesPoint?.data.setAll(dataPoint);

          seriesPoint?.strokes.template.setAll({
            strokeWidth: 1,
            stroke: am5.color(valueColor),
          });
        }

        if (lastValue >= Number(valueClose ?? 0) && newValue < Number(valueClose ?? 0) && lambdaResponseData.isLong) {
          valueLabel = tagDownLabel;
          valueLabelMobile = tagDownLabelMobile;
          valueIcon = downIcon;
          valueColor = 0x9747ff;

          seriesPoint?.data.setAll(dataPoint);

          seriesPoint?.strokes.template.setAll({
            strokeWidth: 1,
            stroke: am5.color(valueColor),
          });
        }
        // else{
        //   valueLabel = tagDownLabel;
        //   valueLabelMobile = tagDownLabelMobile;
        //   valueIcon = downIcon;
        //   valueColor = 0x9747FF;

        //   seriesPoint?.data.setAll(dataPoint);

        //   seriesPoint?.strokes.template.setAll({
        //     strokeWidth: 1,
        //     stroke: am5.color(valueColor),
        //   });
        // }

        if (valueClose > newValue && !refresh) {
          valueLabel = tagDownLabel;
          valueLabelMobile = tagDownLabelMobile;
          valueIcon = downIcon;
          valueColor = 0x9747ff;
          refresh = true;
          seriesPoint?.data.setAll(dataPoint);

          seriesPoint?.strokes.template.setAll({
            strokeWidth: 1,
            stroke: am5.color(valueColor),
          });
        }
      }

      if (range) {
        range.animate({
          key: 'value',
          to: newValue,
          duration: 1000,
          easing: easing,
        });

        range.get('label').setAll({
          fill: series.get('fill'),
          text: chart.getNumberFormatter().format(newValue),
          inside: true,
        });
      }

      let newDataItem = series.dataItems[series.dataItems.length - 1];

      if (newDataItem && lastDataItem && lastDataItem.bullets) {
        newDataItem.animate({
          key: 'valueYWorking',
          to: newValue,
          from: lastValue,
          duration: 1000,
          easing: easing,
        });

        newDataItem.bullets = [];
        newDataItem.bullets[0] = lastDataItem.bullets[0];
        newDataItem.bullets[0] && (newDataItem.bullets[0].get('sprite').dataItem = newDataItem);
        //reset bullets
        if (newDataItem?.bullets[0]?.get('sprite')) {
          let sprite = newDataItem?.bullets[0].get('sprite');
          if (newValue < lastValue) {
            sprite.setAll({ rotation: 45, stateAnimationDuration: 5, stateAnimationEasing: am5.ease.inOut(am5.ease.sine) });
          } else if (newValue > lastValue) {
            sprite.setAll({ rotation: -45, stateAnimationDuration: 5, stateAnimationEasing: am5.ease.inOut(am5.ease.sine) });
          } else if (newValue === lastValue) {
            sprite.setAll({ rotation: 0, stateAnimationDuration: 5, stateAnimationEasing: am5.ease.inOut(am5.ease.sine) });
          }
          sprite.set('centerX', am5.percent(50));
          sprite.set('centerY', am5.percent(50));
        }

        lastDataItem.bullets = [];

        let animation = newDataItem?.animate({
          key: 'locationX',
          to: 0.5,
          from: -0.5,
          duration: 1000,
        });

        if (animation) {
          let tooltip = xAxis.get('tooltip');
          if (tooltip && !tooltip.isHidden()) {
            animation.events.on('stopped', function () {
              xAxis.updateTooltip();
            });
          }
        }
      }

      try {
        let centerDate = (new Date().getTime() - 30000 + (new Date().getTime() + 30000)) / 2;


        let runTimeFromCurrentTime = Date.now() - chartRunTime
        let zoomTimeFromStart = 10000
        let zoomTimeAtEnd = 20000
        if (runTimeFromCurrentTime > 10000) {
          zoomTimeFromStart = 20000
          zoomTimeAtEnd = 35000
        }

        // console.log('chart run time::', chartRunTime)
        console.log('chart run time now::', runTimeFromCurrentTime, zoomTimeFromStart, zoomTimeAtEnd)

        xAxisRef.current.zoomToDates(new Date(centerDate - zoomTimeFromStart), new Date(centerDate + zoomTimeAtEnd));
      } catch (error) { }
    }

    let dataPoint = [];
    const handlePoint = (dataInfo, currentPrice) => {
      valueClose = dataInfo.openPrice;
      dataGame = dataInfo;

      if (dataPoint.length == 0) {
        // if (false) {
        dataPoint.push({
          date: new Date(dataInfo?.startTime - 10000).getTime(), //adding 5 seconds to make the horizontal line add margin to when trade started
          value: +Number(dataInfo?.openPrice),
          //bullet: true,
        });

        dataPoint.push({
          date: new Date(dataInfo?.startTime + (tradeVars.time + 350) * 1000).getTime(),
          value: +Number(dataInfo?.openPrice),
        });

        xAxis.zoomToDates(new Date(Date.now() - 90000), new Date(new Date(Date.now() + 30000)));

        createSeriesPoint('Point Betting', 'value');
      }
      xAxis.zoomToDates(new Date(Date.now() - 90000), new Date(new Date(Date.now() + 30000)));
    };

    // socket.emit("PRACTICE_CURRENT_GAME_INFO", {
    //   roomName: "BTCUSDC1M",
    // });

    const removeFc = () => {
      seriesPoint?.data.clear();
      xAxisRef?.current.axisRanges.clear();
      series1Ref.current.axisRanges.removeValue(rangeX);
      rangeX = undefined;
      dataPoint = [];
      endTime = undefined;
      valueClose = undefined;
      yAxisRef?.current.axisRanges.removeValue(rangeUpDown);
    };


    const intervalAddDataId = setInterval(() => {

      if (temp_array.length) {

        let sock_data = temp_array[temp_array.length - 1];
        console.log("socket", sock_data)
        yAxisRef.current?.onPrivate('selectionMin', function (start, target) {
          min = start;
        });

        yAxisRef.current?.onPrivate('selectionMax', function (end, target) {
          max = end;
        });

        if (data && data?.length > 0) {
          //console.log(sock_data?.price);
          addData(sock_data);
          props.setCPrice(sock_data?.price);
          setNewPrice(sock_data?.price);
          setPrice(sock_data?.price);

          temp_array = []
        }
      }
    }, 1000);


    const HANDLE_PRACTICE_CURRENT_GAME_INFO = async () => {

      if (lambdaResponseData?.openPrice) {
        handlePoint(lambdaResponseData, newprice);
      }

    };

    setTimeout(() => {
    //getPriceFeedWithWebSocket();
    // console.log('Game starts now');
    if (data && data?.length) {
      HANDLE_PRACTICE_CURRENT_GAME_INFO();
      if (lambdaResponseData.openPrice) {
        let lastpoint = data[data.length - 1];
        if (lambdaResponseData.openPrice < lastpoint.value) {
          addData({ price: lambdaResponseData.openPrice - 10 });
        }
        addData({ price: lambdaResponseData.openPrice });
      }
    }
    }, 1000);

    // Make stuff animate on load
    chart.appear(1000, 100);

    rootRef.current = root;
    xAxisRef.current = xAxis;
    yAxisRef.current = yAxis;
    series1Ref.current = series;

    return () => {
      root.dispose();
      clearInterval(intervalAddDataId);
      temp_array = []
      //setTempArray([])
      pythConnection.stop();
    };
  }, [data, lambdaResponseData.openPrice]);


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);

    }, 500);

  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        marginTop: isMobile ? 0 : '20px',
        marginBottom: isMobile ? 0 : '20px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          marginTop: isMobile ? '20px' : '50px',
          marginBottom: isMobile ? '20px' : '50px',
        }}
        id="chartdiv"
        className={loading ? 'hidden' : ''}
      />
    </div>
  );
}

export default Chart3;