import React, { useState, useEffect, useContext, useRef } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import {
  pepe_top,
  pepe_top_base,
  pepe_coin,
  pepe_shine,
  pepe_dollar_eyes,
  coins_stacked,
  cancel_icon_w_gradient,
} from '../assets';
import { useNavigate } from 'react-router-dom';
import { useStorage } from '../utils/StorageContext';
import { StoreContext } from '../utils/store';
import axios from 'axios';
import { useAccount, useNetwork } from 'wagmi';
import { api } from '../constants';

import warningIcon from '../assets/images/warning.svg';
import arrayLogo from '../assets/images/array-logo.png';
import TwitterLogo from '../assets/images/leaderboard-twitter.svg';
import DiscordLogo from '../assets/images/leaderboard-discord.svg';
import '../styles/leaderboard.css';
import Settings from '../components/Settings/Settings';

const ITEMS_PER_PAGE = 10;

const LeaderBoard = () => {
  const tableRef = useRef(null);
  const store = useContext(StoreContext);
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const isSmallScreen = useMediaQuery('(max-width: 640px)');
  const [leaderBoardData, setLeaderBoardData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [startTableIndex, setStartTableIndex] = useState(11);
  const [endTableIndex, setEndableIndex] = useState(20);
  const [isTableRendered, setIsTableRendered] = useState(false);
  const { address: useraddress } = useAccount();
  const {
    tradeVars,
    setLastTrade,
    lasttradeOpen,
    lasttradeClose,
    lasttradeWin,
    lambdaResponseData,
    setSFX,
    sfxAudio,
    setSrc,
    music,
    setTradeVars,
    setGoodLuckModal,
    activeAmountButton: activeButton,
    setActiveAmountButton: setActiveButton,
    setWillRespin,
  } = store;
  const navigate = useNavigate();

  // Method to perform respin
  const respin = () => {
    setWillRespin(true);
    navigate('/slot');
  };

  const scrollToRow = (rowIndex) => {
    const table = tableRef.current;

    console.log('table ref::', table);
    if (table != null) {
      const row = table.querySelector('.account-position');
      console.log('selected row::', row);
      if (row != null) {
        row.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const LoadMore = async () => {
    let results = await fetchLeaderBoardData(startTableIndex, endTableIndex);
    const newDataArray = [...leaderBoardData, ...results.data];
    // update the state with the new array
    setLeaderBoardData(newDataArray);
    //Increasing values
    setStartTableIndex(startTableIndex + 10);
    setEndableIndex(endTableIndex + 10);
  };

  // Method to leaderboard data from server
  const fetchLeaderBoardData = (start = 0, end = 10) => {
    return new Promise(async (res, reject) => {
      // axios.get(
      //     `${api.dev.leader_API}?start=${start}&end=${end}`,
      //     {
      //         headers: {
      //             'Content-Type': 'application/json'
      //         },
      //     }

      // )
      axios
        .get(`${api.offChainBet.leaderBoardDataApiEndpoint}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          console.log('response data::', response);
          res(response.data);
        })
        .catch((error) => {
          reject({ message: error.response.data.message });
        });
    });
  };

  const getLeaderBoardData = async () => {
    try {
      let results = await fetchLeaderBoardData();
      console.log('data::', results);
      setLeaderBoardData(results.data);

      setIsTableRendered(true);

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching bets :', error);
    }
  };

  useEffect(() => {
    getLeaderBoardData();
  }, []);

  useEffect(() => {
    console.log('is table rendered::', isTableRendered);
    scrollToRow(5);
  }, [isTableRendered]);

  if (isLoading) {
    return 'Loading data...';
  }

  return (
    <div
      className="h-full overflow-y-hidden sm:overflow-y-auto overflow-x-hidden pb-[54px]"
      style={{
        background: isSmallScreen ? '#09031D' : 'linear-gradient(#09031D, #160B41)',
      }}
    >
      <div className="flex justify-center relative z-10">
        <img
          src={arbsOrBase === 'base' ? pepe_top_base : pepe_top}
          className="w-[330px] h-[208px] pointer-events-none select-none"
          alt="pepe logo"
        />
        {/* <img
                    src={pepe_shine}
                    className="sm:hidden w-full h-[208px] absolute top-0 left-0 -z-10 pointer-events-none select-none"
                    alt="pepe logo"
                /> */}
      </div>

      <div className="sm:flex justify-start mb-[10px] absolute top-10 right-10">
        <button onClick={() => navigate('/slot')} className="cursor cursor-pointer w-6 h-6 z-10">
          <img src={cancel_icon_w_gradient} alt="cancel Icon" />
        </button>
      </div>

      <div className="">
        <div className=" w-full sm:w-2/3 xl:w-1/2 sm:mx-auto relative text-white">
          {/* <div className='flex flex-col justify-center items-center absolute'></div> */}
          <p className="uppercase text-center text-5xl font-bold gradient-text p-5 pt-2 pb-[28px] tracking-wide font-['Tourney']">
            SPIN LEADERBOARD
          </p>

          <p className="header-sub-text text-center mx-auto leading-6 mb-2">
            240,000 PEG Airdrop
            <br />
            for all spinoooooors!
          </p>
          {/* <p className="header-sub-text-2">...available to those who make the most $5 spins</p> */}
          <p className="header-sub-text-3 mx-auto">thats 1.2% of total token supply, pssst.</p>

          {/* <div class="w-64 h-64 border overflow-hidden">
                        <div class="w-72 h-72 bg-red-500"></div>
                    </div> */}

          <div className="mt-[43px] relative table-container mx-auto p-4">
            <div className="banner-container">
              <div className="banner spinors-banner">
                <p className="banner-text">Spinoooors Airdrop</p>
              </div>
            </div>

            <div className="text-sm">
              <p className="font-bold gradient-text mb-2">Boost Your Airdrop with Every Spin</p>
              <p>$1 Spin = 1 Points</p>
              <p>$2 Spins = 3 Points</p>
              <p>$5 Spins = 10 Points</p>
              <p>$10 Spins = 25 Points</p>
              <p>$20 Spins = 60 Points</p>
              <p>Also, every $50 spun = 25 extra points</p>
            </div>
          </div>

          <div className="mt-[43px] relative table-container mx-auto">
            {/* <div className="banner-container">
                            <div className="banner">
                                <p className="banner-text">Sponsored by Array</p>
                            </div>
                        </div> */}

            {/* <div class="flex justify-end table-top-panel">
                            <div class="icon-group flex items-center justify-center mr-[16px]">
                                <img alt="array logo" src={arrayLogo} width={76} />
                            </div>
                            <div class="icon-group flex items-center justify-center mr-[15px]">
                                <a href="https://twitter.com/array_capital" target="_blank">
                                    {' '}
                                    <img alt="array twitter" src={TwitterLogo} width={15.51} />
                                </a>
                            </div>
                            <div class="icon-group flex items-center justify-center mr-[22px]">
                                <a href="https://discord.com/invite/arraycapital" target="_blank">
                                    {' '}
                                    <img alt="array discord" src={DiscordLogo} width={15.51} />
                                </a>
                            </div>
                        </div> */}

            <table ref={tableRef} class="table-auto leaderboard-table ml-[19px] mt-[12px] pb-4 mb-4">
              <thead>
                <tr className="rounded">
                  <th className="w-1/5 text-center table-header">RANK</th>
                  <th className="w-1/5 text-center table-header">WALLET</th>
                  <th className="w-1/5 text-center table-header">NR OF SPINS</th>
                  <th className="w-1/5 text-center table-header">POINTS EARNT</th>
                  <th className="w-1/5 text-center table-header">BET SIZE TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {leaderBoardData.map((entry, i) => {
                  let rank;
                  if (i === 0) {
                    rank = (
                      <img
                        alt="rank"
                        width={33.41}
                        src="https://uploads-ssl.webflow.com/641c2b181f41df422637adc5/64201c51cc09c983c5c38739_Pepe%20First.png"
                        loading="lazy"
                        className="mx-auto leaderboard_image"
                      />
                    );
                  } else if (i === 1) {
                    rank = (
                      <img
                        alt="rank"
                        width={33.41}
                        src="https://uploads-ssl.webflow.com/641c2b181f41df422637adc5/64201c50a2bd1e700e469ca2_Pepe%20Third.png"
                        loading="lazy"
                        className="mx-auto leaderboard_image"
                      />
                    );
                  } else if (i === 2) {
                    rank = (
                      <img
                        alt="rank"
                        width={33.41}
                        src="https://uploads-ssl.webflow.com/641c2b181f41df422637adc5/64201c50a2bd1e700e469ca2_Pepe%20Third.png"
                        loading="lazy"
                        className="mx-auto leaderboard_image"
                      />
                    );
                  } else {
                    rank = <p className="rank-regular mx-auto">#{i + 1}</p>;
                  }

                  // let wallet = `${entry.useraddress.slice(0, 4)}.....${entry.useraddress.slice(-4)}`

                  let wallet;

                  if (entry.useraddress === useraddress) {
                    wallet = <span className="account-position-text">This is you</span>;
                  } else {
                    wallet = `${entry.useraddress.slice(0, 4)}.....${entry.useraddress.slice(-4)}`;
                  }

                  return (
                    <tr
                      className={`rounded ${entry.useraddress === useraddress ? 'account-position' : ''}`}
                      key={wallet}
                    >
                      <td className="w-1/5 text-center">{rank}</td>
                      <td className="w-1/5 text-center">
                        <p className={`${i <= 2 ? 'top-3-text' : 'regular-text'}  mx-auto`}>{wallet}</p>
                      </td>
                      <td className="w-1/5 text-center">
                        <p className={`${i <= 2 ? 'top-3-text' : 'regular-text'}  mx-auto`}>{entry.spins}</p>
                      </td>
                      <td className="w-1/5 text-center">
                        <p className={`${i <= 2 ? 'top-3-text' : 'regular-text'}  mx-auto`}>{entry.points_earnt}</p>
                      </td>
                      <td className="w-1/5 text-center">
                        <p className={`${i <= 2 ? 'top-3-text' : 'regular-text'}  mx-auto`}>{entry.bet_size_total}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* <a className='cursor-pointer text-xs' onClick={LoadMore} >Load more</a> */}

            <div class="flex items-center mt-4 mb-4">
              <div class="icon-group flex items-center justify-center mr-[35px] ml-auto">
                {/* <img alt="info" src={warningIcon} className="mr-2" /> */}
                {/* <h1 class="text-4xl font-bold table-red-info">Only top 3 wins something</h1> */}
              </div>
              <div class="icon-group flex items-center justify-center mr-[51px]">
                <button
                  onClick={() => navigate('/slot')}
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 pr-2 rounded back-btn mr-[15px] "
                >
                  <p className="btn-text">Back</p>
                </button>
                <button
                  onClick={respin}
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 pr-2 rounded respin-btn"
                >
                  <p className="btn-text">Respin with $20 bet</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Settings classes="!fixed" />
    </div>
  );
};

export default LeaderBoard;
