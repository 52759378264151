import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import './AutoSpin.css';
import BetSmallAmountSVG from '../BetAmountButtonSmall';
import { upload_credits, spin_rounds, cancel_icon_w_gradient } from '../../assets';
import { useSnackbar } from 'notistack';
import useLocalStorage from '../../hooks/useLocalStorage';
import { CreditsContext } from '../../utils/creditsContext';
import { StoreContext } from '../../utils/store';
import { useMediaQuery } from 'usehooks-ts';
import { useStorage } from '../../utils/StorageContext';
//import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid/async'

const AutoSpin = ({ open, isOpen, address }) => {
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedNumberRounds, setSelectedNumberRounds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const INSUFFICIENT_FUNDS_MSG = 'Insufficient Funds';
  const { autoSpinData, setAutoSpinData } = useStorage();
  const { yourCredits } = useContext(CreditsContext);
  const [credits, setCredits] = useState(0);
  const Store = useContext(StoreContext);
  const { setAutoSpinVars } = Store;
  const { setDepositWithdrawModal } = Store;
  const formatter = new Intl.NumberFormat('en-US');
  const isMobile = useMediaQuery('(max-width: 640px)');

  const handleAmountChange = (amount) => {
    setSelectedAmount(amount);
    setSelectedNumberRounds(0);
  };

  const handleNumberChange = (number) => {
    setSelectedNumberRounds(number);
  };

  useEffect(() => {
    const credits = parseFloat(Number(yourCredits).toFixed(2));
    setCredits(credits);
  }, [yourCredits]);

  useEffect(() => {
    if (selectedAmount !== 0 && selectedNumberRounds !== 0) {
      if (selectedAmount <= credits) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    } else setIsActive(false);
  }, [selectedAmount, selectedNumberRounds]);

  const RoundsButton = ({ number }) => {
    return (
      <button
        className={`w-[73px] max-[640px]:h-10 text-white font-manrope font-medium text-xl leading-[27px] rounded-[6px] ${selectedNumberRounds === number ? 'numbers-active' : 'numbers-inactive'
          }`}
        onClick={() => {
          handleNumberChange(number);
        }}
      >
        {number}
      </button>
    );
  };

  const configureAutoSpin = async () => {
    const data = {
      isAuto: true,
      amount: selectedAmount,
      rounds: selectedNumberRounds,
      roundsLeft: selectedNumberRounds,
      isPlay: true,
      isStop: false,
      isStopAt: 0,
      autospin_id: (await nanoid(10)) + `_1_${selectedNumberRounds}`
    };
    console.log("SPIN:", data)
    setAutoSpinData(data);
    setAutoSpinVars(true, selectedAmount, selectedNumberRounds, selectedNumberRounds);
  };

  useEffect(() => {
    console.log('auto spin data::', autoSpinData)
    if (autoSpinData.amount) {
      open(false, selectedAmount);
    }
  }, [autoSpinData]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1000]" onClose={() => open(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[rgba(0,0,0,0.75)] flex">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="modal-dialog transition-all transform w-[766.3px] h-[683.18px] bg-[#09031D] modal flex max-[640px]:w-4/5">
              <div className="mx-auto overflow-hidden">
                {isMobile ? (
                  <div className="flex justify-end w-full h-10">
                    <button className="cursor cursor-pointer w-4 h-4">
                      <img src={cancel_icon_w_gradient} alt="cancelIcon" onClick={() => open(false)} />
                    </button>
                  </div>
                ) : (
                  <div className="flex items-end justify-end absolute right-6">
                    <button onClick={() => open(false)}>
                      <img src={cancel_icon_w_gradient} alt="cancel icon" />
                    </button>
                  </div>
                )}
                <div
                  className='flex items-center justify-center max-[640px]:text-[28px] text-[67px] font-["Tourney"] font-bold max-[640px]:leading-[30.8px] leading-[73.7px] tracking-[0.03em] gradient-text text-center'
                  style={{ marginTop: isMobile ? 10 : 57.33 }}
                >
                  AUTOSPIN
                </div>

                <div className="flex flex-col justify-center text-center mt-[27px]">
                  <h1 className="font-semibold barlow max-[640px]:text-[17px] text-[18px] text-white leading-[21.6px]">
                    Choose Bet Size and Number of Autospins
                  </h1>
                  <h2 className="font-manrope font-semibold text-[11px] text-white leading-[10px] mt-[7px]">
                    Your In App Balance:{' '}
                    {yourCredits
                      ? !isNaN(yourCredits)
                        ? formatter.format(Number(yourCredits).toFixed(2))
                        : '0.00'
                      : '0.00'}{' '}
                    USDC
                  </h2>
                </div>

                <div className="w-[610px] max-[640px]:w-[95%] max-[640px]:mx-auto h-[154.52px] max-[640px]:h-[122px] flex items-center justify-center bet-size mt-[31px] rounded-[6px] border-[#18adfa]/50 border-solid border-[1px] ">
                  <div className="w-auto h-auto flex flex-col">
                    <div className="font-manrope -mt-[15px] font-semibold text-sm leading-[10px] tracking-[0.1em] text-white text-center">
                      BET SIZE
                    </div>

                    <div className="flex flex-row mt-[15px] h-[55.52px] w-[338.26px] max-[640px]:w-[281px] max-[640px]:h-[36px]">
                      <button
                        className="w-1/3 autospin-amount-btn"
                        onClick={() => {
                          if (credits < 5) {
                            enqueueSnackbar(INSUFFICIENT_FUNDS_MSG, {
                              variant: 'error',
                              preventDuplicate: true,
                            });
                            return;
                          }
                          handleAmountChange(5);
                        }}
                      >
                        <BetSmallAmountSVG amount={5} disabled={selectedAmount === 5 ? false : true} />
                      </button>
                      <button
                        className="w-1/3 autospin-amount-btn"
                        onClick={() => {
                          if (credits < 10) {
                            enqueueSnackbar(INSUFFICIENT_FUNDS_MSG, {
                              variant: 'error',
                              preventDuplicate: true,
                            });
                            return;
                          }
                          handleAmountChange(10);
                        }}
                      >
                        <BetSmallAmountSVG amount={10} disabled={selectedAmount === 10 ? false : true} />
                      </button>
                      <button
                        className="w-1/3 autospin-amount-btn"
                        onClick={() => {
                          if (credits < 20) {
                            enqueueSnackbar(INSUFFICIENT_FUNDS_MSG, {
                              variant: 'error',
                              preventDuplicate: true,
                            });
                            return;
                          }
                          handleAmountChange(20);
                        }}
                      >
                        <BetSmallAmountSVG amount={20} disabled={selectedAmount === 20 ? false : true} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-[610px] max-[640px]:w-[95%] max-[640px]:mx-auto h-[135px] max-[640px]:h-[184px] flex flex-col items-center justify-center number-rounds mt-[30px] rounded-[6px] border-[#18adfa]/50 border-solid border-[1px]">
                  <div className="font-manrope font-semibold text-sm leading-[10px] max-[640px]:mt-[10px] tracking-[0.1em] text-white text-center">
                    NUMBER OF ROUNDS
                  </div>
                  {isMobile ? (
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-7 mt-5 h-[51px]">
                        <RoundsButton number={10} />
                        <RoundsButton number={25} />
                      </div>
                      <div className="flex flex-row gap-7 mt-5 h-[51px]">
                        <RoundsButton number={50} />
                        <RoundsButton number={100} />
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row gap-10 mt-5 h-[51px]">
                      <RoundsButton number={10} />
                      <RoundsButton number={25} />
                      <RoundsButton number={50} />
                      <RoundsButton number={100} />
                    </div>
                  )}
                </div>

                <div className="w-full mt-[10px] flex justify-center">
                  <button
                    onClick={() => {
                      if (isActive) {
                        configureAutoSpin();
                      } else {
                        setDepositWithdrawModal(true);
                        open(false);
                      }
                    }}
                  >
                    <img src={isActive ? spin_rounds : upload_credits} alt="upload credits or spin rounds" />
                  </button>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AutoSpin;
