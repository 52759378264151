import { ACTION_TYPES } from "../actions/betActionTypes";

export const INITIAL_STATE = {
    leverage: 0,
    time: 0,
    amount: 0,
    currency: '',
    longShort: '',
};

export const betReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_TRADEVARS:
            return {
                ...state,
                leverage: action.payload.leverage,
                time: action.payload.time,
                amount: action.payload.amount,
                currency: action.payload.currency,
                longShort: action.payload.longShort,
            };
        default:
            return state;
    }
};