import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { how_to_play } from '../assets';
import { cancel_icon_w_gradient } from '../assets';
import { useNavigate } from 'react-router-dom';


export default function ImageModal({ isOpen, setOpen }) {
  const navigate = useNavigate();

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={() => setOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-90" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full flex flex-col sm:max-w-[80%] transform overflow-hidden rounded-2xl bg-how-to-play p-4 text-left align-middle shadow-xl transition-all relative"
                >
                  <span className='absolute top-4 right-4 text-white cursor-pointer' onClick={() => setOpen(false)}>
                    <img src={cancel_icon_w_gradient} alt='' />
                  </span>
                  <img src={how_to_play} className='w-full h-auto' alt='' />
                  <button
                    onClick={() =>{ navigate('/slot');setOpen(false)}}
                    className="barlow font-semibold mx-auto text-white mt-2 text-[0.875rem] leading-[1.125rem] bg-[linear-gradient(99.44deg,rgba(74,59,119,0.42)_16.18%,rgba(50,40,82,0.42)_82.19%)] border-[0.6px] border-solid border-[#886CDD] rounded-md shadow-[0px_0px_5px_rgba(128,0,212,0.71)] px-[3rem] py-[0.688rem]"
                  >
                    Play
                </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}