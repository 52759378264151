import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { createContext } from 'react';
import { useAccount } from 'wagmi';

import {api, getQuest_API } from '../constants';
import { BigNumber } from 'ethers';

const initialChipsAndDays = [
  { day: 1, chips: '2' },
  { day: 2, chips: '2' },
  { day: 3, chips: '2' },
  { day: 4, chips: '5' },
  { day: 5, chips: '5' },
  { day: 6, chips: '5' },
  { day: 7, chips: '10' }
]

const initialCreditsContext = {
  yourCredits: 0,
  setYourCredits: () => { },
  questChips: 0,
  setQuestChips: () => { },
  questStreakCompleted: 0,
  completedStreakForToday: false,
  getQuestChips: async () => Promise.resolve(),
  hasLostStreak: true,
  chipsAndDays: initialChipsAndDays
};

export const CreditsContext = createContext(initialCreditsContext);

const CreditsContextProvider = ({ children }) => {
  const { address } = useAccount();
  const [yourCredits, setYourCredits] = useState(0);
  const [questChips, setQuestChips] = useState(0);
  const [questStreakCompleted, setQuestStreakCompleted] = useState(0);
  const [completedStreakForToday, setCompletedStreakForToday] = useState(false);
  const [hasLostStreak, setHasLostStreak] = useState(false);
  const [chipsAndDays, setChipsAndDays] = useState(initialChipsAndDays);
  const [pillMessage, setPillMessage] = useState(false);
  const [showPills, setShowPills] = useState(false);
  const [userPepeBalance, setUserPepeBalance] = useState(BigNumber.from(0));


  const getQuestChips = useCallback(async () => {
    try {
      // let streak = 0;
      // let sum = 0;
      // let completedStreakForToday = false;
      const { data } = await axios.get(
        // `${getQuest_API}/getstreak?address=${address}`
        `${api.offChainBet.getQuestV2ApiEndpoint}?address=${address}`
      );
      console.log('credits data::', data.data)
      const { data: { chipsAndDays: chipsArray, currentDayStreak, hasLostStreak, hasSpunToday, usersTotalChips } } = data;
      // console.log(data);
      // for (let i = 0; i < data.data.streak.length; i++) {
      //   if (sum + Number(data.data.streak[i].count) <= sum) {
      //     if (i === 0) {
      //       continue;
      //     }
      //     break;
      //   }
      //   if (i === 0) {
      //     completedStreakForToday = true;
      //   }
      //   streak += 1;
      //   sum += Number(data.data.streak[i].count);
      // }
      setQuestStreakCompleted(Number(currentDayStreak));
      setCompletedStreakForToday(hasSpunToday);
      setQuestChips(Number(usersTotalChips));
      setHasLostStreak(hasLostStreak);
      setChipsAndDays(chipsArray);

      if (hasSpunToday === false) {
        setPillMessage(!hasSpunToday);
        setShowPills(!hasSpunToday);
      }

    } catch (err) {
      console.log(err);
    }
  }, [address, yourCredits]);

  useEffect(() => {
    if (address) {
      getQuestChips();
    } else {
      setQuestChips(0);
      setYourCredits(0);
      setCompletedStreakForToday(false);
      setQuestStreakCompleted(0);
      setHasLostStreak(false);
      setChipsAndDays(initialChipsAndDays);
    }
  }, [address]);

  useEffect(() => {
    if (address) {
      getQuestChips();
    } else {
      setQuestChips(0);
      setYourCredits(0);
      setCompletedStreakForToday(false);
      setQuestStreakCompleted(0);
      setHasLostStreak(false);
      setChipsAndDays(initialChipsAndDays);
    }
  }, [getQuestChips]);


  return (
    <CreditsContext.Provider
      value={{
        yourCredits,
        setYourCredits,
        questChips,
        setQuestChips,
        questStreakCompleted,
        setCompletedStreakForToday,
        setQuestStreakCompleted,
        completedStreakForToday,
        getQuestChips,
        hasLostStreak,
        chipsAndDays,
        pillMessage,
        setPillMessage,
        showPills,
        setShowPills,
        userPepeBalance,
        setUserPepeBalance,
      }}
    >
      {children}
    </CreditsContext.Provider>
  );
};

export default CreditsContextProvider;
