import React from 'react';
import { pepe_coin } from '../assets';
import pepe_blue from '../assets/images/pepe_blue.svg';
import Settings from './Settings/Settings';
import { useMediaQuery } from 'usehooks-ts';
import { useStorage } from '../utils/StorageContext';
import { useNetwork } from 'wagmi';

function PepeMobileFooter({ classes = '' }) {
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const isMobile = useMediaQuery('(max-width: 640px)');

  const getNetworkImg = (network) => {
    if (network === 'base') {
      return pepe_blue;
    }
    return pepe_coin;
  };

  return (
    <>
      {isMobile ? (
        <div className={`flex sm:hidden ${classes}`}>
          <div className="w-2/5">
            <img alt="" src={getNetworkImg(arbsOrBase)} className="w-full h-auto pointer-events-none" />
          </div>
          <div className="flex-1 relative flex">
            <Settings classes="bottom-8 mr-0" />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default PepeMobileFooter;
