import React from 'react';

const NewTabIcon = () => {
  return (
    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_95_1157)">
        <path
          d="M8.32459 5.7645C8.22513 5.7645 8.12975 5.80401 8.05942 5.87434C7.9891 5.94467 7.94959 6.04005 7.94959 6.1395V7.64904C7.94926 7.94059 7.83329 8.22011 7.62713 8.42627C7.42097 8.63243 7.14146 8.74839 6.8499 8.74872H2.55428C2.26272 8.74839 1.98321 8.63243 1.77705 8.42627C1.57089 8.22011 1.45492 7.94059 1.45459 7.64904V3.35341C1.45492 3.06186 1.57089 2.78234 1.77705 2.57618C1.98321 2.37002 2.26272 2.25405 2.55428 2.25372H4.06381C4.16326 2.25372 4.25865 2.21421 4.32897 2.14389C4.3993 2.07356 4.43881 1.97818 4.43881 1.87872C4.43881 1.77927 4.3993 1.68388 4.32897 1.61356C4.25865 1.54323 4.16326 1.50372 4.06381 1.50372H2.55428C2.06389 1.5043 1.59375 1.69937 1.24699 2.04612C0.900232 2.39288 0.705169 2.86302 0.70459 3.35341V7.64904C0.705169 8.13943 0.900232 8.60956 1.24699 8.95632C1.59375 9.30308 2.06389 9.49814 2.55428 9.49872H6.8499C7.34029 9.49814 7.81043 9.30308 8.15719 8.95632C8.50395 8.60956 8.69901 8.13943 8.69959 7.64904V6.1395C8.69959 6.04005 8.66008 5.94467 8.58976 5.87434C8.51943 5.80401 8.42405 5.7645 8.32459 5.7645Z"
          fill="currentColor"
        />
        <path
          d="M8.31639 1.50122H5.98514C5.88694 1.5012 5.79266 1.5397 5.72254 1.60845C5.65242 1.67719 5.61206 1.7707 5.61014 1.86888C5.60623 2.07935 5.78326 2.25122 5.99358 2.25122H7.42061L4.43404 5.23872C4.36372 5.30905 4.32422 5.40443 4.32422 5.50388C4.32422 5.60333 4.36372 5.69871 4.43404 5.76903C4.50437 5.83935 4.59975 5.87886 4.6992 5.87886C4.79865 5.87886 4.89403 5.83935 4.96436 5.76903L7.95186 2.78247V4.21466C7.95186 4.31411 7.99137 4.4095 8.06169 4.47982C8.13202 4.55015 8.2274 4.58966 8.32686 4.58966C8.42631 4.58966 8.5217 4.55015 8.59202 4.47982C8.66235 4.4095 8.70186 4.31411 8.70186 4.21466V1.88653C8.70186 1.83592 8.69189 1.7858 8.67251 1.73904C8.65314 1.69229 8.62474 1.6498 8.58895 1.61402C8.55315 1.57824 8.51066 1.54986 8.46389 1.53051C8.41712 1.51115 8.367 1.5012 8.31639 1.50122Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_95_1157">
          <rect width="7.9975" height="8.9975" fill="white" transform="translate(0.70459 0.501221)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NewTabIcon;
