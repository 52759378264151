import React, { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import '../styles/stake.css';
// import Wrapper from '../components/Wrapper';
// import TopBar from '../components/top-nav/top-bar';
import { blueETH, blueToken, bolt, crown, lock, main, pepe_top, finalmobilelogo, PEG_ETH3_, airdrop } from '../assets';
import { useMediaQuery, useDebounce } from 'usehooks-ts';
import { StoreContext } from '../utils/store';
import { useAccount, useSigner, useProvider, erc20ABI } from 'wagmi';
import { BigNumber, ethers } from 'ethers';
import { getPegPrice, getBalancerLpTokenPrice, getDollarValue, getPlsDollarValue } from '../services/service';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClaimAirdrop } from '../components/Claim';
import { pls_airdrop } from '../assets';
import PepeMobileFooter from '../components/PepeMobileFooter';
import {
  pepeLockingContractAddress,
  pepePool,
  pepeStakingContractAddress,
  poolId,
  WETH,
  balancer_pool_tokens,
  plsTokenAddress,
  plsAutoCompounderContractAddress,
} from '../constants';
import { pepeStaking } from '../utils/pepeStaking';
import { pepeLock } from '../utils/pepeLock';
import { useSnackbar } from 'notistack';
import Navbar from '../components/Navbar/Navbar';
import { BalancerSDK, Network } from '@balancer-labs/sdk';
import Settings from '../components/Settings/Settings';
import UPMobile from '../components/UPMobile';
import { plsAutoCompounderABI } from '../utils/plsAutoCompounderABI';

const config = {
  network: Network.ARBITRUM,
  rpcUrl: `https://arb-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API}`,
};
const balancer = new BalancerSDK(config);

const INITIAL_DEPOSIT = 10000;

const Autocompounder = ({ tab }) => {
  const navigate = useNavigate();
  const { data: signer } = useSigner();
  const [deposit_withdraw, setDeposit_Withdraw] = useState('deposit');
  const [currentTab, setCurrentTab] = useState();
  const [isOngoing, setIsOngoing] = useState(false);
  const query = useMediaQuery('(max-width: 640px)');
  const Store = useContext(StoreContext);

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  const { setVaultModal, reloadData, setIsDeposit, vaultData, setVaultData } = Store;

  const { address } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const provider = useProvider();
  const floatRegexp = useMemo(() => /^[+-]?\d*(?:[.,]\d*)?$/, []);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const [peg_input_event, setPEGInputEvent] = useState(null);
  const [weth_input_event, setWETHInputEvent] = useState(null);
  const [formattedInput, setFormattedAmount] = useState('');
  const [peg_amount, setAmount] = useState(0);
  const [weth_amount, setWETHAmount] = useState(0);
  const [calculating, setCalculating] = useState(false);
  const [refresh, refreshData] = useState(false);
  const [priceOfPeg, setPriceOfPeg] = useState(0.0);
  const [totalPlsStaked, setTotalPlsStaked] = useState(0.0);
  const [totalUserDeposit, setTotalUserDeposit] = useState(0.0);
  const [accruedRewards, setAccruedRewards] = useState(0.0);
  const [walletBalance, setWalletBalance] = useState(0.0);
  const [totalPlsStakeUsdc, setTotalPlsStakeUsdc] = useState(0.0);
  const [totalUserDepositUsdc, setTotalUserDepositUsdc] = useState(0.0);
  const [accruedRewardsUsdc, setAccruedRewardsUsdc] = useState(0.0);
  const [walletBalanceUsdc, setWalletBalanceUdsc] = useState(0.0);
  const [walletBigNumber, setWalletBigNumber] = useState(BigNumber.from(0));
  const [depositBigNumber, setDepositBigNumber] = useState(BigNumber.from(0));
  const [amountDepWith, setAmountDepWith] = useState(0.0);
  const [amountDepWithBigNumber, setAmountDepWithBigNumber] = useState(BigNumber.from(0.0));

  const formatter = new Intl.NumberFormat('en-US');
  const debouncedValue_peg = useDebounce(peg_input_event, 2000);
  const debouncedValue_weth = useDebounce(weth_input_event, 2000);

  const plsAutoCompounderContract = useMemo(
    () => new ethers.Contract(plsAutoCompounderContractAddress, plsAutoCompounderABI, provider),
    [provider]
  );

  const plsTokenContract = useMemo(() => new ethers.Contract(plsTokenAddress, erc20ABI, provider), [provider]);


  function addZeroes(num) {
    const dec = num.split('.')[1]
    const len = dec && dec.length > 2 ? dec.length : 2
    return Number(num).toFixed(len)
  }
  const handleDepWithChange = (event) => {
    const amount = event === '' ? walletBalance : event === 'deposit' ? walletBalance : totalUserDeposit;
    const bigNum = event === '' ? walletBigNumber : event === 'deposit' ? walletBigNumber : depositBigNumber;
    setFormattedAmount(amount);
    setAmountDepWithBigNumber(bigNum);
    handleCheckingConversion(amount);
    setDeposit_Withdraw(event);
  }

  const handleChange = useCallback(
    (e) => {
      const value = e?.target?.value;
      const valid = floatRegexp.test(value?.replace(/,/g, ''));
      if (!valid) e?.preventDefault();
      if (valid) {
        const formatted = value?.replace(/,/g, '');
        let newAmount = value?.replace(/,/g, '') || '';

        if (newAmount.toString().length > 18) {
          newAmount = parseFloat(
            newAmount.toString().split('.')[0] + '.' + newAmount.toString().split('.')[1].slice(0, 18)
          );
        }
        setAmount(newAmount);
        setFormattedAmount(formatted);
        handleCheckingConversion(value);
      }
      if (value === '') {
        setAmount('');
        setFormattedAmount('');
        setAmountDepWith(0.0);
      }
    },
    [floatRegexp]
  );

  const handleCustomAmountChange = async (percentage) => {
    const value = deposit_withdraw === '' ? walletBigNumber : deposit_withdraw === 'deposit' ? walletBigNumber : depositBigNumber;
    if (value.gt(BigNumber.from(0))) {
      const percentageAmount = value.mul(percentage).div(1e4);
      const formatted = ethers.utils.formatUnits(percentageAmount, 18);
      setAmount(formatted);
      setFormattedAmount(formatted.toLocaleString());
      setAmountDepWith(await convertingPrice(formatted));
    }
  };

  const handleCheckingConversion = async (amount) => {
    const usdEquiv = await convertingPrice(amount);
    setAmountDepWith(usdEquiv);
  };

  const pegPrice = useCallback(async () => {
    try {
      const price = await getPegPrice();
      if (price) {
        setPriceOfPeg(parseFloat(price));
        return;
      }
      setPriceOfPeg(0.0);
    } catch (error) {
      setPriceOfPeg(0.0);
      console.log('get peg price', error);
    }
  }, [])

  const init = useCallback(() => {
      getTotalStaked();
      getUserDeposits();
      getAccruedwards();
      getUserWalletBalance();
      refreshData(false);
  }, []);

  useEffect(() => {
    pegPrice();
  }, [reloadData, refresh, pegPrice, init]);

  useEffect(() => {
    init();
  },[init]);

  const ethersCalculate = (data, places) => {
    try {
      return parseFloat(ethers.utils.formatUnits(data, 18)).toFixed(places);
    } catch (error) {
      return '0.00';
    }
  };

  const getTotalStaked = async () => {
    try {
      const totalStaked = await plsAutoCompounderContract.totalPlsStaked();
      setTotalPlsStaked(ethersCalculate(totalStaked, 2));
      setTotalPlsStakeUsdc(await convertingPrice(ethersCalculate(totalStaked, 2)));
    } catch (error) {
      console.log({ tvlError: error });
    }
  };

  const getUserDeposits = async () => {
    try {
      const stakeDetails = await plsAutoCompounderContract.plsStakeDetails(address);
      const deposits = ethersCalculate(stakeDetails.plsStaked, 2);
      setDepositBigNumber(stakeDetails.plsStaked);
      setTotalUserDeposit(deposits);
      setFormattedAmount(deposits);
      setTotalUserDepositUsdc(await convertingPrice(deposits));
      handleDepWithChange(deposit_withdraw);
    } catch (error) {
      console.log({ depositError: error });
    }
  };

  const getAccruedwards = async () => {
    try {
      const rewards = await plsAutoCompounderContract.calculatePendingRewards(address);
      setAccruedRewards(ethersCalculate(rewards, 2));
      setAccruedRewardsUsdc(await convertingPrice(ethersCalculate(rewards, 2)));
    } catch (error) {
      console.log({ depositError: error });
    }
  };

  const getUserWalletBalance = async () => {
    try {
      const balance = await plsTokenContract.balanceOf(address);
      if (balance.isZero()) {
        setDeposit_Withdraw('');
      } else {
        setDeposit_Withdraw('deposit');
      }
      setWalletBigNumber(balance);
      setWalletBalance(ethersCalculate(balance, 2));
      setWalletBalanceUdsc(await convertingPrice(ethersCalculate(balance, 2)));
    } catch (error) {
      console.log({ walletError: error });
    }
  };

  const convertingPrice = async (amount) => {
    const newAmount = {
      plutus: amount,
    };
    let prices = await getPlsDollarValue('plutusdao', newAmount);
    return prices;
  };

  const handleVaultModal = () => {
    setIsDeposit(deposit_withdraw === 'deposit' ? 'deposit' : 'withdraw');
    const vaultData = {
      amount: formattedInput,
      amountUsd: amountDepWith,
      amountBigNumber: amountDepWithBigNumber,
    };
    setVaultData(vaultData);
    setVaultModal(true);
  };

  const handleBuyPls = () => {

  }

  return (
    <>
      <div
        className="items-center md:container md:mx-auto"
        style={{
          background: query ? '#09031D' : `url(${main}) no-repeat fixed`,
          backgroundSize: 'cover',
          display: 'flex',
          maxWidth: '1550',
          minWidth: '1330',
          maxHeight: '980',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Navbar />
        {isMobile && <img src={finalmobilelogo} className="my-5 w-[95%] pointer-events-none" alt="Logo" />}
        <div className="stake-bg m-auto w-[90%] relative sm:mt-32 mb-4">
          {!isMobile && (
            <img
              src={pepe_top}
              className="top-[-6rem] w-[220px] sm:w-auto sm:top-[-14rem] left-0 right-0 block mx-auto absolute pointer-events-none"
              alt="Logo"
            />
          )}

          <p className='flex flex-col sm:flex-row sm:gap-1 items-center justify-center pb-5 px-5 text-3xl text-center sm:text-left sm:text-5xl font-["Tourney"] font-extrabold gradient-text uppercase mt-10 sm:mt-24'>
            <>
              <span>Pepe's Vaults</span>
            </>
          </p>
          <p className="px-10 pb-5 text-base text-center text-white barlow">
            <>
              <span>Where your favourite assets enjoy maximum yield</span>
            </>
          </p>

          {/* vault content */}

          <div className="border-border-blue shadow-[0px_0px_11px_#00B7F0] bg-white/[0.05] border-[0.5px] rounded-2xl mx-auto w-[90%] flex flex-col gap-y-8 sm:gap-y-[50px] items-center py-[50px] px-auto mb-[100px]">
            {/* static card */}
            <div className="flex flex-col gap-y-8 sm:gap-y-5 sm:bg-[#FFFFFF0D] sm:py-5 w-[84%] rounded-2xl">
            <div className="flex flex-col sm:flex-row justify-between sm:px-8 gap-10 sm:gap-0">
           <div className=" flex flex-col sm:flex-row items-center gap-2 font-[Tourney] uppercase text-white font-semibold text-[22px] leading-[22px] sm:text-3xl sm:leading-[30px] text-center">
             <span>
               <img src={pls_airdrop} alt="" />
             </span>
             PLS Autocompounder
           </div>

            <div className="flex flex-col sm:flex-row justify-around items-center gap-4  sm:gap-[8rem] h-full">
             <div className="flex sm:flex-col items-end sm:items-center justify-between h-full w-full sm:w-auto gap-7 border-b sm:border-b-0 pb-3 sm:pb-0">
               <span className="text-white font-medium">APY</span>
               <span className='text-blue-400 font-["Tourney"] font-extrabold text-xl'>8.30%</span>
            </div>

            <div className="flex sm:flex-col items-end sm:items-center justify-between h-full w-full sm:w-auto  gap-2 border-b sm:border-b-0 pb-3 sm:pb-0">
               <span className="text-white font-medium">TVL</span>
               <div className="flex flex-col justify-center items-center">
               <span className="text-sm text-white/20">
                      {addZeroes(formatter.format(Number(totalPlsStaked).toFixed(2)))}
                        {/* {totalPlsStaked} */}
                        PLS</span>
                      <span className='text-blue-400 font-["Tourney"] font-extrabold text-xl'>
                        ${addZeroes(formatter.format(Number(totalPlsStakeUsdc).toFixed(2)))}
                        {/* ${totalPlsStakeUsdc} */}
                      </span>
                    </div>
                  </div>

                  <div className="flex sm:flex-col items-end sm:items-center justify-between h-full w-full sm:w-auto gap-2 border-b sm:border-b-0 pb-3 sm:pb-0">
                    <span className="text-white font-medium">YOUR DEPOSIT</span>
                    <div className="flex flex-col justify-center">
                    <span className="text-sm text-white/20">
                        {addZeroes(formatter.format(Number(totalUserDeposit).toFixed(2)))}
                        {/* {totalUserDeposit}  */}
                        PLS</span>
                      <span className='text-blue-400 font-["Tourney"] font-extrabold text-xl'>
                        ${addZeroes(formatter.format(Number(totalUserDepositUsdc).toFixed(2)))}
                        {/* ${totalUserDepositUsdc} */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col-reverse sm:flex-row justify-between sm:px-8 mt-5 w-full sm:h-[17rem] gap-8 sm:gap-0">
           <div className="sm:w-[60%] bg-black/20 sm:h-[16rem] rounded-md sm:p-4">
           <div className="sm:mb-[4.5rem] text-white p-4 pb-1 sm:p-0">Vault Info</div>
             <div className="w-full p-2 sm:p-5 mt-2 sm:mt-10  rounded-md bg-white/5 sm:h-[4rem] border-[0.5px] border-border-blue  shadow-[0px_0px_5px_#00B7F0] ">
               <div className="flex flex-col justify-between h-full gap-5 sm:gap-0">
                    {/* <div className="flex text-white/50 justify-between">
                        <span>Last Harvest </span>
                        <span>YYYY-MM-DD </span>
                      </div> */}

                <div className="flex text-white items-end sm:items-start justify-between">
                   <span>Accrued Rewards(PLS) </span>
                   <div className="flex flex-col-reverse sm:flex-row items-center sm:gap-5">
                   <span className="text-xl">
                          {addZeroes(formatter.format(Number(accruedRewards).toFixed(2)))}</span>
                            {/* {accruedRewards}</span> */}
                          <span className="text-xs text-white/50 ">
                            {`($${addZeroes(formatter.format(Number(accruedRewardsUsdc).toFixed(2)))})`}
                            {/* {`($${accruedRewardsUsdc})`} */}
                            </span>{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sm:w-[38%] bg-black/20  sm:h-[16rem] flex flex-col rounded-md p-4 border-border-blue/50">                  
                <div className="text-white flex justify-around">
                    <div
                      onClick={() => handleDepWithChange('deposit')}
                      className={`flex justify-center py-2  w-[8rem] rounded-t-xs cursor-pointer sm:hover:bg-white/20 transition duration-500 ease-in-out ${
                        deposit_withdraw === 'deposit' ? 'border-b-3 border-b-2 border-border-blue' : ''
                      } `}
                    >
                      Deposit
                    </div>
                    <div
                      onClick={() => handleDepWithChange('withdraw')}
                      className={`flex justify-center py-2  w-[8rem] rounded-t-xs cursor-pointer sm:hover:bg-white/20 transition duration-500 ease-in-out ${
                        deposit_withdraw === 'withdraw' ? 'border-b-3 border-b-2 border-border-blue' : ''
                      } `}
                    >
                      Withdraw
                    </div>
                  </div>
                  <div className="bg-slate-900 w-full h-[9rem] flex flex-col justify-between mt-4 p-3 sm:p-4 rounded-xl shadow-md border-[1px] border-border-blue gap-4 sm:gap-0">
                    <div className="flex justify-between items-center">
                    <div className="flex items-center justify-center gap-2 bg-white/10 p-1 w-[5rem] h-[2rem] rounded-lg mt-4">
                        <img src={pls_airdrop} alt="" className="w-[20px]" />
                        <span className="text-white">PLS</span>
                      </div>

                      {/* upper inputs portions */}
                      <div className="flex flex-col justify-end">
                        {/* usd equivalent of selected amount */}
                        <span className="text-white/20 text-sm text-end">
                          {/* {deposit_withdraw === ''
                            ? `$${walletBalanceUsdc}`
                            : deposit_withdraw === 'deposit'
                            ? `$${walletBalanceUsdc}`
                            : `$${totalUserDepositUsdc}`} */}

                          {`$${addZeroes(formatter.format(Number(amountDepWith).toFixed(2)))}`}
                          {/* {`$${amountDepWith}`} */}
                        </span>

                        {/* main selected amount */}
                        <span className="text-white text-lg">
                          {/* {deposit_withdraw === ''
                            ? walletBalance
                            : deposit_withdraw === 'deposit'
                            ? walletBalance
                            : totalUserDeposit} */}
                          <form>
                            <input
                              type="number"
                              id="vault_input"
                              className="remove-number-arrows bg-slate-900 border-collapse border-0 w-[6rem] p-0 text-3xl text-right text-white placeholder-white outline-none focus:outline-none focus:ring-0"
                              placeholder={0.0}
                              value={formattedInput}
                              onChange={handleChange}
                            />
                          </form>
                        </span>
                      </div>
                    </div>

                    {/* lower wallet balances */}
                    <div className="flex justify-between gap-3">
                      <div className="flex items-center gap-2">
                        <span className="text-white/20 text-sm leading-none">
                          {
                            isMobile ? ("Wallet") : (
                              deposit_withdraw === 'withdraw' ? 'Your Deposit' : 'Wallet'
                            )
                          }
                        </span>

                        {/* main wallet balance / deposits */}
                        <span className="text-white/70 ">
                          {deposit_withdraw === 'deposit' ? walletBalance : totalUserDeposit}
                        </span>
                      </div>

                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleCustomAmountChange(6900)}
                          className="bg-white/10 hover:bg-white/20 h-[25px] text-[14px] text-white/50 rounded-md flex items-center justify-center px-3"                        >
                          69%
                        </button>
                        <button
                          onClick={() => handleCustomAmountChange(1e4)}
                          className="bg-white/10 hover:bg-white/20 h-[25px] text-[14px] text-white/50 rounded-md flex items-center justify-center px-2"
                        >
                          100%
                        </button>
                      </div>
                    </div>
                  </div>

                  {deposit_withdraw === '' && (
                    <button
                      onClick={() => handleBuyPls()}
                      className="py-1 mt-6 px-10 text-white bg-white/10 hover:bg-white/20 transition duration-300 ease-in-out rounded-lg m-auto"
                    >
                      Buy Pls
                    </button>
                  )}

                  {deposit_withdraw !== '' && (
                    <button
                      onClick={() => handleVaultModal()}
                      className="py-1 mt-4 px-10 text-white bg-white/10 hover:bg-white/20 transition duration-300 ease-in-out rounded-lg m-auto"
                    >
                      {deposit_withdraw === 'deposit' ? 'Deposit' : 'Withdraw'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMobile ? (
          <>
            <div className=" flex justify-end w-[92%]">
              <div className="pt-5 mx-2 ">
                <UPMobile />
              </div>
            </div>

            <div className="w-full ">
              <PepeMobileFooter classes="pt-5 w-full" />
            </div>
          </>
        ) : (
          <Settings />
        )}
      </div>
    </>
  );
};

export default Autocompounder;
