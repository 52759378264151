import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import StakeCard from '../StakeCard';
import { blueToken } from '../../assets';
import { StoreContext } from '../../utils/store';
import { pepeEsPegStakingABI } from '../../utils/pepeEsPegStakingABI';
import { useAccount, useSigner, useNetwork } from 'wagmi';
import { BigNumber, ethers } from 'ethers';
import {
  chain_ids,
  arbitriumContracts,
  baseContracts
} from '../../constants';
import { pepeStaking } from '../../utils/pepeStaking';
import { useMediaQuery } from 'usehooks-ts';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { pepeTokenABI } from '../../utils/pepeTokenABI';

const StakeTab = ({ handleUsdcClaims }) => {
  const { data: signer } = useSigner();
  const navigate = useNavigate();
  const {chain} = useNetwork()
  const store = useContext(StoreContext);
  const { setStakeModal, setCurrentStakeTab, reloadData, setIsEsPeg } = store;
  const { address: userAddress } = useAccount();
  const [stakeCount, setStakeCount] = useState(0);
  const [esPegStakes, setEsPegStakes] = useState([]);
  const [totalEsPegReward, setEsPegRewards] = useState(0.0);
  const [refresh, setRefresh] = useState(false);
  const [pegPendingRewards, setPegPendingRewards] = useState(0.0);
  const [pegStakeAmount, setPegStakeAmount] = useState(0.0);
  const [totalEsPEGSupply, setEsPEGTotalSupply] = useState(0.0);

  const {
    pepeStakingContractAddress,
    esPegTokenAddress,
    pepeEsPegStakingContractAddress,
    pepeFeeDistributorV2Address,
  } = chain?.id == chain_ids[0]? arbitriumContracts : baseContracts //// @dev chain Id index 0 is arbitrium


  const pepeEsPegStakingContract = useMemo(
    () => new ethers.Contract(pepeEsPegStakingContractAddress, pepeEsPegStakingABI, signer),
    [signer]
  );

  const pepeStakingContract = useMemo(
    () => new ethers.Contract(pepeStakingContractAddress, pepeStaking, signer),
    [signer]
  );

  const pepeFeeDistributorV2Contract = useMemo(
    () =>
      new ethers.Contract(
        pepeFeeDistributorV2Address,
        [
          'function pepeStakingPendingRewards(address) public view returns (uint256)',
          'function pepeLockUpPendingRewards(address) public view returns (uint256)',
        ],
        signer
      ),
    [signer]
  );

  const esPegTokenContract = useMemo(() => new ethers.Contract(esPegTokenAddress, pepeTokenABI, signer), [signer]);

  const gettingUserStakeCount = useCallback(async () => {
    try {
      const userStakeCount = await pepeEsPegStakingContract.userStakeCount(userAddress);
      setStakeCount(userStakeCount.toNumber());
    } catch (error) {
      console.error(error);
    }
  }, [userAddress, pepeEsPegStakingContract]);

  const getTotalSupply = useCallback(async () => {
    const totalSupply = await esPegTokenContract.totalSupply();
    setEsPEGTotalSupply(totalSupply);
  }, [esPegTokenContract]);

  const gettingAllUserStakes = useCallback(async () => {
    try {
      let stakes = [];
      let totalRewards = BigNumber.from(0);
      for (let index = 1; index <= stakeCount; index++) {
        const stakeData = await pepeEsPegStakingContract.stakes(userAddress, index);
        const totalStaked = await pepeEsPegStakingContract.totalSupply();
        totalRewards = totalRewards.add(stakeData.amountClaimable);
        const staked = { stakeData, totalStaked };
        stakes.push(staked);
      }
      setEsPegRewards(totalRewards);
      setEsPegStakes(stakes);
    } catch (error) {
      console.error({ stakeError: error });
    }
  }, [userAddress, pepeEsPegStakingContract, stakeCount]);

  const getUserPegStakes = useCallback(async () => {
    try {
      const stake_info = await pepeStakingContract.userStake(userAddress);
      let pending_rewards
      if(chain?.id == chain_ids[0]){
        pending_rewards = await pepeFeeDistributorV2Contract.pepeStakingPendingRewards(userAddress);
      }else{
        pending_rewards = await pepeStakingContract.pendingRewards(userAddress);
      }
      const stake = parseFloat(ethers.utils.formatUnits(stake_info.amount, 18)).toFixed(2);
      const rewards = parseFloat(ethers.utils.formatUnits(pending_rewards, 6)).toFixed(4);
      console.log('formatted pending rewards: ', ethers.utils.formatUnits(pending_rewards, 6));
      console.log('stake rewards: ', rewards);
      setPegPendingRewards(rewards);
      setPegStakeAmount(stake);
    } catch (error) {
      console.log(error);
    }
  }, [pepeStakingContract, userAddress, pepeFeeDistributorV2Contract]);

  const initiateStakes = useCallback(async () => {
    await gettingUserStakeCount();
    await gettingAllUserStakes();
    await getUserPegStakes();
    await getTotalSupply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddress, pepeStakingContract, esPegTokenContract, reloadData, pepeEsPegStakingContract, refresh, stakeCount]);

  useEffect(() => {
    if (userAddress) {
      initiateStakes();
    }
  }, [userAddress, initiateStakes, refresh]);

  const handleClaimAllEsPeg = async () => {
    let gasEstimate;
    try {
      gasEstimate = await pepeEsPegStakingContract.estimateGas.claimAll();
      gasEstimate = gasEstimate.toNumber() + 1e4;
    } catch (error) {
      console.error('handleClaimEsPegRewards error: ', error);
      enqueueSnackbar('Your transaction is likely to fail', {
        variant: 'warning',
      });
      gasEstimate = 3e6;
    }

    try {
      const rewards = await pepeEsPegStakingContract.pendingRewards(userAddress);
      const amount = parseFloat(ethers.utils.formatUnits(rewards, 18)).toFixed(2);
      if (amount > 0) {
        await pepeEsPegStakingContract.claimAll({ gasLimit: gasEstimate });
        enqueueSnackbar('All esPeg rewards claimed', {
          variant: 'success',
        });
        setRefresh(true);
      }else{
        enqueueSnackbar('Rewards too low to claim', {
          variant: 'warning',
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Staking failed.', {
        variant: 'error',
      });
      return;
    }
  };

  const handleClaimEsPegRewards = async (esPegStakeID) => {
    let gasEstimate;

    try {
      gasEstimate = await pepeEsPegStakingContract.estimateGas.claim(esPegStakeID);
      gasEstimate = gasEstimate.toNumber() + 1e4;
    } catch (error) {
      console.error('handleClaimEsPegRewards error: ', error);
      enqueueSnackbar('Your transaction is likely to fail', {
        variant: 'warning',
      });
      gasEstimate = 3e6;
    }

    try {
      const rewards = await pepeEsPegStakingContract.pendingRewards(userAddress);
      const amount = parseFloat(ethers.utils.formatUnits(rewards, 18)).toFixed(2);
      if (amount > 0) {
        await pepeEsPegStakingContract.claim(esPegStakeID, { gasLimit: gasEstimate });
        setRefresh(true);
        enqueueSnackbar('Claiming esPeg Rewards Successful!', {
          variant: 'success',
        });
      }else{
        enqueueSnackbar('Rewards too low to claim', {
          variant: 'warning',
        });
      }

      await gettingAllUserStakes()
     
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Claim esPeg Rewards Unsuccessful!', {
        variant: 'error',
      });
    }
  };

  const isMobile = useMediaQuery('(max-width: 640px)');

  return (
    <div className="stake-box-shadow border-border-blue bg-white/[0.05] border-[0.5px] rounded-2xl mx-auto w-[90%] flex flex-col gap-y-8 sm:gap-y-[50px] items-center py-[50px] px-auto mb-[100px]">
      <div className="font-[Tourney] text-white font-semibold text-[22px] leading-[22px] sm:text-3xl sm:leading-[30px] uppercase">
        What's at stake?
      </div>

      {/* static card */}
      <div className="flex flex-col gap-y-[25px] sm:gap-y-[20px] bg-[#FFFFFF0D] py-5 w-[84%] rounded-2xl">
        <div>
          <div className="flex justify-center mb-[16px] space-x-4 barlow -tracking-[2%] text-2xl text-white font-medium">
            <img className="inline-block w-[32px] h-[32px]" src={blueToken} alt="pepe token icon" />
            <span className="">PEG</span>
            {/* <img className="inline-block w-[32px] h-[32px]" src={blueETH} alt='peg weth token icon' />
          <span className="">wETH</span> */}
          </div>
          <div className="flex justify-center text-white font-barlow">Earn 10% Protocol Fees</div>
        </div>
        <div className="mx-auto w-[92%]">
          <table className="justify-center w-full">
            {isMobile ? (
              <>
                <div className="flex">
                  <thead className="text-white text-sm leading-[18px] flex-1 inline-table">
                    <tr className="flex">
                      <th className="p-[5px] flex-1 font-normal text-sm barlow">MY STAKE</th>
                      <th className="p-[5px] flex-1 font-normal text-sm barlow">REWARDS</th>
                      {/* <th className="p-[5px] font-normal text-sm barlow">VESTED</th> */}
                    </tr>
                  </thead>
                </div>

                <StakeCard isPEG={true} rewards={pegPendingRewards} stakeAmount={pegStakeAmount} />
              </>
            ) : (
              <>
                <thead className="text-white text-sm leading-[18px]">
                  <tr>
                    <th className="p-[7px] font-normal text-sm barlow">ASSET</th>
                    <th className="p-[7px] font-normal text-sm barlow">EST APR</th>
                    <th className="p-[7px] font-normal text-sm barlow">MY STAKE</th>
                    <th className="p-[7px] font-normal text-sm barlow">PENDING REWARDS</th>
                    <th className="p-[7px] font-normal text-sm barlow"></th>
                  </tr>
                </thead>
                <StakeCard isPEG={true} rewards={pegPendingRewards} stakeAmount={pegStakeAmount} />
              </>
            )}
          </table>
        </div>
        <div className="space-x-[5px] flex lg:justify-end lg:mr-20 mx-auto mt-5 w-full px-[10px] sm:px-0 sm:w-auto">
          <button
            className="sm:min-w-[130px] w-3/4 flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center bg-white/[0.05] text-white"
            onClick={() => {
              setIsEsPeg(false);
              setStakeModal(true);
              setCurrentStakeTab('stake');
            }}
          >
            Stake
          </button>
          {parseFloat(pegStakeAmount) > 0 && (
            <>
              <button
                className="sm:min-w-[130px] w-3/4 flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center bg-white/[0.05] text-white"
                onClick={() => {
                  setIsEsPeg(false);
                  setStakeModal(true);
                  setCurrentStakeTab('unstake');
                }}
              >
                Unstake
              </button>
              <button
                className={`sm:min-w-[130px] w-3/4 flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center bg-white/[0.05] text-white
              ${pegPendingRewards <= 0 ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}`}
                onClick={() => {
                  // setIsEsPeg(false);
                  // setStakeModal(true);
                  // setCurrentStakeTab('claim');

                  handleUsdcClaims();
                }}
                disabled={pegPendingRewards <= 0 ? true : false}
              >
                Claim
              </button>
            </>
          )}
        </div>
      </div>

      {/* second card */}
      {chain?.id == chain_ids[0] &&
      <div className="flex flex-col gap-y-[25px] sm:gap-y-[20px] bg-[#FFFFFF0D] py-5 w-[84%] rounded-2xl -mt-5">
        <div>
          <div className="flex justify-center mb-[16px] space-x-4 barlow -tracking-[2%] text-2xl text-white font-medium">
            <img className="inline-block w-[32px] h-[32px]" src={blueToken} alt="esPeg token icon" />
            <span className="">esPEG</span>
            {/* <img className="inline-block w-[32px] h-[32px]" src={blueETH} alt='wETH token icon' />
          <span className="">wETH</span> */}
          </div>
          <div className="flex justify-center text-white text-center px-4 font-barlow">
            Stake esPEG for a 90-day vesting period and receive PEG rewards
          </div>
        </div>
        <div className="mx-auto w-[92%]">
          {isMobile ? (
            <table className="justify-center w-full pr-2 bg-transparent">
              <div className="flex">
                <thead className="text-white text-sm leading-[18px] flex-1 inline-table">
                  <tr>
                    <th className="p-[7px] font-normal text-sm barlow">MY STAKE</th>
                    <th className="p-[7px] font-normal text-sm barlow">DAYS LEFT</th>
                    <th className="p-[7px] font-normal text-sm barlow">VESTED</th>
                  </tr>
                </thead>
              </div>
              <tbody className="text-center text-[#FFFFFF66]">
                {esPegStakes.length > 0 &&
                  esPegStakes.map((stake, index) => (
                    <StakeCard
                      handleClaimEsPegRewards={handleClaimEsPegRewards}
                      isPEG={false}
                      stake={stake}
                      totalSupply={totalEsPEGSupply}
                      stakeID={index + 1}
                    />
                  ))}
              </tbody>
            </table>
          ) : (
            <table className="justify-center w-full pr-2 bg-transparent">
              <thead className="text-white text-sm leading-[18px]">
                <tr>
                  <th className="p-[7px] font-normal text-sm barlow">ASSET</th>
                  <th className="p-[7px] font-normal text-sm barlow">TOTAL STAKED</th>
                  <th className="p-[7px] font-normal text-sm barlow">TOTAL SUPPLY</th>
                  <th className="p-[7px] font-normal text-sm barlow">DAYS LEFT</th>
                  <th className="p-[7px] font-normal text-sm barlow">MY STAKE</th>
                  <th className="p-[7px] font-normal text-sm barlow">PEG VESTED</th>
                  <th className="p-[7px] font-normal text-sm barlow"></th>
                </tr>
              </thead>
              {esPegStakes.length > 0 &&
                esPegStakes.map((stake, index) => (
                  <StakeCard
                    handleClaimEsPegRewards={handleClaimEsPegRewards}
                    key={index}
                    isPEG={false}
                    stake={stake}
                    totalSupply={totalEsPEGSupply}
                    stakeID={index + 1}
                  />
                ))}
            </table>
          )}
        </div>
        <div className="space-x-[5px] flex lg:justify-end lg:mr-20 mx-auto mt-5 w-full px-[10px] sm:px-0 sm:w-auto">
          <button
            className="sm:min-w-[130px] w-3/4 flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center bg-white/[0.05] text-white"
            onClick={() => {
              setIsEsPeg(true);
              setStakeModal(true);
              setCurrentStakeTab('stake');
            }}
          >
            Stake
          </button>
          <button
              onClick={() => handleClaimAllEsPeg()}
              className={`min-w-[130px] w-3/4 flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center bg-white/[0.05] text-white disabled:opacity-50 disabled:cursor-not-allowed
              `}
              disabled={typeof totalEsPegReward==="object"? totalEsPegReward.isZero() ? true : false:totalEsPegReward>0 ?true : false}
            >
              Claim all
            </button>
        </div>
        {/* {esPegStakes.length > 0 && (
          <div className="mx-auto mt-5">
            <button
              onClick={() => handleClaimAllEsPeg()}
              className={`min-w-[130px] w-3/4 flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center bg-[rgba(9,3,29,0.24)] shadow-lg text-white
              ${totalEsPegReward.isZero() ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}
              `}
              disabled={totalEsPegReward.isZero() ? true : false}
            >
              Claim all
            </button>
          </div>
        )} */}
      </div>
      }
      <div className="mx-auto mb-[32px]">
        <button
          onClick={() => {
            navigate('/degen-dashboard', { state: { openDefault: true } });
            localStorage.removeItem('hasVisitedDOTW');
          }}
          className="bg-[#09031D] py-[5px] px-[30px] text-[#18ADFA] rounded-md stake-button-shadow"
        >
          How do I earn esPEG
        </button>
      </div>
    </div>
  );
};

export default StakeTab;
