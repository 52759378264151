import { ethers } from 'ethers';
import { useAccount, useSigner, useProvider, erc20ABI, useNetwork } from 'wagmi';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { StoreContext } from '../../utils/store';
import { blueToken, cancel_icon_w_gradient, smartPepe } from '../../assets';
import { useSnackbar } from 'notistack';
import { getBalancerLpTokenPrice } from '../../services/service';
import { chain_ids, arbitriumContracts, baseContracts } from '../../constants';
import { pepeLockUpExtensionABI } from '../../utils/pepe-lockup-extension-abi';

const LockPPLPModal = ({ open, isOpen, promptType, isEsPeg }) => {
  const { data: signer } = useSigner();
  const provider = useProvider();
  const { address } = useAccount();
  const { chain } = useNetwork()

  const { enqueueSnackbar } = useSnackbar();

  const Store = useContext(StoreContext);

  const {
    pepeProxyLPTokenAddress,
    pepeLockupExtensionContractAddress
  } = chain?.id == chain_ids[0]? arbitriumContracts : baseContracts //// @dev chain Id index 0 is arbitrium

  const { pepePPLPReward } = Store;
  const pplpTokenContract = useMemo(() => new ethers.Contract(pepeProxyLPTokenAddress, erc20ABI, provider), [provider]);

  const pepeLockUpExtensionContract = useMemo(
    () => new ethers.Contract(pepeLockupExtensionContractAddress, pepeLockUpExtensionABI, provider),
    [provider]
  );

  const [isComplete, setIsComplete] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [formattedInput, setFormattedAmount] = useState('');
  const [amount, setAmount] = useState('');
  const [pplpPrice, setPPLPPrice] = useState(0);
  const [userPplpLockInfo, setUserPplpLockInfo] = useState(null);

  const floatRegexp = useMemo(() => /^[+-]?\d*(?:[.,]\d*)?$/, []);

  const [hash, setHash] = useState('');

  const handleChange = useCallback(
    async (e) => {
      const value = e.target.value;
      const valid = floatRegexp.test(value.replace(/,/g, ''));
      if (!valid) e.preventDefault();

      if (valid) {
        const formatted = value.replace(/,/g, '');
        setAmount(value.replace(/,/g, '') || '');
        setFormattedAmount(formatted);

        console.log(formatted);
      }
      if (value === '') {
        setAmount(''); // Clear the amount state variable
        setFormattedAmount('');
      }
    },
    [floatRegexp]
  );

  const getPPLPPrice = useCallback(async () => {
    const pplpPrice = await getBalancerLpTokenPrice();
    if (pplpPrice) {
      setPPLPPrice(pplpPrice);
      return;
    }
    setPPLPPrice(0);
  }, []);

  const getUserPPLPAllowance = useCallback(async () => {
    try {
      const pplpAllowance = await pplpTokenContract.allowance(address, pepeLockupExtensionContractAddress);
      const parsedAmount = ethers.utils.parseUnits(amount ? amount.toString() : '0', 18);
      // setPPLPAllowance(pplpAllowance);
      if (amount) {
        console.log('formattedPPLP allowance', ethers.utils.formatUnits(pplpAllowance, 18));
        if (pplpAllowance.gte(parsedAmount)) {
          setIsApproved(true);
          return;
        }
        setIsApproved(false);
        return;
      }
      setIsApproved(false);
    } catch (error) {
      console.log(error);
    }
  }, [pplpTokenContract, address, amount]);

  const approvePPLP = useCallback(async () => {
    try {
      const pplpTokenContract_ = new ethers.Contract(pepeProxyLPTokenAddress, erc20ABI, signer);
      const approveTx = await pplpTokenContract_.approve(
        pepeLockupExtensionContractAddress,
        ethers.constants.MaxUint256
      );
      const approvalReceipt = await approveTx.wait();
      await getUserPPLPAllowance();
      enqueueSnackbar(`Successfully approved PEG-wETH rewards to lock, Tx hash: ${approvalReceipt.transactionHash}`, {
        variant: 'success',
      });
      // const pplpAllowance = await pplpTokenContract.allowance(address, pepeLockupExtensionContractAddress);
      // const parsedAmount = ethers.utils.parseUnits(amount.toString(), 18);
      // if (pplpAllowance.gte(parsedAmount)) {
      setIsApproved(true);
      //}
    } catch (error) {
      console.log('PEG-wETH approval error: ', error);
      enqueueSnackbar('Failed to approve PEG-wETH rewards to lock', {
        variant: 'error',
      });
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signer]);

  const lockPPLP = useCallback(async () => {
    const parsedAmount = pepePPLPReward; //ethers.utils.parseUnits(amount.toString(), 18);
    
    try {
      const pepeLockupExtensionContract_ = new ethers.Contract(
        pepeLockupExtensionContractAddress,
        pepeLockUpExtensionABI,
        signer
      );

      let gasUnits;
      try {
        gasUnits = await pepeLockupExtensionContract_.estimateGas.lockProxyLp(parsedAmount);
        gasUnits = gasUnits.toNumber() + 1e4;
      } catch (error) {
        gasUnits = 3e6;
      }

      const lockTx = await pepeLockupExtensionContract_.lockProxyLp(parsedAmount, { gasLimit: gasUnits });
      const lockReceipt = await lockTx.wait();
      setHash(lockReceipt.transactionHash);
      enqueueSnackbar(`Successfully locked PEG-wETH rewards, Tx hash: ${lockReceipt.transactionHash}`, {
        variant: 'success',
      });
      setIsComplete(true);
    } catch (error) {
      console.error('PEG-wETH lock error: ', error);
      enqueueSnackbar('Failed to lock PEG-wETH rewards', {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, signer]);

  const getUserPPLPLockDetails = useCallback(async () => {
    const userPPLPLockDetails = await pepeLockUpExtensionContract.extensionLockDetails(address);
    //console.log('userPPLPLockDetails', userPPLPLockDetails);
    setUserPplpLockInfo(userPPLPLockDetails);
  }, [address, pepeLockUpExtensionContract]);

  useEffect(() => {
    getPPLPPrice();
    getUserPPLPLockDetails();
    getUserPPLPAllowance();
  }, [getPPLPPrice, getUserPPLPAllowance, getUserPPLPLockDetails]);

  useEffect(() => {
    const formattedReward = ethers.utils.formatUnits(pepePPLPReward, 18);
    //console.log('formattedReward: ', formattedReward);
    setAmount(formattedReward);

    setFormattedAmount(formattedReward);
  }, [pepePPLPReward]);

  const ethersCalculate = (data, places) => {
    try {
      return parseFloat(ethers.utils.formatUnits(data, 18)).toFixed(places);
    } catch (error) {
      return '0.00';
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={() => open(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-deep-blue/[0.89] flex justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`modal-dialog transition-all transform ${
                  isComplete ? 'w-[739px]' : promptType ? 'w-[542px]' : 'w-[520px]'
                } stake-modal flex max-[480px]:w-4/5 relative`}
              >
                <div className="flex flex-col justify-around w-full mx-auto">
                  <div className="flex items-end justify-end mt-4 mr-4">
                    <button onClick={() => open(false)}>
                      <img src={cancel_icon_w_gradient} alt="cancel icon" width="15px" />
                    </button>
                  </div>
                  {isComplete ? (
                    <>
                      <div className='flex items-center justify-center text-7xl font-["Tourney"] font-extrabold gradient-text text-center mt-8'>
                        YEAH!
                      </div>
                      <div className="mt-8 mb-40 text-center text-white barlow">
                        <p className="text-3xl">You have successfully locked</p>
                        <p className='text-[43px] font-["Tourney"] font-extrabold gradient-text'>
                          {parseFloat(formattedInput)?.toFixed(4)} PEG-wETH
                        </p>
                        <p className="text-3xl">to your frenly dealer</p>
                        <a className="text-xs underline" target="__blank" href={'https://arbiscan.io/tx/' + hash}>
                          See tx
                        </a>
                      </div>
                      <img src={smartPepe} className="absolute bottom-0 left-0" />
                    </>
                  ) : (
                    <>
                      <div className='flex uppercase items-center justify-center text-4xl font-["Tourney"] font-extrabold gradient-text text-center mb-4'>
                        {promptType ? (
                          'Add to Position'
                        ) : (
                          <div>
                            <p>LOCK IN YOUR</p>
                            <p>PEG-wETH GIFT!</p>
                          </div>
                        )}
                      </div>
                      {promptType ? (
                        <div className="bg-white/[0.05] rounded-md barlow text-white text-center w-[90%] mx-auto mb-5 p-2">
                          <p className="text-lg font-semibold">Your existing Position</p>
                          <p className="mb-2 text-sm">
                            Locked Liquidity until{' '}
                            {new Date(userPplpLockInfo?.lockDuration * 1000)?.toLocaleDateString() || 'Date Error'}
                          </p>
                          <div className="flex justify-evenly">
                            <div>
                              <p className="uppercase">$PEG-wETH</p>
                              <p className="">
                                {/* {ethers?.utils?.formatUnits(userLockingState?.pegLocked, 18) || 'Ethers 1'} */}
                                {ethersCalculate(userPplpLockInfo?.amount, 4)}
                              </p>
                            </div>
                            <div>
                              <p className="uppercase">TOTAL </p>
                              <p className="">
                                $
                                {(
                                  parseFloat(ethers.utils.formatUnits(userPplpLockInfo?.amount, 18)) * pplpPrice
                                ).toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="flex items-center justify-between w-4/5 px-4 py-5 mx-auto text-white border border-border-blue bg-deep-blue/70 stake-modal-shadow rounded-xl barlow">
                        <p className="text-[#E0DDFF] text-sm flex items-center">
                          <img src={blueToken} alt="" className="w-[35px] h-[35px] mr-4" />
                          <span>PEG-wETH</span>
                        </p>
                        <div className="flex flex-col items-end w-1/2 justify-evenly">
                          <input
                            className="dwf-funds-input-dark !mr-0"
                            onChange={handleChange}
                            placeholder="0.00"
                            value={formattedInput}
                          />

                          <p className="text-xs text-[#E0DDFF]/30">
                            ${amount ? (parseFloat(amount) * pplpPrice).toLocaleString() : '0.00'}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-2 my-4 text-sm text-white">
                        <p>You are locking: {amount ? amount.toLocaleString() : '0.00'} $PEG-wETH</p>
                        {'\n'}
                        {promptType && <p>to your existing lock position</p>}
                      </div>
                      <div className="flex justify-center my-4">
                        {isApproved ? (
                          <button
                            onClick={() => lockPPLP()}
                            className="inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[256px] text-xs inter mx-4"
                          >
                            {' '}
                            Add to lock
                          </button>
                        ) : (
                          <>
                            <button
                              onClick={() => approvePPLP()}
                              disabled={!amount}
                              className={`inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[135px] text-xs inter mx-4 ${
                                !amount ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                              }`}
                            >
                              Approve $PEG-wETH
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  <p className={`text-white text-xs p-8 pt-0 ${isComplete ? 'hidden' : ''}`}>
                    *Pepe would like to thank you for being a committed LP locker by gifting you some extra PEG-wETH for
                    your LP position. This gift will match your existing lock duration and continue to generate USDC
                    rewards. Importantly, this gift won't change your lock time-frame, allowing you to earn more without
                    affecting your existing position. Enjoy!
                  </p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default LockPPLPModal;
