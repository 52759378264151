import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { blueETH, blueToken } from '../../assets';
import LockCard from '../LockCard';
import { StoreContext } from '../../utils/store';
import { enqueueSnackbar } from 'notistack';
import { BigNumber, ethers } from 'ethers';
import { useAccount, useSigner, useNetwork } from 'wagmi';
import {
  arbitriumContracts,
  baseContracts,
  chain_ids
} from '../../constants';
import { pepeEsPegLockABI } from '../../utils/pepeEsPegLockABI';
import { pepeTokenABI } from '../../utils/pepeTokenABI';
import { pepeLock } from '../../utils/pepeLock';
import { getDollarValue } from '../../services/service';
import { useMediaQuery } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';

const Lock = () => {
  const store = useContext(StoreContext);
  const { setLockModal, setPromptType, setStoreState, reloadData, setIsEsPeg, setRefreshLocking, refreshLocking } =
    store;
  const { data: signer } = useSigner();
  const { address: userAddress } = useAccount();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [esPegLocks, setEsPegLocks] = useState([]);
  const [userLockDetails, setUserLockDetails] = useState({});
  const [lockCount, setLockCount] = useState(0);
  const [timetillunlock, setTimeUntilUnlock] = useState('');
  const [timeLocked, setDateLocked] = useState('');
  const [totalTokens, setTotalTokens] = useState({})
  const {chain} = useNetwork()

  const {
    esPegTokenAddress,
    pepeLockingContractAddress,
    pepeEsPegLockUpContractAddress,
    pepeFeeDistributorV2Address,
  } = chain?.id == chain_ids[0]? arbitriumContracts : baseContracts //// @dev chain Id index 0 is arbitrium

  const pepeEsPegLockingContract = useMemo(
    () => new ethers.Contract(pepeEsPegLockUpContractAddress, pepeEsPegLockABI, signer),
    [signer]
  );

  const pepeLockingContract = useMemo(
    () => new ethers.Contract(pepeLockingContractAddress, pepeLock, signer),
    [signer]
  );
  
  const pepeEsPegTokenContract = useMemo(
    () => new ethers.Contract(esPegTokenAddress, pepeTokenABI, signer),
    [signer]
  )

  const pepeFeeDistributorV2Contract = useMemo(
    () =>
      new ethers.Contract(
        pepeFeeDistributorV2Address,
        [
          'function pepeStakingPendingRewards(address) public view returns (uint256)',
          'function pepeLockUpPendingRewards(address) public view returns (uint256)',
        ],
        signer
      ),
    [signer]
  );

  const gettingUserLockCount = useCallback(async () => {
    try {
      const userLockCount = await pepeEsPegLockingContract.userLockCount(userAddress);
      setLockCount(userLockCount.toNumber());
    } catch (error) {
      console.error(error);
    }
  }, [userAddress, pepeEsPegLockingContract]);

  const gettingAllUserEsPegLocks = useCallback(async () => {
    try {
      const userLockCount = await pepeEsPegLockingContract.userLockCount(userAddress);
      const lockCount = userLockCount.toNumber();
      let locks = [];
      if (lockCount === 0) {
        return locks;
      }
      for (let index = 1; index <= lockCount; index++) {
        let lockDetails = {};
        const lock = await pepeEsPegLockingContract.lockUpDetails(userAddress, index);
        if (lock.esPegLocked.isZero()) continue;
        const pendingRewards = await pepeEsPegLockingContract.pendingUsdcRewards(userAddress, index);
        const rewardsAmount = parseFloat(ethers.utils.formatUnits(pendingRewards, 6)).toFixed(2);
        const totalEsPegLock = await pepeEsPegLockingContract.totalEsPegLocked();
        const totalEsPEGSupply = await pepeEsPegLockingContract.totalSupply();
        lockDetails = { ...lockDetails, ...lock, lockID: index, pendingRewards: rewardsAmount, totalEsPegLock:totalEsPegLock, totalEsPEGSupply:totalEsPEGSupply };
        locks.push(lockDetails);
      }

      //console.log({ locks });
      setEsPegLocks(locks);
      const totalSupply = await pepeEsPegTokenContract.totalSupply();
      const totalEsPegLocked = await pepeEsPegLockingContract.totalEsPegLocked();
      // console.log('total supply', totalSupply.toString())
      setTotalTokens({totalSupply: totalSupply, totalEsPegLocked: totalEsPegLocked})
      console.log('total espeglocked', totalEsPegLocked.toString())
    } catch (error) {
      console.error({ lockError: error });
    }
  }, [pepeEsPegLockingContract, pepeEsPegTokenContract, userAddress]);

  const formatDate = (unixtimetamp) => {
    const date = new Date(unixtimetamp * 1000);
    // Define an array of month names
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    // Get the day, month, and year from the Date object
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    // Add the appropriate suffix to the day
    let daySuffix;
    if (day === 1 || day === 21 || day === 31) {
      daySuffix = 'st';
    } else if (day === 2 || day === 22) {
      daySuffix = 'nd';
    } else if (day === 3 || day === 23) {
      daySuffix = 'rd';
    } else {
      daySuffix = 'th';
    }

    // Return the formatted string
    return `${day}${daySuffix} ${month}, ${year}`;
  };

  const getUserPegLockDetails = useCallback(async () => {
    try {
      let userLockDetails_ = await pepeLockingContract.lockDetails(userAddress);
      console.log(userLockDetails_);
      if (!userLockDetails_.totalLpShare.isZero()) {
        let usdValues = await getDollarValue('weth', {
          weth: ethers.utils.formatUnits(userLockDetails_.wethLocked, 18),
          peg: ethers.utils.formatUnits(userLockDetails_.pegLocked, 18),
        });

        const pendingRewards = await pepeFeeDistributorV2Contract.pepeLockUpPendingRewards(userAddress);
        const rewardsAmount = parseFloat(ethers.utils.formatUnits(pendingRewards, 6)).toFixed(3);
        // console.log('rewardsAmount: ', rewardsAmount);
        let modifiedData = {};
        modifiedData = {
          ...modifiedData,
          ...userLockDetails_,
          pegUsd: usdValues[0],
          wethUsd: usdValues[1],
          pendingRewards: rewardsAmount,
        };
        setStoreState('userLockingState', modifiedData);
        const details = modifiedData;
        setUserLockDetails((prevUserLockDetails) => {
          return { ...prevUserLockDetails, ...details };
        });
        // calculate time until unlock
        const dateObj = new Date(modifiedData.unlockTimestamp * 1000);
        const month = dateObj.getUTCMonth() - new Date(modifiedData.lockedAt * 1000).getUTCMonth();
        const days = Math.floor(modifiedData.lockedAt / (1000 * 60 * 60 * 24));
        const hours = dateObj.getUTCHours();
        const minutes = dateObj.getUTCMinutes();
        let fd = formatDate(modifiedData.lockedAt);
        setDateLocked(fd);
        setTimeUntilUnlock(`${month}mn ${days}d ${hours}h ${minutes}m`);
      }
    } catch (error) {
      console.error(error);
    }
  }, [pepeFeeDistributorV2Contract, pepeLockingContract, setStoreState, userAddress]);

  const initiateLocks = useCallback(async () => {
    await getUserPegLockDetails();
    await gettingUserLockCount();
    await gettingAllUserEsPegLocks();
    setRefresh(false);
  }, [getUserPegLockDetails, gettingAllUserEsPegLocks, gettingUserLockCount]);

  const isMobile = useMediaQuery('(max-width: 640px)');

  useEffect(() => {
    if (userAddress) {
      initiateLocks();
      setRefresh(false);
    }
  }, [userAddress, initiateLocks, reloadData, refresh]);

  useEffect(() => {
    const fetchData = async () => {
      if (refreshLocking && userAddress) {
        await initiateLocks();
        setRefreshLocking(false);
        setRefresh(false);
      }
    };

    fetchData();
  }, [initiateLocks, refreshLocking, refresh, setRefreshLocking, reloadData, userAddress]);

  return (
    <div className="stake-box-shadow border-border-blue bg-white/[0.05] border-[0.5px] rounded-2xl mx-auto w-[90%] flex flex-col gap-y-8 sm:gap-y-[50px] items-center py-[50px] px-auto mb-[100px]">
      <div className="font-[Tourney] uppercase text-white font-semibold text-[22px] leading-[22px] sm:text-3xl sm:leading-[30px] text-center">
        Your locked position
      </div>

      {/* static card */}
      <div className="flex flex-col gap-y-[25px] sm:gap-y-5 bg-[#FFFFFF0D] py-5 w-[84%] rounded-2xl">
        <div>
          <div className="flex sm:mt-5 justify-center mb-[16px] space-x-4 barlow -tracking-[2%] text-2xl text-white font-medium">
            <div className="flex items-center space-x-1">
              <img className="inline-block w-[32px] h-[32px]" src={blueToken} />
              <span className="">PEG</span>
            </div>
            <div className="flex items-center space-x-1">
              <img className="inline-block w-[32px] h-[32px]" src={blueETH} />
              <span className="">wETH</span>
            </div>
          </div>
          <div className="flex justify-center text-white font-barlow">Earn 30% Protocol Fees</div>
        </div>
        <div className="mx-auto w-[92%]">
          <table className="justify-center w-full">
            {isMobile ? (
              <>
                <div className="flex">
                  <thead className="text-white text-sm leading-[18px] flex-1 inline-table">
                    <tr className="flex">
                      <th className="p-[5px] flex-1 font-normal text-sm barlow">LOCK</th>
                      <th className="p-[5px] flex-1 font-normal text-sm barlow">UNLOCK</th>
                      <th className="p-[5px] flex-1 font-normal text-sm barlow">REWARDS</th>
                    </tr>
                  </thead>
                </div>

                <LockCard isPEG={true} lock={userLockDetails} allowRefresh={() => setRefresh(true)} />
              </>
            ) : (
              <>
                <thead className="text-white text-sm leading-[18px]">
                  <tr>
                    <th className="p-[7px] font-normal text-sm barlow">ASSET</th>
                    <th className="p-[7px] font-normal text-sm barlow">DATE LOCKED</th>
                    <th className="p-[7px] font-normal text-sm barlow">UNLOCK DATE</th>
                    <th className="p-[7px] font-normal text-sm barlow">EST APR</th>
                    <th className="p-[7px] font-normal text-sm barlow">PEG LOCKED</th>
                    <th className="p-[7px] font-normal text-sm barlow">PENDING REWARDS</th>
                    <th className="p-[7px] font-normal text-sm barlow"></th>
                  </tr>
                </thead>
                <LockCard isPEG={true} lock={userLockDetails} allowRefresh={() => setRefresh(true)} />
              </>
            )}
          </table>
        </div>
        <div className="space-x-[5px] flex lg:justify-end lg:mr-20 mx-auto mt-5">
          <button
            className="px-[17px] sm:px-[30px] w-3/4 flex-1 rounded-lg mx-0 sm:mx-1 py-2 flex items-center justify-center bg-white/[0.05] text-white"
            onClick={() => {
              setIsEsPeg(false);
              setLockModal(true);
              setPromptType(false);
            }}
          >
            Lock PEG-wETH
          </button>
        </div>
      </div>

      {/* second card */}
      <div className="flex flex-col gap-y-[25px] sm:gap-y-5 bg-[#FFFFFF0D] py-5 w-[84%] rounded-2xl -mt-5">
        <div>
          <div className="flex justify-center sm:mt-5 mb-[16px] space-x-4 barlow -tracking-[2%] text-2xl text-white font-medium">
            <div className="flex items-center space-x-1">
              <img className="inline-block w-[32px] h-[32px]" src={blueToken} />
              <span className="">esPEG</span>
            </div>
            <div className="flex items-center space-x-1">
              <img className="inline-block w-[32px] h-[32px]" src={blueETH} />
              <span className="">wETH</span>
            </div>
          </div>
          <div className="flex justify-center text-white text-center font-barlow px-4">
            Lock for 45 days and unlock your position as PEG & wETH
          </div>
        </div>
        <div className="mx-auto w-[92%]">
          <table className="justify-center w-full pr-2 bg-transparent">
            {isMobile ? (
              <>
                <div className="flex">
                  <thead className="text-white text-sm leading-[18px] flex-1 inline-table">
                    <tr className="flex">
                      <th className="p-[5px] flex-1 font-normal text-sm barlow">LOCK</th>
                      <th className="p-[5px] flex-1 font-normal text-sm barlow">UNLOCK</th>
                      <th className="p-[5px] flex-1 font-normal text-sm barlow">REWARDS</th>
                    </tr>
                  </thead>
                </div>

                {esPegLocks.length > 0 &&
                  esPegLocks.map((lock) => (
                    <LockCard isPEG={false} lock={lock} lockID={lock.lockID} totalTokens={totalTokens} allowRefresh={() => setRefresh(true)} />
                  ))}
              </>
            ) : (
              <>
                <thead className="text-white text-sm leading-[18px]">
                  <tr>
                    <th className="p-[7px] font-normal text-sm barlow">ASSET</th>
                    <th className="p-[7px] font-normal text-sm barlow">TOTAL LOCKED</th>
                    <th className="p-[7px] font-normal text-sm barlow">TOTAL SUPPLY</th>
                    <th className="p-[7px] font-normal text-sm barlow">DAYS LEFT</th>
                    <th className="p-[7px] font-normal text-sm barlow">esPEG LOCKED</th>
                    <th className="p-[7px] font-normal text-sm barlow">PENDING REWARDS</th>
                    <th className="p-[7px] font-normal text-sm barlow"></th>
                  </tr>
                </thead>
                {esPegLocks.length > 0 &&
                  esPegLocks.map((lock) => (
                    <LockCard isPEG={false} lock={lock} key={lock.lockID} lockID={lock.lockID} totalTokens={totalTokens} allowRefresh={() => setRefresh(true)} />
                  ))}
              </>
            )}
          </table>
        </div>
        <div className="space-x-[5px] flex lg:justify-end lg:mr-20 mx-auto mt-5">
          <button
            className="px-[17px] sm:px-[30px] w-3/4 flex-1 rounded-lg mx-0 sm:mx-1 py-2 flex items-center justify-center bg-white/[0.05] text-white"
            onClick={() => {
              setIsEsPeg(true);
              setLockModal(true);
              setPromptType(false);
            }}
          >
            Lock esPEG-wETH
          </button>
        </div>

        <div className="mx-auto mt-5">
          <button
            onClick={() => {
              navigate('/degen-dashboard', { state: { openDefault: true } });
              localStorage.removeItem('hasVisitedDOTW');
            }}
            className="min-w-[130px] w-3/4 flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center bg-[rgba(9,3,29,0.24)] shadow-lg text-white w-[170px] text-xs"
          >
            How do I earn esPEG
          </button>
        </div>
      </div>
    </div>
  );
};

export default Lock;
