export const DownArrow = ({ selected = false }) => (
  <svg height="100%" width="auto" viewBox="0 0 134 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={selected ? '1' : '0.4'} filter="url(#filter0_d_4824_99760)">
      <g opacity="0.16">
        <path
          d="M107.336 28.5957L107.023 31.597L104.789 37.6106L108.97 43.1661L124.491 47.2897L125.772 48.2438L127.287 56.259C127.618 58.0101 126.083 59.5453 124.332 59.2143L89.7565 52.6798C87.7596 52.3026 87.0005 49.8431 88.4378 48.4058L95.7833 41.0603C97.0041 39.8394 97.0041 37.86 95.7833 36.6391L76.3504 17.2062C75.1296 15.9854 73.1501 15.9854 71.9295 17.2062L49.9933 39.1424C45.3396 43.7971 37.7652 43.7976 33.1098 39.1424L7.30237 13.335C5.96171 11.9944 5.96171 9.8208 7.30237 8.48014L11.3762 4.40629C12.7169 3.06562 14.8904 3.06562 16.2311 4.40629L39.3409 27.5163C40.5617 28.7372 42.5412 28.7372 43.7618 27.5163L65.6984 5.58013C70.3519 0.925927 77.9261 0.924953 82.582 5.58013L104.712 27.7104C105.428 28.4264 106.404 28.7191 107.336 28.5957Z"
          fill={selected ? 'url(#selectedInnerColor)' : '#09031D'}
        />
        <path
          d="M109.702 44.2503C105.001 43.0821 102.285 38.0473 104.065 33.5419C104.662 32.0302 105.697 30.3737 107.336 28.5957C107.993 28.5085 108.629 28.2157 109.134 27.7107L116.479 20.3651C117.916 18.928 120.376 19.6871 120.753 21.6841L125.773 48.2441L109.702 44.2503Z"
          fill={selected ? 'url(#selectedRoundedTip)' : '#09031D'}
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64.4074 4.28866C69.7742 -1.07898 78.5049 -1.07986 83.874 4.28846L92.827 13.2414C93.5403 13.9547 93.5403 15.1112 92.827 15.8245C92.1137 16.5378 90.9572 16.5378 90.2439 15.8245L81.2911 6.87174C81.2911 6.87171 81.2912 6.87178 81.2911 6.87174C77.3485 2.92982 70.9308 2.93082 66.9907 6.87154L45.0539 28.8078C43.1199 30.7418 39.9839 30.7421 38.0499 28.8078C38.0499 28.8078 38.05 28.8079 38.0499 28.8078L14.9399 5.6978C14.3125 5.07044 13.2955 5.07044 12.6681 5.6978L8.59425 9.77166C7.96689 10.399 7.96689 11.4161 8.59425 12.0434L34.4016 37.8508C34.4016 37.8508 34.4016 37.8508 34.4016 37.8508C38.3438 41.7928 44.7617 41.7921 48.702 37.851L70.6386 15.9147C72.5727 13.9805 75.7084 13.9805 77.6425 15.9147L97.0754 35.3476C99.0096 37.2817 99.0096 40.4176 97.0754 42.3518L89.7299 49.6973C89.3305 50.0967 89.5415 50.7802 90.0964 50.8851L124.671 57.4196C125.158 57.5115 125.585 57.0851 125.493 56.598L118.958 22.0231C118.854 21.4685 118.17 21.2568 117.771 21.6564C117.771 21.6564 117.771 21.6564 117.771 21.6564L110.425 29.0019C108.491 30.9361 105.355 30.9361 103.421 29.0019L96.2706 21.8517C95.5573 21.1384 95.5573 19.9819 96.2706 19.2686C96.9839 18.5553 98.1404 18.5553 98.8537 19.2686L106.004 26.4188C106.511 26.9264 107.334 26.9264 107.842 26.4188L115.187 19.0733C117.662 16.5989 121.898 17.905 122.548 21.3447C122.548 21.3446 122.548 21.3447 122.548 21.3447L129.082 55.9198C129.652 58.9348 127.009 61.5791 123.993 61.0091L89.4179 54.4745C85.9788 53.8245 84.672 49.589 87.1468 47.1142L94.4923 39.7687C94.9999 39.2612 94.9999 38.4382 94.4923 37.9306L75.0595 18.4978C74.5519 17.9902 73.7292 17.9902 73.2216 18.4978L51.2854 40.4338C51.2853 40.4339 51.2854 40.4338 51.2854 40.4338C45.9183 45.8019 37.1872 45.8023 31.8186 40.434L6.01117 14.6265C3.95721 12.5726 3.95721 9.24253 6.01117 7.18857L10.085 3.11472C12.139 1.06076 15.469 1.06076 17.523 3.11472L40.633 26.2247C41.1402 26.732 41.9632 26.7324 42.4708 26.2247L64.4074 4.28866Z"
        fill={selected ? 'url(#selectedOutline)' : '#09031D'}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4824_99760"
        x="0.470703"
        y="0.262573"
        width="132.689"
        height="68.8245"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4824_99760" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4824_99760" result="shape" />
      </filter>

      {/* for the selected arrow background outline */}
      <linearGradient
        id="selectedOutline"
        x1="4.33887"
        y1="0.106445"
        x2="129.028"
        y2="0.106445"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00B7F0" />
        <stop offset="1" stop-color="#4819BE" />
      </linearGradient>

      <linearGradient
        id="selectedRoundedTip"
        x1="6.16504"
        y1="1.93298"
        x2="127.2"
        y2="1.93298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00B7F0" />
        <stop offset="1" stop-color="#4819BE" />
      </linearGradient>

      <linearGradient
        id="selectedInnerColor"
        x1="6.16504"
        y1="1.93298"
        x2="127.2"
        y2="1.93298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00B7F0" />
        <stop offset="1" stop-color="#4819BE" />
      </linearGradient>
    </defs>
  </svg>
);
