import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { cancel_icon_w_gradient, smartPepe } from '../../assets';
import { useAccount, useSigner, erc20ABI, useNetwork } from 'wagmi';
import { ethers, BigNumber } from 'ethers';
import pepe_blue from '../../assets/images/pepe_blue.svg';
import { baseContracts, arbitriumContracts, chain_ids } from '../../constants';
import { getPegPrice } from '../../services/service';
import { pepeStaking } from '../../utils/pepeStaking';
import { pepeEsPegStakingABI } from '../../utils/pepeEsPegStakingABI';
import { useSnackbar } from 'notistack';
import { StoreContext } from '../../utils/store';
import { useStorage } from '../../utils/StorageContext';
import { dollarFormatter } from '../../utils/dollarFormatter';

const StakeModal = ({ open, isOpen, currentTab, isEsPeg, esPegStakeID }) => {
  const Store = useContext(StoreContext);
  const { setCurrentStakeTab, setStoreState, reloadData } = Store;
  const floatRegexp = useMemo(() => /^[+-]?\d*(?:[.,]\d*)?$/, []);
  const { address } = useAccount();
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const [isComplete, setIsComplete] = useState(false);
  const [formattedInput, setFormattedAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [userPegOrEsPegBalance, setUserPegOrEsPegBalance] = useState(BigNumber.from(0));
  const [allowance, setAllowance] = useState(BigNumber.from(0));
  const { data: signer } = useSigner();
  const { enqueueSnackbar } = useSnackbar();
  const [hash, setHash] = useState('');
  const [totalStaked, setTotalStaked] = useState(BigNumber.from(0));
  const [refresh, setFreshData] = useState(false);
  const [pegPrice, setPegPrice] = useState(0.0);
  // const [totalRewards, setClaimableReward] = useState(0.0);

  const {
    esPegTokenAddress,
    pepeStakingContractAddress,
    pegContractAddress,
    pepeEsPegStakingContractAddress,
    esPegStakingContractAddress,
  } = chain?.id == chain_ids[0] ? arbitriumContracts : baseContracts; //// @dev chain Id index 0 is arbitrium

  const [isApproved, setIsApproved] = useState(false);

  const stakingContract = useMemo(
    () =>
      new ethers.Contract(
        isEsPeg ? esPegStakingContractAddress : pepeStakingContractAddress,
        isEsPeg ? pepeEsPegStakingABI : pepeStaking,
        signer
      ),
    [isEsPeg, signer]
  );

  const tokenContract = useMemo(
    () =>
      new ethers.Contract(isEsPeg && currentTab !== 'claim' ? esPegTokenAddress : pegContractAddress, erc20ABI, signer),
    [isEsPeg, signer, currentTab]
  );

  //const usdcContract = useMemo(() => new ethers.Contract(USDC_ADDRESS, erc20ABI, signer), [signer]);

  const pegCurrentPrice = useCallback(async () => {
    const price = await getPegPrice();
    if (price) {
      setPegPrice(parseFloat(price));
      return;
    }
    setPegPrice(0.0);
  }, []);

  const getUserBalance = useCallback(async () => {
    try {
      const balance = await tokenContract.balanceOf(address);
      setUserPegOrEsPegBalance(balance);

      if (currentTab === 'stake') setFormattedAmount(0.0);

      // const my_total_staked = await tokenContract.getUserStake(address);
      // setTotalStaked(ethers.utils.formatUnits(my_total_staked, 18).toLocaleString());
    } catch (error) {
      console.log('get balance', error);
    }
  }, [tokenContract, address, currentTab]);

  // const getUserUSDCBalance = useCallback(async () => {
  //   try {
  //     const balance = await usdcContract.balanceOf(address);
  //! use a different state variable to track:     setUserPegOrEsPegBalance(ethers.utils.formatUnits(balance, 6).toLocaleString());
  //   } catch (error) {
  //     console.log('get balance', error);
  //   }
  // }, [usdcContract, address]);

  // const getClaimableBalance = useCallback(async () => {
  //   try {
  //     setFormattedAmount(0);
  //     const functionParams = isEsPeg ? [address, esPegStakeID] : [address];
  //     const userRewardDetails = await stakingContract[isEsPeg ? 'stakes' : 'pendingRewards'](...functionParams);
  //     setUserPegOrEsPegBalance(
  //       isEsPeg ? userRewardDetails.amount : userRewardDetails
  //     );
  //     setClaimableReward(
  //       ethers.utils.formatUnits(isEsPeg ? userRewardDetails.amount : userRewardDetails, 18).toLocaleString()
  //     );
  //   } catch (error) {}
  // }, [isEsPeg, address, esPegStakeID, stakingContract]);

  const getUserAllowance = useCallback(async () => {
    try {
      const allowance = await tokenContract.allowance(
        address,
        isEsPeg ? pepeEsPegStakingContractAddress : pepeStakingContractAddress
      );
      setAllowance(allowance);
      if (!amount) {
        if (allowance.gt(ethers.utils.parseUnits('50', 18))) {
          setIsApproved(true);
          return;
        }
        setIsApproved(false);
      } else {
        if (allowance.gte(ethers.utils.parseUnits(amount.toString(), 18))) {
          setIsApproved(true);
          return;
        }
        setIsApproved(false);
      }
    } catch (error) {
      console.error('get allowance 1:', error);
    }
  }, [tokenContract, address, isEsPeg, amount]);

  useEffect(() => {
    //if(currentTab != "claim"){
    pegCurrentPrice();
    // console.log('current tab::', currentTab)
    if (currentTab === 'unstake') {
      getUserStake();
    }

    if (currentTab === 'stake') {
      getUserBalance();
    }

    getUserAllowance();
    // getClaimableBalance()-
    // if (!isEsPeg && currentTab === 'claim') {
    //   console.log('espeg claim');
    //   getUserUSDCBalance();
    // }
    setStoreState('reloadData', !reloadData);
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signer, refresh, tokenContract, stakingContract, isEsPeg, currentTab]);

  useEffect(() => {
    getUserAllowance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, refresh]);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      const valid = floatRegexp.test(value.replace(/,/g, ''));
      if (!valid) e.preventDefault();
      if (valid) {
        const formatted = value.replace(/,/g, '');
        let newAmount = value.replace(/,/g, '') || '';

        if (newAmount.toString().length > 18) {
          newAmount = parseFloat(
            newAmount.toString().split('.')[0] + '.' + newAmount.toString().split('.')[1].slice(0, 18)
          );
        }
        setAmount(newAmount);
        setFormattedAmount(formatted);
      }
      if (value === '') {
        setAmount('');
        setFormattedAmount('');
      }
    },
    [floatRegexp]
  );

  const handleCustomAmountChange = async (percentage) => {
    const value = currentTab === 'stake' ? userPegOrEsPegBalance : totalStaked;
    console.log('total staked: ', ethers.utils.formatUnits(totalStaked, 18).toLocaleString());

    //let value;

    // switch (currentTab) {
    //   case 'stake':
    //     value = userPegOrEsPegBalance;
    //     break;
    //   case 'unstake':
    //     value = totalStaked;
    //     break;
    //   default:
    //     value = totalRewards;
    //     break;
    // }
    ///Suiiiii
    // console.log(currentTab, ':', value)
    if (value.gt(BigNumber.from(0))) {
      const percentageAmount = value.mul(percentage).div(1e4);
      const formatted = ethers.utils.formatUnits(percentageAmount, 18);
      console.log('formatted: ', formatted);
      setAmount(formatted);
      setFormattedAmount(formatted.toLocaleString());
    }
  };

  const handleApprove = useCallback(async () => {
    let parsedAmount;
    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(realAmount.toString(), 18);
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(allowance)) {
      try {
        await tokenContract.approve(
          isEsPeg ? pepeEsPegStakingContractAddress : pepeStakingContractAddress,
          ethers.constants.MaxUint256
        );
        const allowance = await tokenContract.allowance(
          address,
          isEsPeg ? pepeEsPegStakingContractAddress : pepeStakingContractAddress
        );
        setIsApproved(true);
        setAllowance(allowance);
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Approval failed.', {
          variant: 'error',
        });
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, allowance, amount, tokenContract, isEsPeg]);

  const handleStake = async () => {
    let parsedAmount;
    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(realAmount.toString(), 18);
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(ethers.utils.parseUnits('1', 18))) {
      let estimateGas;
      try {
        estimateGas = await stakingContract.estimateGas.stake(parsedAmount);
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (e) {
        estimateGas = 3e6;
      }

      try {
        let tx = await stakingContract.stake(parsedAmount, { gasLimit: estimateGas });
        const receipt = await tx?.wait();
        // console.log("reciept:",receipt);
        enqueueSnackbar('Staking Successful.', {
          variant: 'success',
        });
        setHash(receipt.transactionHash);
        setIsComplete(true);
        console.log('in stake:', { reloadData });
        setStoreState('reloadData', !reloadData);
        getUserAllowance();
        getUserStake();
        return;
      } catch (error) {
        console.log('errors:', error);
        enqueueSnackbar('Staking failed.', {
          variant: 'error',
        });
        return;
      }
    } else {
      enqueueSnackbar('You cannot stake lessthan 2 PEG.', {
        variant: 'error',
      });
    }
  };

  const getUserStake = useCallback(async () => {
    const functionParams = isEsPeg ? [address, esPegStakeID] : [address];
    const userStakeDetails = await stakingContract[isEsPeg ? 'stakes' : 'userStake'](...functionParams);
    setTotalStaked(userStakeDetails.amount);
    setUserPegOrEsPegBalance(userStakeDetails.amount);
    setFormattedAmount(0.0);
  }, [isEsPeg, address, esPegStakeID, stakingContract]);

  const handleUnStake = async () => {
    enqueueSnackbar('Unstaking, confirm in wallet', {
      variant: 'info',
    });
    const stakingContract1 = new ethers.Contract(pepeStakingContractAddress, pepeStaking, signer);
    let parsedAmount;

    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(realAmount.toString(), 18);
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(BigNumber.from(0))) {
      let estimateGas;
      try {
        estimateGas = await stakingContract1.estimateGas.unStake(parsedAmount);
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (error) {
        estimateGas = 3e6;
      }
      try {
        const tx = await stakingContract1.unStake(parsedAmount, { gasLimit: estimateGas });
        await tx.wait();
        setFreshData(!refresh);
        enqueueSnackbar('Unstaking Successful!', {
          variant: 'success',
        });
        open(false);
        console.log('in unstake:', { reloadData });
        setStoreState('reloadData', !reloadData);
        getUserAllowance();
        getUserStake();

        return;
      } catch (error) {
        setFreshData(!refresh);
        console.log(error);
        enqueueSnackbar('Unstaking failed.', {
          variant: 'error',
        });
        return;
      }
    } else {
      setFreshData(!refresh);
      enqueueSnackbar('Unable to unStake 0 staking', {
        variant: 'warning',
      });
      return;
    }
  };

  const handleClaimRewards = async () => {
    let gasEstimate;

    try {
      gasEstimate = await stakingContract.estimateGas.claimRewards();
      gasEstimate = gasEstimate.toNumber() + 1e4;
    } catch (error) {
      console.error('handleClaimRewards error: ', error);
      enqueueSnackbar('Your transaction is likely to fail', {
        variant: 'warning',
      });
      gasEstimate = 3e6;
    }

    try {
      await stakingContract.claimRewards({ gasLimit: gasEstimate });
      enqueueSnackbar('Claiming Rewards Successful!', {
        variant: 'success',
      });

      getUserAllowance();
      getUserStake();
      setStoreState('reloadData', !reloadData);
      setFreshData(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Claim rewards unsuccessful!', {
        variant: 'error',
      });
    }
  };

  const handleClaimEsPegRewards = async () => {
    let gasEstimate;

    try {
      gasEstimate = await stakingContract.estimateGas.claim(esPegStakeID);
      gasEstimate = gasEstimate.toNumber() + 1e4;
    } catch (error) {
      console.error('handleClaimEsPegRewards error: ', error);
      enqueueSnackbar('Your transaction is likely to fail', {
        variant: 'warning',
      });
      gasEstimate = 3e6;
    }

    try {
      await stakingContract.claim(esPegStakeID, { gasLimit: gasEstimate });
      setFreshData(true);

      setStoreState('reloadData', !reloadData);

      getUserAllowance();
      getUserStake();

      enqueueSnackbar('Claiming esPeg Rewards Successful!', {
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Claim esPeg Rewards Unsuccessful!', {
        variant: 'error',
      });
    }
  };
  useEffect(() => {
    setAmount(0);
    setIsComplete(false);
    setIsApproved(false);
  }, [isOpen]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={() => open(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-deep-blue/[0.89] flex justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom={`${isComplete ? 'opacity-100' : 'opacity-0'} scale-95`}
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`modal-dialog transition-all transform ${
                  isComplete ? 'w-[739px] h-[600px]' : 'w-[628px] h-[344px]'
                } stake-modal flex max-[480px]:w-4/5 relative`}
              >
                <div className="flex flex-col justify-around w-full py-2 mx-auto">
                  <div className="flex items-end justify-end mr-4 -mt-2">
                    <button onClick={() => open(false)}>
                      <img src={cancel_icon_w_gradient} alt="cancel icon" width="15px" />
                    </button>
                  </div>
                  {isComplete ? (
                    <>
                      <div className='flex items-center justify-center text-7xl font-["Tourney"] font-extrabold gradient-text text-center mt-2'>
                        YEAH!
                      </div>
                      <div className="mb-40 text-center text-white barlow">
                        <p className="text-3xl">You have successfully staked</p>
                        {/* <p className='text-[43px] font-["Tourney"] font-extrabold gradient-text'>
                          {typeof amount == 'object' ? parseFloat(ethers.utils.formatUnits(amount, 18)).toFixed(4) : parseFloat(amount).toFixed(4)} {isEsPeg ? 'esPEG' : 'PEG'}
                        </p> */}
                        <p className='text-[43px] font-["Tourney"] font-extrabold gradient-text'>
                          {typeof amount === 'object'
                            ? Number.isInteger(parseFloat(ethers.utils.formatUnits(amount, 18)))
                              ? parseFloat(ethers.utils.formatUnits(amount, 18))
                              : parseFloat(ethers.utils.formatUnits(amount, 18)).toFixed(4)
                            : Number.isInteger(parseFloat(amount))
                            ? parseFloat(amount)
                            : parseFloat(amount).toFixed(4)}{' '}
                          {isEsPeg ? 'esPEG' : 'PEG'}
                        </p>
                        <p className="text-3xl">to your frenly dealer</p>
                        <a
                          className="text-xs underline"
                          target="__blank"
                          href={`${
                            arbsOrBase === 'base'
                              ? 'https://basescan.org/tx/' + hash
                              : ' https://arbiscan.io/tx/' + hash
                          }`}
                        >
                          See tx
                        </a>
                      </div>
                      <img
                        src={arbsOrBase === 'base' ? pepe_blue : smartPepe}
                        alt=""
                        className={`absolute bottom-0 left-0 ${arbsOrBase === 'base' && 'w-[150px]'}`}
                      />
                    </>
                  ) : (
                    <>
                      <div className='flex items-center justify-center text-[33px] font-["Tourney"] font-extrabold gradient-text text-center'>
                        {currentTab === 'stake'
                          ? isEsPeg
                            ? 'STAKE esPEG!'
                            : 'STAKE PEG!'
                          : currentTab === 'unstake'
                          ? isEsPeg
                            ? 'UNSTAKE esPEG!'
                            : 'UNSTAKE PEG!'
                          : 'CLAIM REWARDS!'}
                      </div>
                      <div className="flex justify-center gap-4 text-white">
                        <button
                          className={`${currentTab === 'stake' ? 'border-b border-border-blue' : ''}`}
                          onClick={() => {
                            setCurrentStakeTab('stake');
                          }}
                        >
                          Stake
                        </button>
                        {!isEsPeg && (
                          <button
                            className={`${currentTab === 'unstake' ? 'border-b border-border-blue' : ''}`}
                            onClick={() => {
                              setCurrentStakeTab('unstake');
                            }}
                          >
                            Unstake
                          </button>
                        )}
                        {/* <button
                          className={`${currentTab === 'claim' ? 'border-b border-border-blue' : ''}`}
                          onClick={() => {
                            if (!isEsPeg) { getUserUSDCBalance() } else { getUserBalance() }
                            getClaimableBalance();
                            setCurrentStakeTab('claim');
                          }}
                        >
                          Claim Rewards
                        </button> */}
                      </div>
                      {currentTab === 'stake' ? (
                        <>
                          <div className="flex flex-col items-center justify-between w-4/5 px-4 py-5 mx-auto text-white border border-border-blue bg-deep-blue/70 stake-modal-shadow rounded-xl barlow sm:flex-row">
                            <p className="text-[#E0DDFF] text-sm flex flex-col">
                              <span className="text-slate-400 text-[11px]">Wallet Balance</span>
                              <span className="mr-1">
                                {isEsPeg ? 'esPEG' : 'PEG'}{' '}
                                {parseFloat(ethers.utils.formatUnits(userPegOrEsPegBalance, 18)).toFixed(4)}
                              </span>
                              <span className="text-slate-400">
                                $
                                {(parseFloat(ethers.utils.formatUnits(userPegOrEsPegBalance, 18)) * pegPrice).toFixed(
                                  2
                                )}
                              </span>
                            </p>
                            <div className="flex items-center w-auto sm:w-1/2 justify-evenly">
                              {/* <p className='text-xl'>1,000,000.00</p> */}
                              <input className="dwf-funds-input-dark" onChange={handleChange} value={formattedInput} />
                              {/* <button
                                onClick={() => handleCustomAmountChange(69)}
                                className="bg-white/10 opacity-80 rounded-md mr-2 p-1.5"
                              >
                                69%
                              </button> */}
                              <button
                                onClick={() => handleCustomAmountChange(1e4)}
                                className="bg-white/10 opacity-80 rounded-md p-1.5"
                              >
                                100%
                              </button>
                            </div>
                          </div>
                        </>
                      ) : currentTab === 'unstake' ? (
                        <>
                          <div className="flex flex-col items-center justify-between w-4/5 px-4 py-5 mx-auto text-white border border-border-blue bg-deep-blue/70 stake-modal-shadow rounded-xl barlow sm:flex-row">
                            <p className="text-[#E0DDFF] text-sm flex flex-col">
                              <span className="text-slate-400 text-[11px]">Stake Balance</span>
                              <span className="mr-1">
                                {isEsPeg ? 'esPEG' : 'PEG'}{' '}
                                {parseFloat(ethers.utils.formatUnits(userPegOrEsPegBalance, 18)).toFixed(4)}
                              </span>
                              <span className="text-slate-400">
                                $
                                {(parseFloat(ethers.utils.formatUnits(userPegOrEsPegBalance, 18)) * pegPrice).toFixed(
                                  4
                                )}
                              </span>
                            </p>
                            <div className="flex items-center w-auto sm:w-1/2 justify-evenly">
                              {/* <p className='text-xl'>1,000,000.00</p> */}
                              <input className="dwf-funds-input-dark" onChange={handleChange} value={formattedInput} />
                              {/* <button
                                onClick={() => handleCustomAmountChange(6900)}
                                className="bg-white/10 opacity-80 rounded-md mr-2 p-1.5"
                              >
                                69%
                              </button> */}
                              <button
                                onClick={() => handleCustomAmountChange(1e4)}
                                className="bg-white/10 opacity-80 rounded-md p-1.5"
                              >
                                100%
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex flex-col items-center justify-between w-4/5 px-4 py-5 mx-auto text-white border border-border-blue bg-deep-blue/70 stake-modal-shadow rounded-xl barlow sm:flex-row">
                            <p className="text-[#E0DDFF] text-sm">
                              <span className="mr-1">
                                {isEsPeg ? 'PEG' : 'USDC'}{' '}
                                {parseFloat(ethers.utils.formatUnits(userPegOrEsPegBalance, 18)).toFixed(4)}
                              </span>
                            </p>
                            <div className="flex items-center w-auto sm:w-1/2 justify-evenly">
                              {/* <p className='text-xl'>1,000,000.00</p> */}
                              <input className="dwf-funds-input-dark" onChange={handleChange} value={formattedInput} />
                              {/* <button
                                onClick={() => handleCustomAmountChange(6900)}
                                className="bg-white/10 opacity-80 rounded-md mr-2 p-1.5"
                              >
                                69%
                              </button> */}
                              <button
                                onClick={() => handleCustomAmountChange(1e4)}
                                className="bg-white/10 opacity-80 rounded-md p-1.5"
                              >
                                100%
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="flex justify-center text-xs text-white">
                        <p>
                          You are{' '}
                          {currentTab === 'stake' ? 'staking' : currentTab === 'unstake' ? 'unstaking' : 'claiming'}:
                        </p>
                        <p>
                          &nbsp;
                          {currentTab === 'stake'
                            ? `${isEsPeg ? 'esPEG' : 'PEG'} ${parseFloat(formattedInput).toFixed(4)} (${dollarFormatter(
                                formattedInput * pegPrice
                              )})`
                            : currentTab === 'unstake'
                            ? `  ${isEsPeg ? 'esPEG' : 'PEG'} ${parseFloat(formattedInput).toFixed(
                                4
                              )} (${dollarFormatter(formattedInput * pegPrice)})`
                            : ` ${isEsPeg ? 'PEG' : 'USDC'}  ${formattedInput}`}
                        </p>
                      </div>
                      <div className="flex justify-center">
                        {currentTab === 'stake' ? (
                          <>
                            <button
                              disabled={isApproved}
                              onClick={() => handleApprove()}
                              className={`inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[135px] text-xs inter mx-4
                              ${isApproved ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}
                              `}
                            >
                              {' '}
                              Approve{' '}
                            </button>
                            <button
                              onClick={() => handleStake()}
                              className={`inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[135px] text-xs inter mx-4
                              ${!isApproved ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}
                              `}
                            >
                              {' '}
                              Stake{' '}
                            </button>
                          </>
                        ) : currentTab === 'unstake' ? (
                          <button
                            disabled={isEsPeg}
                            className={`inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[135px] text-xs inter mx-4 ${
                              isEsPeg ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                            }`}
                            onClick={() => (isEsPeg ? '' : handleUnStake())}
                          >
                            {' '}
                            Unstake{' '}
                          </button>
                        ) : (
                          <button
                            disabled={formattedInput <= 0}
                            onClick={() => (isEsPeg ? handleClaimEsPegRewards() : handleClaimRewards())}
                            className={`inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[170px] text-xs inter mx-4 ${
                              formattedInput <= 0 ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                            }`}
                          >
                            {' '}
                            Send to my Wallet, Ser!{' '}
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default StakeModal;
