import { useEffect, useState } from 'react';

const useLocalStorage = (keyName, defaultValue) => {
  const [localStorageData, setLocalStorageData] = useState(() => {
    try {
      const value = localStorage.getItem(keyName);
      if (value) {
        return isJSON(value);
      } else {
        return defaultValue;
      }
    } catch (err) {
      console.log(err);
      return defaultValue;
    }
  });

  function isJSON(data) {
    try {
      return JSON?.parse(data);
    } catch (e) {
      return data;
    }
  }

  function toString(data) {
    try {
      const newData = data?.localStorageData ? data.localStorageData : data;
      return JSON.stringify(newData);
    } catch (e) {
      return data;
    }
  }

  const setValue = (newValue) => {
    try {
      localStorage.setItem(keyName, toString(newValue));
      setLocalStorageData(newValue);
      return true;
    } catch (err) {
      console.error('UserlocaStorage error: ', err);
      return false;
    }
  };

  const removeValue = () => {
    try {
      localStorage.removeItem(keyName);
      return true;
    } catch (err) {
      console.error('UserlocaStorage error: ', err);
      return false;
    }
  };
  return { localStorageData, setLocalStorageData: setValue, removeLocalStorageData: removeValue };
};

export default useLocalStorage;
