import React from 'react';

const Speedometer = ({hovered}) => {
  return (
     <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 20" fill="none">
     <path d="M20.5174 4.02864C20.197 3.70825 19.694 3.66101 19.3195 3.91624C17.9084 4.87802 10.8309 9.71435 10.0702 10.4749C8.96719 11.578 8.96719 13.3728 10.0702 14.4759C10.6218 15.0274 11.3463 15.3032 12.0707 15.3032C12.7951 15.3032 13.5196 15.0274 14.0712 14.4759C14.8318 13.7153 19.6681 6.6377 20.6299 5.22666C20.885 4.85214 20.8378 4.34913 20.5174 4.02864ZM12.7375 13.1422C12.3698 13.51 11.7715 13.51 11.4039 13.1422C11.0362 12.7746 11.0362 12.1765 11.4037 11.8088C11.6993 11.5167 13.7087 10.0836 16.181 8.36512C14.4625 10.8374 13.0294 12.8468 12.7375 13.1422Z" fill="url(#paint0_linear_1736_98625)"/>
     <path d="M4.34878 16.8616L3.18463 17.4295C2.3328 15.9093 1.88604 14.2124 1.88604 12.4754C1.88604 11.2028 2.12071 9.98389 2.54898 8.85976L3.88293 9.4996C4.01448 9.56269 4.15334 9.59258 4.29013 9.59258C4.64154 9.59258 4.97891 9.39526 5.14106 9.05718C5.36625 8.58761 5.16822 8.02429 4.6986 7.79905L3.38158 7.16732C5.01852 4.49763 7.85074 2.63635 11.1277 2.33411V3.75246C11.1277 4.27329 11.5499 4.69548 12.0707 4.69548C12.5915 4.69548 13.0137 4.27329 13.0137 3.75246V2.3334C14.1372 2.43567 15.2243 2.72179 16.2563 3.18764C16.7309 3.40194 17.2895 3.19075 17.5038 2.71617C17.7181 2.24146 17.507 1.68295 17.0323 1.46865C15.4685 0.762755 13.7992 0.404785 12.0707 0.404785C8.84647 0.404785 5.81527 1.66032 3.53538 3.94017C1.25558 6.22006 0 9.25126 0 12.4754C0 14.858 0.707171 17.1752 2.04508 19.1763C2.22529 19.4458 2.52324 19.5953 2.82963 19.5953C2.96891 19.5953 3.10999 19.5644 3.24253 19.4998L5.17572 18.5567C5.64383 18.3284 5.83819 17.7639 5.60984 17.2957C5.38148 16.8277 4.8169 16.6334 4.34878 16.8616Z" fill="url(#paint1_linear_1736_98625)"/>
     <path d="M23.0774 7.5138C22.8632 7.03913 22.3048 6.82789 21.8299 7.04229C21.3552 7.25659 21.1441 7.81509 21.3584 8.28976C21.9536 9.60801 22.2553 11.0163 22.2553 12.4754C22.2553 14.215 21.8071 15.9146 20.9527 17.4365L19.7373 16.8578C19.2672 16.6339 18.7044 16.8335 18.4805 17.3038C18.2566 17.774 18.4562 18.3367 18.9265 18.5606L20.9068 19.5036C21.0371 19.5656 21.1752 19.5953 21.3118 19.5953C21.6182 19.5953 21.9162 19.4456 22.0962 19.1763C23.4342 17.1751 24.1413 14.8579 24.1413 12.4754C24.1413 10.7469 23.7834 9.07756 23.0774 7.5138Z" fill="url(#paint2_linear_1736_98625)"/>
     <defs>
       <linearGradient id="paint0_linear_1736_98625" x1="9.24295" y1="3.75244" x2="25.3768" y2="4.02698" gradientUnits="userSpaceOnUse">
         <stop stop-color={`${hovered ? '#00B7F0' : '#fff'} `}/>
         <stop offset="1" stop-color={`${hovered ? '#4819BE' : '#fff'} `}/>
       </linearGradient>
       <linearGradient id="paint1_linear_1736_98625" x1="0" y1="0.404785" x2="24.5672" y2="0.787908" gradientUnits="userSpaceOnUse">
         <stop stop-color={`${hovered ? '#00B7F0' : '#fff'} `}/>
         <stop offset="1" stop-color={`${hovered ? '#4819BE' : '#fff'} `}/>
       </linearGradient>
       <linearGradient id="paint2_linear_1736_98625" x1="18.3887" y1="6.9585" x2="26.4258" y2="7.02075" gradientUnits="userSpaceOnUse">
         <stop stop-color={`${hovered ? '#00B7F0' : '#fff'} `}/>
         <stop offset="1" stop-color={`${hovered ? '#4819BE' : '#fff'} `}/>
       </linearGradient>
     </defs>
   </svg>
  );
};

export default Speedometer;
