import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [
    {
      // time: new Date("Thu May 27 2010 00:00:00 GMT+0700 (Indochina Time)"),
      time: new Date(),
      price: 0,
      high: 0,
      low: 0,
      volume: 0,
    },
  ],
  game: {
    startPrice: 0,
    endPrice: 0,
    startTime: '',
    endTime: '',
    roomName: '',
    gameId: '',
    currentPhase: null,
    startBettingTime: null,
    endBettingTime: null,
    gameResult: null,
  },
  open: false,
  startGame: false,
  endPrice: null,
  typeBetting: '',
  bettingInfo: {
    profitLong: 0.0,
    profitShort: 0.0,
    totalInvestShort: 0.0,
    totalInvestLong: 0.0,
    totalBettingLong: 0.0,
    totalBettingShort: 0.0,
    expectedLong: 0.0,
    expectedShort: 0.0,
    myBettingInfo: {
      haveBet: false,
      record: {
        amount: 0,
        walletAddress: '',
        bettingSide: '',
        timeStamp: '',
        b2oReward: 0,
        rewardRate: 0,
        isHedge: false,
      },
    },
  },
  dataGameResult: {
    status: '',
    profit: 0,
    b2oReward: 0,
    yourGameSide: '',
    startPrice: 0,
    endPrice: 0,
    resultProfit: 0,
  },
  totalBetStats: {
    totalExpectedProfit: 0,
    totalInvestAllRoom: 0,
  },
  bettingPopup: '',
  newPrice: {},
  dataGameHot: [
    {
      endTime: '',
      isOldGame: false,
      profitLong: 0,
      profitShort: 0,
      roomName: '',
      totalLong: 0,
      totalShort: 0,
      duration: 0,
      symbol: 'BTC',
    },
  ],
  popupResultPractice: {},
};

export const dataChartPracticeSlice = createSlice({
  name: 'dataChartPractice',
  initialState,
  reducers: {
    setDataChartPractice(state, action) {
      state.data = action.payload;
    },
    setGameDataPractice(state, action) {
      state.game = action.payload;
    },
    setGameOpenPractice(state, action) {
      state.open = action.payload;
    },
    setEndPricePractice(state, action) {
      state.endPrice = action.payload;
    },
    setTypeBettingPractice(state, action) {
      state.typeBetting = action.payload;
    },
    setDataBettingInfoPractice(state, action) {
      state.bettingInfo = action.payload;
    },
    setDataBettingPopupPractice(state, action) {
      state.bettingPopup = action.payload;
    },
    setStartGamePractice(state, action) {
      state.startGame = action.payload;
    },
    setGameResultPractice(state, action) {
      state.dataGameResult = action.payload;
    },
    setTotalBetStatsPractice(state, action) {
      state.totalBetStats = action.payload;
    },
    setNewPricePractice(state, action) {
      state.newPrice = action.payload;
    },
    setDataGameHotPractice(state, action) {
      state.dataGameHot = action.payload;
    },
    setPopupResultPractice(state, action) {
      state.popupResultPractice = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDataChartPractice,
  setGameDataPractice,
  setGameOpenPractice,
  setEndPricePractice,
  setTypeBettingPractice,
  setDataBettingInfoPractice,
  setStartGamePractice,
  setDataBettingPopupPractice,
  setGameResultPractice,
  setTotalBetStatsPractice,
  setNewPricePractice,
  setDataGameHotPractice,
  setPopupResultPractice,
} = dataChartPracticeSlice.actions;

export default dataChartPracticeSlice.reducer;
