import React from 'react';
import stars from '../assets/images/Group 166.svg';
import dots from '../assets/images/Group 165.svg';
import '../styles/chart.css';

const AnimatingChartBackground = ({ numberOfDivs, image }) => {
  const divs = [];

  for (let i = 0; i < numberOfDivs; i++) {
    // const animationDuration = `${(i) * 30}s`; // Adjust this calculation based on your needs
    // const translateValue = 100 + (i - 1) * 80;
    // const TransformTranslateValue = 80 + (i - 2) * 80;

    const animationName = `flying-${i}`;
    const animationDuration = `${(i + 1) * 30}s`; // Adjust this calculation based on your needs
    const translateValue = (i + 1) * 100; // Adjust this calculation based on your needs

    // const animationStyle = {
    //     '--animation-duration': animationDuration,
    //     '--translate-value': `${translateValue}%`,
    //     transform: i === 1 ? 'none' : `translateX(-${TransformTranslateValue}%)`
    // };


    const keyframesStyle = `
        @keyframes ${animationName} {
          0% {
            transform: translateX(${translateValue}%);
          }
          100% {
            transform: translateX(-${translateValue}%);
          }
        }
      `;

    const animationStyle = {
      animationDuration,
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      animationDirection: 'normal',
      animationPlayState: 'running',
      animationName: animationName
    };

    divs.push(
      <style key={`animating-styles-${i}`}>{keyframesStyle}</style>,
      <div key={i} className="absolute z-10 object-contain w-full h-full overflow-hidden" >
        <img src={stars} alt="stars" className="w-full h-full flight" style={animationStyle} />
        <img src={dots} alt="stars" className="w-full h-full flight" style={animationStyle} />
      </div>
    );
  }

  return <>{divs}</>;
};

export default AnimatingChartBackground;