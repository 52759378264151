import { ethers } from 'ethers';

import { network, metamaskChains } from '../constants';

let _provider = new ethers.providers.JsonRpcProvider('https://arb1.arbitrum.io/rpc');
global._provider = _provider;

let chain_ids = [42161, 8453];
let _walletConnect;

export async function checkMetamaskSession() {
  if (window.ethereum) connectMetamask(true);
}

export async function connectMetamask(resume) {
  let metamask = window.ethereum;
  if (!metamask && !resume) return alert('Metamask is not installed.');

  _provider = new ethers.providers.Web3Provider(metamask);

  let accounts;
  if (resume) {
    accounts = await _provider.send('eth_accounts');
  } else {
    accounts = await _provider.send('eth_requestAccounts', []);
  }

  if (!accounts || !accounts.length) return;
  const network_ = await _provider.getNetwork();

  console.log({ chain_ids, networkID: network_.chainId });

  //switch chain if not on arbitrum
  if (!chain_ids.includes(network_.chainId)) {
    console.log({ chainSelected: network_.chainId });
    switchMetamaskChain(network_.chainId === 8453 ? 'base' : 'arbitrium');
  }

  metamask.on('chainChanged', (_chainId) => {
    const chain = parseInt(_chainId);

    // console.log({chainSelected: parseInt(_chainId)});
    localStorage.setItem('arbsOrBase', chain === 8453 ? 'base' : 'arbitrium');

    window.location.reload();
  });

  if (accounts.length) {
    handleAccountsChanged();
  }
  metamask.on('accountsChanged', handleAccountsChanged);
}

export async function switchMetamaskChain(chainType = 'arbitrium') {
  let metamask = window.ethereum;
  let mainChain = chainType === 'arbitrium' ? metamaskChains[0] : chainType === 'base' ? metamaskChains[1] : [];
  try {
    await metamask.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: mainChain.chainId }],
    });
    localStorage.setItem('arbsOrBase', chainType);
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await metamask.request({
          method: 'wallet_addEthereumChain',
          params: [mainChain],
        });
      } catch (addError) {
        alert(
          `Error Adding ${(chainType = 'arbitrium'
            ? 'Arbitruim'
            : 'Base')} to metamask. You can add it manually to metamask`
        );
      }
    } else {
      alert('Unable to switch accounts');
    }
  }
}

export async function disconnectWallet(force) {
  if (force && _walletConnect) await _walletConnect.disconnect();
}

async function handleAccountsChanged() {
  const _signer = _provider.getSigner();
  //const _address = await _signer.getAddress();
  global._signer = _signer; //would use store in subsiqu
  //console.log(_address);
  // amplitude.getInstance().setUserId(_address);
}

export const mask = (value) => {
  value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat('en-US', options).format(parseFloat(value) / 100);

  //console.log(result);
  const maskedResult = '$ ' + result;
  return { maskedResult, result };
};

export const displayWeb3Alert = () => {
  if (typeof window.web3 === 'undefined' || typeof window.ethereum === 'undefined') {
    alert('Please install metamask or use a compatible browser');
  }
};
