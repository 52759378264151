import React, { useEffect, useState, useRef, useContext, useMemo, useCallback } from 'react';
import {
  finalmobilelogo,
  pepe_top_base,
  bolt,
  crown,
  umbrella,
  lock_peg_picture_desktop,
  lock_peg_picture_mobile,
  degen,
} from '../assets';
import up from '../assets/images/dropDownArrow.svg';
import PepeMobileFooter from '../components/PepeMobileFooter';
import Navbar from '../components/Navbar/Navbar';
import axios from 'axios';
import {
  api,
  chain_ids,
  arbitriumContracts,
  baseContracts,
  months,
} from '../constants';
import { tokenDistributorABI } from '../utils/tokenDistributorABI';
import { useAccount, useSigner, useNetwork } from 'wagmi';
import { Disclosure, Transition } from '@headlessui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { StoreContext } from '../utils/store';
import { useSnackbar } from 'notistack';
import { BigNumber, ethers } from 'ethers';
import { useStorage } from '../utils/StorageContext';
import { degenTokenDistributorABI } from '../utils/degenTokenDistributorABI';
import UPMobile from '../components/UPMobile';
import { cancel_icon_w_gradient } from '../assets';
import infoIcon from '../assets/icons/info.svg';

// there should be two different images for mobile and large screens. this should preserve the stacking such that it looks good on all mobile devices and no positioning gymnastics will be needed
const ITEMS_PER_PAGE = 10;

export default function StakePage() {
  const tableRef = useRef(null);
  const myRef = useRef(null);
  const { arbsOrBase } = useStorage()
  const location = useLocation();
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [topThreeData, setTopThreeData] = useState([]);
  const [exists, setExists] = useState(false);
  let [currentUser, setCurrentUser] = useState([]);
  const [count, setCount] = useState(ITEMS_PER_PAGE);
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(10);
  const { address: userAddress } = useAccount();
  const { data: signer } = useSigner();
  const [isTableRendered, setIsTableRendered] = useState(false);
  const [epochEndDate, setEpochEndDate] = useState('');
  const { chain } = useNetwork();
  const [isToday, setIsToday] = useState(true);

  const navigate = useNavigate();
  const Store = useContext(StoreContext);
  const { setDegenStakeModal } = Store;
  const { enqueueSnackbar } = useSnackbar();
  const [refresh, refreshData] = useState(false);
  const [claimableAmount, setClaimableAmount] = useState(BigNumber.from(0));

  const [totalAmountClaimed, setTotalAmountClaimed] = useState(BigNumber.from(0));

  const {
    degenTokenDistributorContractAddress
  } = chain?.id == chain_ids[0] ? arbitriumContracts : baseContracts //// @dev chain Id index 0 is arbitrium

  const degenTokenDistributorContract = useMemo(
    // () => new ethers.Contract(tokenDistributorContractAddress, tokenDistributorABI, signer),
    () => new ethers.Contract(degenTokenDistributorContractAddress, degenTokenDistributorABI, signer),
    [signer]
  );

  const fetchLeaderBoardData = () => {
    return new Promise(async (res, reject) => {
      axios
        .get(
          // `${api.dev.degen_API}?start=${startCount}&end=${endCount}`, {
          `${api.offChainBet.degenWeekPointsApiEndpoint}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          // console.log({ response });
          res(response.data);
        })
        .catch((error) => {
          reject({ message: error.response.data.message });
        });
    });
  };

  const getLeaderBoardData = async () => {
    try {
      let results = await fetchLeaderBoardData();
      console.log(results);
      let three = results.data?.slice(0, 3);
      let ten = results.data?.slice(3, count);
      setLeaderBoardData(results.data?.slice(3, results.data.length));
      setDisplayedData((prevData) => [...prevData, ...ten]);
      if (topThreeData <= 0) setTopThreeData(three);
      let userData = results.data.find((user) => user.user_address === userAddress);
      let userRank = results.data.findIndex((user) => user.user_address === userAddress);
      if (userData) {
        setExists(true);
        setCount(userRank + 1);
      } else {
        setExists(false);
        setCount(10);
      }
      setCurrentUser((prevData) => [...prevData, userData]);
      setCurrentUser((prevData) => [...prevData, userRank]);

      setTimeout(() => setIsTableRendered(true), 2000);
    } catch (error) {
      console.error('Error fetching bets :', error);
    }
  };

  //console.log("prrr" + localStorage.getItem("hasVisitedDOTW") ? false : true);

  const userFetchLeaderBoardData = () => {
    return new Promise(async (res, reject) => {
      axios
        .get(
          // `${api.dev.degen_API}?start=${startCount}&end=${endCount}`, {
          `${api.offChainBet.userDegenWeekPointsApiEndpoint}?address=${userAddress}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          // console.log({ response });
          res(response.data);
        })
        .catch((error) => {
          reject({ message: error.response.data.message });
        });
    });
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const formattedDate = `${months[date.getUTCMonth()]} ${date.getUTCDate()}, ${date.getUTCFullYear()} ${(
      '0' + date.getUTCHours()
    ).slice(-2)}:${('0' + date.getUTCMinutes()).slice(-2)}:${('0' + date.getUTCSeconds()).slice(-2)}`;

    return formattedDate;
  }

  const getCurrentEpoch = async () => {
    const { data } = await axios.get(api.offChainBet.getWrappedDegenEpochsApiEndpoint);

    if (data.data) {
      let epoch_data = data.data;
      for (let i = 0; i < epoch_data.length - 1; i++) {
        if (epoch_data[i].active) {
          // let end_date = new Date(Number(epoch_data[i].end_date));
          // const monthIndex = end_date.getMonth();
          // const day = end_date.getDate().toString().padStart(2, "0");
          // const formattedDate = months[monthIndex] + "-" + day;

          const formattedDate = formatDate(Number(epoch_data[i].end_date));

          return setEpochEndDate(formattedDate);
        }
      }
    }
  };

  useEffect(() => {
    getLeaderBoardData();
    userFetchLeaderBoardData();
    getCurrentEpoch();
  }, []);

  useEffect(() => {
    if (location.state && location.state.myRank === true) {
      scrollToMe();
      setTimeout(() => {
        location.state.myRank = false;
        console.log({ myRank: location.state?.myRank });
      }, 3000);
    }
    setIsTableRendered(false);
  }, [isTableRendered, location]);

  useEffect(() => {
    // Update the visible users when loadCount changes
    setDisplayedData(leaderBoardData?.slice(0, count));
  }, [leaderBoardData, count]);

  const handleLoadMore = () => {
    setCount((prevLoadCount) => prevLoadCount + 50);
  };

  const scrollToRow = () => {
    if (!location?.state?.openDefault) {
      const table = tableRef.current;
      if (table != null) {
        const row = table.querySelector('.account-position');
        if (row != null) {
          row.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  };

  const scrollToMe = () => {
    const me = myRef.current;
    if (me != null) {
      me.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Method to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling animation
    });
  };

  const getTotalEsPegClaimed = useCallback(async () => {
    try {
      // const totalClaimed = await tokenDistributorContract.amountClaimed(esPegTokenAddress, userAddress);
      const totalClaimed = await degenTokenDistributorContract.amountClaimed(userAddress);
      setTotalAmountClaimed(totalClaimed);
    } catch (e) {
      console.error({ totalClaimedError: e });
    }
  }, [degenTokenDistributorContract, userAddress]);

  const getClaimableEsPeg = useCallback(async () => {
    try {
      let amount = await degenTokenDistributorContract.totalClaimable(userAddress);
      const epoch3Amount = await degenTokenDistributorContract.claimableAmount(3, userAddress);
      amount = amount.sub(epoch3Amount);
      setClaimableAmount(amount);
    } catch (e) {
      console.error(e);
    }
  }, [degenTokenDistributorContract, userAddress]);

  const claimEsPeg = useCallback(async () => {
    if (claimableAmount.isZero()) {
      enqueueSnackbar('You have no claimable amount', { variant: 'error' });
      return;
    }
    let estimateGas;
    const currentEpoch = await degenTokenDistributorContract.currentEpoch();
    console.log({ currentEpoch });
    try {
      // const gasUnits = await tokenDistributorContract.estimateGas.claim(esPegTokenAddress);
      const gasUnits = await degenTokenDistributorContract.estimateGas.claimAll();
      estimateGas = gasUnits.toNumber() + 1e4;
    } catch (e) {
      enqueueSnackbar('Your transaction is likely to fail', { variant: 'error' });
      estimateGas = 3e6;
      console.error(e);
    }

    // if (!(await tokenDistributorContract.claimEnabled(esPegTokenAddress))) {
    if (!(await degenTokenDistributorContract.claimEnabled(currentEpoch))) {
      enqueueSnackbar('Claiming is not enabled yet', { variant: 'error' });
      return;
    }

    try {
      // const tx = await tokenDistributorContract.claim(esPegTokenAddress, { gasLimit: estimateGas });
      const tx = await degenTokenDistributorContract.claimAll({ gasLimit: estimateGas });
      await tx.wait();
      initiateClaims();
      enqueueSnackbar('Claim successful', { variant: 'success' });
    } catch (e) {
      console.error(e);
    }
  }, [claimableAmount, degenTokenDistributorContract]);

  useEffect(() => {
    if (userAddress) {
      initiateClaims();
    }
  }, [userAddress, getClaimableEsPeg]);

  const initiateClaims = useCallback(async () => {
    await getClaimableEsPeg();
    await getTotalEsPegClaimed();
  }, [getTotalEsPegClaimed, getClaimableEsPeg]);

  const hasClaimableEsPeg = async (data) => {
    const amount = parseFloat(ethers.utils.formatUnits(data, 18)).toFixed(4);
    return amount > 0;
  };

  const [showTooltip, setShowTooltip] = useState(false);

  const handleTooltipToggle = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div className="bg-[rgba(9,3,29,1)] text-white min-h-screen lg:pb-20">
      <Navbar />
      <img src={arbsOrBase === 'base' ? pepe_top_base : finalmobilelogo} alt="" className="block mx-auto pointer-events-none sm:hidden" />
      <div className="sm:pt-20 barlow">
        <div className="relative px-4 py-8 w-[90%] mx-auto bg-[rgba(255,255,255,0.05)] border-2 border-solid border-[rgba(10,130,182,0.7)] rounded-2xl lg:pb-20 flex flex-col">
          <img src={degen} className="absolute top-[13rem] w-[15%] hidden lg:block" />
          <span className="z-[10] w-[14px] sm:w-auto absolute top-4 right-4 text-white cursor-pointer">
            <img src={cancel_icon_w_gradient} alt="" onClick={() => navigate('/slot')} />
          </span>

          {/* <div className="flex justify-end sm:hidden">
            <button className="w-6 flex justify-center items-center absolute top-4 right-4 md-2" onClick={handleClose}>
              <img alt="" src={cancel_icon_w_gradient} className="" />
            </button>
          </div> */}

          <img
            src={arbsOrBase === 'base' ? pepe_top_base : finalmobilelogo}
            alt=""
            className="pointer-events-none hidden mx-auto sm:block w-[25rem] absolute top-[-7rem] left-0 right-0"
          />
          <div className='flex flex-col sm:flex-row font-["Tourney"] gradient-text font-bold text-[1.75rem] sm:text-[3.125rem] justify-center items-center sm:pt-[5rem] mb-4 uppercase text-center'>
            <span>Degens of the week</span>
          </div>

          <div className="flex flex-col sm:flex-row text-center justify-center text-[11px] sm:text-base mb-4">
            <span>Pepe sees you, fren. You might just have the guts to seize the title of DEGENS OF THE WEEK</span>
          </div>
          <>
            <div className="flex flex-col items-center w-full sm:w-3/5 p-4 mx-auto mb-5 bg-deep-blue/40 rounded-2xl">
              <span className=" button-gradient-text barlow mb-[10px] font-bold text-center">
                CURRENT EPOCH ENDS ON {epochEndDate}
              </span>
              <p className="flex flex-col items-center justify-center gap-4 mb-[10px] lg:flex-row w-full">
                <div className="flex relative items-center gap-2">
                  <span className=" text-[#E0DDFF] barlow text-center">MY CLAIMABLE esPEG </span>

                  <img
                    src={infoIcon}
                    alt="Info Icon"
                    className="w-4 h-4 cursor-pointer text-[#E0DDFF]"
                    onMouseEnter={handleTooltipToggle}
                    onMouseLeave={handleTooltipToggle}
                  />

                  {showTooltip && (
                    <div className="absolute bg-[#43334F] transition duration-500 ease-in text-white p-4 rounded shadow-md bottom-8 right-0">
                      Note that esPEG rewards change dynamically as more players join and accumulate points.
                    </div>
                  )}
                </div>
                <span className='font-["Tourney"] font-extrabold gradient-text-b text-3xl'>
                  {parseFloat(ethers.utils.formatUnits(claimableAmount, 18)).toFixed(4)}
                </span>
                <button
                  className={`max-w-[150px] w-3/4 flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center ${hasClaimableEsPeg(claimableAmount) === true ? 'bg-white/[0.05]' : 'bg-[rgba(9,3,29,0.24)] shadow-lg'
                    }`}
                  onClick={() => claimEsPeg()}
                >
                  {' '}
                  Claim{' '}
                </button>
                <button
                  className={`max-w-[150px] w-3/4 flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center ${hasClaimableEsPeg(claimableAmount) === false
                      ? 'bg-[rgba(9,3,29,0.24)] shadow-lg'
                      : 'bg-white/[0.05]'
                    }`}
                  onClick={() => {
                    navigate('/stake');
                  }}
                >
                  {' '}
                  Stake/Lock{' '}
                </button>
              </p>
              <div className="flex flex-col items-center mb-2 text-sm sm:flex-row">
                <p className="text-[#E0DDFF]/50 barlow flex sm:block flex-row sm:flex-col text-center sm:text-left items-end">
                  <span className="mr-2">CLAIMED esPEG</span>
                  <span className="font-bold">
                    {parseFloat(ethers.utils.formatUnits(totalAmountClaimed, 18)).toFixed(4)}
                  </span>
                </p>
                <hr className="border-[0.5px] border-white opacity-40 rotate-90 w-[24px] hidden sm:block" />
                <p className="text-[#E0DDFF]/50 barlow flex sm:block flex-row sm:flex-col text-center sm:text-left items-end">
                  <span className="mr-2 font-light">TOTAL esPEG</span>
                  <span className="font-bold">
                    {parseFloat(ethers.utils.formatUnits(claimableAmount.add(totalAmountClaimed)), 18).toFixed(4)}
                  </span>
                </p>
              </div>
            </div>
            <div className="self-center w-full mb-4 sm:w-3/5">
              <Disclosure defaultOpen={localStorage.getItem('hasVisitedDOTW') ? false : true}>
                {({ open }) => (
                  <>
                    {/* save visit state */}
                    {localStorage.setItem('hasVisitedDOTW', true)}
                    <div
                      className={`flex flex-col sm:gap-0 ${open
                          ? 'border-[1px] sm:border-0 border-solid border-[#18ADFA] shadow-[0px_0px_14px_#00B7F0;] sm:shadow-none rounded-lg'
                          : 'gap-[10px]'
                        }`}
                    >
                      <Disclosure.Button
                        className={`flex w-full sm:w-4/5 mx-auto ${!open
                            ? 'rounded-lg justify-center gap-2  bg-[#F82C5D]'
                            : 'rounded-t-lg justify-end pr-4 bg-[rgba(255,255,255,0.05)] sm:w-full'
                          }  px-4 py-2 text-left text-sm font-medium text-white flex-col sm:flex-row`}
                      >
                        {/* <img src={up} className={`self-center ${!open ? 'rotate-180' : ''} invisible`} /> */}
                        <span className={`${open ? 'hidden' : ''} text-center sm:text-left text-lg sm:text-base`}>
                          How to collect Degen Points for biweekly esPEG rewards!
                        </span>
                        <img src={up} className={` self-center ${!open ? 'rotate-180 hidden sm:block' : 'mt-2'} `} />
                      </Disclosure.Button>
                      <img src={up} className={` sm:hidden self-center ${!open ? 'rotate-180 ' : 'mt-2 hidden '} `} />
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-white bg-[rgba(255,255,255,0.05)] rounded-b-lg">
                          <p className="mb-4 text-lg font-bold text-center sm:text-xl">
                            How to collect Degen Points for biweekly esPEG rewards!
                          </p>
                          <ol className="px-2 mx-4 text-sm list-decimal sm:text-base sm:mx-0 lg:px-20">
                            <li className="mb-10">
                              <p className="font-bold">Spin to Score</p>
                              <p>
                                Each spin you make earns you DEGEN points. The higher your wager, the more DEGEN points
                                you earn. For every spin that misses the mark, Pepe rewards your grit with DOUBLE the
                                number of DEGEN points.
                              </p>
                              <p className=" flex-1 block uppercase text-xl text-center font-[Tourney] mt-4 mb-4 sm:mb-0">
                                Bet size & DEGENS points
                              </p>
                              <table class="table-auto leaderboard-table pb-4 mb-4 mx-auto w-full">
                                <thead>
                                  <tr className="rounded text-white">
                                    <th className="w-1/5 text-center barlow text-white font-normal text-sm">WAGER</th>
                                    <th className="w-1/5 text-center barlow text-white font-normal text-sm">
                                      TRADE WON
                                    </th>
                                    <th className="w-1/5 text-center barlow text-white font-normal text-sm">
                                      TRADE LOST <span className="font-bold gradient-text-d">(x2)</span>
                                    </th>
                                  </tr>
                                </thead>
                                <div className="border-[1px] border-solid border-[#18ADFA] shadow-[0px_0px_14px_#00B7F0;] rounded-[3px] table-row-group">
                                  <tr className={`align-middle info-row row-space`}>
                                    <td className="w-1/5 text-center">$5</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold">+1</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold gradient-text-d">
                                      +2
                                    </td>
                                  </tr>
                                  <tr className="!h-[3px] bg-[#211C33]"></tr>
                                  <tr className={`align-middle info-row row-space`}>
                                    <td className="w-1/5 text-center">$10</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold">+3</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold gradient-text-d">
                                      +6
                                    </td>
                                  </tr>
                                  <tr className="!h-[3px] bg-[#211C33]"></tr>
                                  <tr className={`align-middle info-row row-space`}>
                                    <td className="w-1/5 text-center">$20</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold">+8</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold gradient-text-d">
                                      +16
                                    </td>
                                  </tr>
                                  <tr className="!h-[3px] bg-[#211C33]"></tr>
                                  <tr className={`align-middle info-row row-space`}>
                                    <td className="w-1/5 text-center">$50</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold">+25</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold gradient-text-d">
                                      +50
                                    </td>
                                  </tr>
                                  <tr className="!h-[3px] bg-[#211C33]"></tr>
                                  <tr className={`align-middle info-row row-space`}>
                                    <td className="w-1/5 text-center">$75</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold">+45</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold gradient-text-d">
                                      +90
                                    </td>
                                  </tr>
                                  <tr className="!h-[3px] bg-[#211C33]"></tr>
                                  <tr className={`align-middle info-row`}>
                                    <td className="w-1/5 text-center">$100</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold">+80</td>
                                    <td className="w-1/5 text-center align-middle font-extrabold gradient-text-d">
                                      +160
                                    </td>
                                  </tr>
                                </div>
                              </table>
                            </li>
                            <li className="mb-10">
                              <>
                                <p className="font-bold">Bi-weekly Rewards</p>
                                <p>
                                  Once every fortnight, the top degens on the leaderboard share an impressive 75,000
                                  esPEG prize pool.
                                </p>
                              </>
                            </li>
                            <li className="mb-10">
                              <p className="font-bold">Escrow to Earnings</p>
                              <p>
                                Your esPEG rewards gradually metamorphose into PEG over a three-month span. Too long?
                                Slash this wait time to 1.5 months by providing liquidity with wETH on Balancer.
                              </p>
                            </li>
                            <li className="mb-10">
                              <p className="font-bold">Medal of Honour</p>
                              <p>Making the leaderboard is the only way to claim the 10th piece of Pepe's Puzzle.</p>
                            </li>
                          </ol>
                          <hr className="border-[-0.5px] w-[85%] sm:w-[35%] mx-auto my-4 border-border-blue" />
                          {/* <p className="px-2 text-xl font-bold text-center">
                            {' '}
                            Got what it takes to be the Degen of this Week?
                          </p> */}
                          <p className="text-xl font-bold text-center"> Let's find out!</p>
                          <div className="flex justify-center gap-4 my-4 sm:gap-8">
                            <button
                              className="inline-block degen-spin-btn text-white bg-deep-blue/40 inter py-2 px-0 sm:px-4 rounded-md stake-btn-shadow w-[120px] sm:w-[135px] text-xs inter mt-8"
                              onClick={() => navigate('/slot')}
                            >
                              {' '}
                              Yeah, let's spin!!!{' '}
                            </button>
                            <Disclosure.Button className="inline-block text-white bg-deep-blue/40 inter py-2 px-0 sm:px-4 rounded-md stake-btn-shadow w-[120px] sm:w-[135px] text-xs inter mt-8">
                              {' '}
                              Close info{' '}
                            </Disclosure.Button>
                          </div>
                        </Disclosure.Panel>
                      </Transition>
                    </div>
                  </>
                )}
              </Disclosure>
            </div>

            <div className="w-full sm:w-3/5 mx-auto bg-[rgba(255,255,255,0.05)] border-[1px] border-solid border-[#18ADFA] shadow-[0px_0px_14px_#00B7F0;] rounded-2xl py-6 px-4 sm:px-10 flex flex-col">
              <div className="flex">
                <p className=" flex-1 hidden sm:block uppercase text-3xl text-center font-[Tourney] my-4">
                  Degens Leaderboard
                </p>
                <button className={`${!userAddress && 'hidden'} text-[#18ADFA]`} onClick={() => scrollToMe()}>
                  My position
                </button>
              </div>
              <table ref={tableRef} class="table-auto leaderboard-table degen pb-4 mb-4">
                <thead>
                  <tr className="rounded text-white">
                    <th className="w-1/5 text-center table-header text-sm">RANK</th>
                    <th className="w-1/5 text-center table-header text-sm">WALLET</th>
                    <th className="hidden w-1/5 text-center table-header sm:table-cell text-sm">SPINS</th>
                    <th className="w-1/5 text-center table-header text-sm">POINTS</th>
                  </tr>
                </thead>
                <div className="border-[1px] border-solid border-[#18ADFA] shadow-[0px_0px_14px_#00B7F0;] rounded-[3px] topThree table-row-group">
                  {topThreeData.map((entry, i) => {
                    let rank;
                    if (i === 0) {
                      rank = (
                        <img
                          alt="rank"
                          width={33.41}
                          src="https://uploads-ssl.webflow.com/641c2b181f41df422637adc5/64201c51cc09c983c5c38739_Pepe%20First.png"
                          loading="lazy"
                          className="mx-auto leaderboard_image"
                        />
                      );
                    } else if (i === 1) {
                      rank = (
                        <img
                          alt="rank"
                          width={33.41}
                          src="https://uploads-ssl.webflow.com/641c2b181f41df422637adc5/64201c51cc09c983c5c38739_Pepe%20First.png"
                          loading="lazy"
                          className="mx-auto leaderboard_image"
                        />
                      );
                    } else if (i === 2) {
                      rank = (
                        <img
                          alt="rank"
                          width={33.41}
                          src="https://uploads-ssl.webflow.com/641c2b181f41df422637adc5/64201c50a2bd1e700e469ca2_Pepe%20Third.png"
                          loading="lazy"
                          className="mx-auto leaderboard_image"
                        />
                      );
                    } else {
                      rank = <p className="mx-auto rank-regular">#{i + 1}</p>;
                    }

                    let wallet;

                    if (entry.user_address === userAddress) {
                      wallet = <span className="account-position-text">This is you</span>;
                    } else {
                      wallet = `${entry.user_address?.slice(0, 4)}.....${entry.user_address?.slice(-4)}`;
                    }

                    return (
                      <>
                        <tr
                          className={`rounded align-middle ${entry.user_address === userAddress ? 'account-position' : ''
                            }`}
                          key={wallet}
                        >
                          <td className="w-1/5 text-center">{rank}</td>
                          <td className="w-1/5 text-center align-middle">
                            <p className={`regular-text mx-auto`}>{wallet}</p>
                          </td>
                          <td className="hidden w-1/5 text-center align-middle sm:table-cell">
                            <p className={`regular-text mx-auto`}>{entry.spins}</p>
                          </td>
                          <td className="w-1/5 text-center align-middle">
                            <p className={`regular-text mx-auto`}>{entry.points}</p>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </div>
                <tbody>
                  {displayedData.map((entry, i) => {
                    let rank = <p className="mx-auto rank-regular">#{i + 4}</p>;
                    let wallet;

                    if (entry.user_address === userAddress) {
                      wallet = <span className="account-position-text">This is you</span>;
                    } else {
                      wallet = `${entry.user_address.slice(0, 4)}.....${entry.user_address.slice(-4)}`;
                    }

                    return (
                      <>
                        <tr
                          className={`rounded ${entry.user_address === userAddress ? 'account-position' : ''}`}
                          key={wallet}
                          ref={myRef}
                        >
                          <td className="w-1/5 text-center">{rank}</td>
                          <td className="w-1/5 text-center">
                            <p className={`regular-text mx-auto`}>{wallet}</p>
                          </td>
                          <td className="hidden w-1/5 text-center sm:table-cell">
                            <p className={`regular-text mx-auto`}>{entry.spins}</p>
                          </td>
                          <td className="w-1/5 text-center">
                            <p className={`regular-text mx-auto`}>{entry.points}</p>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  {!exists && userAddress && (
                    <tr className={`rounded account-position text-border-blue`}>
                      <td className="w-1/5 text-center top-3-text">#{parseInt(currentUser[1]) + 1}</td>
                      <td className="w-1/5 text-center">
                        <p className={`regular-text  mx-auto`}>
                          <span className="account-position-text">This is you</span>
                        </p>
                      </td>
                      <td className="hidden w-1/5 text-center sm:table-cell">
                        <p className={`regular-text mx-auto top-3-text`}>0</p>
                      </td>
                      <td className="w-1/5 text-center">
                        <p className={`regular-text  mx-auto top-3-text`}>0</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="justify-center gap-8 mt-0 sm:mt-[50px] flex">
              {/* <button
                className="inline-block degen-spin-btn text-white bg-deep-blue/40 inter py-2 px-4 rounded-md stake-btn-shadow w-[135px] text-xs font-medium"
                onClick={() => navigate('/slot')}
              >
                {' '}
                Yeah, let's spin!!!{' '}
              </button>

              {displayedData.length !== leaderBoardData.length && (
                <button
                  className="inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[135px] text-xs font-medium"
                  onClick={() => handleLoadMore()}
                >
                  {' '}
                  Load More{' '}
                </button>
              )} */}

              <button
                className="inline-block degen-spin-btn text-white bg-deep-blue/40 inter py-1 sm:py-2 px-4 rounded-md stake-btn-shadow w-[135px] text-xs mt-8 sm:mt-0"
                onClick={() => navigate('/slot')}
              >
                {' '}
                Yeah, let's spin!!!{' '}
              </button>

              {displayedData.length !== leaderBoardData.length && (
                <button
                  className="inline-block text-white bg-deep-blue/40 inter py-1 sm:py-2 px-4 rounded-md stake-btn-shadow w-[135px] text-xs mt-8 sm:mt-0"
                  onClick={() => handleLoadMore()}
                >
                  {' '}
                  Load More{' '}
                </button>
              )}
            </div>
          </>
        </div>
      </div>
      <div className=" p-4 w-[90%] mx-auto ">
        <div className="flex justify-end">
          <UPMobile />
        </div>
      </div>
      <PepeMobileFooter classes="pt-16" />
    </div>
  );
}
