import React from 'react';

const OptionsIcon = () => {
  return (
    <svg width="24" viewBox="0 0 17 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.132568 3.58018V0.730183H2.98257V3.58018H0.132568Z" fill="white" fill-opacity="0.4" />
      <path d="M7.14128 3.58018V0.730183H9.99128V3.58018H7.14128Z" fill="white" fill-opacity="0.4" />
      <path d="M14.15 3.58018V0.730183H17V3.58018H14.15Z" fill="white" fill-opacity="0.4" />
    </svg>
  );
};

export default OptionsIcon;
