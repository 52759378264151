import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import {
  pepe_top,
  pepe_top_svg_2,
  resultsharecoins,
  resultsharePepe,
  cancel_icon_w_gradient,
  information,
  pepePills,
  pepe_throwing_coins_mobile,
  pepe_throwing_coins_desktop,
  pepe_top_base,
} from '../assets';
import close from '../assets/icons/close.png';
import pepePillsBase from '../assets/images/pill_base.svg';
import Pepe_logo from '../assets/images/pepe_top_results.png';
import Pepe_logo_base from '../assets/images/pepe_top_base_results.svg';
import Results_coins from '../assets/images/results_coin.png';
import '../styles/results.css';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { CreditsContext } from '../utils/creditsContext';
import { StoreContext } from '../utils/store';
import { useStorage } from '../utils/StorageContext';
import '../index.css';
import { useNetwork } from 'wagmi';

const ShareResult = ({
  referralCode,
  amount,
  currency,
  longShort,
  time,
  won,
  openPrice,
  closePrice,
  leverage,
  imagesDoneLoading,
  closeShare,
  userPoints,
  betPills,
  show,
}) => {
  const [loadedImages, setLoadedImages] = useState(0);
  const totalImages = 3; // Update the total number of images in the child component
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 640px)');
  // const isIOSMobile = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const { chipsAndDays, questStreakCompleted, showPills } = useContext(CreditsContext);
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const store = useContext(StoreContext);
  const { setQuestsModal } = store;
  const [coinPart, setCoinPart] = useState('')

  console.log(currency);

  useEffect(() => {
    const parts = currency.split('.');
  if (parts[1]) {
    const restParts = parts[1].split('/');
    setCoinPart(restParts[0]);
    console.log(coinPart)
    const usdPart = restParts[1];
  }},[])
  
  
  

  const handleImageLoad = () => {
    setLoadedImages((prevLoadedImages) => prevLoadedImages + 1);
  };

  useEffect(() => {
    console.log('images length::', loadedImages, totalImages);

    // let checkLoadedImages = (isMobile && isIOSMobile) ? loadedImages : loadedImages

    if (loadedImages === totalImages) {
      console.log('all images loaded......');
      // imagesDoneLoading(true); // Invoke the parent component's callback when all images are loaded
    }
  }, [loadedImages, totalImages]);

  const pillsNotice =
    'Pepe gives you Degen Points for every spin you make. You can trade these for sweet esPEG rewards! The bigger your bet size, the more Degen Points you earn. And if your trade ends in a loss, Pepe DOUBLES your Degen Points!';

  const toolStyle = {
    color: 'white',
    backgroundColor: '#43334F',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '26px',
    paddingRight: '26px',
    borderRadius: '4px',
    fontSize: '14px',
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '400px',
    width: '229.025px',
    height: '139.793p',
  };

  return (
    <div className={`share-container ${show ? 'block' : 'hidden'}`} style={{ height: '80%' }}>
      {!isMobile ? (
        <div id="result-panel-share" className="pt-10 pb-10 pl-9 pr-4 sm:px-0 bg-[#000000]  w-full mx-auto mt-[119px]">
          <div className=" relative w-full flex sm:w-[45.813rem] mx-auto results-shadow-mobile bg-[#09031D]/90 border-0 border-t-2 border-b-2 border-[#18ADFA] sm:border-solid sm:border-l-2 sm:border-r-2 sm:rounded-[0.875rem] mb-8 h-[420px]">
            <div className="relative w-[60%] ">
              <div className={`absolute left-[15px] top-[15px] w-[550px]`}>
                <div className=" absolute right-[-7rem] top-[6rem] w-[23rem] h-[110px] bg-gradient-to-r from-[#48B9F3]/20 to-[#8A40B8]/20"></div>
                <img src={arbsOrBase === 'base' ? Pepe_logo_base : Pepe_logo} alt="" onLoad={handleImageLoad} />
              </div>

              <div className="absolute bottom-[-20rem] left-[3rem] d-flex flex pr-4 pl-8 mb-6 flex-col w-full justify-center items-start right-2 top-2 text-[#18ADFA]">
                {/* <div>
                  <span className="w-[10rem] font-manrope">Referral Code:</span>
                </div>
                <div>
                  <span className="font-manrope">{referralCode}</span>
                </div> */}

                <img
                  src={Results_coins}
                  alt=""
                  className="absolute bottom-[295px] left-[-40px] w-[750px]"
                  onLoad={handleImageLoad}
                />
              </div>
            </div>

            <div className=" relative w-[50%] flex items-start">
              <button className="absolute top-2 right-2 z-20 p-3" onClick={() => navigate('/slot')}>
                <img src={cancel_icon_w_gradient} onLoad={handleImageLoad} alt="" />
              </button>

              <div
                className="relative pb-80 sm:top-0 w-full grid justify-start items-center mt-8 sm:mt-0 mb-8 sm:mb-12"
                style={{ top: 70 }}
              >
                <p className="font-manrope w-full font-bold text-[1.5rem] text-left leading-[2.125rem] mb-5 relative -left-7 scale-[0.8]">
                  <>
                    {longShort === 'Short' || longShort == false ? (
                      <>
                        <span className="font-barlow hidden sm:inline">${amount} </span>
                        {/* <span className="manrope sm:inline backdrop:text-[#18ADFA]">. </span> */}
                        {/* <span>{currency} </span> */}
                        {coinPart ? (
                          <span>
                            {'. '}
                            {coinPart}{' '}
                          </span>
                        ) : (
                          <span>
                            {'. '}
                            {currency}{' '}
                          </span>
                        )}
                        <span className="manrope backdrop:text-[#18ADFA]">. </span>
                        {/* <span className="manrope backdrop:sm:text-[#18ADFA]">{longShort} </span> */}
                        <span className="manrope backdrop:sm:text-[#18ADFA]">Short </span>
                        <span className="manrope backdrop:text-[#18ADFA]">. </span>
                        <span>{time}s</span>
                      </>
                    ) : (
                      <>
                        <span className="font-barlow hidden sm:inline">${amount} </span>
                        {/* <span className="manrope sm:inline text-[#18ADFA]">. </span> */}
                        {/* <span>{currency} </span> */}
                        {/* <span>{coinPart} </span> */}
                        {coinPart ? (
                          <span>
                            {'. '}
                            {coinPart}{' '}
                          </span>
                        ) : (
                          <span>
                            {'. '}
                            {currency}{' '}
                          </span>
                        )}
                        <span className="manrope text-[#18ADFA]">. </span>
                        <span className="manrope text-[#18ADFA]">Long </span>
                        <span className="manrope text-[#18ADFA]">. </span>
                        <span>{time}s</span>
                      </>
                    )}
                    {/* <span className="font-barlow hidden sm:inline">${amount} </span>
                    <span className="manrope backdrop:hidden sm:inline text-[rgb(0,183,240)]">.</span>
                    <span>{currency} </span>
                    <span className="manrope backdrop:text-[rgb(0,183,240)]">. </span>
                    <span className="manrope backdrop:sm:text-[rgb(24,173,250)]">{longShort}</span>
                    <span className="manrope backdrop:text-[rgb(0,183,240)]">. </span>
                    <span>{time}s</span> */}
                  </>
                </p>

                <p className="text-center barlow font-extrabold text-[4rem] mb-10">
                  <>
                    {/* {won ? ( */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -35 250 90">
                      <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" stopColor="#48B9F3" />
                          <stop offset="100%" stopColor="#8A40B8" />
                        </linearGradient>
                        <style>
                          {
                            "\n      @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900');\n\n    "
                          }
                        </style>
                      </defs>
                      <text
                        x="40%"
                        y="10%"
                        dominantBaseline="middle"
                        // fontWeight="bold"
                        fontFamily="Barlow"
                        textAnchor="middle"
                        fontSize="74px"
                        fill="url(#gradient)"
                      >
                        {won ? '+80%' : '+0%'}
                      </text>
                    </svg>
                  </>
                  {/* <span className="pills-and-points w-[148.445px] text-left text-[1rem] rotate-[9.50deg] leading-[1.2rem] sm:leading-[2.125rem] absolute -right-24 sm:-right-12 top-4 ">
                    <span className="text-[20px] sm:text-[30px] barlow font-extrabold lowercase tracking-widest">
                      +{userPoints}
                    </span>
                    <br />
                    <span className="text-[15px] sm:text-[20px] font-extrabold uppercase tracking-widest leading-[5px]">
                      DEGEN POINTS
                    </span>
                  </span> */}
                  <Tooltip
                    title={pillsNotice}
                    placement="bottom"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: toolStyle,
                      },
                      arrow: {
                        sx: {
                          color: '#43334F',
                        },
                      },
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="190"
                      height="190"
                      viewBox="-30 -10 208.445 73.125"
                      className="absolute left-[220px] top-[0px] cursor-pointer"
                      onClick={() => {
                        navigate('/degen-dashboard');
                      }}
                    >
                      <defs>
                        <linearGradient id="userPointsGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="34.8958%" stop-color="#FB5780" />
                          <stop offset="100%" stop-color="#4819BE" />
                        </linearGradient>
                        <linearGradient id="degenPointsGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="34.8958%" stop-color="#FB5780" />
                          <stop offset="100%" stop-color="#4819BE" />
                        </linearGradient>
                      </defs>
                      <g transform="translate(-24 4) rotate(9.50)">
                        <rect width="188.445" height="53.125" fill="none" />
                        <text
                          style={{
                            fontSize: '45px',
                            fontWeight: 'bold',
                            textTransform: 'lowercase',
                            letterSpacing: 'widest',
                          }}
                          x="20"
                          y="0"
                          dy="20"
                          fontFamily="Barlow"
                        >
                          <tspan fill="url(#userPointsGradient)" fontFamily="Barlow">{`+${userPoints}`}</tspan>
                        </text>
                        <text
                          style={{
                            fontSize: '24px',
                            // fontFamily: 'barlow',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            letterSpacing: 'widest',
                            dominantBaseline: 'hanging',
                          }}
                          x="40"
                          y="25"
                          fontFamily="'Barlow', sans-serif"
                        >
                          <tspan fill="url(#degenPointsGradient)" x="20" dy="0" fontFamily="'Barlow', sans-serif">
                            DEGEN
                          </tspan>
                          <tspan fill="url(#degenPointsGradient)" x="20" dy="25" fontFamily="'Barlow', sans-serif">
                            POINTS
                          </tspan>
                          <image
                            xlinkHref={information}
                            alt="information icon"
                            x="150"
                            y="-10"
                            width="20"
                            height="20"
                          />
                        </text>
                      </g>
                    </svg>
                  </Tooltip>
                </p>
                <div className="w-full sm:w-[100%] mx-auto flex justify-end h-[80px] absolute right-[40px] bottom-[26.5rem] top-[135px]">
                  {betPills && betPills != null && (
                    <div className="flex justify-end mt-[-27px] sm:mr-0 h-full sm:h-auto pill-margin !mr-[6px]">
                      <p
                        className="ml-10 flex flex-row justify-end items-center gap-1 cursor-pointer"
                        onClick={() => setQuestsModal(true)}
                      >
                        <img
                          src={arbsOrBase === 'base' ? pepePillsBase : pepePills}
                          alt="pills icons"
                          className="inline-block"
                          onLoad={handleImageLoad}
                          style={{ marginTop: isMobile ? -5 : 0, width: '23px' }}
                        ></img>
                        <span className="text-white mt-[2px] rotate-[12.50deg] text-[24px] text-xl font-extrabold uppercase tracking-widest">
                          {`+${betPills}`}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
                {/* <>
                  {(betPills && betPills != null) && (
                    <div className="flex justify-end mt-8 sm:mt-[0.2rem] mr-4 sm:mr-[-8.5rem] h-full sm:h-auto pill-margin-share">
                      <p
                        className="ml-12 flex flex-row justify-end items-center gap-1 cursor-pointer sm:mt-[-30px] sm:mr-[65px] sm:mb-[revert]"
                        onClick={() => setQuestsModal(true)}
                      >
                        <img
                          src={pepePills}
                          alt="pills icons"
                          className="inline-block w-[26px]"
                          style={{ marginTop: isMobile ? 4 : 0 }}
                        ></img>
                        <span className="text-white rotate-[11.10deg] text-[22px] sm:text-[24px] font-extrabold uppercase tracking-widest">
                          {`+${betPills}`}
                        </span>
                      </p>
                    </div>
                  )} 
                </> */}

                <div className="mt-3 text-[1.363rem]">
                  <table>
                    <tr className="gap-5">
                      <td className="pr-[25px]">
                        <span className="drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">Entry Price</span>
                      </td>
                      <td>
                        {' '}
                        <span className="text-[#18ADFA] drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">
                          {/* {openPrice?`$${openPrice?.toLocaleString()}`:'Loading...'} */}
                          {openPrice
                            ? '$' +
                              Number(openPrice).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 'Loading...'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="pr-[25px]">
                        <span className="drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">Mark Price</span>
                      </td>
                      <td>
                        <span className="text-[#18ADFA] drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">
                          {/* {closePrice?`$${closePrice?.toLocaleString()}`:'Loading...'} */}
                          {closePrice
                            ? '$' +
                              Number(closePrice).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 'Loading...'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="pr-[25px]">
                        <span className="drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">Leverage</span>
                      </td>
                      <td>
                        {' '}
                        <span className="text-[#18ADFA] drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">
                          {leverage ? `${leverage}x` : 'Loading...'}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          id="result-panel-share"
          className="pt-20 pl-4 pr-4 bg-[#000000] overflow-hidden sm:overflow-visible w-full sm:w-[35.813rem] mx-auto mt-[-21px] "
        >
          {/* <div className="d-flex flex pr-4 mb-2 flex-col w-full justify-center items-end right-2 top-2 text-[#18ADFA]" style={{

            fontSize: '13px',
            position: 'absolute',
            bottom: '-198px',
            right: '255px'

          }}>
            <div>
              <span className="font-barlow">Referral Code:</span>
            </div>
            <div>
              <span className="font-manrope">{referralCode}</span>
            </div>
          </div> */}
          <div className="overflow-hidden min-h-[186px] h-[186px] sm:overflow-visible w-full sm:w-[30.813rem] mx-auto results-shadow-mobile bg-[#09031D]/90 border-0 border-t-2 border-b-2 border-[#18ADFA] sm:border-solid sm:border-l-2 sm:border-r-2 sm:rounded-[0.875rem] mb-8">
            <div className="relative min-h-[186px] h-[186px] pt-[0px] sm:pt-20 overflow-hidden sm:overflow-visible pb-[0px] sm:pb-[3.125rem]">
              <img
                src={arbsOrBase === 'base' ? pepe_top_base : pepe_top}
                alt=""
                className="pointer-events-none hidden mx-auto sm:block w-[25rem] absolute top-[-9rem] left-0 right-0"
                onLoad={handleImageLoad}
              />
              <button
                onClick={() => navigate('/slot')}
                className="z-20 absolute top-0 right-0 sm:hidden p-2"
                style={{ width: '30px', height: '30px' }}
              >
                {/* <img src={cancel_icon_w_gradient} alt="" /> */}
                <img src={close} alt="" />
              </button>

              <p className="font-manrope font-bold text-[1.1rem] text-center leading-[2.125rem] mb-[6px] ml-[80px]">
                <>
                  <span className="font-manrope hidden sm:inline">${amount} </span>
                  <span className="font-manrope hidden sm:inline text-[rgb(0,183,240)]">. </span>
                  <span>{currency} </span>
                  <span className="font-manrope text-[rgb(0,183,240)]">. </span>
                  <span className="font-manrope text-[rgb(24,173,250)]">{longShort} </span>
                  <span className="font-manrope text-[rgb(0,183,240)]">. </span>
                  <span className="font-manrope">{time}s</span>
                </>
              </p>

              <div className="w-[85%] mx-auto bg-gradient-to-r from-[#48B9F3]/20 to-[#8A40B8]/20 rounded-[0.875rem] min-h-[61px] h-[61px]">
                <p className="text-center barlow font-extrabold text-[3.875rem] sm:text-[90px]">
                  <div className="relative flex justify-center items-center">
                    <>
                      {won ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 -17 150 30"
                          className="winlose-svg-container"
                        >
                          <defs>
                            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                              <stop offset="0%" stopColor="#48B9F3" />
                              <stop offset="100%" stopColor="#8A40B8" />
                            </linearGradient>
                          </defs>
                          <text
                            x="68%"
                            y="0%"
                            dominantBaseline="middle"
                            fontWeight="bold"
                            fontFamily="Barlow"
                            textAnchor="middle"
                            fontSize="18px"
                            fill="url(#gradient)"
                            className="font-barlow"
                          >
                            +80%
                          </text>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 -17 150 30"
                          className="winlose-svg-container"
                        >
                          <defs>
                            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                              <stop offset="0%" stopColor="#48B9F3" />
                              <stop offset="100%" stopColor="#8A40B8" />
                            </linearGradient>
                          </defs>
                          <text
                            x="68%"
                            y="0%"
                            dominantBaseline="middle"
                            fontWeight="bold"
                            fontFamily="Barlow"
                            textAnchor="middle"
                            fontSize="18px"
                            fill="url(#gradient)"
                            className="font-barlow"
                          >
                            +0%
                          </text>
                        </svg>
                      )}
                    </>

                    <Tooltip
                      title={pillsNotice}
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: toolStyle,
                        },
                        arrow: {
                          sx: {
                            color: '#43334F',
                          },
                        },
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="200"
                        height="200"
                        viewBox="-30 -10 208.445 73.125"
                        className="absolute top-[-82px] right-[40px] cursor-pointer min-h-[196px] h-[196px] degenpoints-svg-container"
                        onClick={() => {
                          navigate('/degen-dashboard');
                        }}
                      >
                        <defs>
                          <linearGradient id="userPointsGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="34.8958%" stop-color="#FB5780" />
                            <stop offset="100%" stop-color="#4819BE" />
                          </linearGradient>
                          <linearGradient id="degenPointsGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="34.8958%" stop-color="#FB5780" />
                            <stop offset="100%" stop-color="#4819BE" />
                          </linearGradient>
                        </defs>
                        <g transform="translate(-24 4) rotate(9.50)">
                          <rect width="188.445" height="63" fill="none" />
                          <text className="userpoints-svg-text" x="20" y="0" dy="20" fontFamily="'Barlow', sans-serif">
                            <tspan fill="url(#userPointsGradient)">{`+${userPoints}`}</tspan>
                          </text>
                          <image xlinkHref={information} alt="information icon" x="64" y="44" width="13" height="13" />
                          <text className="degenpoints-svg-text" x="40" y="38" fontFamily="'Barlow', sans-serif">
                            <tspan fill="url(#degenPointsGradient)" x="20" dy="0">
                              DEGEN
                            </tspan>
                            <tspan fill="url(#degenPointsGradient)" x="20" dy="18">
                              POINTS
                            </tspan>
                            <image
                              xlinkHref={information}
                              alt="information icon"
                              x="40"
                              y="25"
                              width="20"
                              height="20"
                            />
                            <img
                              src={information}
                              alt="information icon"
                              className="inline-block rotate-[-5deg] pb-[5px] sm:ml-[2px] font-barlow"
                            />
                          </text>
                        </g>
                      </svg>
                    </Tooltip>
                  </div>
                </p>
              </div>
              <div className="w-full sm:w-[85%] mx-auto flex justify-end h-[70px] absolute right-[16px]">
                {betPills && betPills != null && (
                  <div className="flex justify-end mt-[-27px] sm:mr-0 h-full sm:h-auto pill-margin !mr-[6px]">
                    <p
                      className="ml-10 flex flex-row justify-end items-center gap-1 cursor-pointer"
                      onClick={() => setQuestsModal(true)}
                    >
                      <img
                        src={arbsOrBase === 'base' ? pepePillsBase : pepePills}
                        alt="pills icons"
                        className="inline-block"
                        onLoad={handleImageLoad}
                        style={{ marginTop: isMobile ? -5 : 0, width: '16px' }}
                      ></img>
                      <span className="text-white rotate-[5.50deg] text-[13px] sm:text-base font-extrabold uppercase tracking-widest">
                        {`+${betPills}`}
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <img
                src={arbsOrBase === 'base' ? Pepe_logo_base : pepe_top_svg_2}
                alt=""
                className="relative w-full h-auto -top-12 sm:hidden pepe-logo-mobile"
                style={{
                  width: '100%',
                  position: 'absolute',
                  top: '10px',
                  left: '-65px',
                }}
                onLoad={handleImageLoad}
              />

              {/* <div className="w-[34rem] lg:w-[150%] h-[calc((34rem_*_(414/571))_-_5rem_-_5rem)] lg:h-[calc((1.5_*_(30.813rem)_*_(414_/_571))_-_5rem_-_16rem)] relative hidden sm:block pointer-events-none">
                <img src={Pepe_logo} alt="" className="relative w-full h-auto -top-20" onLoad={handleImageLoad} />
              </div> */}

              <div
                className="d-flex flex pr-4 mb-2 flex-col w-full justify-center items-end right-2 top-2 text-[#18ADFA]"
                style={{
                  fontSize: '13px',
                  position: 'absolute',
                  bottom: '-111px',
                  right: '232px',
                }}
              >
                <div>
                  <span className="font-barlow">Referral Code:</span>
                </div>
                <div>
                  <span className="font-manrope">{referralCode}</span>
                </div>
              </div>

              {/* account for the relative positioning of image above on mobile screens */}
              <div className="relative mb-[1.6rem] top-[15px] ml-[90px] sm:top-0 sm:mb-[24px] min-h-[62px] h-[62px]">
                <p className="flex justify-center gap-5 mb-[2px] text-xs font-medium align-center font-manrope">
                  <span className="drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">Entry Price</span>
                  <span className="text-[#18ADFA] drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">
                    {/* {openPrice? '$'+openPrice?.toLocaleString(): 'Loading...'} */}
                    {openPrice
                      ? '$' +
                        Number(openPrice).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 'Loading...'}
                  </span>
                </p>
                <p className="flex justify-center gap-5 mb-[2px] text-xs font-medium align-center font-manrope">
                  <span className="drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">Mark Price</span>
                  <span className="text-[#18ADFA] drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">
                    {/* {closePrice? '$'+closePrice?.toLocaleString(): 'Loading...'} */}
                    {closePrice
                      ? '$' +
                        Number(closePrice).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 'Loading...'}
                  </span>
                </p>
                <p className="flex justify-center gap-5 text-xs font-medium align-center font-manrope">
                  <span className="drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">Leverage</span>
                  <span className="text-[#18ADFA] drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)]">
                    {leverage ? leverage + 'x' : 'Loading...'}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareResult;
