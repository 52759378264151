import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  finalmobilelogo,
  pepe_top,
  pepe_top_base,
  cancel_icon_w_gradient,
  information_white,
  arrow_up_white,
  arrow_down_white,
  counterPepeUpArrow,
  counterPepeDownArrow,
} from '../../assets';
import leftCoinStack from '../../assets/images/pepeCoinStackLeft.svg';
import rightCoinStack from '../../assets/images/pepeCoinStackRight.svg';
import { useStorage } from '../../utils/StorageContext';
import { StoreContext } from '../../utils/store';
import { Tooltip } from '@mui/material';
import { BitCoinIcon } from '../BitcoinIcon';
import { ARBIcon } from '../ARBIcon';
import { EthereumIcon } from '../EthereumIcon';
import { UpArrow } from '../UpArrow';
import { DownArrow } from '../DownArrow';
import { useNavigate } from 'react-router-dom';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useNetwork } from 'wagmi';

const renderTime = ({ remainingTime }) => {
  return (
    <div className="timer">
      <div className="value font-barlow text-xl font-medium text-white">{remainingTime}</div>
    </div>
  );
};

const CounterPepeModal = ({ isOpen, open }) => {
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(0);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [duration, setDuration] = useState(9);
  const [isChosen, setIsChosen] = useState(false);
  const [initialDirection, setInitialDirection] = useState(null);
  const [playCount, setPlayCount] = useState(true);
  const loadingTime = 3000;
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const store = useContext(StoreContext);
  const {
    tradeVars: { time, currency, longShort },
    updateLongShort,
  } = store;
  const pillsNotice = `Doubt Pepe's settings? Become his counterparty! Press the button and trade in the opposite direction.`;
  const toolStyle = {
    color: 'white',
    backgroundColor: '#43334F',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '26px',
    paddingRight: '26px',
    borderRadius: '4px',
    fontSize: '14px',
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '400px',
    width: '229.025px',
    height: '139.793p',
  };

  useEffect(() => {
    setButtonDisabled(false);
    setIsChosen(false);
    setLoaded(0);
    setPlayCount(true);

    if (isOpen) setInitialDirection(longShort);
  }, [isOpen]);

  const load = () => {
    setButtonDisabled(true);

    const timer = setTimeout(() => {
      setLoaded(100);
      navigate('/chart');
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  };

  const handleTradeVars = () => {
    if (longShort === 'Long') {
      updateLongShort('Short');
    } else {
      updateLongShort('Long');
    }

    setPlayCount(false);
    setIsChosen(true);
    load();
  };

  const handleStart = () => {
    setPlayCount(false);
    load();
  };

  return (
    <>
      <Transition
        appear
        show={isOpen}
        as={Fragment}
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, overflow: 'auto' }}
      >
        <Dialog
          as="div"
          className="relative z-[1000]"
          onClose={() => {
            return;
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-75"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black" style={{ pointerEvents: 'none' }} />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full lg:h-screen items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-75"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="shadow-xl transition-all relative w-screen">
                  <div className="text-white min-h-screen lg:h-screen bg-[#09031D] sm:bg-transparent pb-16">
                    <span id="top"> </span>
                    <img
                      src={arbsOrBase === 'base' ? pepe_top_base : pepe_top}
                      alt=""
                      className={`pointer-events-none block mx-auto w-[80vw] sm:w-[25vw]`}
                    />
                    <div id="counterPepe" className={`barlow lg:relative lg:-top-6`}>
                      <div className="relative w-full sm:w-[60%] mx-auto max-w-[750px]">
                        <div className="relative stake-modal mx-auto">
                          <div className="py-[1.875rem] px-4 sm:px-0">
                            <div className="pt-4 pb-8">
                              <p className="barlow text-[20px] font-semibold smp-blue-heading mb-4 tracking-[0.03em]">
                                PREPARING YOUR TRADE
                              </p>
                              <div className="flex justify-center w-3/4 sm:w-auto mx-auto sm:mx-0">
                                <div className="smp-progress-bar">
                                  <div
                                    className="smp-progress-done"
                                    style={{ transition: `width ${loadingTime}ms` }}
                                  ></div>
                                  <div
                                    className="smp-progress-left"
                                    style={{ width: `${100 - loaded}%`, transition: `width ${loadingTime}ms` }}
                                  ></div>
                                </div>
                              </div>
                            </div>

                            <div className="w-full sm:mx-auto sm:w-3/5 max-w-[50rem] bg-[rgba(255,255,255,0.15)] rounded-lg pt-[1rem] pr-[0.625rem] lg:pr-[3.75rem] pb-[1rem] pl-[0.625rem] lg:pl-[3.75rem]">
                              <h1 className='font-["Tourney"] gradient-text font-bold text-[1.75rem] sm:text-[32px] mb-[30px] leading-[110%] text-center pt-2'>
                                PEPE&apos;S SETTINGS
                              </h1>
                              <div className=" lg:p-[1px] bg-transparent lg:bg-gradient-to-r from-[rgb(0,183,240)] to-[rgba(72,25,190)] mx-auto w-4/5 rounded-[10px] shadow-none lg:shadow-[0px_0px_24px_#18ADFA] px-[20px] py-3 mb-[30px] text-[60px] lg:min-w-[230px]">
                                <div className=" lg:bg-deep-blue/[0.93] rounded-[9px] w-full flex justify-center gap-8 items-center py-1">
                                  <div
                                    className={`w-[3rem] ${
                                      currency === 'BTC'
                                        ? 'sm:w-[40px] min-w-[40px]'
                                        : currency === 'ETH'
                                        ? 'sm:w-[32px] min-w-[32px]'
                                        : 'sm:w-[32px]'
                                    }`}
                                  >
                                    {currency === 'BTC' ? (
                                      <BitCoinIcon selected={true} />
                                    ) : currency === 'ETH' ? (
                                      <EthereumIcon selected={true} />
                                    ) : (
                                      <ARBIcon selected={true} />
                                    )}
                                  </div>
                                  <div className="w-[58px] sm:w-[58px] min-w-[58px]  mt-2">
                                    <img
                                      // className='w-[8rem]'
                                      src={initialDirection === 'Long' ? counterPepeUpArrow : counterPepeDownArrow}
                                      alt="Arrow"
                                    />
                                  </div>
                                  <p className="text-[2rem] sm:text-[34px] font-bold">{time}s</p>
                                </div>
                              </div>
                              <button
                                onClick={handleStart}
                                disabled={isButtonDisabled}
                                className={`inline-flex items-center justify-center gap-2 ${
                                  isChosen
                                    ? 'border-border-blue border-[0.5px] bg-deep-blue/50'
                                    : 'degen-spin-btn hover:scale-105 transition-all duration-300'
                                }  text-white rounded-md stake-btn-shadow barlow font-bold mx-auto px-2 py-2 w-[170px] text-[18px] scale-100`}
                              >
                                {' '}
                                START{' '}
                              </button>
                            </div>
                            <div className="flex items-center justify-center flex-col-reverse sm:flex-row my-8 sm:mt-8 mb-0 gap-3">
                              <div className="flex items-center gap-2">
                                <CountdownCircleTimer
                                  isPlaying={playCount}
                                  duration={duration}
                                  // colors={['#0DCB9D','#F7B801', '#FB7F25', '#F82C5D']}
                                  colors={['#0DCB9D', '#F7B801', '#F82C5D']}
                                  colorsTime={[6, 3, 0]}
                                  size={33}
                                  strokeWidth={4}
                                  onComplete={() => load()}
                                >
                                  {renderTime}
                                </CountdownCircleTimer>
                                <p className="text-[13px] barlow text-left flex sm:hidden justify-center text-[#E0DDFF] leading-4">
                                  Doubt Pepe&apos;s settings? Become his counterparty! Press the button and trade in the
                                  opposite direction.
                                </p>
                              </div>
                              <button
                                className={` flex  items-center justify-center gap-2 border-border-blue border-[0.5px] ${
                                  isChosen ? 'degen-spin-btn' : 'gradient-btn'
                                } text-white px-1 py-2 sm:px-6 rounded-md stake-btn-shadow text-[21px] barlow font-bold w-full sm:w-auto`}
                                onClick={handleTradeVars}
                                disabled={isButtonDisabled}
                              >
                                <img
                                  src={initialDirection === 'Long' ? arrow_down_white : arrow_up_white}
                                  className="flex items-center justify-center pt-1"
                                  style={{ height: '30px' }}
                                />{' '}
                                COUNTER PEPE!{' '}
                              </button>
                              <Tooltip
                                title={pillsNotice}
                                placement="right"
                                arrow={false}
                                componentsProps={{
                                  tooltip: {
                                    sx: toolStyle,
                                  },
                                  arrow: {
                                    sx: {
                                      color: '#43334F',
                                    },
                                  },
                                }}
                              >
                                <img
                                  src={information_white}
                                  alt="information icon"
                                  className="hidden sm:inline-block font-barlow"
                                />
                              </Tooltip>
                            </div>
                          </div>
                          <img
                            src={leftCoinStack}
                            className="hidden sm:block sm:fixed bottom-0 -left-6 sm:left-12 lg:left-12 h-44 sm:h-48 lg:h-52 w-auto sm:z-10"
                            alt="stack of coins"
                          />
                          <img
                            src={rightCoinStack}
                            className="hidden sm:block sm:fixed bottom-0 right-0 sm:right-6 lg:right-12 h-auto w-28 sm:w-40 lg:w-48 sm:z-10"
                            alt="stack of coins"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CounterPepeModal;
