import React, { useState, useEffect, useContext } from 'react';
import { extendtime, cancel_icon_w_gradient, pill } from '../../assets';
import { CountdownCircleTimer, useCountdown } from 'react-countdown-circle-timer';
import { CreditsContext } from '../../utils/creditsContext';
import ExtendTimeButton from './ExtendTimeButton';
import { Tooltip } from '@mui/material';

const renderTime = ({ remainingTime }) => {
  return (
    <div className="timer">
      <div className="text-xl font-medium text-white value font-barlow">{remainingTime}</div>
    </div>
  );
};

const ExtendTime = ({ timeLeft }) => {
  const [duration, setDuration] = useState(timeLeft - 1);
  const [canExtend, setCanExtend] = useState(false);
  const [hasExtendedBefore, setHasExtendedBefore] = useState(false);
	const [syncing, setSyncing] = useState(false);
  const [startTimer, setStartTimer] = useState(false)
  const creditsContext = useContext(CreditsContext);
  const { questChips } = creditsContext;
  const [toolTipText, setToolTipText] = useState('Time extension not available')

  useEffect(() => {
    if (!hasExtendedBefore) {
      if (timeLeft > 5 && timeLeft <= 15) {
        //@dev we subtract 4 secs to account for the time lost during load of the component
        setCanExtend(true);
      } else if (timeLeft <= 5) {
        setCanExtend(false);
        setToolTipText('Too late fren. Not enough time');
      } else if (timeLeft > 15) {
        setCanExtend(false);
        setToolTipText('Not yet fren. Wait for 15 sec mark');
      }
    }
  }, [timeLeft, hasExtendedBefore]);

  useEffect(() => {
    setStartTimer(true)
  }, [])

  useEffect(() => {
    if (questChips == 0) {
      setToolTipText('No pills: Sorry fren. Out of pills today')
      setCanExtend(false)
    }
  }, [questChips])

  return (
    <>
      <Tooltip
        title={!canExtend ? (`${toolTipText}`) : (null)}
        placement="bottom"
        arrow={true}
        componentsProps={{
          tooltip: {
            sx: {
              color: 'white',
              backgroundColor: '#43334F',
              paddingTop: '16px',
              paddingBottom: '16px',
              paddingLeft: '16px',
              paddingRight: '16px',
              borderRadius: '4px',
              fontSize: '14px',
              fontFamily: 'Barlow',
              fontStyle: 'normal',
              fontWeight: '400px',              // width: '229.025px',
              // height: '139.793p',
            },
          },
          arrow: {
            sx: {
              color: '#43334F',
            },
          },
        }}
      >


        <div className={`xs:flex xs:flex-col xs:justify-center flex flex-col sm:flex sm:flex-row items-center justify-between sm:gap-[33px] bg-[#09031DF2] py-3.5 sm:px-[30px] px-2 w-full sm:w-fit mx-auto rounded-[10px] border-[0.5px] border-solid border-[#18ADFA] shadow-[0px_0px_24px_0px_#18ADFA_,_0px_5px_24px_0px_#000000] transition duration-150 ease-in ${!canExtend ? ('grayscale-[90%] ') : ('')}`}>
          <div className="flex items-center justify-center h-full gap-x-3">
            <img src={extendtime} className="h-[18px] sm:h-[25px] my-auto" alt="" />
            <div className="sm:hidden">
              <CountdownCircleTimer
                isPlaying={startTimer}
                duration={duration}
                updateInterval={1}
                // duration={timeLeft}
                colors={['#0DCB9D', '#F7B801', '#FB7F25', '#F82C5D']}
                colorsTime={[15, 10, 5, 0]}
                size={30}
                strokeWidth={4}
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
          </div>

          <div className="flex items-center justify-between mt-4 xs:gap-3 sm:gap-5 sm:mt-0 w-fit">
            <div className="flex items-center justify-between xs:space-x-3 sm:space-x-5 ">
              <ExtendTimeButton disabled={!canExtend} setHasExtendedBefore={setHasExtendedBefore} setDuration={setDuration} duration={duration} seconds={5} pills={1} syncing={syncing} setSyncing={setSyncing}/>
              <ExtendTimeButton disabled={!canExtend} setHasExtendedBefore={setHasExtendedBefore} setDuration={setDuration} duration={duration} seconds={10} pills={2} syncing={syncing} setSyncing={setSyncing}/>
              <ExtendTimeButton disabled={!canExtend} setHasExtendedBefore={setHasExtendedBefore} setDuration={setDuration} duration={duration} seconds={15} pills={3} syncing={syncing} setSyncing={setSyncing}/>
            </div>

            <div className="hidden sm:flex">
              <CountdownCircleTimer
                isPlaying={startTimer}
                duration={duration}
                updateInterval={1}
                // duration={timeLeft}
                colors={['#0DCB9D', '#F7B801', '#FB7F25', '#F82C5D']}
                colorsTime={[15, 10, 5, 0]}
                size={40}
                strokeWidth={4}
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default ExtendTime;

const buttonStyle = {
  background: 'linear-gradient(92.59deg, rgba(0, 183, 240, 0.15) 9.56%, rgba(72, 25, 190, 0.15) 106.95%)',
};

