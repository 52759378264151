import React, { useContext, useEffect, useState, useCallback, useMemo, useLayoutEffect, useRef } from 'react';
import '../styles/chart.css';
import TopBar from '../components/top-nav/top-bar';
import Navbar from '../components/Navbar/Navbar';
import Wrapper from '../components/Wrapper';
import {
  pepe_top,
  pepe_top_base,
  pepe_top_mobile,
  extend_time,
  winningPercent,
  losingPercent,
  tokenPEPE,
  tokenUSDC,
} from '../assets';
import pepe_winning from '../assets/images/pepeMood-happy.png';
import pepe_winning_base from '../assets/images/pepe_happy_base.svg';
import pepe_losing from '../assets/images/pepeMood-sad.png';
import pepe_losing_base from '../assets/images/pepe_sad_base.svg';
import PepeFooter from '../components/Pepe-footer/pepefooter';
import PauseIcon from '../assets/icons/pause.svg';
import PlayIcon from '../assets/icons/play.svg';
import StopIcon from '../assets/icons/stop.svg';

import Settings from '../components/Settings/Settings';
import bulbFrame_winning from '../assets/images/bulbFrame_winning.png';
import bulbFrame_losing from '../assets/images/bulbFrame_losing.png';
import stars from '../assets/images/Group 166.svg';
import dots from '../assets/images/Group 165.svg';
import down_arrow from '../assets/images/Group 163.svg';
import up_arrow from '../assets/images/Group 164.svg';
import bitCoinIcon from '../assets/icons/bitcoin.svg';
import ethCoinIcon from '../assets/icons/ethcoin.svg';
import arbicon from '../assets/icons/arbicon.svg';

import { StoreContext } from '../utils/store';
import { useSnackbar } from 'notistack';

import { ethers } from 'ethers';
import axios from 'axios';
import { useNetwork, chainId, useAccount, useSigner } from 'wagmi';
import { abi } from '../utils/pepebet-abi';
import {
  pepeBetContractAddress,
  approvedAssets,
  prod_API,
  trade_API,
  api,
  pepeAPI,
  tokens,
  getQuest_API,
  emailAlert_API,
} from '../constants';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Chart3 from '../components/Chart2Practice';
import { useMediaQuery } from 'usehooks-ts';
import NoAccessMobileModal from '../components/NoAccessMobileAlert/NoAccessMobileModal';
import { getLeverage, getSelectedTokenData } from '../services/service';
import useLocalStorage from '../hooks/useLocalStorage';
import AutoSpinResult from '../components/modal/AutoSpinResult';
import AutoSpinIndicator from '../components/AutoSpinIndicator';
import { useStorage } from '../utils/StorageContext';
import { CreditsContext } from '../utils/creditsContext';
import ExtendTime from '../components/ExtendTime/ExtendTime';
import { EthereumIcon } from '../components/EthereumIcon';
import { BitCoinIcon } from '../components/BitcoinIcon';
import { ARBIcon } from '../components/ARBIcon';
import AnimatingChartBackground from './../components/AnimatingChartBackground';

let isSet = false;
let isClosed = false;
let closedAmount = 0;
let tradeEvent = '';
let profit = false;

const Chart = () => {
  const isAutoSpinning = false;
  const navigate = useNavigate();
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const creditsCtx = useContext(CreditsContext);
  const { yourCredits, chipsAndDays, questStreakCompleted } = useContext(CreditsContext);
  const { getQuestChips, completedStreakForToday } = creditsCtx;
  const Store = useContext(StoreContext);
  const {
    tradeVars,
    price,
    setStartTime,
    setGoodLuckModal,
    sfxAudio,
    setSrc,
    music,
    setSFX,
    setStoreState,
    setQuestChips,
    setQuestSpinDay,
    setDaysSpun,
    lambdaResponseData, //response from lambda, including openPrice details
    autoSpinVars,
    depositWithdrawToken,
    setAutoSpinVars,
    setCounterPepeModal,
  } = Store;
  const [timeLeft, setTimeLeft] = useState(tradeVars.time - 2);
  const intervalRef = useRef(); // To store the interval object for the checking bet details
  const prevRemainingTime = useRef(); // To store the previous remaining time
  const [arrow, setArrow] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [showChart, setShowChart] = useState(false);
  const [sp, setSp] = useState(20000.0); //local state for starting price
  const [cprice, setCPrice] = useState(0.0);
  const [payout, setPayout] = useState(71);
  // const [newPrice, setNewPrice] = useState(0); //hack to get current price. store seems not stable
  const barRef = useRef(null);
  const progress = (timeLeft / tradeVars.time) * 100;
  const { autoSpinData, setAutoSpinData, betCurrency, setIsAutoWinning } = useStorage();
  const [showAutoSpinFinal, setShowAutoSpinFinal] = useState(false);
  const [numOfRounds, setNumberRounds] = useState(0);
  const [betAmount, setBetAmount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [winlossSrc, setWinlossSrc] = useState();
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const pepeBetContract = useMemo(() => new ethers.Contract(pepeBetContractAddress, abi, signer), [signer]);
  const [noAccessMobileModal, setNoAccessMobileModal] = useState(false);
  const isMobileDevice = useMediaQuery('(max-width: 640px)');
  const isDesktopOrLarger = useMediaQuery('(min-width: 1024px)');
  const isShortOrLong = useMediaQuery('(max-height: 800px)');
  const [showWinModal, setShowWinModal] = useState(false);
  const formatter = new Intl.NumberFormat('en-US');
  const { state } = useLocation();

  const [pepeWin, setPepeWin] = useState(true);

  const [tradeStatus, setTradeStatus] = useState(null);

  const fetchDetails = useCallback(
    async (lambdaReturn) => {
      console.log('fetch details params: ', lambdaReturn);
      setTradeStatus(null);
      setStartTime(new Date());
      //setSp(parseFloat(lambdaReturn.openPrice));
      setShowChart(true); //then show the chart
      //const betStatusAPIGateway = 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/trade';

      // console.log("LAMBDA VARS",lambdaReturn)
      //console.log('store state value of returned data from placeBet: ', lambdaResponseData);
      //call lambda
      try {
        const interval = setInterval(async () => {
          try {
            const lambdaResponse = await axios.post(api.offChainBet.getBetEndpoint, { betId: lambdaReturn.betId });
            const betData = lambdaResponse?.data?.message;
            const serverTime = lambdaResponse.data.serverTime;
            // console.log('lambda response: ', lambdaResponse);
            // console.log('lambda response body message: ', lambdaResponse?.data?.message);
            // console.log('lambda response data: ', lambdaResponse?.data);

            //sync with ui time
            //console.log(Math.floor((betData.serverTime - lambdaResponseData.start_time)/1000) )
            let tleft = tradeVars.time - Math.floor((serverTime - parseInt(lambdaReturn.startTime)) / 1000);

            if (tleft !== prevRemainingTime.current) {
              setTimeLeft(tleft <= 0 ? 0 : tleft);
              prevRemainingTime.current = tleft;
            }

            if (serverTime >= parseInt(lambdaReturn.startTime) + (tradeVars.time + 1) * 1000) {
              setShowWinModal(true);
              // console.log('closing server time:', serverTime);
              // console.log('closing start + runTime:', lambdaReturn.start_time + ((tradeVars.time+2) * 1000));
              if (!autoSpinData.isAuto) {
                clearInterval(interval);
              }

              // musicToggle('endTrade')
              // setShowChart(false);

              if (autoSpinData.isAuto) {
                if (!betData.is_active) {
                  clearInterval(interval);
                  setTradeStatus(betData);
                  const src = parseFloat(betData.payout) > 0 ? winningPercent : losingPercent;
                  setWinlossSrc(src);
                  handleAutoSpinOperations(autoSpinData);
                }
              } else {
                setShowChart(false);
                setTimeout(() => {
                  navigate('/result', { state: { betid: lambdaReturn.betId, endprice: closedAmount } });
                }, 2000);
              }
            }
          } catch (e) {
            console.error('get bet status interval error: ', e);
            ///keep fetching h
          }
          //JSON.stringify({ status: 'success', data: res.rows, serverTime: Date.now() })
        }, 700);

        // Wait for the interval to finish before closing the modal and playing the music
        await new Promise((resolve) => setTimeout(resolve, 2000));
        // setGoodLuckModal(false);
        musicToggle('startTrade');
        setCounterPepeModal(false);
        tradeEvent = 'startTrade';

        intervalRef.current = interval;
        return () => clearInterval(interval);
      } catch (err) {
        console.log(err);

        navigate('/slot');
        enqueueSnackbar('Bet was not placed successfully. ', { variant: 'error' });
      }
    },
    [address]
  );

  const handleAutoSpinOperations = (data) => {
    if (data.roundsLeft === 0) {
      const rounds = data.rounds;
      const amount = data.amount;
      setBetAmount(amount);
      setNumberRounds(rounds);
      setShowWinModal(true);
      setTimeout(() => {
        setShowAutoSpinFinal(true);
      }, 5000);
    } else {
      setShowWinModal(true);
      setTimeout(() => {
        navigate('/slot');
      }, 5000);
    }
  };

  // const binance_url = `https://api.binance.com/api/v3/ticker/price?symbol=${tradeVars.currency}USDC`;

  // const API_Gateway = 'https://24ocl1u168.execute-api.us-west-2.amazonaws.com/Prod/';

  const start = 21075.0;

  const sendOpenTrade = async () => {
    /// to lambda since values are generated instantly, only animations delay
    try {
      const data = await lambdaPlaceBet(tradeVars.amount, tradeVars.currency, tradeVars.time, tradeVars.longShort);
      //setLambdaReturn(data)
      setStoreState('lambdaResponseData', data);
      //either pass data as a prop or set state...
      // setTimeout(() => {
      if (autoSpinData.isAuto) {
        const { isAuto, amount, rounds, roundsLeft, autospin_id, isPlay, isStop, isStopAt } = autoSpinData;
        var values = autospin_id.split('_').filter((item) => /^\d+$/.test(item));
        let id = autospin_id.replace(values.join('_'), '').slice(0, -1);
        setAutoSpinData({
          isAuto,
          amount,
          rounds,
          roundsLeft: roundsLeft,
          autospin_id: `${id}_${rounds - roundsLeft}_${rounds}`,
          isPlay,
          isStop,
          isStopAt,
          currentBetId: data.betId,
          currentBetStartTime: data.start_time,
        });
      }
      return data;
      // }, 6000);
    } catch (e) {
      console.log({ sendTradeError: e });
      // setGoodLuckModal(false);
      setCounterPepeModal(false);
      if (autoSpinData.isAuto) {
        const { isAuto, amount, rounds, roundsLeft, autospin_id, isPlay, isStop, isStopAt } = autoSpinData;
        const whatsLeft = roundsLeft + 1;
        var values = autospin_id.split('_').filter((item) => /^\d+$/.test(item));
        let id = autospin_id.replace(values.join('_'), '').slice(0, -1);
        setAutoSpinData({
          isAuto,
          amount,
          rounds,
          roundsLeft: whatsLeft,
          autospin_id: `${id}_${rounds - whatsLeft}_${rounds}`,
          isPlay,
          isStop,
          isStopAt,
        });
        setAutoSpinVars(isAuto, amount, rounds, whatsLeft);
      }
      navigate('/slot');
      // enqueueSnackbar(e.message, {
      //   variant: 'error',
      //   preventDuplicate: true,
      // });
      return;
    }
  };

  //Method to log transaction
  // const longRefereeTransaction = async (address, transaction_amount, tdate, transaction_id) => {
  //   const transactionParams = {
  //     refer_id: address,
  //     transaction_amount: transaction_amount,
  //     tdate: tdate,
  //     transaction_id: transaction_id,
  //   };
  //   console.log('transaction params: ', transactionParams);

  //   try {
  //     const response = await axios.post(``, transactionParams);
  //     const data = response.data?.message; //unsure of returned data type, assuming json
  //     return data;
  //   } catch (e) {
  //     console.error('Failed to log transaction: ', e);
  //     throw new Error('Failed to log transaction');
  //   }
  // };
  //  const { userAddress, amount, duration, isLong, asset, wagerTokenAddress, autoSpinId } = betData;

  const lambdaPlaceBet = async (betAmount, assetTicker, betDuration, isLong) => {
    // const usdcToken = tokens.find((token) => token.name === 'USDC')
    const lambdaParams = {
      userAddress: ethers.utils.getAddress(address),
      amount: parseFloat(betAmount.toString()),
      duration: parseInt(betDuration.toString()),
      isLong: isLong === 'Long' ? true : false,
      asset:
        assetTicker === 'BTC'
          ? ethers.utils.getAddress(approvedAssets.WBTC)
          : assetTicker === 'ETH'
          ? ethers.utils.getAddress(approvedAssets.WETH)
          : ethers.utils.getAddress(approvedAssets.ARB),
      wagerTokenAddress: ethers.utils.getAddress(getSelectedTokenData(depositWithdrawToken).tokenAddres),
      autospin_id: autoSpinData.autospin_id ? autoSpinData.autospin_id.toString() : '',
      chainId: chain.id,
    };
    console.log('lambda params: ', lambdaParams);

    try {
      const lambdaResponse = await axios.post(api.offChainBet.placeBetApiEndpoint, lambdaParams);
      console.log('place bet response: ', lambdaResponse);
      const data = lambdaResponse.data?.message; //unsure of returned data type, assuming json
      console.log('final data: ', data);
      //calculate the pills after the bet is started successfully
      RunQuestsCalculation(data.betId);
      getQuestChips();
      return data;
    } catch (e) {
      console.error('Failed to place bet: ', e);
      runPlaceBetEmailAlert(e.toString());
      // logFaileBet(e.toString())
      navigate('/slot');
      enqueueSnackbar('Bet was not placed successfully. ', { variant: 'error' });
      // throw new Error('Failed to place bet');
    }
  };

  useEffect(() => {
    if (address) {
      const getUserQuest = async () => {
        try {
          // const { data } = await axios.get(
          //   // 'https://4huqqpgjld.execute-api.us-west-2.amazonaws.com/get?address=0x58B540D4899f04CB66E31c91d3D4Ee1a969200eA'
          //   `${getQuest_API}/get?address=${address}`
          // );
          const { data } = await axios.get(
            // 'https://4huqqpgjld.execute-api.us-west-2.amazonaws.com/get?address=0x58B540D4899f04CB66E31c91d3D4Ee1a969200eA'
            `${api.offChainBet.getQuestV2ApiEndpoint}?address=${address}`
          );
          console.log(`New data is ${data}`);
          if (data === undefined) {
            setQuestChips(0);
            setQuestSpinDay(0);
            setDaysSpun(0);
          } else {
            setQuestChips(Number(data.data.chips));
            setQuestSpinDay(Number(data.data.spinDay));
            setDaysSpun(Number(data.data.spunDays));
          }
        } catch (err) {}
      };
      getUserQuest();
    } else {
      setQuestChips(0);
      setQuestSpinDay(0);
      setDaysSpun(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const RunQuestsCalculation = async (betId) => {
    // console.log('returned data::', returnedQuestChips, returnedQuestSpinDay, returnedDaysSpun)
    console.log('betId running quests::', betId);
    // const { data } = await axios.post(
    //   `${getQuest_API}/addUpdatev2?address=${address}`,
    //   {
    //     betId: betId,
    //   },
    //   {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   }
    // );
    const { data } = await axios.post(
      `${api.offChainBet.addUpdateQuestApiEndpoint}?address=${address}`,
      {
        betId: betId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log('quests data::', data.data)
    if (data.data.hasSpunToday) {
      await getQuestChips();
    }
  };

  //https://zy3oz9itxj.execute-api.us-west-2.amazonaws.com

  const runPlaceBetEmailAlert = async (error) => {
    console.log();
    try {
      // console.log('returned data::', returnedQuestChips, returnedQuestSpinDay, returnedDaysSpun)
      const { data } = await axios.post(
        // `${emailAlert_API}/alert`,
        `${api.offChainBet.placebetErrorEmailAlertsApiEndpoint}`,
        {
          useraddress: address,
          error: error,
          emails: ['asad@reallygreattech.com'],
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (e) {
      console.log('An error occured running placebet email alert: ', e);
    }
  };

  const logFaileBet = async (error) => {
    console.log();
    try {
      // console.log('returned data::', returnedQuestChips, returnedQuestSpinDay, returnedDaysSpun)
      const { data } = await axios.post(
        `https://zk3h1rc2af.execute-api.us-west-2.amazonaws.com/addfailedbet`,
        {
          address: address,
          message: error,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (e) {
      console.log('An error occured running placebet email alert: ', e);
    }
  };

  useEffect(() => {
    if (tradeVars?.amount) {
      if (!autoSpinData.isAuto) {
        musicToggle('onStart');
      }

      //get leverage
      getLeverage().then((res) => {
        setPayout(res);
      });

      setTimeout(async () => {
        isSet = true;

        let ss;
        if (state != undefined) {
          ss = {
            betId: autoSpinData.currentBetId,
            startTime: autoSpinData.currentBetStartTime,
          };
        } else {
          ss = await sendOpenTrade();
        }

        // RunQuestsCalculation()

        setTimeLeft((prevState) => {
          return tradeVars ? tradeVars?.time : prevState;
        });
        prevRemainingTime.current = tradeVars?.time || 0;
        //console.log('how many times i render');
        fetchDetails(ss);
        musicToggle('startTrade');
        //setGoodLuckModal(false);
      }, 500);
    } else {
      navigate('/slot');
      enqueueSnackbar('Bet was not placed successfully. ', { variant: 'error' });
    }

    // return () => {
    //   // setStartingPrice(null);
    // };
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    // const bar = document.querySelector('.loading');
    if (timeLeft === 0) {
      if (isWinning()) {
        setSFX(sfxAudio[5]); //Sound to play sfx when trade is WON
      } else {
        setSFX(sfxAudio[6]); //Sound to play when trade is LOST
      }
    }

    const intervalId = setInterval(() => {
      if (timeLeft === 0) {
        isClosed = true;
        closedAmount = price;
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeLeft]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      gettingNewPriceValues();
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (isMobileDevice) {
      setNoAccessMobileModal(true);
    } else {
      setNoAccessMobileModal(false);
    }
  }, [isMobileDevice]);

  const gettingNewPriceValues = () => {
    if (isSet) {
      // setStartingPrice(newPrice);
      isSet = false;
    }

    if (isClosed) {
      isClosed = false;
      closedAmount = price;
    }

    //});
  };

  const sendPlaceBetErrorEmailAlert = async (error) => {
    console.log();
    try {
      const { data } = await axios.post(
        `${emailAlert_API}/alert`,
        {
          useraddress: address,
          error: error,
          emails: ['adam@razorgrip.com'],
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (e) {
      console.log('An error occured running placebet email alert: ', e);
    }
  };

  const musicToggle = (event) => {
    if (event === 'onStart') {
      setSrc(music[1]); //Sound to play when Chart is opened
    }

    if (event === 'startTrade') {
      setSFX(sfxAudio[4]); //Sound to play on rocket start
    }

    if (event === 'endTrade') {
      tradeEvent = 'endTrade';
    }
  };

  const isWinning = () => {
    if (tradeEvent !== 'endTrade') {
      if (tradeVars?.longShort === 'Short') {
        if (price < parseFloat(lambdaResponseData?.openPrice)) {
          profit = true;
          return true;
        } else {
          profit = false;
          return false;
        }
      }

      if (tradeVars?.longShort === 'Long') {
        if (price > parseFloat(lambdaResponseData?.openPrice)) {
          profit = true;
          return true;
        } else {
          profit = false;
          return false;
        }
      }
    } else {
      return profit;
    }
  };

  const handleStopAutoSpin = () => {
    const isStoppedAt = autoSpinData.roundsLeft - 1;
    setAutoSpinData({ ...autoSpinData, isStopAt: isStoppedAt, isStop: true });
    navigate('/slot');
  };

  useEffect(() => {
    if (autoSpinData.isAuto) setIsAutoWinning(isWinning());
  }, [autoSpinData.isAuto, isWinning]);

  const openPriceFeedSource = (coin) => {
    window.open(`https://pyth.network/price-feeds/crypto-${coin}-usd`, '_blank');
  };

  return (
    <Wrapper>
      {showAutoSpinFinal && (
        <AutoSpinResult isOpen={showAutoSpinFinal} numOfRounds={numOfRounds} amountForAutoSpin={betAmount} />
      )}
      {!noAccessMobileModal ? (
        <>
          <Navbar />
          <div className="absolute top-4  flex justify-center z-[11] pointer-events-none">
            <img src={arbsOrBase === 'base' ? pepe_top_base : pepe_top} className="w-auto h-[14rem]" alt="Logo" />
          </div>
          <div className="relative flex flex-col h-[100%] mx-auto md:container chart-container">
            <div className="basis-24">{/* <Jackpot amount="$16,578" /> */}</div>
            <div className="flex flex-col h-full mt-[6rem]">
              {/* CARD WRAPPER */}
              <div className={`z-20 absolute mx-auto w-full -mt-[5%]`}>
                {showChart && <ExtendTime timeLeft={timeLeft} />}
              </div>
              <div
                id="card-wrapper"
                // className={ pepeWin ? ("bg-gradient-to-b from-[#0B8883] to-[#073634] bg-[#0B8883]/20 w-full chart-wrapper z-10 relative rounded-xl flex flex-col justify-center items-center chart") : ("bg-gradient-to-b from-[#780730] to-[#240708] bg-[#0B8883]/20 w-full chart-wrapper z-10 relative rounded-xl flex flex-col justify-center items-center chart") }
                className={
                  isWinning()
                    ? 'bg-gradient-to-b from-[#0B8883] to-[#073634]  w-full chart-wrapper z-10 relative rounded-xl flex flex-col justify-center items-center chart_winning transition ease-in duration-200'
                    : 'bg-gradient-to-b from-[#780730] to-[#240708] bg-[#0B8883]/20 w-full chart-wrapper z-10 relative rounded-xl flex flex-col justify-center items-center chart_losing transition ease-in duration-200'
                }
              >
                {/* <div className="w-[107%] flex justify-center object-fill h-24 absolute top-[-47px]">
              <img src={horizontal_bulbs} alt="Horizontal" />
            </div> */}

                {showWinModal && (
                  <div className="winlossImage">
                    {!tradeStatus || !tradeStatus.is_active ? (
                      <div className="text-white">Loading...</div>
                    ) : (
                      <img src={winlossSrc} className="" alt="win or loss image" />
                    )}
                  </div>
                )}

                <div className="absolute z-20 flex items-center justify-center bulb-container">
                  {/* <img src={bulb_frame} className="bulb-frame" alt="Horizontal" /> */}
                  {isWinning() ? (
                    <img src={bulbFrame_winning} className="bulb-frame" alt="Horizontal" />
                  ) : (
                    <img src={bulbFrame_losing} className="bulb-frame" alt="Horizontal" />
                  )}
                </div>

                {/* main chart component */}
                <div className="absolute w-full h-[90%] z-20">
                  {/* COMMENTED OUT ASTRONAUT AND MOON */}

                  {showChart && <Chart3 setCPrice={setCPrice} />}
                  {/* //we pass the state function here to get the updated state value  */}
                  {/* <AmChart /> */}
                </div>

                {/* token and times component */}
                <div className="w-full h-full z-[1000] rounded-xl absolute flex flex-col justify-between">
                  <p className="flex flex-row items-center z-[10000] text-white text-left uppercase manrope text-sm p-8 pl-1">
                    <span className="inline-flex items-baseline pl-5 pr-1 font-bold">
                      {tradeVars.currency === 'ETH' ? (
                        <img src={ethCoinIcon} className="w-5 h-5" alt="Ethereum" />
                      ) : tradeVars.currency === 'BTC' ? (
                        <img src={bitCoinIcon} className="w-5 h-5" alt="Bitcoin" />
                      ) : (
                        <img src={arbicon} className="w-5 h-5" alt="arb" />
                      )}
                    </span>

                    {tradeVars.currency === 'ETH' ? (
                      <a className="cursor-pointer" onClick={() => openPriceFeedSource('eth')}>{`ETH ${'\u00B7'} ${
                        tradeVars.time
                      }s ${'\u00B7'} PYTH`}</a>
                    ) : tradeVars.currency === 'BTC' ? (
                      <a className="cursor-pointer" onClick={() => openPriceFeedSource('btc')}>{`BTC ${'\u00B7'} ${
                        tradeVars.time
                      }s ${'\u00B7'} PYTH`}</a>
                    ) : (
                      <a className="cursor-pointer" onClick={() => openPriceFeedSource('arb')}>{`ARB ${'\u00B7'} ${
                        tradeVars.time
                      }s ${'\u00B7'} PYTH`}</a>
                    )}
                  </p>

                  <div className=" w-full flex flex-row z-[100000] justify-between">
                    <div className="flex pl-1">
                      <div className="mb-5 mt-1 w-[80px]">
                        <p className="text-[#E0DDFF] opacity-70 text-left uppercase manrope text-sm pl-3">Time Left</p>
                        <span className="relative z-50 pl-5 mt-6 text-4xl font-bold text-center text-white manrope chart-text">
                          {timeLeft}s
                        </span>
                      </div>
                      <div className="w-40 h-1 mt-8 ml-2 bg-gradient-to-r from-pepe-blue\30 to-[#4819BE]\30 rounded-lg self-center">
                        <div
                          ref={barRef}
                          style={{ width: `${progress}%` }}
                          className="h-full w-0 bg-gradient-to-r from-pepe-blue to-[#4819BE] rounded-lg loading"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* main component winloss backgrounds */}
                <div
                  className={
                    isWinning()
                      ? 'w-full h-full bg-gradient-to-b from-[#0B8883] to-[#073634] z-10 rounded-xl relative flex flex-col justify-between'
                      : 'w-full h-full bg-gradient-to-b from-[#780730] to-[#240708] bg-[#0B8883] z-10 rounded-xl relative flex flex-col justify-between'
                  }
                ></div>

                {/* DOTS AND STARS */}

                <AnimatingChartBackground numberOfDivs={25} image={stars} />
              </div>

              <div
                id="stats-wrapper"
                className="flex flex-col w-[95%] sm:w-[80%] lg:w-[735.6px] items-end bg-gradient-to-r from-pepe-blue-dim to-pepe-purple-dim relative h-auto items-center justify-between mx-auto z-0 rounded-b-2xl opacity-100 shadow-darker"
              >
                <div
                  className={
                    isWinning()
                      ? 'flex flex-col items-center w-full h-[132px] pt-5 pb-2 rounded-b-lg bg-deep-blue chartData_Glow transition ease-in duration-200'
                      : 'flex flex-col items-center w-full h-[132px] pt-5 pb-2 rounded-b-lg bg-deep-blue chartDataGlow_Losing transition ease-in duration-200'
                  }
                >
                  <div className="w-full mx-3 mt-2 flex justify-evenly items-center gap-1 text-[#E0DDFF]">
                    <div className="my-auto">
                      <button>
                        {tradeVars && tradeVars?.longShort === 'Long' ? (
                          <img src={up_arrow} className="w-[70px] h-[39px]" alt="long trade" />
                        ) : (
                          <img src={down_arrow} className="w-[70px] h-[39px]" alt="short trade" />
                        )}
                      </button>
                    </div>

                    <button className="my-auto shadowBlur flex justify-center items-center gap-[2px] ">
                      {/* <img src={betCurrency === 'PEPE' ? tokenPEPE : tokenUSDC} /> */}${tradeVars?.amount}
                    </button>

                    <div className={`my-auto ${tradeVars.currency === 'BTC' ? 'w-12' : 'w-10'}`}>
                      {tradeVars.currency === 'ETH' ? (
                        <EthereumIcon selected={true} />
                      ) : tradeVars.currency === 'BTC' ? (
                        <BitCoinIcon selected={true} />
                      ) : (
                        <ARBIcon selected={true} />
                      )}
                    </div>
                    <div className="my-auto">
                      <p className=" text-left font-manrope opacity-50 text-[13px] text-[#E0DDFF] font-medium">
                        Entry Price
                      </p>
                      <span className="relative  opacity-60 text-center font-bold text-[26px] mt-6 font-manrope chart-text z-50">
                        {/* {tradeVars ? `$${openingPrice.toLocaleString()}` : 0.0} */}
                        {`$${formatter.format(Number(lambdaResponseData?.openPrice).toFixed(2))}`}
                      </span>
                    </div>

                    <div className="my-auto w-[60px]">
                      <p className=" text-left font-manrope text-[13px] text-[#E0DDFF] font-medium opacity-70">
                        Profit
                      </p>
                      <span className="relative text-left font-bold text-[26px] mt-6 font-manrope chart-text z-50">
                        {isWinning() ? (
                          <p className="font-manrope font-bold text-[#0ACA9C] text-[26px]  ">+{Math.round(payout)}%</p>
                        ) : (
                          <p className="font-manrope font-bold text-[#F82C5D] text-[26px]  ">0%</p>
                        )}
                      </span>
                    </div>

                    <div className="my-auto">
                      {isWinning() ? (
                        <img
                          src={arbsOrBase === 'base' ? pepe_winning_base : pepe_winning}
                          className="w-[100px] transition ease-in duration-200"
                        />
                      ) : (
                        <img
                          src={arbsOrBase === 'base' ? pepe_losing_base : pepe_losing}
                          className="w-[100px] transition ease-in duration-200"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={
                    !autoSpinData.isAuto || isMobileDevice || isDesktopOrLarger
                      ? 'hidden'
                      : 'flex flex-1 justify-center items-start pb-8'
                  }
                >
                  <AutoSpinIndicator classes="relative" wider={true} />
                </div>
              </div>
            </div>
          </div>
          <Settings />
          <div className="chart-pepefooter">
            <PepeFooter />
          </div>
        </>
      ) : (
        <>
          <Navbar />
          <div className="absolute flex mt-[30px] justify-center z-[100] pointer-events-none">
            <div className="relative flex items-center">
              <img src={pepe_top_mobile} className="h-[170px] w-auto" alt="Logo" />
            </div>
          </div>
          {/* chart container */}

          <div
            className={
              isWinning()
                ? 'chartGlowMobile-win w-[93%] h-[344.58px] mt-[80px] rounded-[10px] flex items-center justify-center transition ease-in duration-200'
                : 'chartGlowMobile-lose w-[93%] h-[344.58px] mt-[80px] rounded-[10px] flex items-center justify-center transition ease-in duration-200'
            }
          >
            {showWinModal && (
              <div className="winlossImage">
                {!tradeStatus || !tradeStatus.is_active ? (
                  <div className="text-white">Loading...</div>
                ) : (
                  <img src={winlossSrc} className="" alt="win or loss image" />
                )}
              </div>
            )}
            <p className="flex flex-row items-center w-full text-[#83828C] text-left uppercase manrope text-xs absolute -top-[30px]">
              <div className="flex items-center w-5">
                {tradeVars.currency === 'ETH' ? (
                  <EthereumIcon selected={true} />
                ) : tradeVars.currency === 'BTC' ? (
                  <BitCoinIcon selected={true} />
                ) : (
                  <ARBIcon selected={true} />
                )}
              </div>
              {tradeVars.currency === 'ETH' ? (
                <a className="cursor-pointer" onClick={() => openPriceFeedSource('eth')}>{`ETH ${'\u00B7'} ${
                  tradeVars.time
                }s ${'\u00B7'} PYTH`}</a>
              ) : tradeVars.currency === 'BTC' ? (
                <a className="cursor-pointer" onClick={() => openPriceFeedSource('btc')}>{`BTC ${'\u00B7'} ${
                  tradeVars.time
                }s ${'\u00B7'} PYTH`}</a>
              ) : (
                <a className="cursor-pointer" onClick={() => openPriceFeedSource('arb')}>{`ARB ${'\u00B7'} ${
                  tradeVars.time
                }s ${'\u00B7'} PYTH`}</a>
              )}
            </p>

            {/* PEPE_WINNING FACE */}
            <div className="absolute z-20 right-4 top-[-50px]">
              <div className="relative">
                {isWinning() ? (
                  <img
                    src={arbsOrBase === 'base' ? pepe_winning_base : pepe_winning}
                    className="w-[90px] transition ease-in duration-200"
                  />
                ) : (
                  <img
                    src={arbsOrBase === 'base' ? pepe_losing_base : pepe_losing}
                    className="w-[90px] transition ease-in duration-200"
                  />
                )}
              </div>
            </div>

            <div className="absolute w-full h-[90%] z-20">{showChart && <Chart3 setCPrice={setCPrice} />}</div>

            <div
              className={
                isWinning()
                  ? 'w-full h-full bg-gradient-to-b from-[#0B8883] to-[#073634] rounded-[10px] relative transition ease-in duration-200'
                  : 'w-full h-full bg-gradient-to-b from-[#780730] to-[#240708] rounded-[10px] relative transition ease-in duration-200'
              }
            ></div>
          </div>
          <div className="mt-10 w-[93%]">{showChart && <ExtendTime timeLeft={timeLeft} />}</div>

          {/* info container */}
          <div className="w-[90%] h-[139.58px] rounded-[10px] mt-[31px] bg-[#09031D] bg-opacity-70">
            <div className="mt-[15px] w-full h-[38px] flex items-center justify-around">
              <div className="flex items-center h-full my-auto">
                <button>
                  {tradeVars && tradeVars?.longShort === 'Long' ? (
                    <img src={up_arrow} className="w-[47.53px] h-[23px]" alt="long trade" />
                  ) : (
                    <img src={down_arrow} className="w-[47.53px] h-[23px]" alt="short trade" />
                  )}
                </button>
              </div>

              <div className="text-white font-manrope font-semibold text-[25px] leading-[30px]">{timeLeft}s</div>

              <div className={`my-auto ${tradeVars.currency === 'BTC' ? 'w-8' : 'w-6'}`}>
                {tradeVars.currency === 'ETH' ? (
                  <EthereumIcon selected={true} />
                ) : tradeVars.currency === 'BTC' ? (
                  <BitCoinIcon selected={true} />
                ) : (
                  <ARBIcon selected={true} />
                )}
              </div>

              <div className="my-auto">
                <p className="text-left w-[48px]font-manrope text-[11px] leading-[13.2px] text-[#E0DDFF] font-normal">
                  Profit
                </p>
                <span className="relative z-50">
                  {isWinning() ? (
                    <p className="elative text-left w-[48px] font-semibold text-[17px] font-manrope text-[#18ADFA]">
                      +{Math.round(payout)}%
                    </p>
                  ) : (
                    <p className="elative text-left w-[48px] font-semibold text-[17px] font-manrope text-[#F82C5D]">
                      0%
                    </p>
                  )}
                </span>
              </div>
            </div>

            {/* divider */}
            <div className="mt-[14px] mb-[14px] w-full flex justify-center">
              <div className="bg-gradient-to-r from-[#48B9F3] to-[#8A40B8] h-[1px] w-[90%] opacity-30"></div>
            </div>

            <div className="my-[15px] w-full h-[38px] flex items-center justify-evenly">
              <div className="my-auto">
                <p className="text-left font-manrope text-[11px] leading-[13.2px] text-[#E0DDFF] font-normal">Bet</p>
                <div className="flex gap-[1px]">
                  {/* <img src={betCurrency === 'PEPE' ? tokenPEPE : tokenUSDC} /> */}
                  <span className="relative text-left font-semibold text-[17px] font-manrope text-white z-50">
                    ${tradeVars?.amount}
                  </span>
                </div>
              </div>

              <div className="my-auto">
                <p className="text-left font-manrope text-[11px] leading-[13.2px] text-[#E0DDFF] font-normal">
                  Entry Price
                </p>
                <span className="relative text-left font-semibold text-[17px] font-manrope text-white z-50">
                  {`$${formatter.format(Number(lambdaResponseData?.openPrice).toFixed(2))}`}
                </span>
              </div>

              <div className="hidden my-auto lg:block">
                <button className="w-[140px] h-[100px] -mt-[30px]">
                  <img src={extend_time} className="w-full h-full" alt="extend time" />
                </button>
              </div>
            </div>
          </div>

          {/* new autospin card for mobile container */}
          {autoSpinData?.isAuto && (
            <div
              className={`w-[90%] h-[154px] flex justify-end mt-[30px] ${isShortOrLong ? 'mb-[70px]' : 'mb-[30px]'}`}
            >
              <div className="autospin-chart-mobile">
                <span className="barlow font-medium text-[16px] leading-[19.2px] credits-styling">
                  {autoSpinData?.rounds - autoSpinData?.roundsLeft}/{autoSpinData?.rounds}
                </span>
                <div className="font-manrope text-[#D9D9D980] font-semibold text-[11px] leading-[15.03px] tracking-[0.04em]">
                  Credits
                  <p className="barlow font-medium text-[16px] leading-[19.2px] text-white">
                    ${formatter.format(Number(yourCredits).toFixed(2))}
                  </p>
                </div>

                <div className="autospin-actions">
                  <div className="relative">
                    {isWinning() ? (
                      <img
                        src={pepe_winning}
                        className="transition duration-200 ease-in autospin-win"
                        alt="winning auto spin pepe smiling"
                      />
                    ) : (
                      <img
                        src={pepe_losing}
                        className="transition duration-200 ease-in autospin-loss"
                        alt="losing auto spin pepe crying"
                      />
                    )}
                  </div>

                  <div className="flex flex-row gap-2">
                    {/* stop icon */}
                    <button className="[26.16px] h-[23px]" onClick={() => handleStopAutoSpin()}>
                      <img src={StopIcon} className="[26.16px] h-[23px] cursor-pointer" alt="stop autospin icon" />
                    </button>

                    {/* pause or play icons */}
                    {autoSpinData?.isPlay ? (
                      <button
                        className="[26.16px] h-[23px]"
                        onClick={() => setAutoSpinData({ ...autoSpinData, isPlay: !autoSpinData.isPlay })}
                      >
                        <img src={PauseIcon} className="[26.16px] h-[23px] cursor-pointer" alt="stop autospin icon" />
                      </button>
                    ) : (
                      <button
                        className="[26.16px] h-[23px]"
                        onClick={() => setAutoSpinData({ ...autoSpinData, isPlay: !autoSpinData.isPlay })}
                      >
                        <img src={PlayIcon} className="[26.16px] h-[23px] cursor-pointer" alt="stop autospin icon" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={!autoSpinData.isAuto || isDesktopOrLarger ? 'hidden' : 'w-full flex justify-end items-start'}>
            {/* <AutoSpinIndicator
              classes="relative top-4"
              show={!isDesktopOrLarger}
              wider={!isMobileDevice}
              showHeading={false}
            /> */}
          </div>
          <div className="relative flex justify-end w-full mt-12">
            <Settings />
          </div>
          <div className="chart-pepefooter w-[150px] absolute bottom-0 left-[-17px]">
            {!isMobileDevice && <PepeFooter />}
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default Chart;
