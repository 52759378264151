import React from 'react';
import { mainback_1, main, mainMobile } from '../assets';
import { useMediaQuery } from 'usehooks-ts';

export default function Wrapper(props) {
  const query = useMediaQuery('(max-width: 640px)');

  return (
    <div
      className="items-center h-full md:container md:mx-auto"
      style={{
        height: '100vh',
        background: query ? `url(${mainMobile}) no-repeat fixed` : `url(${main}) no-repeat fixed`,
        backgroundSize: 'cover',
        display: 'flex',
        maxWidth: '1550',
        minWidth: '1330',
        maxHeight: '980',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {props.children}
    </div>
  );
}
