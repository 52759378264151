import { Disclosure, Transition } from '@headlessui/react';
import React, { useContext, useState } from 'react';
import up from '../assets/images/dropDownArrow.svg';
import { useStorage } from '../utils/StorageContext';
import { StoreContext } from '../utils/store';
import axios from 'axios';
import { useAccount } from 'wagmi';
import { permission_API } from '../constants';

const Disclaimer = () => {
  const Store = useContext(StoreContext);
  const { setDisclaimerModal } = Store;
  const { address } = useAccount();
  const [consentChecked, setConsentChecked] = useState(false);

  const agreement = async () => {
    try {
      await axios.post(permission_API, {
        write: true,
        useraddress: address,
      });
    } catch (e) {
      console.log('DISCLOSURE ERROR: ', e.message);
    }
  };

  const handleConsent = () => {
    if (consentChecked) {
      agreement();
      setDisclaimerModal(false);
    }

    return;
  };

  return (
    <div className="w-full max-w-[600px] stake-modal mx-auto flex flex-col barlow">
      <p className='flex items-center justify-center pt-5 text-[33px] font-["Tourney"] font-extrabold gradient-text text-center uppercase mb-4'>
        Disclaimer
      </p>
      {/* <p className="text-sm text-white px-4 sm:px-8">
        The following disclaimer governs your use of the Pepe's Game website and its associated services (referred to as
        "the website" or "the service"). By accessing or using the website, you acknowledge and agree to the terms and
        conditions outlined in this disclaimer.
      </p> */}
      {/* <div className="self-center w-full sm:w-4/5">
        <Disclosure>
          {({ open }) => (
            <div className={`flex flex-col gap-[10px] sm:gap-0 `}>
              <Disclosure.Button
                className={`flex w-full sm:w-4/5 mx-auto ${
                  !open ? '' : 'hidden'
                }  px-4 py-2 text-left text-sm font-medium text-white justify-center gap-2`}
              >
                <span className={`${open ? 'hidden' : ''} text-center sm:text-left text-[13px] sm:text-base`}>
                  Expand Disclaimer
                </span>
                <img src={up} className={`self-center rotate-180 `} />
              </Disclosure.Button>
              <Transition
                enter="transition duration-500 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="p-4 text-sm text-white rounded-b-lg">
                  <ul className="px-2 ml-2 text-[13px] list-decimal sm:text-base sm:ml-0">
                    <li className="mb-3">
                      <p>
                        <span>
                          Pepe’s Game is a decentralized gamified options protocol (the “Protocol”). The interface
                          website accessible at:{' '}
                          <span
                            className="font-bold text-blue-500 cursor-pointer"
                            onClick={() => window.open('https://www.pepesgame.com/', '_blank')}
                          >
                            {' '}
                            https://www.pepesgame.com/
                          </span>{' '}
                          is an interface operated by Fractal Tech Corp. (“Us”, “We”), which gives You the ability to
                          interact with the Protocol (the “Interface”). The Pepe’s Game Protocol is made up of free,
                          public, open-source or source-available software including a set of smart contracts that are
                          deployed on the Arbitrum Blockchain and which may be deployed on other blockchains from
                          time-to-time. Your use of the Pepe’s Game Protocol and Interface involves various risks,
                          including, but not limited to, risks associated with and inherent to the trading of options,
                          losses while digital assets are being supplied to the Pepe’s Game Protocol, smart contract
                          vulnerabilities, user errors when engaging with the interface, interface vulnerabilities, bugs
                          and failures, and losses of your digital assets due to the fluctuation of prices of tokens.
                          Before engaging with the Pepe’s Game Protocol and Interface, you should review the relevant
                          documentation to make sure you understand how the Pepe’s Game Protocol works, including its
                          various intricacies, and understand and appreciate the risk of utilising a protocol like
                          Pepe’s Game. Additionally, just as you can access email email protocols such as SMTP through
                          multiple email clients, you may be able to access the Pepe’s Game Protocol through dozens of
                          web or mobile interfaces which may be provided by parties other than Us. You are responsible
                          for doing your own due diligence on those interfaces to understand the fees and risks they
                          present and understand that We have no ability to control interfaces created by parties other
                          than Us. You agree to the assumption of all risks associated with your use of the Pepe’s Game
                          protocol and interface.
                        </span>
                      </p>
                    </li>
                    <li className="mb-3">
                      <p>
                        <span>
                          AS DESCRIBED IN THE PEPE’S GAME PROTOCOL TERMS OF SERVICE AGREEMENT, THE PEPE’S GAME PROTOCOL
                          IS PROVIDED "AS IS", AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. We further make no
                          representations or warranties about the suitability of any information published on this
                          Website for any purpose, including any and all information presented on the Interface which
                          may be used for the purpose of the gamified options experience, and we hereby disclaim any
                          liability for any use of the information contained thereon the Interface by You for any
                          purpose whatsoever. The website may contain hyperlinks to other websites. Such third party
                          websites are managed by others which We do not have any control or influence over. We do not
                          accept responsibility and do not endorse any use of such websites or information therein.
                        </span>
                      </p>
                    </li>
                    <li className="mb-3">
                      <p>
                        <span>
                          Although Pepe’s Game corporate entity, Fractal Tech Corp. domiciled in Panama and d/b/a/
                          “Pepe’s Game Protocol” (“Pepe’s Game Protocol”) developed much of the initial code for the
                          Pepe’s Game Protocol, it does not provide, own, or control the Pepe’s Game Protocol, which is
                          run by smart contracts deployed on the Arbitrum blockchain and is facilitated and maintained
                          by the Pepe’s Game Protocol DAO (the “Organisation”) on a continuing basis. Upgrades and
                          modifications to the protocol are managed in a community-driven way by holders of the PEG
                          governance token. You agree that no developer, entity or Organisation involved in creating,
                          facilitating or managing the Pepe’s Game Protocol will be liable for any claims or damages
                          whatsoever associated with your use, inability to use, or your interaction with other users
                          of, the Pepe’s Game Protocol, including any direct, indirect, incidental, special, exemplary,
                          punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything
                          else of value and you waive any rights to all claims in any and all jurisdictions arising out
                          of any interaction with the Pepe’s Game Protocol or the associated DAO, Organisation or d/b/a
                          entity.
                        </span>
                      </p>
                    </li>
                    <li className="mb-3">
                      <p>
                        <span>
                          PEPE’S GAME RESERVES THE RIGHT TO MODIFY THIS DISCLAIMER AND WILL NOTIFY YOU BY REUPLOADING A
                          CURRENT VERSION OF THIS DISCLAIMER AT THE SAME LINK. ALL MODIFICATIONS WILL BE EFFECTIVE WHEN
                          THEY ARE POSTED, AND YOUR CONTINUED USE OF THE PEPE’S GAME PROTOCOL INTERFACE WILL SERVE AS
                          CONFIRMATION OF YOUR ACCEPTANCE OF THESE MODIFICATIONS. IF YOU DO NOT ACCEPT MODIFICATIONS TO
                          THIS DISCLAIMER AS MAY BE MADE FROM TIME-TO-TIME, YOU MUST IMMEDIATELY CEASE USING THE
                          INTERFACE.{' '}
                        </span>
                      </p>
                    </li>
                    <li className="mb-3">
                      <p>
                        <span>
                          YOU UNDERSTAND THE IMPLICATIONS AGREEING TO THIS DISCLAIMER MAY HAVE UPON YOUR PAST, PRESENT
                          AND FUTURE LEGAL RIGHTS, ACTIONS AND CLAIMS (IF ANY) AND ACKNOWLEDGE AND ACCEPT THE DISCLAIMER
                          NOTWITHSTANDING.{' '}
                        </span>
                      </p>
                    </li>
                  </ul>

                  <div className="flex justify-center gap-4 my-4 sm:gap-8">
                    <Disclosure.Button className="inline-block mx-auto">
                      <img src={up} className={`self-center`} />
                    </Disclosure.Button>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>
      </div> */}
      <div className="text-sm text-white font-bold text-[13px] px-4 sm:px-8 pb-4 ">
        By connecting your wallet to Pepe's Game, you hereby acknowledge that you have read, understood and agreed to
        the Pepe's Game{' '}
        <span
        className='cursor-pointer text-blue-500 hover:text-blue-400'
          onClick={() =>
            window.open(
              'https://docs.google.com/document/d/e/2PACX-1vTmZSQA6MgkmAyM9P7cJrraa_FLqWTKmBjPIkfycIub8kKFqP_rH6KaO8dRbBUYu5GkGyG063jSWfPt/pub',
              '_blank'
            )
          }
        >
          Terms and Conditions
        </span>
        ,{' '}
        <span
          className='cursor-pointer text-blue-500 hover:text-blue-400'
          onClick={() =>
            window.open(
              'https://docs.google.com/document/d/e/2PACX-1vSkFH0xvDpaWriy2PxPkcVpfVV08lvlFpVIpqey7NzmlE5iK8dFCBDJZjLPmsk2Fq6ktB13O_Kc9toy/pub',
              '_blank'
            )
          }
        >
          Privacy Policy
        </span>{' '}
        and 
        {' '}<span
        className='cursor-pointer text-blue-500 hover:text-blue-400'
          onClick={() =>
            window.open(
              'https://docs.google.com/document/d/e/2PACX-1vSsRy5PEbd_cay0rqcdthJld2oYAKwvIfWYfLFD3ROGnPQPQ5xGjUEfVaWsnhrlEU2RGeBiWqUd5bdf/pub',
              '_blank'
            )
          }
        >
         Disclaimer.
        </span>
      </div>
      <div>
        <div className="flex justify-center flex-col">
            <div >
              {!consentChecked ? (
                <div 
                className="flex items-center cursor-pointer px-8" onClick={() => setConsentChecked(true)}>
                  <input  
                    id="default-checkbox"
                    type="checkbox"
                    className="mr-3 w-5 h-5 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label for="default-checkbox" className="text-sm text-white/70 mt-4">
                  I have read, understand and agree to the Pepe’s Game Terms and Conditions, Privacy Policy and Disclaimer
                  </label>
                </div>
              ) : (
                <div className="flex items-center  px-8" onClick={() => setConsentChecked(false)}>
                  <input
                    checked
                    id="checked-checkbox"
                    type="checkbox"
                    className="mr-3 w-5 h-5 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label for="checked-checkbox" className="text-sm text-white/70 mt-4">
                  I have read, understand and agree to the Pepe’s Game Terms and Conditions, Privacy Policy and Disclaimer
                  </label>
                </div>
              )}
            </div>

          <button
            onClick={handleConsent}
            className=" w-[80%] bg-deep-blue/80 px-7 py-2 border-wallet-popup-copy-btn inter text-sm text-white rounded-md mt-4 mb-8 self-center mx-auto"
          >
            Agree and Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
