import React, { useContext } from 'react';
import { CreditsContext } from '../utils/creditsContext';
import { StoreContext } from '../utils/store';
import { useStorage } from '../utils/StorageContext';
import pepe_winning from '../assets/images/pepeMood-happy.png';
import pepe_losing from '../assets/images/pepeMood-sad.png';
import PauseIcon from '../assets/icons/pause.svg';
import PlayIcon from '../assets/icons/play.svg';
import StopIcon from '../assets/icons/stop.svg';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

/**
 *
 * @param classes - classes to position and set the offset.ie. top, right, etc from it's closest relative positioned ancestor. position must be either relative/absolute
 * @param show - sets whether to make it visible or not
 * @param wider - sets the container in landscape mode, looks nicer on tablet
 * @param showHeading - whether to show the AUTOSPIN heading or not
 */
const AutoSpinIndicator = ({ classes = 'absolute top-4 right-4', show = true, wider = false, showHeading = true }) => {
  const formatter = new Intl.NumberFormat('en-US');
  const { yourCredits } = useContext(CreditsContext);
  const { autoSpinData, isSpinning, isRunning, setAutoSpinData, isAutoWinning } = useStorage();
  const store = useContext(StoreContext);
  const { autoSpinVars, tradeVars } = store;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleStopAutoSpin = () => {
    const isStoppedAt = autoSpinData.roundsLeft - 1;
    setAutoSpinData({ ...autoSpinData, isStopAt: isStoppedAt, isStop: true });
  };

  const handleWatch = () => {
    console.log("isRunning indicator::", isRunning)
    if (autoSpinData?.isAuto && (tradeVars?.time > 0)) {
      if (autoSpinData?.isPlay === false && isRunning == false) {
        enqueueSnackbar('Autospin is Paused', {
          variant: 'warning',
          preventDuplicate: true,
        });
      } else {
        navigate('/chart', { state: { watch: true } });
      }
    }
  }

  return (
    <>
      {/* <div className={`z-[1000000] ${classes} ${!show ? 'opacity-0' : ''}`}> */}
      <div className={`z-[30] ${classes} ${!show ? 'opacity-0' : ''}`}>
        <div
          className={`hidden lg:block relative ${!showHeading ? 'p-2' : wider ? 'pt-5 pr-[10px] pb-[15px] pl-[10px]' : 'pt-5 pr-[10px] pb-[15px] pl-[10px]'
            } rounded-md bg-[#0A0420] border-2 border-solid border-[#18ADFA] w-[220px] global-autospin-container`}
        >
          <div
            className={
              !showHeading
                ? 'hidden'
                : `gradient-text font-["Tourney"] font-bold text-2xl ${wider ? 'mb-4' : 'mb-1'
                } text-center pointer-events-none`
            }
          >
            AUTOSPIN
          </div>

          {/* <div className={`flex items-center justify-between ${wider ? 'gap-16' : 'gap-2'}`}>
            <span className="font-semibold text-base gradient-text-b">ROUNDS</span>
            <span className="barlow font-medium text-white text-lg">
              {autoSpinData?.rounds - autoSpinData?.roundsLeft}/{autoSpinData?.rounds}
            </span>
          </div>

          <div className={`flex items-center justify-between ${wider ? 'gap-16' : 'gap-2'}`}>
            <span className="font-semibold text-base gradient-text-b">CREDITS</span>
            <span className="barlow font-medium text-white text-lg">
              ${formatter.format(Number(yourCredits).toFixed(2))}
            </span>
          </div> */}

          <div className="w-full flex justify-center pointer-events-none">
            <div className="w-[150px] h-[52px]">
              <div className="flex flex-row gap-4 items-end">
                <span className="font-manrope font-semibold text-xs leading-[16.39px] tracking-[0.04em] gradient-text-b">
                  ROUNDS
                </span>
                <span className="barlow font-medium text-white text-[19px] leading-[22.8px]">
                  {autoSpinData?.rounds - autoSpinData?.roundsLeft}/{autoSpinData?.rounds}
                </span>
              </div>
              <div className="flex flex-row gap-4 items-end mt-[5px]">
                <span className="font-manrope font-semibold text-xs leading-[16.39px] tracking-[0.04em] gradient-text-b">
                  CREDITS
                </span>
                <span className="barlow font-medium text-white text-[19px] leading-[22.8px]">
                  ${formatter.format(Number(yourCredits).toFixed(2))}
                </span>
              </div>
            </div>
          </div>

          <div className="autospin-indicator mt-6">
            <div className="relative">
              {isAutoWinning ? (
                <img
                  onClick={() => handleWatch()}
                  src={pepe_winning}
                  className="w-[45px] h-[45px] transition ease-in duration-200"
                  alt="winning auto spin pepe smiling"
                />
              ) : (
                <img
                  onClick={() => handleWatch()}
                  src={pepe_losing}
                  className="w-[45px] h-[45px] transition ease-in duration-200"
                  alt="losing auto spin pepe crying"
                />
              )}
            </div>
            <div className="w-[136.53px] h-11 gap-[5px] flex flex-col justify-center items-start z-[100000000]">
              <span className="font-manrope font-semibold text-xs leading-[16.39px] tracking-[0.04em] text-white">
                {
                  autoSpinData?.isAuto && isRunning
                    ? 'Running...'
                    : autoSpinData?.isAuto && isSpinning
                      ? 'Spinning...'
                      : autoSpinData?.isAuto && tradeVars?.time > 0
                        ? `${tradeVars.currency} - ${tradeVars.longShort} - ${tradeVars?.time}s`
                        : autoSpinData?.isPlay === false
                          ? 'Paused'
                          : 'Loading...'
                }
              </span>
              <div className="flex flex-row gap-3">
                <button
                  className="flex flex-row justify-center items-center p-[1px] bg-gradient-to-r from-[rgb(0,183,240)] to-[rgba(72,25,190)] bg-opacity-80 flex-none order-0 rounded-[6px]"
                  onClick={() => handleWatch()}
                >
                  {/* <span class="absolute inset-0 border-gradient"></span> */}
                  <span className="rounded-[inherit] h-[23px] px-[13px] font-inter font-normal bg-black text-white text-xs leading-[18px] flex items-center justify-center">
                    Watch
                  </span>
                </button>

                <div className="flex flex-row gap-2">
                  {/* stop icon */}
                  <button disabled={autoSpinData.isStop} className="[26.16px] h-[23px]" onClick={() => handleStopAutoSpin()}>
                    <img
                      src={StopIcon}
                      className="[26.16px] h-[23px] cursor-pointer"
                      alt="stop autospin icon"
                    />
                  </button>

                  {/* pause or play icons */}
                  {autoSpinData?.isPlay ? (
                    <button
                      className="[26.16px] h-[23px]"
                      onClick={() => setAutoSpinData({ ...autoSpinData, isPlay: !autoSpinData.isPlay })}
                    >
                      <img
                        src={PauseIcon}
                        className="[26.16px] h-[23px] cursor-pointer"
                        alt="stop autospin icon"
                      />
                    </button>
                  ) : (
                    <button
                      className="[26.16px] h-[23px]"
                      onClick={() => setAutoSpinData({ ...autoSpinData, isPlay: !autoSpinData.isPlay })}
                    >
                      <img
                        src={PlayIcon}
                        className="[26.16px] h-[23px] cursor-pointer"
                        alt="stop autospin icon"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoSpinIndicator;
