import '../styles/welcome-page.css';
import React, { useContext, useEffect } from 'react';
import { finalmobilelogo, pepe_top, pepe_top_base } from '../assets';
import connect from '../assets/images/B1 Button.svg';
import { useStorage } from '../utils/StorageContext';
import { StoreContext } from '../utils/store';
import { useAccount } from 'wagmi';
import Navbar from '../components/Navbar/Navbar';
import PepeDesktopFooter from '../components/PepeDesktopFooter';
import PepeMobileFooter from '../components/PepeMobileFooter';
import GradientBox from '../components/GradientBox/GradientBox';
import ConnectAndPlayBtn from '../components/Connect&PlayBtn';
import { useNetwork } from 'wagmi';

const Welcome = () => {
  // const query = useMediaQuery('(max-width: 640px)');
  // const navigate = useNavigate();
  // const [currentstep, setCurrentStep] = useState(1);
  // const [totalstep] = useState(3);

  // const renderStep = useCallback(() => {
  //   switch (currentstep) {
  //     case 1:
  //       return <Step_one />;
  //     case 2:
  //       return <Step_two />;
  //     case 3:
  //       return <Step_three />;
  //     default:
  //       <Step_one />;
  //   }
  // }, [currentstep]);

  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const Store = useContext(StoreContext);
  const { address } = useAccount();
  const { setConnectModal, setQuestChips, setQuestSpinDay, setDaysSpun } = Store;

  useEffect(() => {
    if (address == undefined) {
      setQuestChips(0);
      setQuestSpinDay(0);
      setDaysSpun(0);
    }
  }, [address]);

  return (
    <div className="bg-responsive">
      <Navbar />
      <img
        className="pointer-events-none sm:hidden block mx-auto w-full h-auto"
        src={finalmobilelogo}
        alt="Pepe Logo"
      />
      <div className="relative">
        <div className="hidden sm:flex items-center justify-center h-[12rem]">
          <img
            src={arbsOrBase === 'base' ? pepe_top_base : pepe_top}
            alt=""
            className="pointer-events-none select-none w-[25rem]"
          />
        </div>
        <div>
          <GradientBox classes="relative w-[87%] sm:w-1/2 sm:min-w-[37rem] max-w-[40rem] mx-auto">
            <div className="p-4 sm:py-8 sm:px-16 barlow">
              <span className="gradient-text text-center block w-full font-bold text-[1.5rem] sm:text-[3rem] mb-2">
                WELCOME, DEGEN
              </span>

              <div className="sm:hidden px-2 text-[17px]">
                <p className="text-[#E0DDFF] text-center mb-8 sm:mb-0">
                  Ready to experience that unique thrill of trading in one single click?
                </p>
              </div>
              <div className="hidden sm:block text-[#E0DDFF] sm:mb-4">
                <p className="mb-4">Ready to experience the thrill of trading?</p>
                <p className="mb-4">
                  Simply load up your credits, pick your wager, and let my spinners do the rest! Once my spinners reveal
                  the asset, direction, and duration of your trade, sit back and watch as my rocket blasts off to follow
                  live price action. Will it end up in your favor?{' '}
                </p>
                <p className="font-bold">One way to find out!</p>
              </div>

              <ConnectAndPlayBtn />
              {/* <button className="w-full max-w-[200px] block mx-auto sm:m-0" onClick={() => setConnectModal(true)}>
                <img src={connect} alt="connect and play" className="w-full h-auto pointer-events-none sm:ml-[-1.2rem]" />
              </button> */}
            </div>
          </GradientBox>
        </div>
      </div>
      <PepeMobileFooter classes="pt-12" />
      <PepeDesktopFooter />
    </div>
  );
};

export default Welcome;
