import React from 'react'

const Share=()=>{
    return (
        <>
        this is a share page
        </>
    )
}

export default Share;