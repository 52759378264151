import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { pepe_coin } from '../assets';
import pepe_blue from '../assets/images/pepe_blue.svg';
import { StoreContext } from '../utils/store';
import Settings from './Settings/Settings';
import { useMediaQuery } from 'usehooks-ts';
import { useStorage } from '../utils/StorageContext';
import { useNetwork } from 'wagmi';

const PepeDesktopFooter = observer(() => {
  const store = useContext(StoreContext);
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const isMobile = useMediaQuery('(max-width: 640px)');

  const getNetworkImg =useCallback((network) => {
    console.log({network})
    if (network === 'base') {
      return pepe_blue;
    }
    return pepe_coin;
  },[chain?.id]);

  return (
    <>
      {!isMobile ? (
        <div className="hidden sm:block">
          <div className="absolute -bottom-[150px] left-0 pepefooter z-10 pointer-events-none">
            <img src={getNetworkImg(arbsOrBase)} className="w-[250px] h-auto" alt="" />
          </div>
          <Settings />
        </div>
      ) : null}
    </>
  );
});

export default PepeDesktopFooter;
