import React from 'react';
import './PlayButton.css';
import { pepe_slanted } from '../../assets';

function PlayButton({ text, action, disabled }) {
  return (
    <>
      <button
        id="playbutton-main"
        className="purple-button flex justify-center items-center overflow-hidden"
        onClick={action}
        disabled={disabled}
      >
        <div className="flex flex-col tracking-[0.03em] relative top-[2px]">
          <span className="barlow leading-none font-bold spintext">{text}</span>
          <span className="w-full flex justify-end">
            <span className="nowFont">now</span>
          </span>
        </div>

        <img src={pepe_slanted} alt="pepe peeping in the button" className="absolute w-[42px] transition-all translate-y-[40px] peeping-pepe" />
      </button>
    </>
  );
}

export default PlayButton;
