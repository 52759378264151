import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { blueETH, blueToken } from '../../assets';
import up from '../../assets/images/dropDownArrow.svg';
import { StoreContext } from '../../utils/store';
import { ethers } from 'ethers';
import { useAccount, useNetwork, useSigner } from 'wagmi';
import { 
  arbitriumContracts,
  baseContracts,
  chain_ids,
  apr_tooltip_message } from '../../constants';
import { pepeEsPegLockABI } from '../../utils/pepeEsPegLockABI';
import { pepeLock } from '../../utils/pepeLock';
import { enqueueSnackbar } from 'notistack';
import { useMediaQuery } from 'usehooks-ts';
import Tooltip from '@mui/material/Tooltip';

const LockCard = ({ isPEG, lock, lockID, totalTokens, allowRefresh }) => {
  const [activeStackRow, setActiveStakeRow] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [currentDays, setCurrentDays] = useState(0);
  const Store = useContext(StoreContext);
  const {
    setLockModal,
    setPromptType,
    setIsEsPeg,
    userLockingState,
    setEsPegLockID,
    setClaimLockModal,
    setLockingReward,
    setClaimConfirmModal,
    setEsPegUnlockHash,
    setRefreshLocking
  } = Store;
  const { data: signer } = useSigner();
  const {chain} = useNetwork()
  const { address: userAddress } = useAccount();
  const [hash, setHash] = useState('');
  const [isComplete, setIsComplete] = useState(false);

  const isMobile = useMediaQuery('(max-width: 640px)');

  const {
    pepeLockingContractAddress,
    pepeEsPegLockUpContractAddress,
  } = chain?.id == chain_ids[0]? arbitriumContracts : baseContracts //// @dev chain Id index 0 is arbitrium

  const pepeEsPegLockingContract = useMemo(
    () => new ethers.Contract(pepeEsPegLockUpContractAddress, pepeEsPegLockABI, signer),
    [signer]
  );

  const pepeLockingContract = useMemo(
    () => new ethers.Contract(pepeLockingContractAddress, pepeLock, signer),
    [signer]
  );

  const initiatelockData = useCallback(async () => {
    setNumberOfDays(
      dateConversion(
        lock.lockedAt ? lock.lockedAt : userLockingState?.lockedAt,
        lock.lockedAt ? lock.unlockTimestamp : userLockingState?.unlockTimestamp
      )
    );
    setCurrentDays(getDaysFromStart(lock.lockedAt ? lock.lockedAt : userLockingState?.lockedAt));
  }, [lock]);

  useEffect(() => {
    if (lock) {
      initiatelockData();
    }
  }, [lock, initiatelockData]);

  const dateConversion = (start, endDate) => {
    const timestampMs = endDate * 1000;
    const startDate = new Date(start * 1000);
    const timeDifferenceMs = timestampMs - startDate;
    const days = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
    return days;
  };

  const getDaysFromStart = (startTimestamp) => {
    const startTimestampMs = startTimestamp * 1000;
    const currentTimestampMs = Date.now();
    const timeDifferenceMs = currentTimestampMs - startTimestampMs;
    const days = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
    return days;
  };

  const ethersCalculate = (data, places) => {
    try {
      return parseFloat(ethers.utils.formatUnits(data, 18)).toFixed(places);
    } catch (error) {
      return '10.000';
    }
  };

  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const handleClaimPegReward = async () => {
    try {
      const lockingReward = await pepeLockingContract.pendingUsdcRewards(userAddress);
      const isZero = lockingReward.isZero();
      if (isZero) {
        enqueueSnackbar('You have no claimable rewards', {
          variant: 'error',
        });
        return;
      }
      setLockingReward(lock.lockedAt ? lock?.pendingRewards : userLockingState?.pendingRewards);
      setIsEsPeg(false);
      setClaimLockModal(true);
      return;
    } catch (error) {
      console.error({ pegLockRewardError: error });
      enqueueSnackbar('Claiming Locked Rewards Failed.', {
        variant: 'error',
      });
      return;
    }
  };

  const handleClaimEsPegReward = async () => {
    try {
      const lockingReward = await pepeEsPegLockingContract.pendingUsdcRewards(userAddress, lockID);
      const isZero = lockingReward.isZero();
      if (isZero) {
        enqueueSnackbar('You have no claimable rewards', {
          variant: 'error',
        });
        return;
      }
      setEsPegLockID(lockID);
      setLockingReward(lock.lockedAt ? lock?.pendingRewards : userLockingState?.pendingRewards);
      setIsEsPeg(true);
      setClaimLockModal(true);
      return;
    } catch (error) {
      console.error({ pegEsLockRewardError: error });
      enqueueSnackbar('Claiming Locked Rewards Failed.', {
        variant: 'error',
      });
      return;
    }
  };

  const handleUnlockEsPeg = async () => {
    let estimateGas;
    try {
      estimateGas = await pepeEsPegLockingContract.estimateGas.unlock(lockID);
      estimateGas = estimateGas.toNumber() + 1e4;
    } catch (error) {
      estimateGas = 3e6;
    }
    try {
      const tx = await pepeEsPegLockingContract.unlock(lockID, { gasLimit: estimateGas });
      const receipt = await tx.wait();
      enqueueSnackbar(`EsPeg unlock successful. Tx hash: ${receipt.transactionHash}`, {
        variant: 'success',
      });
      allowRefresh();
    } catch (error) {
      enqueueSnackbar('Unlocking EsPeg failed.', {
        variant: 'error',
      });
      return;
    }
  }

  return (
    <>
      {userLockingState.lockedAt || lock.lockedAt ? (
        isMobile ? (
          <>
            <div className={`${activeStackRow && 'border-border-blue border-[0.5px] rounded-md'} text-center`}>
              <tr
                className={`bg-[#09031D3D] cursor-pointer flex`}
                style={{ borderBottom: 'none' }}
                onClick={() => setActiveStakeRow(!activeStackRow)}
              >
                <td className="py-5 flex-1 text-white/40 barlow font-normal text-base leading-4">
                  {isPEG ? (
                    <>{ethersCalculate(lock.lockedAt ? lock?.pegLocked : userLockingState?.pegLocked, 2) || '0'}</>
                  ) : (
                    <>{ethersCalculate(lock.lockedAt ? lock?.esPegLocked : userLockingState?.esPegLocked, 2) || '0'}</>
                  )}
                </td>
                <td className="py-5 flex-1 text-white/40 barlow font-normal text-base leading-4">
                  {convertTimestampToDate(lock.lockedAt ? lock?.unlockTimestamp : userLockingState?.unlockTimestamp) ||
                    'n/a'}
                </td>
                <td className="py-5 flex-1 text-white/40 barlow font-normal text-base leading-4">
                  {(lock.lockedAt ? lock?.pendingRewards : userLockingState?.pendingRewards) || '0'}
                </td>
              </tr>
              {/* active state */}
              {activeStackRow && (
                <tr className=" bg-[#FFFFFF0D]">
                  <td colSpan={7} className="w-[999px] sm:w-auto">
                    <div className="pt-2.5 w-full">
                      <div className="mx-[3.75%]">
                        <table className=" w-full justify-between bg-transparent p-2">
                          <tbody className="text-white/40 text-sm leading-[18px]">
                            <tr>
                              <th className="pb-5 font-normal text-sm barlow text-left">
                                {isPEG ? 'PEG' : 'esPEG'} Locked
                              </th>
                              <th className="pb-5 font-normal text-sm barlow text-right">
                                {isPEG
                                  ? ethersCalculate(lock.lockedAt ? lock?.pegLocked : userLockingState?.pegLocked, 2)
                                  : ethersCalculate(
                                      lock.lockedAt ? lock?.esPegLocked : userLockingState?.esPegLocked,
                                      2
                                    )}
                              </th>
                            </tr>
                            <tr>
                              <th className="pb-5 font-normal text-sm barlow text-left">wETH Locked</th>
                              <th className="pb-5 font-normal text-sm barlow text-right whitespace-nowrap">
                                {ethersCalculate(lock.lockedAt ? lock?.wethLocked : userLockingState?.wethLocked, 4)}
                              </th>
                            </tr>
                            <tr>
                              <th className="pb-5 font-normal text-sm barlow text-left">Date Locked</th>
                              <th className="pb-5 font-normal text-sm barlow text-right whitespace-nowrap">
                                {convertTimestampToDate(lock.lockedAt ? lock?.lockedAt : userLockingState?.lockedAt)}
                              </th>
                            </tr>
                            <tr>
                              <th className="pb-5 font-normal text-sm barlow text-left">Unlock Date</th>
                              <th className="pb-5 font-normal text-sm barlow text-right">
                                {convertTimestampToDate(
                                  lock.lockedAt ? lock?.unlockTimestamp : userLockingState?.unlockTimestamp
                                )}
                              </th>
                            </tr>
                            {isPEG ? (
                              <tr>
                                <th className="pb-5 font-normal text-sm barlow text-left">Last Added</th>
                                <th className="pb-5 font-normal text-sm barlow text-right">
                                  {convertTimestampToDate(lock.lockedAt)}
                                </th>
                              </tr>
                            ) : (
                              ''
                            )}
                            {/* <tr>
                              <th className="pb-5 font-normal text-sm barlow text-left">Last Added</th>
                              <th className="pb-5 font-normal text-sm barlow text-right">
                                {
                                  isPEG ? (
                                    convertTimestampToDate(lock.lastLockedAt ? lock?.lastLockedAt : userLockingState?.lastLockedAt)
                                  ):(
                                    convertTimestampToDate(lock.lockedAt)
                                  )
                                }
                                
                              </th>
                            </tr> */}
                            {/* <tr>
                              <th className="pb-5 font-normal text-sm barlow text-left">PEG Claimed</th>
                              <th className="pb-5 font-normal text-sm barlow text-right">n/a</th>
                            </tr> */}
                            <tr>
                              <th className="text-white  font-normal text-sm barlow text-left">Claimable USDC</th>
                              <th className="text-white font-normal text-sm barlow text-right">
                                {lock.lockedAt ? lock?.pendingRewards : userLockingState?.pendingRewards}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div
                        className="flex flex-nowrap justify-evenly w-full mx-auto mt-5 mb-[12px] pt-[12px] px-[7px] text-sm text-white barlow gap-[12px] "
                        style={{ borderTop: 'solid 0.5px #18ADFA' }}
                      >
                        <button
                          onClick={() =>
                            isPEG
                              ? handleClaimPegReward()
                              : currentDays < numberOfDays
                              ? handleClaimEsPegReward()
                              : handleUnlockEsPeg()
                          }
                          className="bg-[#FFFFFF1A] py-[5px] w-[90px] text-white rounded-md"
                        >
                          
                          {isPEG
                            ? 'Claim'
                            : currentDays < numberOfDays
                            ? 'Claim'
                            : 'Unlock'}
                        </button>
                        {isPEG && (
                          <button
                            onClick={() => {
                              setLockModal(true);
                              setPromptType(true);
                            }}
                            className="bg-[#FFFFFF1A] py-[5px] w-[90px] text-white rounded-md"
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </div>
          </>
        ) : (
          <tbody className="text-center text-[#FFFFFF66]">
            <>
              {isPEG ? (
                <tr
                  className="bg-[#09031D3D] border-b-2 border-transparent cursor-pointer"
                  onClick={() => setActiveStakeRow(!activeStackRow)}
                >
                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4 align-middle">
                    <img src={blueToken} className="w-4 h-4 mr-1 inline-block" />
                    <span>{isPEG ? 'PEG-wETH' : 'esPEG-wETH'}</span>
                  </td>
                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4">
                    {convertTimestampToDate(lock.lockedAt ? lock?.lockedAt : userLockingState?.lockedAt)}
                  </td>
                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4">
                    {convertTimestampToDate(lock.lockedAt ? lock?.unlockTimestamp : userLockingState?.unlockTimestamp)}
                  </td>
                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4">
                    <Tooltip title={apr_tooltip_message} placement="bottom" arrow>
                      <span>TBD</span>
                    </Tooltip>
                  </td>
                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4">
                    {ethersCalculate(lock.lockedAt ? lock?.pegLocked : userLockingState?.pegLocked, 2)}
                  </td>
                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4">
                    ${lock.lockedAt ? lock?.pendingRewards : userLockingState?.pendingRewards}
                  </td>
                  <td className="py-5 pr-2 text-white barlow font-normal text-base leading-4">
                    <img src={up} className={`${activeStackRow ? 'rotate-0' : 'rotate-180'} transition-all`} />
                  </td>
                </tr>
              ) : (
                <tr
                  className="bg-[#09031D3D] border-b-2 border-transparent cursor-pointer"
                  onClick={() => setActiveStakeRow(!activeStackRow)}
                >
                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4 align-middle">
                    <img src={blueToken} className="w-4 h-4 mr-1 inline-block" />
                    <span>{isPEG ? 'PEG-wETH' : 'esPEG-wETH'}</span>
                  </td>
                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4">
                    <span>{parseFloat(ethersCalculate(totalTokens?.totalEsPegLocked, 2)).toLocaleString()}</span>
                  </td>
                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4">
                    <span> {parseFloat(ethersCalculate(totalTokens?.totalSupply, 2)).toLocaleString()}</span>
                  </td>
                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4">
                    {numberOfDays - currentDays < 0 ? '0' : numberOfDays - currentDays}
                  </td>

                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4">
                    {ethersCalculate(lock?.esPegLocked, 2)}
                    {/* ${ethersCalculate(lock?.esPegLocked, 2)} */}
                  </td>
                  <td className="py-5 text-white/40 barlow font-normal text-base leading-4">{lock?.pendingRewards}</td>
                  <td className="py-5 pr-2 text-white barlow font-normal text-base leading-4">
                    <img src={up} className={`${activeStackRow ? 'rotate-0' : 'rotate-180'} transition-all`} />
                  </td>
                </tr>
              )}
              {/* active state */}
              {activeStackRow && (
                <tr className="bg-[#09031D3D]">
                  <td colSpan={7}>
                    <div className="pt-2.5 w-full">
                      <div className="flex flex-col justify-around mx-[3.75%] border-border-blue bg-white/[0.05] border-[0.5px] h-[312px] rounded-md p-5">
                        <div className="flex justify-between">
                          <p>{isPEG ? 'PEG' : 'esPEG'} Locked</p>
                          <p>
                            {isPEG
                              ? ethersCalculate(lock.lockedAt ? lock?.pegLocked : userLockingState?.pegLocked, 2)
                              : ethersCalculate(lock.lockedAt ? lock?.esPegLocked : userLockingState?.esPegLocked, 2)}
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <p>wETH Locked</p>
                          <p>{ethersCalculate(lock.lockedAt ? lock?.wethLocked : userLockingState?.wethLocked, 2)}</p>
                        </div>
                        <div className="flex justify-between">
                          <p>Date Locked</p>
                          <p>{convertTimestampToDate(lock.lockedAt ? lock?.lockedAt : userLockingState?.lockedAt)}</p>
                        </div>
                        <div className="flex justify-between">
                          <p>Unlock Date</p>
                          <p>
                            {convertTimestampToDate(
                              lock.lockedAt ? lock?.unlockTimestamp : userLockingState?.unlockTimestamp
                            )}
                          </p>
                        </div>
                        {isPEG && (
                          <div className="flex justify-between">
                            <p>Last Added</p>
                            <p>
                              {convertTimestampToDate(
                                lock.lastLockedAt ? lock?.lastLockedAt : userLockingState?.lastLockedAt
                              )}
                            </p>
                          </div>
                        )}
                        {/* <div className="flex justify-between">
                          <p>USDC Rewards Claimed</p>
                          <p>n/a</p>
                        </div> */}
                        <div className="flex justify-between text-white">
                          <p>Claimable USDC Rewards</p>
                          <p>{lock.lockedAt ? lock?.pendingRewards : userLockingState?.pendingRewards}</p>
                        </div>
                      </div>

                      <div className="space-x-[5px] float-right mr-8 mx-auto mt-5 pb-[30px] flex">
                        {isPEG ? (
                          <button
                            onClick={() => handleClaimPegReward()}
                            className="flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:py-[3px] sm:px-[50px] flex items-center justify-center bg-white/[0.05] text-white"
                          >
                            Claim Rewards
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              currentDays < numberOfDays ? handleClaimEsPegReward() : handleUnlockEsPeg()
                            }
                            // disabled={currentDays < numberOfDays}
                            // className={`flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:py-[3px] sm:px-[50px] flex items-center justify-center bg-white/[0.05] text-white ${
                            //   currentDays < numberOfDays ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                            // }`}
                            className='flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:py-[3px] sm:px-[50px] flex items-center justify-center bg-white/[0.05] text-white opacity-100'
                          >
                            {currentDays >= numberOfDays ? 'Unlock EsPEG' : 'Claim Rewards'}
                          </button>
                        )}
                        {isPEG ? (
                          <button
                            className="flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:py-[3px] sm:px-[20px] sm:w-[250px] sm:h-[50px] flex items-center justify-center bg-white/[0.05] text-white"
                            onClick={() => {
                              setLockModal(true);
                              setPromptType(true);
                            }}
                          >
                            {/* lock.lockedAt < 1 ? 'Lock' : 'Add to position' */}
                            Add to Position
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </>
          </tbody>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default LockCard;
