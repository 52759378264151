import React from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { pepe_coin } from '../../assets';
import { hand, body } from '../../assets';
import { useStorage } from '../../utils/StorageContext';
import pepe_blue from '../../assets/images/pepe_blue.svg';
import { useNetwork } from 'wagmi';

const PepeFooter = (props) => {
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';

  const query = useMediaQuery('(max-width: 640px)');
  let display = props.display;

  return (
    <div
      style={{
        display: query ? display : 'block',
      }}
      className="absolute bottom-0 left-0 pepefooter z-10 pointer-events-none"
    >
      <img src={arbsOrBase === 'base' ? pepe_blue : pepe_coin} className="w-[250px] h-auto" alt="Pepe" />
    </div>
  );
};

export default PepeFooter;
