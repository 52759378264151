import React, { useState, createContext, useContext, useEffect } from 'react';
// socket IO imports
import { io } from 'socket.io-client';
// import WebSocket from 'ws';
import useWebSocket from "react-use-websocket"
import SocketConfig from './configs/socketConfig';

const SocketContext = createContext();

export const SocketContextProvider = ({ children }) => {
  // variable declarations
  const [startingPrice, setStartingPrice] = useState(0);
  const [closingPrice, setClosingPrice] = useState(0);
  
  const socket = new WebSocket(process.env.REACT_APP_SOCKET_FEED);


  useEffect(()=>{

    // console.log(readyState)
    // console.log(getWebSocket)
    socket.onopen = () => {
      socket.send(
        JSON.stringify({
          "type": "subscribe",
          "product_ids": [
              "BTC-USD"
          ],
          "channels": ["ticker"]
        })
      )
    }

    socket.onerror = (error) =>{
      console.log(error)
    }

    
  },[socket])
  
 

  return (
    <SocketContext.Provider
      value={{
        startingPrice,
        setStartingPrice,
        closingPrice,
        setClosingPrice,
        socket,
        //socket1
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => useContext(SocketContext);
