import React from 'react';

export const UpArrow = ({ selected = false }) => {
  return (
    <svg height="100%" width="auto" viewBox="0 0 133 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={selected ? '1' : '0.4'} filter={selected ? 'url(#filter0_d_4824_99758)' : 'url(#notSelectedFilter)'}>
        <g opacity="0.6">
          <path
            d="M106.865 32.9295L106.552 29.9282L104.319 23.9146L108.5 18.359L124.02 14.2355L125.302 13.2813L126.816 5.2661C127.147 3.51508 125.612 1.97984 123.861 2.3108L89.2858 8.84533C87.2888 9.22257 86.5297 11.682 87.9671 13.1194L95.3126 20.4649C96.5334 21.6857 96.5334 23.6652 95.3126 24.886L75.8797 44.3189C74.6589 45.5397 72.6794 45.5397 71.4588 44.3189L49.5226 22.3827C44.8689 17.728 37.2945 17.7275 32.6391 22.3827L6.83167 48.1901C5.49101 49.5308 5.49101 51.7043 6.83167 53.045L10.9055 57.1189C12.2462 58.4595 14.4197 58.4595 15.7604 57.1189L38.8702 34.0088C40.091 32.788 42.0705 32.788 43.2911 34.0088L65.2277 55.945C69.8812 60.5992 77.4554 60.6002 82.1113 55.945L104.241 33.8147C104.957 33.0987 105.933 32.806 106.865 32.9295Z"
            fill={selected ? 'url(#paint0_linear_4824_99758)' : '#09031D'}
          />
          <path
            d="M109.231 17.2748C104.53 18.443 101.814 23.4779 103.594 27.9833C104.192 29.4949 105.227 31.1514 106.865 32.9295C107.522 33.0167 108.158 33.3094 108.663 33.8145L116.008 41.16C117.446 42.5971 119.905 41.838 120.282 39.841L125.302 13.2811L109.231 17.2748Z"
            fill={selected ? 'url(#paint1_linear_4824_99758)' : '#09031D'}
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M63.9367 57.2365C69.3035 62.6041 78.0342 62.605 83.4033 57.2367L92.3563 48.2837C93.0696 47.5705 93.0696 46.414 92.3563 45.7007C91.643 44.9874 90.4865 44.9874 89.7732 45.7007L80.8204 54.6534C76.8778 58.5953 70.4601 58.5943 66.52 54.6536L44.5832 32.7173C42.6492 30.7833 39.5132 30.7831 37.5792 32.7173L14.4692 55.8273C13.8418 56.4547 12.8248 56.4547 12.1974 55.8273L8.12355 51.7535C7.49619 51.1261 7.49619 50.1091 8.12355 49.4817L33.9309 23.6743C37.8731 19.7324 44.291 19.733 48.2313 23.6742L70.1679 45.6105C72.102 47.5446 75.2377 47.5446 77.1718 45.6105L96.6047 26.1776C98.5389 24.2434 98.5389 21.1075 96.6047 19.1734L89.2592 11.8279C88.8598 11.4285 89.0708 10.745 89.6257 10.6401L124.201 4.10557C124.687 4.01365 125.114 4.44009 125.022 4.92714L118.488 39.5021C118.383 40.0567 117.7 40.2683 117.3 39.8687L109.954 32.5232C108.02 30.5891 104.884 30.5891 102.95 32.5232L95.7999 39.6734C95.0866 40.3867 95.0866 41.5432 95.7999 42.2565C96.5132 42.9698 97.6697 42.9698 98.383 42.2565L105.533 35.1063C106.041 34.5988 106.864 34.5988 107.371 35.1063L114.717 42.4518C117.192 44.9262 121.427 43.6202 122.077 40.1805L128.612 5.60532C129.181 2.59034 126.538 -0.0539284 123.522 0.516083L88.9472 7.05062C85.5081 7.70069 84.2013 11.9361 86.6761 14.4109L94.0216 21.7564C94.5292 22.264 94.5292 23.087 94.0216 23.5945L74.5888 43.0274C74.0812 43.5349 73.2585 43.5349 72.751 43.0274L50.8147 21.0913C45.4476 15.7233 36.7165 15.7228 31.3479 21.0912L5.54047 46.8986C3.48651 48.9526 3.48651 52.2826 5.54047 54.3366L9.61432 58.4104C11.6683 60.4644 14.9983 60.4644 17.0523 58.4104L40.1623 35.3004C40.6695 34.7931 41.4925 34.7928 42.0001 35.3004L63.9367 57.2365Z"
          fill={selected ? 'url(#paint2_linear_4824_99758)' : '#09031D'}
        />
      </g>
      <defs>
        <filter
          id="notSelectedFilter"
          x="0"
          y="0.262573"
          width="132.689"
          height="68.8245"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4824_99764" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4824_99764" result="shape" />
        </filter>

        <filter
          id="filter0_d_4824_99758"
          x="0"
          y="0.43811"
          width="132.689"
          height="68.8245"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4824_99758" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4824_99758" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_4824_99758"
          x1="17.1741"
          y1="51.6782"
          x2="139.265"
          y2="39.9977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6F37FC" />
          <stop offset="1" stop-color="#00B7F0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4824_99758"
          x1="17.1741"
          y1="51.6782"
          x2="139.265"
          y2="39.9977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6F37FC" />
          <stop offset="1" stop-color="#00B7F0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4824_99758"
          x1="15.6905"
          y1="53.009"
          x2="141.539"
          y2="41.3506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6F37FC" />
          <stop offset="1" stop-color="#00B7F0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
