import { combineReducers } from '@reduxjs/toolkit';

//Practice
import dataChartPracticeReducer from '../features/chartDataPractice/chartDataPracticeSlice';

const combinedReducer = combineReducers({
  dataChartPractice: dataChartPracticeReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'logOut') {
    state = {};
  }
  return combinedReducer(state, action);
};
export default rootReducer;
