import React, { useContext, useEffect, useState } from 'react';
import './claim.css';
import { bolt, crown, pepePls, pepe_top, pepe_top_base } from '../../assets';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../../utils/store';
import { useStorage } from '../../utils/StorageContext';
import { ethers, BigNumber } from 'ethers';
import {
  unvestPlsAllRoundsWithNames,
  round2Address,
  unvestPlsAddresses,
  unvestPlsWithNames,
  unvestAddresses,
  unvestAddressesWithNames,
  pegOuro,
  unvestAllRoundsWithNames,
} from '../../constants';
import { unvestABI } from '../../utils/unvestABI';
import { useAccount, useNetwork, useSigner, useProvider, erc20ABI } from 'wagmi';
import { cancel_icon_w_gradient } from '../../assets';
import UPMobile from '../UPMobile';

const ClaimAirdrop = ({ isPLS }) => {
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const Store = useContext(StoreContext);
  const { setStoreState, reloadData } = Store;

  const { address } = useAccount();
  const { data: signer } = useSigner();
  // const { enqueueSnackbar } = useSnackbar();
  const provider = useProvider();

  const addresses = isPLS ? unvestPlsAddresses : unvestAddresses; //switch addesses when isPLS parameter changes
  const addressesWithNames = isPLS ? unvestPlsWithNames : unvestAddressesWithNames; //switch addresses when isPLS changes
  const addressesAllRounds = isPLS ? unvestPlsAllRoundsWithNames : unvestAllRoundsWithNames;

  const [claimableAmount, setClaimableAmount] = useState(BigNumber.from(0));
  const [numberOfApproval, setNumberOfApproval] = useState(0);
  const [allClaimableData, setAllClaimabaleData] = useState([]);
  const [balanceAmount, setBalanceAmount] = useState(BigNumber.from(0));
  const [claimedBalance, setClaimedBalanceAmount] = useState(BigNumber.from(0));
  const [claimableNewRound, setNewRoundClaimableAmount] = useState(BigNumber.from(0));
  const [claimableNewRoundApproval, setNewRoundNumberOfApproval] = useState(BigNumber.from(0));
  const [allMilestones, setAllMileStones] = useState([]);
  const [totalVestedData, setTotalVestedData] = useState([]);
  const [alreadyClaimedData, setAlreadyClaimedData] = useState([]);
  const [reload, setReload] = useState(false);
  const [firstRoundCalimed, setFirstRoundClaimed] = useState(BigNumber.from(0));
  const [showOuro, setShowOuro] = useState(false);

  //function for getting claim airdrop data
  const claimableRound_2 = () => {
    //initiate contract
    //loop through all contracts and get user claimables and
    const all_calls = round2Address.map((contract_address) => {
      return new Promise((resolve, reject) => {
        //initiate contract and make call to claimable balance
        let contract = new ethers.Contract(contract_address, unvestABI, provider);
        contract
          .claimableBalanceOf(address)
          .then((response) => {
            // console.log("Round 2 claimable balance:", response)
            resolve({ response, contract_address });
          })
          .catch((error) => {
            reject(error);
          });
      });
    });

    Promise.all(all_calls).then((response) => {
      console.log('All Data:', response);
      //setAllClaimabaleData(response)
      let all_claimable_amount = BigNumber.from(0);
      let non_zero_count = 0;
      //sum all amounts
      for (let i = 0; i < response.length; i++) {
        if (!response[i].response.isZero()) {
          non_zero_count++;
          all_claimable_amount = all_claimable_amount.add(response[i].response);
        }
      }
      // console.log("ccc:", ethers.utils.formatUnits(all_claimable_amount), non_zero_count)
      setNewRoundClaimableAmount(all_claimable_amount);
      setNewRoundNumberOfApproval(non_zero_count);
      // setClaimableAmount(all_claimable_amount);
    });
  };

  //function for getting claim airdrop data
  const claimable = () => {
    //initiate contract
    //loop through all contracts and get user claimables and
    const all_calls = addresses.map((contract_address) => {
      return new Promise((resolve, reject) => {
        //initiate contract and make call to claimable balance
        let contract = new ethers.Contract(contract_address, unvestABI, provider);
        contract
          .claimableBalanceOf(address)
          .then((response) => {
            // console.log("claimable balance:", response)
            resolve({ response, contract_address });
          })
          .catch((error) => {
            reject(error);
          });
      });
    });

    Promise.all(all_calls).then((response) => {
      //console.log("All claimable Data :", response)
      setAllClaimabaleData(response);
      let all_claimable_amount = BigNumber.from(0);
      let non_zero_count = 0;
      //sum all amounts and display as user's claimable
      for (let i = 0; i < response.length; i++) {
        if (!response[i].response.isZero()) {
          non_zero_count++;
          all_claimable_amount = all_claimable_amount.add(response[i].response);
        }
      }
      //setStoreState("claimableAmount",all_claimable_amount)

      setClaimableAmount(all_claimable_amount);
      setNumberOfApproval(non_zero_count);
      // setClaimableAmount(all_claimable_amount);
    });
  };

  //function for getting balance/total vested amount
  const getTotalBalance = async () => {
    const aa = await fetchDataFromContracts();
    setTotalVestedData(aa.all_data);
    setBalanceAmount(aa.all_sum);
  };

  const getFirstRoundClaimed = async () => {
    const alreadyClaimedFirstRound = await fetchClaimedBalances();
    setFirstRoundClaimed(alreadyClaimedFirstRound);
  };

  async function fetchDataFromContracts() {
    let all_sum = BigNumber.from(0);
    let all_data = [];
    for (const round of unvestAllRoundsWithNames) {
      let group_sum = BigNumber.from(0);
      for (const contractAddress of round.address) {
        try {
          const contract = new ethers.Contract(contractAddress, unvestABI, provider);

          // Call contract functions or retrieve data
          const result = await contract.balanceOf(address);

          if (!result.isZero()) {
            if (round.name === 'PEG-PUB-100') {
              let d = ethers.utils.parseUnits('1500', 18);
              if (typeof group_sum === 'object') {
                group_sum = group_sum.add(d);
              } else {
                group_sum = d;
              }
            } else {
              if (typeof group_sum === 'object') {
                group_sum = group_sum.add(result);
              } else {
                group_sum = result;
              }
            }
          }

          //console.log(`Data from contract at address ${round.name}:`, group_sum);
        } catch (error) {
          console.error(`Error fetching data from contract at address ${contractAddress}:`, error);
        }
      }

      all_data.push({ group_sum, vest: round.name });

      if (typeof all_sum === 'object') {
        all_sum = all_sum.add(group_sum);
      } else {
        all_sum = group_sum;
      }
    }

    return { all_sum, all_data };
  }

  //get only first vesting contracts already claimed
  async function fetchClaimedBalances() {
    let sum = BigNumber.from(0);
    let all_data = [];

    for (const contractAddress of unvestAddressesWithNames) {
      try {
        const contract = new ethers.Contract(contractAddress.address, unvestABI, provider);

        // Call contract functions or retrieve data
        const result = await contract.claimedBalanceOf(address);

        if (!result.isZero()) {
          if (typeof sum === 'object') {
            sum = sum.add(result);
          } else {
            sum = result;
          }
        }

        //console.log(`Data from contract at address ${contractAddress.name}:`, sum);
      } catch (error) {
        console.error(`Error fetching data from contract at address ${contractAddress}:`, error);
      }
    }

    return sum;
  }

  //function for getting balance/total vested amount
  const getAlreadyClaimed = () => {
    //add the round2 contract addresses
    const all_address = isPLS ? addresses : addresses.concat(round2Address);
    //loop through all contracts and get user claimables and
    const all_calls = all_address.map((contract_address) => {
      return new Promise((resolve, reject) => {
        //initiate contract and make call to claimable balance
        let contract = new ethers.Contract(contract_address, unvestABI, provider);
        contract
          .claimedBalanceOf(address)
          .then((response) => {
            // console.log("total claimed balance:", response, contract_address)
            resolve({ response, contract_address });
          })
          .catch((error) => {
            reject(error);
          });
      });
    });

    Promise.all(all_calls).then((response) => {
      // console.log("All claimed balance Data:", response)
      setAlreadyClaimedData(response);
      let all_claimed_balance_amount = BigNumber.from(0);
      //sum all amounts and display as user's locked balance
      for (let i = 0; i < response.length; i++) {
        if (!response[i].response.isZero()) {
          all_claimed_balance_amount = all_claimed_balance_amount.add(response[i].response);
        }
      }

      setClaimedBalanceAmount(all_claimed_balance_amount);
    });
  };

  //function for getting milestone information of each vesting
  const getMileStonesData = () => {
    //initiate contract
    //loop through all contracts and get milestone
    const all_calls = addresses.map((contract_address) => {
      return new Promise((resolve, reject) => {
        //initiate contract and make call to claimable balance
        let contract = new ethers.Contract(contract_address, unvestABI, provider);
        contract
          .milestones()
          .then((response) => {
            // console.log("all milestones:", response, contract_address)
            resolve({ response, contract_address });
          })
          .catch((error) => {
            reject(error);
          });
      });
    });

    Promise.all(all_calls).then((response) => {
      // console.log("All milestone Data:", response)
      setAllMileStones(response);
    });
  };

  //function to claim data
  const claim = () => {
    //initiate contract
    const claimableSum = isPLS ? claimableAmount : claimableAmount.add(claimableNewRound);

    //loop through all contracts and get user claimables and
    const all_calls = addresses.concat(round2Address).map((contract_address) => {
      return new Promise((resolve, reject) => {
        //initiate contract and make call to claimable balance
        let contract = new ethers.Contract(contract_address, unvestABI, signer);
        contract
          .claimableBalanceOf(address)
          .then((response) => {
            //console.log("claimable balance:",response)
            //if the balance is not zero we initate the approval/withdrawal
            if (!response.isZero()) {
              let estimateGas;
              try {
                contract.estimateGas.claim().then((gasEst) => {
                  estimateGas = gasEst.toNumber() + 1e4;
                });
              } catch (error) {
                estimateGas = 3e6;
              }

              contract
                .claim({ gasLimit: estimateGas })
                .then((claim_res) => {
                  resolve(claim_res);
                })
                .catch((err) => {
                  reject(err);
                });
            } else {
              resolve(response);
            }
            //resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });

    Promise.all(all_calls)
      .then((response) => {
        setStoreState('claimModal', true);
        setStoreState('claimingAmounts', claimableSum);
        setReload(!reload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    claimable();
    claimableRound_2();
    getTotalBalance();
    getAlreadyClaimed();
    getMileStonesData();
    getFirstRoundClaimed();
  }, [reload, reloadData]);

  const handleAlreadyClaimed = (milestone, isString = true) => {
    const claimed = totalVestedData.map((tv, index) => {
      if (tv.vest === milestone.name) {
        if (milestone.name === 'PEG-PUB-100') {
          return tv.group_sum.gt(BigNumber.from(0)) ? '1,500' : '0.0';
        }
        return alreadyClaimedData.map((alcd) => {
          if (alcd.contract_address === milestone.address[0]) {
            let final = parseFloat(ethers.utils.formatUnits(tv.group_sum.add(alcd.response).mul(10), 18)).toFixed(4);
            if (!isString && typeof final === 'string') final = parseFloat(final);
            return final;
          }
        });
      }
    });
    return claimed;
  };

  const returnMileStonesData = (milestone, index) => {
    try {
      return (
        <>
          {(milestone.name !== 'PEG-OURO' ||
            (milestone.name === 'PEG-OURO' && handleAlreadyClaimed(milestone, false)?.[index]?.[index] > 0)) && (
            <div className="bg-white/[0.05] min-w-[210px] rounded-2xl border-[0.5px] border-border-blue text-[#E0DDFF]/70 flex-1 flex flex-col items-center p-4 stake-bg-shadow sm:m-4 sm:ml-0">
              <p className=" text-[18px] sm:text-xl uppercase barlow">{milestone.label}</p>
              <p className="text-[30px] sm:text-4xl font-semibold uppercase barlow">
                {handleAlreadyClaimed(milestone, true)} PEG
              </p>
            </div>
          )}
        </>
      );
    } catch (e) {
      console.error('Milestone Error ', e);
      return 'An error occurred';
    }
  };

  return (
    <>
      <div className="mb-24 bg-white/[0.05] rounded-2xl border-[0.5px] border-border-blue text-[#E0DDFF]/70 mx-auto w-[90%] flex-1 flex flex-col items-center py-6 px-4 stake-box-shadow mb-5 relative">
        {isPLS ? (
          <>
            <img
              src={arbsOrBase === 'base' ? pepe_top_base : pepe_top}
              className="top-[-6rem] w-[270px] sm:w-auto sm:top-[-14rem] left-0 right-0 block mx-auto absolute pointer-events-none"
              alt="Logo"
            />
            <span className="w-[14px] sm:w-auto absolute top-4 right-4 text-white cursor-pointer">
              <a href="" onClick={() => navigate('/slot')}>
                <img src={cancel_icon_w_gradient} alt="" />
              </a>
            </span>
            <p className='flex flex-col sm:flex-row sm:gap-1 items-center justify-center pb-5 pl-10 pr-10 text-3xl text-center sm:text-left sm:text-5xl font-["Tourney"] font-extrabold gradient-text uppercase mt-24'>
              <span>Claim What is Yours!</span>
            </p>
            <p className="pb-5 text-base text-center text-white barlow">All your PLS Assets Airdrops in One Place</p>
          </>
        ) : (
          ''
        )}
        <div className="flex flex-col items-center w-[90%] lg:w-3/4 p-8 sm:px-0 sm:py-2 mx-auto mb-5 bg-deep-blue/40 rounded-2xl">
          <p className="flex flex-col items-center gap-4 mb-4 sm:flex-row mt-5 w-[150%] sm:w-auto">
            <span className="text-white barlow font-medium leading-[33px] -tracking-[0.02em] text-base sm:text-2xl text-center">
              Your total PEG Airdrops
            </span>
          </p>

          <div className="flex flex-col sm:w-full sm:max-w-[493px] sm:py-4 sm:h-[144px] mt-5 gap-8 sm:gap-0">
            <div className="flex flex-col sm:flex-row sm:h-[38px] justify-center items-center gap-y-[10px] text-center sm:text-left px-2">
              <div className="flex  flex-col sm:flex-row items-center gap-5 sm:w-full sm:justify-start">
                <span className="uppercase barlow font-light text-[16px] leading-4 titles">
                  {isPLS ? 'TOTAL VESTED PLS' : 'TOTAL VESTED PEG'}
                </span>
                <span className="font-[Tourney] font-bold ml-[10px] text-[30px] amount gradient-text-b">
                  {isPLS
                    ? parseFloat(ethers.utils.formatUnits(balanceAmount.add(claimedBalance).mul(10), 18)).toFixed(4)
                    : parseFloat(ethers.utils.formatUnits(balanceAmount.add(firstRoundCalimed).mul(10), 18)).toFixed(4)}
                </span>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row sm:h-[38px] justify-center items-center text-center sm:text-left px-2">
              <div className="flex flex-col sm:flex-row items-center gap-5 sm:gap-8 sm:w-full sm:justify-start">
                <span className="uppercase barlow font-light text-[16px] leading-4 titles">ALREADY CLAIMED</span>
                <span className="font-[Tourney] font-bold text-[30px] amount gradient-text-b">
                  {parseFloat(ethers.utils.formatUnits(claimedBalance, 18)).toFixed(4)}
                </span>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row sm:h-[38px] justify-center items-center text-center sm:text-left px-2">
              <div className="flex flex-col sm:flex-row items-center justify-center gap-5 sm:w-full sm:justify-start">
                <span className="uppercase barlow font-light text-[16px] leading-4 titles">CLAIMABLE AMOUNT</span>
                <span className="font-[Tourney] font-bold text-[30px] amount gradient-text-b">
                  {isPLS
                    ? parseFloat(ethers.utils.formatUnits(claimableAmount, 18)).toFixed(4)
                    : parseFloat(ethers.utils.formatUnits(claimableAmount.add(claimableNewRound), 18)).toFixed(4)}
                </span>
                <button onClick={() => claim()} className="flex px-[20px] mt-2 py-1 claim-active rounded-[6px]">
                  Claim{' '}
                  {isPLS
                    ? numberOfApproval > 1
                      ? '(' + numberOfApproval + ' approvals)'
                      : null
                    : numberOfApproval + claimableNewRoundApproval > 1
                    ? '(' + (numberOfApproval + claimableNewRoundApproval) + ' approvals)'
                    : null}{' '}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[90%] sm:w-3/4 flex flex-col items-center mx-auto mb-2">
          <div className={`${!isPLS ? 'text-center grid sm:grid-cols-2 sm:gap-1 gap-[30px] w-full' : ''} `}>
            {addressesAllRounds.map((milestone, index) =>
              milestone.name !== 'PEG-PUB-100' ? (
                returnMileStonesData(milestone, index)
              ) : !allClaimableData.find((item) => item.contract_address === pegOuro)?.response?.isZero() ? (
                <div className="bg-white/[0.05] min-w-[210px] rounded-2xl border-[0.5px] border-border-blue text-[#E0DDFF]/70 flex-1 flex flex-col items-center p-4 stake-bg-shadow sm:m-4 sm:ml-0">
                  <p className="text-[18px] sm:text-xl uppercase barlow">{milestone.label}</p>
                  {/* <p className="text-2xl uppercase barlow">END ON: {addressesWithNames.map((itm)=>{if(itm.address===milestone.contract_address) return new Date(ethers.utils.formatUnits(milestone.response[1].timestamp,1)*10000).toLocaleDateString()})}</p> */}
                  <p className="text-[30px] sm:text-4xl font-semibold uppercase barlow">
                    {totalVestedData.map((tv) => {
                      if (tv.vest === milestone.name) {
                        if (milestone.name === 'PEG-PUB-100') {
                          //top 100
                          return !tv.group_sum.isZero() ? '1,500' : '0.0';
                        }
                        return alreadyClaimedData.map((alcd) => {
                          if (alcd.contract_address === milestone.address[0])
                            return parseFloat(
                              ethers.utils.formatUnits(tv.group_sum.add(alcd.response).mul(10), 18)
                            ).toFixed(4);
                        });
                        //return parseFloat(ethers.utils.formatUnits(tv.group_sum,18)).toFixed(4)*10
                      }
                    })}{' '}
                    PEG
                  </p>
                </div>
              ) : null
            )}
          </div>
        </div>

        {/* && !allClaimableData.find(item=>item.contract_address===pegOuro)?.response?.isZero() */}

        <div
          className={`flex flex-col w-full sm:w-[313px] ${
            isPLS ? 'mt-[98px] mb-[170px]' : 'my-5'
          } mx-auto items-center`}
        >
          <div className="flex flex-col justify-center text-center w-auto sm:w-[298px]">
            <span className="text-white barlow font-medium text-[21px] leading-[29.4px] -tracking-[0.02em]">
              Dont forget to earn $$
            </span>
            <span className="text-white barlow font-medium text-[21px] leading-[29.4px] -tracking-[0.02em]">
              with staking or locking your PEG!
            </span>
          </div>

          <div className="flex gap-5 justify-center mt-[30px] w-full sm:w-auto">
            <button
              className="dark-claims w-[146.5px] h-10 barlow font-medium text-[15px] leading-6 text-white"
              onClick={() => navigate('/stake', { state: 'stake' })}
            >
              <img src={bolt} className="self-center inline-block mr-2" />
              Stake
            </button>
            <button
              className="dark-claims w-[146.5px] h-10 barlow font-medium text-[15px] leading-6 text-white"
              onClick={() => navigate('/lock', { state: 'lock' })}
            >
              <img src={crown} className="self-center inline-block mr-2" />
              Lock
            </button>
          </div>
        </div>
        {isPLS ? (
          <>
            <div className="flex absolute z-10 bottom-0 left-0">
              <img
                src={pepePls}
                className="lg:w-[350px] md:w-[350px] sm:w-[300px] w-[210px] rounded-2xl"
                alt="pepe holding pls coin"
              />
            </div>
          </>
        ) : (
          ''
        )}

        <div className="absolute bottom-[-100px] right-[20px] p-2 w-[90%] mx-auto sm:hidden">
          {/* <div className='flex justify-end my-2' >
          <UPMobile />
        </div> */}
        </div>
      </div>
    </>
  );
};

export default ClaimAirdrop;
