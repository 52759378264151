import React, { useContext, useState } from 'react';
import { pepe_top } from '../../assets';
import './SummarizePopup.css';
import leftCoinStack from '../../assets/images/pepeCoinStackLeft.svg';
import rightCoinStack from '../../assets/images/pepeCoinStackRight.svg';
import { StoreContext } from '../../utils/store';
import { useMediaQuery } from 'usehooks-ts';

export const SummarizePopup = ({ closePopup }) => {
  const store = useContext(StoreContext);
  const { tradeVars: { time, amount, currency, longShort } } = store;
  const [loaded, setLoaded] = useState(0);
  const loadingTime = 3000;
  const isMobile = useMediaQuery('(max-width: 640px)');

  React.useEffect(() => {
    const loadingBarTimeoutId = setTimeout(() => {
      setLoaded(100);
    }, 1000);

    // const closePopupTimeoutId = setTimeout(() => {
    //   closePopup();
    // }, 1000 + loadingTime);

    return () => {
      clearTimeout(loadingBarTimeoutId);
      //clearTimeout(closePopupTimeoutId);
    };
  }, []);

  return (
    <div className="fixed overflow-y-scroll overflow-x-hidden h-screen inset-0 z-[1001] bg-black bg-opacity-95 text-white summarize-popup-show">
      <div className="flex justify-center relative z-10">
        <img
          src={pepe_top}
          style={{
            width: isMobile ? '80vw': '25vw',
            height: 'auto',
          }}
          alt="pepe logo"
        />
      </div>
      <div className="smp-card w-full sm:pt-[1.5rem] ml-0 -mr-8 sm:mx-auto sm:w-3/5 lg:w-[35vw] bg-deep-blue text-center">
        <div className='text-[35px] sm:text-[30px] lg:text-[3vw] font-["Tourney"] font-bold gradient-text tracking-[0.03em] lg:pt-[2vh] lg:pb-[1vh]'>
          GOOD LUCK!
        </div>
        <div className="text-[26px] sm:text-[26px] lg:text-[2.5vw] barlow font-bold tracking-[0.03em] uppercase">
          ${amount} - {currency} - {longShort} - {time}s
        </div>
        {/* preparing round div */}
        <div className="pt-4 lg:pt-2 xl:pt-[26px] pb-[191px] sm:pb-8">
          <p className="barlow text-[20px] font-semibold smp-blue-heading mb-4 tracking-[0.03em]">PREPARING ROUND</p>
          <div className="flex justify-center">
            <div className="smp-progress-bar">
              <div className="smp-progress-done" style={{ transition: `width ${loadingTime}ms` }}></div>
              <div
                className="smp-progress-left"
                style={{ width: `${100 - loaded}%`, transition: `width ${loadingTime}ms` }}
              ></div>
            </div>
          </div>
        </div>

        <img
          src={leftCoinStack}
          className="block absolute sm:fixed bottom-0 -left-6 sm:left-12 lg:left-12 h-44 sm:h-48 lg:h-52 w-auto sm:-z-10"
          alt="stack of coins"
        />
        <img
          src={rightCoinStack}
          className=" block absolute sm:fixed bottom-0 right-0 sm:right-6 lg:right-12 h-auto w-28 sm:w-40 lg:w-48 sm:-z-10"
          alt="stack of coins"
        />
      </div>
    </div>
  );
};
