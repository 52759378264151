import React, { createContext } from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import { BigNumber } from 'ethers';
import {
  LEVEL1,
  LEVEL2,
  LEVEL3,
  LEVEL4,
  WIN_TRADE,
  LOSE_TRADE,
  BET1,
  BET2,
  BET3,
  SPIN,
  ROCKET,
  WIN_LONG,
  WIN_SHORT,
} from '../constants.js';
import down_arrow from '../assets/images/Group 163.svg';
import up_arrow from '../assets/images/Group 164.svg';
const up = <img src={up_arrow} className="mx-auto w-[138px] h-[69px]" />;
const down = <img src={down_arrow} className="mx-auto w-[138px] h-[69px]" />;

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const store = useLocalObservable(() => ({
    /*observables here*/
    /*actions here*/
    /*computed values i.e. derived state here*/
    trades: [],
    loading: false,
    leverage: [up, down, up, down, up, down],
    currency: ['BTC', 'ETH'],
    longShort: ['Long', 'Short'],
    time: [20, 30, 40],
    startingPrice: 0,
    startStoreTime: null,
    amount: 0,
    factoryTradeId: null,
    reimburse: false,
    showTransactionDetails: false,
    goodluckModal: false,
    transactionDetails: {
      size: 0,
      margin: 0,
      entryPrice: 0,
      currentPrice: 0,
      isProfitable: false,
      PnL: 0,
    },
    tradeVars: {
      leverage: 0,
      time: 0,
      amount: 0,
      currency: '',
      longShort: '',
      timeIndex: 4,
      currencyIndex: 3,
      leverageIndex: 3,
    },
    lambdaResponseData: {},
    connectmodal: false,
    referralModal: false,
    slotmodal: false,
    showDepositWithdrawModal: false,
    showAutoSpinModal: false,
    showAutoSpinResult: false,
    showSideMenu: false,
    playState: false,
    music: [LEVEL1, LEVEL2, LEVEL3, LEVEL4, WIN_LONG, WIN_SHORT],
    sfxAudio: [BET1, BET2, BET3, SPIN, ROCKET, WIN_TRADE, LOSE_TRADE],
    track: LEVEL1,
    sfx: true,
    vol: 1,
    trackPosition: 0,
    autoplay: true,
    price: 0,
    activeAmountButton: '',
    willRespin: false,
    respinSlots: [],
    stakeModal: false,
    degenStakeModal: false,
    lockModal: false,
    claimModal: false,
    vaultModal: false,
    promptType: '',
    showHowToPlay: false,
    claimTx: '',
    lockingAmounts: {},
    claimingAmounts: '0',
    userLockingState: {},
    claimConfirmModal: false,
    claimableAmount: 0,
    depositWithdrawToken: 'USDC',
    depositWithdrawOperation: 'deposit',
    // questChips: localStorage.getItem('questChips') ? parseInt(localStorage.getItem('questChips'), 10) : 0,
    // questSpinDay: localStorage.getItem('questSpinDay') ? parseInt(localStorage.getItem('questSpinDay'), 10) : 0,
    // questDaysSpun: localStorage.getItem('questDaysSpun') ? parseInt(localStorage.getItem('questDaysSpun'), 10) : 0,
    questChips: 0,
    questSpinDay: 0,
    questDaysSpun: 0,
    questWeeksSpun: 0,
    questSpinWeek: 0,
    isQuestsOpen: false,
    referralCode: '',
    currentStakeTab: '',
    isEsPeg: false,
    esPegStakeID: 0,
    esPegLockID: 0,
    claimLockModal: false,
    lockingReward: '0',
    refreshLocking: false,
    lockPPLPModal: false,
    disclaimerModal: false,
    reloadData: false,
    pepePPLPReward: BigNumber.from(0),
    isDeposit: '',
    vaultData: {
      amount: 0.0,
      amountUsd: 0.0,
      amountBigNumber: BigNumber.from(0),
    },
    counterPepeModal: false,
    pulloutModal: false,
    esPegUnlockHash: '',

    setEsPegUnlockHash(value) {
      this.esPegUnlockHash = value;
    },

    setPulloutModal(value) {
      this.pulloutModal = value;
    },

    setCounterPepeModal(value) {
      this.counterPepeModal = value;
    },

    updateLongShort(value) {
      const { amount, currency, time } = this.tradeVars;
      this.setTradeVars(amount, currency, time, value);
    },

    setPepePPLPReward(value) {
      this.pepePPLPReward = value;
    },

    setDisclaimerModal(value) {
      this.disclaimerModal = value;
    },

    setLockPPLPModal(value) {
      this.lockPPLPModal = value;
    },

    setIsEsPeg(value) {
      this.isEsPeg = value;
    },

    setEsPegStakeID(value) {
      this.esPegStakeID = value;
    },

    setEsPegLockID(value) {
      this.esPegLockID = value;
    },

    setLockingReward(value) {
      this.lockingReward = value;
    },

    setRefreshLocking(value) {
      this.refreshLocking = value;
    },

    setCurrentStakeTab(value) {
      const valid = ['stake', 'unstake', 'claim'];
      if (!valid.includes(value)) return;

      this.currentStakeTab = value;
    },

    setQuestsModal(value) {
      this.isQuestsOpen = value;
    },

    setVol(value) {
      this.vol = value;
    },

    setQuestSpinWeek(value) {
      this.questSpinWeek = value;
    },

    setQuestChips(value) {
      this.questChips = value;
    },

    setQuestSpinDay(value) {
      this.questSpinDay = value;
    },

    setDaysSpun(value) {
      this.questDaysSpun = value;
    },

    setVaultModal(value) {
      this.vaultModal = value;
    },

    setIsDeposit(value) {
      this.isDeposit = value;
    },

    setVaultData(value) {
      console.log({ VaultStore: value });
      this.vaultData.amount = value.amount;
      this.vaultData.amountUsd = value.amountUsd;
      this.vaultData.amountBigNumber = value.amountBigNumber;
    },

    getWeekNumber(date) {
      // Copy date so don't modify original
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
      // Get first day of year
      let yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
      // Calculate full weeks to nearest Thursday
      let weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
      // Return week number
      return weekNo;
    },

    calculateQuestChip() {
      const today = new Date().getDay();
      const currentWeek = this.getWeekNumber(new Date());
      let chipsToAdd = 0;
      let daysSpun = this.questDaysSpun;
      let lastSpinDay = this.questSpinDay;
      let lastSpinWeek = this.questSpinWeek == 0 ? this.getWeekNumber(new Date()) : this.questSpinWeek;

      if (lastSpinDay !== today) {
        if (lastSpinDay === 0 || today === lastSpinDay + 1) {
          // User has not spun the wheel before or has already spun today
          if (daysSpun === 0) {
            // First time spinning
            chipsToAdd = 5;
          } else if (daysSpun === 1) {
            chipsToAdd = 5;
          } else if (daysSpun === 2) {
            chipsToAdd = 5;
          } else if (daysSpun === 3) {
            chipsToAdd = 5;
          } else if (daysSpun === 4) {
            chipsToAdd = 10;
          } else if (daysSpun === 5) {
            chipsToAdd = 20;
          } else if (daysSpun === 6) {
            chipsToAdd = 50;
          }
          daysSpun = Math.min(daysSpun + 1, 7);
        } else {
          // Calculate days since last spin and check if user missed any days
          let daysSinceLastSpin = (today - lastSpinDay + 7) % 7;
          for (let i = 1; i < daysSinceLastSpin; i++) {
            let dayToCheck = (lastSpinDay + i) % 7;
            if (dayToCheck === 0) {
              dayToCheck = 7;
            }
            if (dayToCheck !== today) {
              daysSpun = 1;
              break;
            }
          }
          if (daysSpun === 1) {
            chipsToAdd = 5;
          } else if (daysSinceLastSpin === 1) {
            // User spun the wheel yesterday
            chipsToAdd = 10;
            daysSpun = daysSpun + 1;
          } else if (daysSinceLastSpin === 2) {
            // User spun the wheel 2 days ago
            chipsToAdd = 20;
            daysSpun = daysSpun + 1;
          } else if (daysSinceLastSpin === 3) {
            // User spun the wheel 3 days ago
            chipsToAdd = 50;
            daysSpun = daysSpun + 1;
          } else {
            // User missed more than 3 days, reset to day 1 rules
            chipsToAdd = 5;
            daysSpun = 1;
          }
        }
      }

      if (lastSpinWeek !== currentWeek) {
        // console.log('returend chips::', lastSpinWeek, typeof lastSpinWeek, currentWeek, typeof currentWeek)
        daysSpun = 1;
        chipsToAdd = 5;
      }

      let chips = this.questChips + chipsToAdd;

      // Update questSpinWeek if the spin occurred in a new week
      if (lastSpinWeek !== currentWeek) {
        this.setQuestSpinWeek(currentWeek);
        // localStorage.setItem('questSpinWeek', currentWeek);
      }

      this.setQuestChips(chips);
      // localStorage.setItem('questChips', chips);
      this.setQuestSpinDay(today);
      // localStorage.setItem('questSpinDay', today);
      this.setDaysSpun(daysSpun);
      // localStorage.setItem('questDaysSpun', daysSpun);

      return [chips, today, daysSpun, currentWeek];
    },
    checkMissedDays() {
      const today = new Date().getDay();
      const lastSpinDay = this.questSpinDay;
      const currentWeek = this.getWeekNumber(new Date());
      const lastSpinWeek = this.questSpinWeek == 0 ? this.getWeekNumber(new Date()) : this.questSpinWeek;

      if (currentWeek !== lastSpinWeek) {
        // A new week has started, reset days spun count to 0
        this.setDaysSpun(0);
      } else if (lastSpinDay !== today) {
        if (lastSpinDay === 0 || today === lastSpinDay + 1) {
          // User has not spun the wheel before or has already spun today
          // Do nothing, since no days have been missed
        } else {
          // Calculate days since last spin and check if user missed any days
          let daysSinceLastSpin = (today - lastSpinDay + 7) % 7;
          for (let i = 1; i < daysSinceLastSpin; i++) {
            let dayToCheck = (lastSpinDay + i) % 7;
            if (dayToCheck === 0) {
              dayToCheck = 7;
            }
            if (dayToCheck !== today) {
              // User missed a day, so reset the days spun count to 0
              this.setDaysSpun(0);
              break;
            }
          }
        }
      }
    },

    setDepositWithdrawToken(value) {
      this.depositWithdrawToken = value;
    },

    setDepositWithdrawOperation(value) {
      this.depositWithdrawOperation = value;
    },

    setClaimingAmounts(value) {
      this.claimingAmounts = value;
    },

    setLockingAmounts(value) {
      this.lockingAmounts = value;
    },
    autoSpinVars: {
      isAuto: false,
      amount: 0,
      rounds: 0,
      roundsLeft: 0,
    },

    setClaimTx(value) {
      this.claimTx = value;
    },

    setPromptType(value) {
      this.promptType = value;
    },

    setStakeModal(value) {
      this.stakeModal = value;
    },

    setDegenStakeModal(value) {
      this.degenStakeModal = value;
    },

    setClaimModal(value) {
      this.claimModal = value;
    },

    setClaimLockModal(value) {
      this.claimLockModal = value;
    },

    setLockModal(value) {
      this.lockModal = value;
    },

    setShowHowToPlay(value) {
      this.showHowToPlay = value;
    },

    setRespinSlots(value) {
      this.respinSlots = value;
    },

    setWillRespin(value) {
      this.willRespin = value;
    },

    setActiveAmountButton(value) {
      this.activeAmountButton = value;
    },

    setAutoplay(value) {
      this.autoplay = value;
    },

    setTrackPosition(value) {
      this.trackPosition = value;
    },

    sampleData: null,

    setSampleData(value) {
      this.sampleData = value;
    },

    isApproved: false,
    setIsApproved(value) {
      this.isApproved = value;
    },

    lasttradeOpen: 0.0,
    lasttradeClose: 0.0,
    lasttradeWin: 0.0,

    setLastTrade(values) {
      this.lasttradeOpen = values.openPrice;
      this.lasttradeClose = values.closePrice;
      this.lasttradeWin = values.win;
    },

    setSrc(value) {
      this.track = value;
    },
    setPlayState(value) {
      this.playState = value;
    },

    SFXtoggle(value) {
      this.sfx = value;
    },

    setSFX(value) {
      let sfx = new Audio();
      sfx.src = value;
      sfx.load();
      if (this.sfx) {
        sfx.play();
      }
    },

    setConnectModal(value) {
      this.connectmodal = value;
    },

    setReferralModal(value) {
      this.referralModal = value;
    },

    setSlotModal(value) {
      this.slotmodal = value;
    },

    setGoodLuckModal(value) {
      this.goodluckModal = value;
    },

    setDepositWithdrawModal(value) {
      this.showDepositWithdrawModal = value;
    },

    setAutoSpinModal(value) {
      this.showAutoSpinModal = value;
    },

    setAutoSpinResult(value) {
      this.showAutoSpinResult = value;
    },

    setSideMenu(value) {
      this.showSideMenu = value;
    },

    setStoreState(state, value) {
      //console.log('Store', state, value);
      this[state] = value;
    },
    setTransactionDetails(value) {
      // console.log('Value: ', value);
      this.transactionDetails = {
        size: value.size,
        margin: value.margin,
        entryPrice: value.entryPrice,
        currentPrice: value.currentPrice,
        isProfitable: value.isProfitable,
        PnL: value.PnL,
      };
    },
    addTrade(current_trade) {
      this.trade = [...this.trade, current_trade];
    },

    myTrades() {
      return this.trade;
    },

    getTradeVars() {
      return this.tradeVars;
    },

    doRandomizeTradeVars() {
      //randomize leverage
      this.tradeVars.leverageIndex = Math.floor(Math.random() * (3 - 2 + 1) + 2); //this value is needed to hack the effect in the slot machine when animated
      this.tradeVars.leverage = this.leverage[Math.floor(Math.random() * (3 - 2 + 1) + 2)];
      //randomize currency
      this.tradeVars.currencyIndex = Math.floor(Math.random() * (3 - 2 + 1) + 2);
      this.tradeVars.currency = this.currency[Math.floor(Math.random() * (3 - 2 + 1) + 2)];
      //randomize longshort
      this.tradeVars.longShort = this.longShort[Math.floor(Math.random() * this.longShort.length)];
      //randomize time
      this.tradeVars.timeIndex = Math.floor(Math.random() * (4 - 2 + 1) + 2);
      this.tradeVars.time = this.time[Math.floor(Math.random() * (4 - 2 + 1) + 2)];
      this.tradeVars.amount = this.amount;
      this.loading = !this.loading;
    },
    setStartTime(time) {
      this.startStoreTime = time;
    },
    setPrice(value) {
      this.price = value;
    },
    setStartingPrice(price) {
      this.startingPrice = price;
    },
    setTradeVars(amount, currency, time, longShort) {
      this.tradeVars.amount = amount;
      this.tradeVars.currency = currency;
      this.tradeVars.time = time;
      this.tradeVars.longShort = longShort;
    },
    setAutoSpinVars(isAuto, amount, rounds, roundsLeft, id) {
      this.autoSpinVars.isAuto = isAuto;
      this.autoSpinVars.amount = amount;
      this.autoSpinVars.rounds = rounds;
      this.autoSpinVars.roundsLeft = roundsLeft;
      this.autoSpinVars.id = id;
    },
    setClaimConfirmModal(value) {
      this.claimConfirmModal = value;
    },
    showWalletMenuPopup: false,
    setWalletMenuPopup(value) {
      this.showWalletMenuPopup = value;
    },
  }));
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
