import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useContext, useState } from 'react';
import { useConnect, useAccount, useDisconnect, useNetwork } from 'wagmi';
import { useNavigate, useLocation } from 'react-router-dom';
import { cancel_icon_w_gradient } from '../../assets';
import GradientBox from '../../../src/components/GradientBox/GradientBox';
import LoaderIcon from '../LoaderIcon';
import { StoreContext } from '../../utils/store';
import { checkrefreeURL, addreferURL, permission_API, api, chain_ids } from '../../constants';
import axios from 'axios';
import { useStorage } from '../../utils/StorageContext';
import { checkMetamaskSession, switchMetamaskChain } from '../../utils/inits';

// Tourney

export default function MyModal({ open, isOpen }) {
  const navigate = useNavigate();
  const { search: ref } = useLocation();
  const store = useContext(StoreContext);
  const { setReferralModal, setDisclaimerModal } = store;
  const { address, connector, isConnected } = useAccount();
  const { connect, connectors, isLoading, pendingConnector } = useConnect();
  const { chain, chains } = useNetwork();
  const { disconnect } = useDisconnect();

  const [refresh, setRefresh] = useState(false);
  const handleDisconnect = () => {
    disconnect();
    closeModal();
    navigate('/');
    // window.location.reload();
    // setRefresh(!refresh)
  };

  //Method to close connect Wallet Modal
  const closeModal = () => {
    open(false);
    // navigate('/slot');
  };

  //Method to check if you are not a refree yet and open referralModal
  const checkRefree = async (referee) => {
    try {
      // const response = await axios.get(`${checkrefreeURL}&referee=${referee}`);
      const response = await axios.get(`${api.offChainBet.checkUserRefereeApiEndpoint}&referee=${referee}`);
      console.log('refree data :: ', referee, response.data);

      if (response.data.status === 'success') {
        setReferralModal(true);
      }
    } catch (e) {
      console.error('Failed to check for referralCode: ', e);
      throw new Error('Failed to check for referralCode');
    }
  };

  //Method to add refer
  const addRefer = async (refCode, referee) => {
    try {
      // const response = await axios.get(`${addreferURL}refCode=${refCode}&referee=${referee}`);
      const response = await axios.get(
        `${api.offChainBet.addRefererApiEndpoint}/add?refCode=${refCode}&referee=${referee}`
      );
      // const data = response.data.refcode[0]; //unsure of returned data type, assuming json
    } catch (e) {
      console.error('Failed to check for referralCode: ', e);
      throw new Error('Failed to check for referralCode');
    }
  };

  //Method to update permissions
  const agreement = async () => {
    try {
      const data = await axios.post(permission_API, {
        write: false,
        useraddress: address,
      });

      const consent = data?.data[0]?.consent?.S;

      if (!consent) {
        setDisclaimerModal(true);
      }
    } catch (e) {
      console.log('AGREEMENT ERROR: ', e.message);
    }
  };

  useEffect(() => {}, [refresh]);

  useEffect(() => {
    if (isConnected) {
      //console.log(window.location);

      console.log({ chain, chains });

      if (Object.keys(chain).length > 0) {
        if (!chain_ids.includes(chain.id)) {
          // open(false);
          alert('Please select a chain supported chain network');
          return;
        } else {
          switchMetamaskChain(chain.id === 8453 ? 'base' : 'arbitrium');
          // checkMetamaskSession();
        }
      }

      if (window.location.pathname === '/') {
        navigate('/slot');
      }

      if ((ref !== '') & (ref !== undefined)) {
        const userReferrenceCode = ref.split('=')[1]?.trim();
        //console.log('Location Referral::', userReferrenceCode, address)
        addRefer(userReferrenceCode, address);
      } else {
        checkRefree(address);
      }

      open(false);

      // if(!agreed){
      //     setDisclaimerModal(true)
      // }

      agreement();
    }
  }, [isConnected]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-90" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="transform transition-all relative w-full">
                <div className="relative">
                  <div>
                    <GradientBox
                      classes="relative w-[87%] sm:w-1/2 sm:min-w-[37rem] max-w-[40rem] mx-auto py-12"
                      roundedClass="rounded-[0.625rem]"
                    >
                      <div className="text-white py-[1.875rem] sm:pt-[1.375rem] sm:pb-[1.75rem] px-5 sm:px-[2.188rem]">
                        <div className="flex justify-end mb-5 sm:-[0.688rem]">
                          <button onClick={closeModal}>
                            <img src={cancel_icon_w_gradient} alt="cancel" className="w-4 h-auto sm:w-auto" />
                          </button>
                        </div>

                        {isConnected && (
                          <>
                            <h2 className='gradient-text font-["Tourney"] font-bold text-[1.75rem] sm:text-[4.188rem] leading-[1.925rem] sm:leading-[4.606rem] tracking-[0.03em] text-center mb-5 sm:mb-[2.861rem]'>
                              <span className="sm:hidden flex flex-col">
                                <span>SEE YOU</span>
                                <span>NEXT TIME!</span>
                              </span>

                              <span className="hidden sm:flex flex-col">
                                <span>SEE YOU NEXT</span>
                                <span>TIME!</span>
                              </span>
                            </h2>

                            <div className="text-[1.063rem] sm:text-lg leading-[1.375rem] sm:leading-[1.563rem] tracking-[0.03em] text-[#E0DDFF] sm:text-white text-center mb-[1.875rem] sm:mb-[4.75rem] sm:font-['Manrope'] font-medium">
                              <p className="sm:hidden">{`${address.substring(0, 7)}...${address.slice(-5)}`}</p>
                              <p className="hidden sm:block">{address}</p>
                              <p>Connected to {connector?.name}</p>
                            </div>

                            <div className="relative">
                              <div className="z-10 relative">
                                <div className="sm:flex justify-center items-center gap-6 sm:mb-[4.5rem] barlow">
                                  <img
                                    src={connector?.options?.logo}
                                    alt={connector?.name}
                                    className="hidden sm:block w-[3.25rem]"
                                  />
                                  <button
                                    className="relative connect-wallet-gradient-shadow flex justify-center min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[1.875rem] sm:mb-0 disabled:opacity-40"
                                    disabled={!connector?.ready}
                                    // onClick={disconnect}
                                    onClick={handleDisconnect}
                                  >
                                    <img
                                      src={connector?.options?.logo}
                                      alt={connector?.name}
                                      className="w-5 sm:w-0 sm:hidden"
                                    />
                                    <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                                      Disconnect
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {/* {isConnected ? (
                          <>
                            <h2 className='gradient-text font-["Tourney"] font-bold text-[1.75rem] sm:text-[4.188rem] leading-[1.925rem] sm:leading-[4.606rem] tracking-[0.03em] text-center mb-5 sm:mb-[2.861rem]'>
                              <span className="sm:hidden flex flex-col">
                                <span>SEE YOU</span>
                                <span>NEXT TIME!</span>
                              </span>

                              <span className="hidden sm:flex flex-col">
                                <span>SEE YOU NEXT</span>
                                <span>TIME!</span>
                              </span>
                            </h2>

                            <div className="text-[1.063rem] sm:text-lg leading-[1.375rem] sm:leading-[1.563rem] tracking-[0.03em] text-[#E0DDFF] sm:text-white text-center mb-[1.875rem] sm:mb-[4.75rem] sm:font-['Manrope'] font-medium">
                              <p className="sm:hidden">{`${address.substring(0, 7)}...${address.slice(-5)}`}</p>
                              <p className="hidden sm:block">{address}</p>
                              <p>Connected to {connector?.name}</p>
                            </div>

                            <div className="relative">
                              <div className="z-10 relative">
                                <div className="sm:flex justify-center items-center gap-6 sm:mb-[4.5rem] barlow">
                                  <img
                                    src={connector?.options?.logo}
                                    alt={connector?.name}
                                    className="hidden sm:block w-[3.25rem]"
                                  />
                                  <button
                                    className="relative connect-wallet-gradient-shadow flex justify-center min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[1.875rem] sm:mb-0 disabled:opacity-40"
                                    disabled={!connector?.ready}
                                    // onClick={disconnect}
                                    onClick={handleDisconnect}
                                  >
                                    <img
                                      src={connector?.options?.logo}
                                      alt={connector?.name}
                                      className="w-5 sm:w-0 sm:hidden"
                                    />
                                    <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                                      Disconnect
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ''
                          // <>
                          //   <h2 className='gradient-text font-["Tourney"] font-bold text-[1.75rem] sm:text-[4.188rem] leading-[1.925rem] sm:leading-[4.606rem] tracking-[0.03em] text-center mb-5'>
                          //     CONNECT
                          //   </h2>
                          //   <p className="sm:font-['Manrope'] text-[1.063rem] leading-[1.375rem] sm:leading-[25px] tracking-[0.03em] text-[#E0DDFF] mb-[1.875rem] sm:mb-[2.343rem]">
                          //     Please connect your wallet to gain access
                          //     <br className="hidden sm:inline" /> to PEPE's GAME.
                          //   </p>
                          //   <div className="relative sm:mb-[3.688rem]">
                          //     {connectors.map((connector) => (
                          //       <div key={connector.id} className="z-10 relative">
                          //         <div className="sm:flex justify-center items-center gap-6 sm:mb-[1.587rem] barlow">
                          //           <img
                          //             src={connector.options?.logo}
                          //             alt={connector.name}
                          //             className="hidden sm:block w-[3.25rem]"
                          //           />
                          //           <button
                          //             className="relative connect-wallet-gradient-shadow flex justify-center min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[1.875rem] sm:mb-0 sm:leading-[1.563rem] sm:min-w-[12rem]"
                          //             disabled={!connector.ready}
                          //             onClick={() => {
                          //               connect({ connector });
                          //             }}
                          //           >
                          //             <img
                          //               src={connector.options?.logo}
                          //               alt={connector.name}
                          //               className="w-5 sm:w-0 sm:hidden"
                          //             />
                          //             <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                          //               {connector.name}
                          //             </span>
                          //             <span className="self-center">
                          //               <div
                          //                 role="status"
                          //                 className={
                          //                   isLoading && connector.id === pendingConnector?.id ? 'sm:ml-2' : 'hidden'
                          //                 }
                          //               >
                          //                 <LoaderIcon />
                          //                 <span className="sr-only">Loading...</span>
                          //               </div>
                          //             </span>
                          //           </button>
                          //         </div>
                          //       </div>
                          //     ))}
                          //   </div>
                          //   <div className="sm:font-['Manrope'] text-center text-[0.938rem] leading-[1.219rem] sm:leading-[1.537rem] tracking-[0.03em] text-[#E0DDFF] sm:text-[#E0DDFF]/60">
                          //     <p className="mb-[2px] sm:text-xl sm:font-semibold leading-[1.688rem]">
                          //       New to Arbitrum?
                          //     </p>
                          //     <p>
                          //       <a
                          //         className="underline"
                          //         href="https://arbitrum.io/bridge-tutorial/"
                          //         target="_blank"
                          //         rel="noreferrer"
                          //       >
                          //         Add to your Wallet
                          //       </a>{' '}
                          //       or{' '}
                          //       <a
                          //         className="underline"
                          //         href="https://arbitrum.io/bridge-tutorial/"
                          //         target="_blank"
                          //         rel="noreferrer"
                          //       >
                          //         Learn More
                          //       </a>
                          //       .
                          //     </p>
                          //   </div>
                          // </>
                        )} */}
                      </div>
                    </GradientBox>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
