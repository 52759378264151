export const ARBIcon = ({selected = false}) =>{
    return (
        <svg width="auto" height="80%" viewBox="0 0 86 87" fill="none" xmlns="http://www.w3.org/2000/svg">
        
        {selected ?(
            <g filter="url(#filter0_d_6439_196119)">
            <path d="M76.9531 51.4722C72.2787 70.2238 53.2837 81.6362 34.53 76.9618C15.7828 72.2852 4.37034 53.2903 9.04907 34.5409C13.7213 15.7851 32.7141 4.37257 51.4635 9.04699C70.2172 13.7214 81.6275 32.7185 76.9531 51.4722Z" fill="url(#paint0_linear_6439_196119)"/>
            </g>
        ):(
            <g filter="url(#filter0_d_5498_135476)">
            <path d="M76.9531 51.4808C72.2787 70.2323 53.2837 81.6448 34.53 76.9704C15.7828 72.2938 4.37034 53.2988 9.04907 34.5494C13.7213 15.7936 32.7141 4.38112 51.4635 9.05554C70.2172 13.73 81.6275 32.7271 76.9531 51.4808Z" fill="url(#selectedFilter)"/>
            </g>
            
        )}
     
        <path d="M43.1246 49.6854L49.4746 59.6482L55.3392 56.2508L47.0015 43.1101L43.1246 49.6854Z" fill="white"/>
        <path d="M60.6792 52.2542V49.5322L51.5641 35.3455L48.1824 41.0779L56.9761 55.3008L60.1577 53.4577C60.4693 53.2052 60.6595 52.8314 60.6792 52.4313V52.2542Z" fill="white"/>
        <path d="M22.5003 54.8215L26.9906 57.409L41.9375 33.4495L39.4021 33.3839C37.2406 33.3544 34.9151 33.9152 33.8458 35.6598L25.3572 48.807L22.5036 53.1949V54.8248H22.5003V54.8215Z" fill="white"/>
        <path d="M50.561 33.4463L43.8764 33.4692L28.7557 58.4224L34.043 61.4657L35.4796 59.0291L50.561 33.4463Z" fill="white"/>
        <path d="M63.4972 33.4135C63.4348 32.0132 62.687 30.7343 61.4996 29.9931L44.88 20.4369C43.6762 19.8466 42.2691 19.8466 41.0686 20.4369C40.9309 20.5057 24.9081 29.7963 24.9081 29.7963C24.6851 29.9046 24.4719 30.0292 24.2718 30.1735C23.2189 30.9245 22.5662 32.1149 22.5006 33.4037V53.1917L25.3542 48.8072L25.3312 33.5414C25.3575 33.1184 25.574 32.7281 25.9184 32.4789C25.9938 32.4232 42.2888 22.9948 42.3412 22.9686C42.7348 22.7751 43.1973 22.7751 43.5942 22.962L59.9974 32.3969C60.3844 32.6429 60.6272 33.0626 60.6501 33.5218V52.4342C60.6436 52.831 60.4632 53.2049 60.1614 53.4607L56.9798 55.3037L55.3398 56.2547L49.4752 59.6522L43.5286 63.0989C43.2006 63.2137 42.8398 63.2071 42.5151 63.0792L35.4795 59.0324L34.0429 61.469L40.3667 65.1092C40.5766 65.2272 40.7636 65.3322 40.9144 65.4207C41.1506 65.5519 41.3113 65.6437 41.3704 65.67C41.8984 65.9028 42.4724 66.0209 43.0497 66.0143C43.5811 66.0143 44.1059 65.9159 44.5979 65.7224L61.8736 55.7202C62.8608 54.9528 63.4545 53.7886 63.5004 52.5391V33.4135H63.4972Z" fill="white"/>
        <defs>
        <filter id="filter0_d_6439_196119" x="0.00012207" y="-0.000244141" width="86.0004" height="86.0093" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="4"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6439_196119"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6439_196119" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_6439_196119" x1="1.60416" y1="19.5555" x2="96.2695" y2="70.9536" gradientUnits="userSpaceOnUse">
        <stop offset="0.0714569" stop-color="#00B7F0"/>
        <stop offset="1" stop-color="#643EC6"/>
        </linearGradient>
        <linearGradient
          id="selectedFilter"
          x1="1.60403"
          y1="19.5557"
          x2="96.2694"
          y2="70.9538"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0714569" stop-color="#BEB7C9" />
          <stop offset="1" stop-color="#7A787C" />
        </linearGradient>
        </defs>
        </svg>

    )
}