import React, { useContext, useState, useEffect, useRef } from 'react';
import { BigNumber, ethers } from 'ethers';
import { twitter, discord, whitepaper, token, tokenRed, menu, arrow } from '../../assets';
import XIcon from '../../assets/icons/XIcon.svg';
import { Link } from 'react-router-dom';
import { StoreContext } from '../../utils/store';
import { useAccount, useSigner, erc20ABI } from 'wagmi';
import { pUSD_ADDRESS, USDC_ADDRESS } from '../../constants';
import DepositWithdrawFunds from '../../components/DepositWithdrawFunds/DepositWithdrawFunds';
import { pUSD_ABI } from '../../utils/pUSD-ABI';
import { useSnackbar } from 'notistack';
import '../../styles/top-bar.css';
import { SideMenu } from '../SideMenu';

const TopBar = ({ classes, red }) => {
  // const [toggleMenu, setToggleMenu] = useState(false);
  const store = useContext(StoreContext);
  const { setConnectModal, setDepositWithdrawModal, setSideMenu } = store;
  const [showButton, setShowButton] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { isConnected, address } = useAccount();
  const dropRef = useRef(null);
  //const { enqueueSnackbar } = useSnackbar();

  //const { data: signer } = useSigner();

  //const usdcContract = new ethers.Contract(USDC_ADDRESS, erc20ABI, signer);

  // const mintTestTokens = async () => {
  //   const pusdContract = new ethers.Contract(pUSD_ADDRESS, pUSD_ABI, signer);

  //   let estimatedGas;
  //   try {
  //     estimatedGas = await pusdContract.estimateGas.mint(1000e6);
  //     estimatedGas = estimatedGas.toNumber() + 1e4;
  //   } catch (e) {
  //     estimatedGas = 1e6;
  //   }
  //   try {
  //     await pusdContract.mint(1000e6, { gasLimit: estimatedGas });
  //     enqueueSnackbar('Minted 1000 pUSD', {
  //       variant: 'success',
  //     });
  //   } catch (e) {
  //     enqueueSnackbar('Failed to mint', {
  //       variant: 'error',
  //     });
  //   }
  // };

  useEffect(() => {
    if (dropdownOpen) {
      dropRef.current.classList.add('border-b-0');
      dropRef.current.classList.add('rounded-b-none');
      dropRef.current.classList.add('!border-[rgba(24,173,250,0.21)]');
    } else {
      dropRef.current.classList.remove('border-b-0');
      dropRef.current.classList.remove('rounded-b-none');
      dropRef.current.classList.remove('!border-[rgba(24,173,250,0.21)]');
    }
  }, [dropdownOpen]);

  useEffect(() => {
    const currentPage = window.location.pathname;
    if (currentPage === '/') {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, []);

  return (
    <>
      <div className={`flex justify-between w-full h-[50px] mt-2 ${classes}`}>
        <button className="p-4 min-w-[58.98px]" onClick={() => setSideMenu(true)}>
          <img src={menu} alt="" />
        </button>

        <div className="flex flex-row items-center justify-center gap-4">
          <div className="full-menu">
            <a href="https://twitter.com/PepesGame" target="_blank" rel="noreferrer">
              <img src={XIcon} className="w-[19px] h-[15px] socials" alt="twitter icon" />
            </a>
            <a href="https://discord.gg/pepesgame" target="_blank" rel="noreferrer">
              <img src={discord} className="w-[22px] h-[16px] socials" alt="discord icon" />
            </a>
            <a href="https://pepes-game.gitbook.io/pepes-docs" target="_blank" rel="noreferrer">
              <img src={whitepaper} className="w-[15px] h-[19px] socials" alt="whitepaper icon" />
            </a>
            {showButton && (
              <button
                onClick={() => setDepositWithdrawModal(true)}
                className={`manrope flex justify-center items-center w-[120px] h-[40px] border-2 border-sky-500 rounded-lg bg-deep-blue  hover:bg-gradient-to-r from-bluetint to-purpletint ${
                  red ? 'drop-red-shadow' : 'drop-shadow-3xl'
                }`}
              >
                <span className={`${red ? 'text-red' : 'text-[#1683BB]'}`}>Credits</span>
              </button>
            )}
            <div
              onMouseOver={() => {
                setDropdownOpen(true);
              }}
              onMouseLeave={() => {
                setDropdownOpen(false);
              }}
              className="relative inline-block"
            >
              <button
                ref={dropRef}
                // onClick={() => setDropdownOpen(!dropdownOpen)}
                className={`manrope p-2 flex justify-center items-center gap-1.5 flex-row w-[120px] h-[40px] border-2 border-sky-500 rounded-lg bg-deep-blue text-[#1683BB]  hover:bg-gradient-to-r from-bluetint to-purpletint ${
                  red ? 'drop-red-shadow' : 'drop-shadow-3xl'
                }`}
              >
                <img src={red ? tokenRed : token} className="w-[17px] h-[17px]" alt="" />
                <p className={` text-base manrope text-[#1683BB] font-bold ${red ? 'text-red' : 'text-white'}`}>PEG</p>
                <img src={arrow} className="w-[15px] h-[10px] -rotate-90" alt="" />
              </button>
              {dropdownOpen && (
                <div className="dropdown-content absolute bg-deep-blue z-[999] w-full manrope font-bold rounded-b-md border-2 border-[rgba(24,173,250,0.21)] text-center">
                  {/* remove coming soon tag for span below after logic has been implemented */}
                  <span
                    className="text-border-blue px-5 py-2 cursor-pointer no-underline block border-b-2 border-[rgba(24,173,250,0.21)] hover:bg-gradient-to-r from-bluetint to-purpletint coming-soon"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    Buy
                  </span>
                  {/* <Link
                    className="text-border-blue px-5 py-2 cursor-pointer no-underline block hover:bg-gradient-to-r border-b-2 border-[rgba(24,173,250,0.21)] from-bluetint to-purpletint"
                    to="/chart"
                  >
                    Chart
                  </Link> */}
                  <span
                    onClick={() => setDepositWithdrawModal(true)}
                    className="block px-5 py-2 no-underline cursor-pointer text-border-blue rounded-b-md hover:bg-gradient-to-r from-bluetint to-purpletint"
                  >
                    Credits
                  </span>
                  {/* <span
                    onClick={async () => {
                      await mintTestTokens();
                    }}
                    className="block px-5 py-2 no-underline cursor-pointer text-border-blue rounded-b-md hover:bg-gradient-to-r from-bluetint to-purpletint"
                  >
                    Mint pUSD
                  </span> */}
                </div>
              )}
            </div>
          </div>

          <button
            onClick={() => setConnectModal(true)}
            className={`manrope mr-4 px-2 flex justify-center items-center w-[120px] h-[40px] border-2 border-sky-500 rounded-lg bg-deep-blue ${
              isConnected ? '' : ' hover:bg-gradient-to-r from-bluetint to-purpletint '
            } ${red ? 'drop-red-shadow' : 'drop-shadow-3xl'}`}
          >
            <span className={`${red ? 'text-red' : 'text-[#1683BB]'} overflow-x-hidden text-ellipsis w-[110px]`}>
              {isConnected ? `${address.slice(0, 7)}...` : 'Connect'}
            </span>
          </button>
        </div>
      </div>
    </>
  );
};
export default TopBar;
