import { ethers } from 'ethers';
import { tokenPEPE, blue_usdc_coin, y2k, blueToken, ethCoin, buyUsdbc } from './assets';
import { usdcCoinLogo } from './assets';

const erc20_abi = [
  'function totalSupply() view returns (uint256)',
  'function decimals() view returns (uint8)',
  'function balanceOf(address account) view returns (uint256)',
  'function transfer(address recipient, uint256 amount) returns (bool)',
  'function allowance(address owner, address spender) view returns (uint256)',
  'function approve(address spender, uint256 amount) returns (bool)',
];

const chain_ids = [42161,8453]

const apr_tooltip_message = 'APR figure will populate once sufficient baseline data is available';

const tokens = [
  {
    name: 'USDC',
    contractAddress: '0xc2449F5f984ad13079dcD5ce806038642F3761Df',
    tokenAddres: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    formatUnits: 6,
    icon: blue_usdc_coin,
    apis: {
      prod: {
        history_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/history',
        prod_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod',
        prod_PEPE_API: 'https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
        trade_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/trade',
        SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
        leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
      },
      dev: {
        history_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/history',
        prod_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/',
        prod_PEPE_API: 'https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
        trade_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/trade',
        SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
        leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
      },
    },
    trade: true,
  },
  {
    name: 'esPEG',
    contractAddress: '0xc2449F5f984ad13079dcD5ce806038642F3761Df',
    tokenAddres: '0x4DA849bD6495a6552bF002fC9A5F9bba2a13BFDE',
    formatUnits: 18,
    icon: blueToken,
    apis: {
      prod: {
        history_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/history',
        prod_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod',
        prod_PEPE_API: 'https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
        trade_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/trade',
        SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
        leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
      },
      dev: {
        history_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/history',
        prod_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/',
        prod_PEPE_API: 'https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
        trade_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/trade',
        SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
        leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
      },
    },
    trade: false,
  },
  {
    name: 'PEG',
    contractAddress: '',
    tokenAddres: '0x4fc2A3Fb655847b7B72E19EAA2F10fDB5C2aDdbe',
    baseAddress: '0xc3ad7D4326A285fAF40521c10f2f91a40Ee4600c',
    formatUnits: 18,
    icon: blueToken,
    trade: false,
    appToken: true,
  },
  {
    name: 'USDbC',
    contractAddress: '',
    tokenAddres: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    formatUnits: 6,
    icon: usdcCoinLogo,
    trade: true,
    appToken: true,
  },
  {
    name: 'WETH',
    contractAddress: '',
    tokenAddres: '0x4200000000000000000000000000000000000006',
    formatUnits: 18,
    icon: ethCoin,
    trade: true,
    appToken: true,
  },
  {
    name: 'PEPE',
    contractAddress: '0x92a92C7B3694BE8CbbE435102De58C037Be19F9a',
    tokenAddres: '0xA54B8e178A49F8e5405A4d44Bb31F496e5564A05',
    formatUnits: 18,
    icon: tokenPEPE,
    apis: {
      prod: {
        history_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/history',
        prod_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod',
        prod_PEPE_API: 'https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
        trade_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/trade',
        SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
        leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
      },
      dev: {
        history_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/history',
        prod_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/',
        prod_PEPE_API: 'https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
        trade_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/trade',
        SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
        leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
      },
    },
    trade: true,
  },
  {
    name: 'Y2K',
    contractAddress: '0x92a92C7B3694BE8CbbE435102De58C037Be19F9a',
    tokenAddres: '0xA54B8e178A49F8e5405A4d44Bb31F496e5564A05',
    formatUnits: 18,
    icon: y2k,
    apis: {
      prod: {
        history_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/history',
        prod_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod',
        prod_PEPE_API: 'https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
        trade_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/trade',
        SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
        leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
      },
      dev: {
        history_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/history',
        prod_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/',
        prod_PEPE_API: 'https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
        trade_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/trade',
        SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
        leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
      },
    },
    trade: false,
  },
];

//new contract addresses
const pepeBetContractAddress = '0xc2449F5f984ad13079dcD5ce806038642F3761Df';
const pepeBetTokenContractAddress = '0x92a92C7B3694BE8CbbE435102De58C037Be19F9a';
const pUSD_ADDRESS = '0x89174058db6159146176204cbD24784EF038203c';
const USDC_ADDRESS = '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8';
const PEPE_ADDRESS = '0xA54B8e178A49F8e5405A4d44Bb31F496e5564A05';
const referralContract = '0xC65B2472127A98DF1cFC7e9DA9CD4BD6c82a9B33';
//new contract addresses

// //staking contract
// const pepeStakingContractAddress = '0xB46EB17840aA098C9D1895e761E934269fb92F8A';

// //degen staking contract
// const esPegStakingContractAddress = '0x2b5bb2057754390eac62a598a4f382d810f375d8';

// ///@dev: distribution contract for any token distribution event, including token claim.
// const tokenDistributorContractAddress = '0xd70AcE6185A1d678F55D90c65478aa7a5886D45a';

// ///@dev: distribution contract for any degen distribution event, including token claim.
// const degenTokenDistributorContractAddress = '0x0c921268669B98b41f292Ad9Afa3Fc5d305c0678';

// //locking contract
// const pepeLockingContractAddress = '0x97266FBA8da120dF196a7352bE1f01773CA30ce0';
// //locking contract

//peg contract address
const pegContractAddress = '0x4fc2A3Fb655847b7B72E19EAA2F10fDB5C2aDdbe';

// ///@dev contract address off esPeg token
// const esPegTokenAddress = '0x4DA849bD6495a6552bF002fC9A5F9bba2a13BFDE';

// ///@dev contract address off esPeg lock up
// const pepeEsPegLockUpContractAddress = '0x283bFFf80201aFd7bE82fF0b0c06e8d3Def6178B';

// ///@dev contract address off esPeg staking
// const pepeEsPegStakingContractAddress = '0x2b5bB2057754390EAC62A598a4f382d810F375d8';

// ///@dev token address off peg-weth
// const pepeProxyLPTokenAddress = '0x4FE1019f3D722490d91989a2cED9cacb168c4973';

// /// @dev pepeLockup extension contract address
// const pepeLockupExtensionContractAddress = '0x6E094EbFfb591f193DdcC4F268D2D01CbA9D117e';

// //fee taker wallet
// const feeDistributor = '0x09EFbDda4207a8c8dB062044B971D9b4292f3745';
// //pepepool
// const pepePool = '0x6ffDC600D7DbeD170cf9401Da6B3F73b9B7f2a8c';

//PEG/WETH
const poolId = '0x3efd3e18504dc213188ed2b694f886a305a6e5ed00020000000000000000041d';
//wrap eth wallet address
const WETH = '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1';

// pls autocompounder address
const plsAutoCompounderContractAddress = '0xBd470638c8Eb9f2FbE763575f02bDd9181403256';

// pls token address
const plsTokenAddress = '0x51318B7D00db7ACc4026C88c3952B66278B6A67F';

const balancerLPTokenAddress = '0x3eFd3E18504dC213188Ed2b694F886A305a6e5ed';
const balancerVaultAddress = '0xBA12222222228d8Ba445958a75a0704d566BF2C8';

/**
 * @dev - The pepe fee distributor contract address
 */
const pepeFeeDistributorV2Address = '0x7F05B179afeB61042407daB44F30ee662836F474';
/**
 * @dev - The address of off chain bet contract
 */
const offChainBetContractAddress = '0xE8768Ad7174046DdA3a564d1E4aB199d6E43c649';
/**
 * @dev - offchain contract for base chain
  */
const baseOffchainBetContract = '0x477321F5c48dF7a3E2358b400884898B0901bBF2'

/**
 * @dev conbining all the bet contracts on the various chains into one object
 */
const pepeChains = [
  {chainId:42161, name:'arbitrium', betContract:'0xE8768Ad7174046DdA3a564d1E4aB199d6E43c649'},
  {chainId:8453, name:'base', betContract:'0x477321F5c48dF7a3E2358b400884898B0901bBF2'}
]

////////////////////////////////////STAKE/LOCK CONTRACTS FOR DIFFERENT CHAINS////////////////////////////////////////////////

const baseContracts={
    //staking contract
    pepeStakingContractAddress : '0x6E79365e8a3cda3818C006A90CB877fC3B512580',
    //degen staking contract
    esPegStakingContractAddress : '0x2b5bb2057754390eac62a598a4f382d810f375d8',
    ///@dev: distribution contract for any token distribution event, including token claim.
    tokenDistributorContractAddress : '0xd70AcE6185A1d678F55D90c65478aa7a5886D45a',
    ///@dev: distribution contract for any degen distribution event, including token claim.
    degenTokenDistributorContractAddress : '0x0c921268669B98b41f292Ad9Afa3Fc5d305c0678',
    //locking contract
    pepeLockingContractAddress : '0x97266FBA8da120dF196a7352bE1f01773CA30ce0',
    //peg contract address
    pegContractAddress : '0xc3ad7D4326A285fAF40521c10f2f91a40Ee4600c',
    ///@dev contract address off esPeg token
    esPegTokenAddress : '0x4DA849bD6495a6552bF002fC9A5F9bba2a13BFDE',
    ///@dev contract address off esPeg lock up
    pepeEsPegLockUpContractAddress : '0x283bFFf80201aFd7bE82fF0b0c06e8d3Def6178B',
    ///@dev contract address off esPeg staking
    pepeEsPegStakingContractAddress : '0x2b5bB2057754390EAC62A598a4f382d810F375d8',
    ///@dev token address off peg-weth
    pepeProxyLPTokenAddress : '0x4FE1019f3D722490d91989a2cED9cacb168c4973',
    /// @dev pepeLockup extension contract address
    pepeLockupExtensionContractAddress : '0x6E094EbFfb591f193DdcC4F268D2D01CbA9D117e',
    //fee taker wallet
    feeDistributor : '0x09EFbDda4207a8c8dB062044B971D9b4292f3745',
    //pepepool
    pepePool : '0x6ffDC600D7DbeD170cf9401Da6B3F73b9B7f2a8c',
    //The pepe fee distributor contract address
    pepeFeeDistributorV2Address : '0x7F05B179afeB61042407daB44F30ee662836F474',
    //PEG/WETH
    poolId : '0x3efd3e18504dc213188ed2b694f886a305a6e5ed00020000000000000000041d',
    
}

const arbitriumContracts={
    //staking contract
  pepeStakingContractAddress : '0xB46EB17840aA098C9D1895e761E934269fb92F8A',
  //degen staking contract
  esPegStakingContractAddress : '0x2b5bb2057754390eac62a598a4f382d810f375d8',
  ///@dev: distribution contract for any token distribution event, including token claim.
  tokenDistributorContractAddress : '0xd70AcE6185A1d678F55D90c65478aa7a5886D45a',
  ///@dev: distribution contract for any degen distribution event, including token claim.
  degenTokenDistributorContractAddress : '0x0c921268669B98b41f292Ad9Afa3Fc5d305c0678',
  //locking contract
  pepeLockingContractAddress : '0x97266FBA8da120dF196a7352bE1f01773CA30ce0',
  //peg contract address
  pegContractAddress : '0x4fc2A3Fb655847b7B72E19EAA2F10fDB5C2aDdbe',
  ///@dev contract address off esPeg token
  esPegTokenAddress : '0x4DA849bD6495a6552bF002fC9A5F9bba2a13BFDE',
  ///@dev contract address off esPeg lock up
  pepeEsPegLockUpContractAddress : '0x283bFFf80201aFd7bE82fF0b0c06e8d3Def6178B',
  ///@dev contract address off esPeg staking
  pepeEsPegStakingContractAddress : '0x2b5bB2057754390EAC62A598a4f382d810F375d8',
  ///@dev token address off peg-weth
  pepeProxyLPTokenAddress : '0x4FE1019f3D722490d91989a2cED9cacb168c4973',
  /// @dev pepeLockup extension contract address
  pepeLockupExtensionContractAddress : '0x6E094EbFfb591f193DdcC4F268D2D01CbA9D117e',
  //fee taker wallet
  feeDistributor : '0x09EFbDda4207a8c8dB062044B971D9b4292f3745',
  //pepepool
  pepePool : '0x6ffDC600D7DbeD170cf9401Da6B3F73b9B7f2a8c',
  //The pepe fee distributor contract address
  pepeFeeDistributorV2Address : '0x7F05B179afeB61042407daB44F30ee662836F474',
  //PEG/WETH
  poolId : '0x3efd3e18504dc213188ed2b694f886a305a6e5ed00020000000000000000041d',
  

}

/////////////////////////////////END OF STAKE/LOCK  CONTRACTS DEF////////////////////////////////////////////////////////////

//peg/eth balancer pool tokens
const balancer_pool_tokens = [WETH, pegContractAddress];

const coingecko_api = 'https://api.coingecko.com/api/v3/simple/price';

const round2Address = [
  //"0xF37651A88B1f9C261C8832db4f9402Fad5CE6F12" test second round vesting
  '0x6fab90ab5a8ec8721781415231bb3d439a581148', //private raise round 2
  '0xd268168d1c3672d468d10518f95ce49b505cd6ef', //alpha testnet round 2
  '0x476cd2e5382922ffda338c565b4050c0ea128690', //beta spinners round 2
  '0x18ea96d0a19e2ca9c51df144a767734be4923987', //PEG-OURO round 2
  '0x3adc762d0b2b32d355542c1ba1a4dffd29655f7e', //LP loackers round 2
  '0x8d140a45b39e70d06554de49d022c9caf5f0262b', //beta review round 2
  '0x06bccee6c4e6660d832cfb7bac77fa45913aaf3c', //top 100 round 2
];

const unvestAllRoundsWithNames = [
  { address: ['0x3e4055e2aff66be709b2e55e6a409b3159127e81'], name: 'PEG-PRIV', label: 'Private Raise Airdrop' },
  {
    address: ['0x10933ae009961f34adb457e59fbbc7f8305cca62'],
    name: 'PEG-PUB-100',
    label: 'Top 100 Contributors Airdrop',
  },
  { address: ['0x9e97f7a09a286fba583be66a463a8b9ef8a06274'], name: 'PEG-ALFEED', label: 'Alpha Feedback Airdrop' },
  { address: ['0xf716ffde89c57c1e80bacfbb4f1be7b6785f0707'], name: 'PEG-BETAP', label: 'Beta Spinooors Airdrop' },
  { address: ['0x13326a25d266169c2ad67979b636a466d1f36d08'], name: 'PEG-OURO', label: 'Ouro Private Raise' },
  { address: ['0xae2782a2731677c8eb24a24a83c07f8c763c5bf2'], name: 'PEG-LPLOCK', label: 'LP Lockers Airdrop' },
  { address: ['0xb255186c04f23e5cf0c0ab35804b0bd84f4c06b6'], name: 'PEG-BETAR', label: 'Beta Reviewers Airdrop' },
];

const unvestPlsAllRoundsWithNames = [
  { address: ['0xE4A69b1b85a2525a4ebCfb3d4B6A3bA0323A112C'], name: 'PLS-PRIV', label: 'pls Assets airdrop' },
];

const esPLSClaimAddress = '0x1dC2068816E72F7bBbFBb92919EDcA7797D9ca15';
const esPEGAddress = '0x4DA849bD6495a6552bF002fC9A5F9bba2a13BFDE';
const esPEGStakingAddress = '0x2b5bB2057754390EAC62A598a4f382d810F375d8';

//unvest address
const unvestAddresses = [
  // "0xE4A69b1b85a2525a4ebCfb3d4B6A3bA0323A112C",
  '0x3e4055e2aff66be709b2e55e6a409b3159127e81',
  '0x10933ae009961f34adb457e59fbbc7f8305cca62',
  '0x9e97f7a09a286fba583be66a463a8b9ef8a06274',
  '0xf716ffde89c57c1e80bacfbb4f1be7b6785f0707',
  '0x13326a25d266169c2ad67979b636a466d1f36d08',
  '0xae2782a2731677c8eb24a24a83c07f8c763c5bf2',
  '0xb255186c04f23e5cf0c0ab35804b0bd84f4c06b6',
];

//unvest pls
const unvestPlsAddresses = ['0xE4A69b1b85a2525a4ebCfb3d4B6A3bA0323A112C'];

const top100ContractAddress = '0x10933ae009961f34adb457e59fbbc7f8305cca62';
const pegOuro = '0x13326a25d266169c2ad67979b636a466d1f36d08';

const unvestAddressesWithNames = [
  { address: '0x3e4055e2aff66be709b2e55e6a409b3159127e81', name: 'PEG-PRIV', label: 'Private Raise Airdrop' },
  { address: '0x10933ae009961f34adb457e59fbbc7f8305cca62', name: 'PEG-PUB-100', label: 'Top 100 Contributors Airdrop' },
  { address: '0x9e97f7a09a286fba583be66a463a8b9ef8a06274', name: 'PEG-ALFEED', label: 'Alpha Feedback Airdrop' },
  { address: '0xf716ffde89c57c1e80bacfbb4f1be7b6785f0707', name: 'PEG-BETAP', label: 'Beta Spinooors Airdrop' },
  { address: '0x13326a25d266169c2ad67979b636a466d1f36d08', name: 'PEG-OURO', label: 'Ouro Private Raise' },
  { address: '0xae2782a2731677c8eb24a24a83c07f8c763c5bf2', name: 'PEG-LPLOCK', label: 'LP Lockers Airdrop' },
  { address: '0xb255186c04f23e5cf0c0ab35804b0bd84f4c06b6', name: 'PEG-BETAR', label: 'Beta Reviewers Airdrop' },
];

const unvestPlsWithNames = [
  { address: '0xE4A69b1b85a2525a4ebCfb3d4B6A3bA0323A112C', name: 'PLS-PRIV', label: 'pls Assets airdrop' },
];

const twitter_api = 'https://r041c9t6dl.execute-api.us-west-2.amazonaws.com';

const addreferURL = 'https://fz4nbkbkad.execute-api.us-west-2.amazonaws.com/add?';
const checkrefreeURL = '	https://0huda0zugh.execute-api.us-west-2.amazonaws.com/check?';

const approvedAssets = {
  WETH,
  WBTC: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
  ARB: '0x912CE59144191C1204E64559FE8253a0e49E6548',
};

const network = {
  label: 'Arbitrum',
  router: '0x5ABFF8F8D5b13253dCAB1e427Fdb3305cA620119',
  explorer: 'https://arbiscan.io',
  rpc: 'https://arb1.arbitrum.io/rpc', // for walletconnect
  currencies: {
    weth: WETH,
    usdc: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    arb: '0x912CE59144191C1204E64559FE8253a0e49E6548',
  },
  poolInception: {
    weth: 1637154307000,
    usdc: 1637154307000,
    cap: 1637154307000,
  },
  cap: '0x031d35296154279DC1984dCD93E392b1f946737b',
};

//API Endpoints
const history_API = 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/history';
const prod_API = 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod';
const trade_API = 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/trade';

const SESSION_API = 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod';
const permission_API = 'https://4c7ygocnf1.execute-api.us-west-2.amazonaws.com/consent-function';
const referral_API = 'https://lwwh5yp881.execute-api.us-west-2.amazonaws.com/default';
const getQuest_API = 'https://4huqqpgjld.execute-api.us-west-2.amazonaws.com';
const autoSpin_API = 'https://ubfo4e2e7e.execute-api.us-west-2.amazonaws.com';
const emailAlert_API = 'https://zy3oz9itxj.execute-api.us-west-2.amazonaws.com';


/**
 * @dev - Notice, with multitoken support, might be a good idea to fetch approved tokens from the API
 */
const api = {
  prod: {
    history_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/history',
    prod_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod',
    prod_PEPE_API: 'https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
    trade_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/trade',
    SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
    leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
    degen_API: 'https://0wdy2peh64.execute-api.us-west-2.amazonaws.com/scores',
    share_api: 'https://yh65s3wni7.execute-api.us-west-2.amazonaws.com/default/',
  },
  dev: {
    history_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/history',
    prod_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/',
    prod_PEPE_API: 'https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
    trade_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/trade',
    SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
    leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
    degen_API: 'https://0wdy2peh64.execute-api.us-west-2.amazonaws.com/scores',
  },
  offChainBet: {
    degenWeekPointsApiEndpoint: "https://h5bsywit82.execute-api.us-west-2.amazonaws.com",
    depositApiEndpoint: "https://2lhzs6idjk.execute-api.us-west-2.amazonaws.com",
    depositWithdrawPoolEndpoint: "https://b4gnfrzo5k.execute-api.us-west-2.amazonaws.com",
    distributeWithdrawFeesEndpoint: "https://v0jryws4ya.execute-api.us-west-2.amazonaws.com",
    extendTimeEndpoint: "https://cgl0zwfdj9.execute-api.us-west-2.amazonaws.com",
    getBetEndpoint: "https://npq46grrv5.execute-api.us-west-2.amazonaws.com",
    getQuestV2ApiEndpoint: "https://ftycmqjwyd.execute-api.us-west-2.amazonaws.com",
    getQuestsDaysChipsApiEndpoint: "https://bsez227du9.execute-api.us-west-2.amazonaws.com",
    getReferralCodeEndpoint: "https://bk6m0zqx7e.execute-api.us-west-2.amazonaws.com",
    getUserBalanceEndpoint: "https://576pc3b6nd.execute-api.us-west-2.amazonaws.com",
    getUserHistoryEndpoint: "https://dnq0gaijl2.execute-api.us-west-2.amazonaws.com",
    getWrappedDegenEpochsApiEndpoint: "https://8gmptupqnd.execute-api.us-west-2.amazonaws.com",
    leaderBoardDataApiEndpoint: "https://x2hmhqlhwf.execute-api.us-west-2.amazonaws.com",
    //placeBetApiEndpoint: "https://vi35hnzddk.execute-api.us-west-2.amazonaws.com",
    placeBetApiEndpoint: "https://l5p7wchmi2.execute-api.us-west-2.amazonaws.com",
    referralDashboardDataApiEndpoint: "https://9rozgdcg7c.execute-api.us-west-2.amazonaws.com",
    updateQuestsDaysChipsApiEndpoint: "https://ogt82fn3d6.execute-api.us-west-2.amazonaws.com",
    updateWrappedDegenEpochsApiEndpoint: "https://vqe0pax1sc.execute-api.us-west-2.amazonaws.com",
    userDegenWeekPointsApiEndpoint: "https://4eylz0osbk.execute-api.us-west-2.amazonaws.com",
    withdrawApiEndpoint: "https://4h3ej3glsc.execute-api.us-west-2.amazonaws.com",

    addUpdateQuestApiEndpoint: "https://po9mukhaoj.execute-api.us-west-2.amazonaws.com",
    checkUserRefereeApiEndpoint: "https://palc19p89l.execute-api.us-west-2.amazonaws.com",
    getAutoSpinDataApiEndpoint: "https://ubojn5rlu1.execute-api.us-west-2.amazonaws.com",
    getBetQuestsApiEndpoint: "https://qdppijvoa8.execute-api.us-west-2.amazonaws.com",
    getMyReferrerApiEndpoint: "https://23uub5u7u0.execute-api.us-west-2.amazonaws.com",
    pepeSessionCheckApiEndpoint: "https://gw20iqtbr7.execute-api.us-west-2.amazonaws.com",
    pepeSessionNonceApiEndpoint: "https://gumv167wpd.execute-api.us-west-2.amazonaws.com",
    pepeSessionsApiEndpoint: "https://gk76pvc0b4.execute-api.us-west-2.amazonaws.com",
    placebetErrorEmailAlertsApiEndpoint: "https://jkr0t1n3fa.execute-api.us-west-2.amazonaws.com",
    addRefererApiEndpoint: "https://nd925ei5fg.execute-api.us-west-2.amazonaws.com",
    getBetConstraintsEndpoint: "https://esio4mi0r0.execute-api.us-west-2.amazonaws.com",
    getReferStatsApiEndpoint: "https://sgpjsbnegc.execute-api.us-west-2.amazonaws.com",

//////////////////////////// BETA-DEV ENDPOINTS ///////////////////////////////////////

    // getQuestsDaysChipsApiEndpoint: "https://zycm0awbpl.execute-api.us-west-2.amazonaws.com",
    // getWrappedDegenEpochsApiEndpoint: "https://nucp5k7az7.execute-api.us-west-2.amazonaws.com",
    // referralDashboardDataApiEndpoint: "https://09if4d289h.execute-api.us-west-2.amazonaws.com",
    // updateQuestsDaysChipsApiEndpoint: "https://idpbm2tyjl.execute-api.us-west-2.amazonaws.com",
    // updateWrappedDegenEpochsApiEndpoint: "https://xkm3qdp9n0.execute-api.us-west-2.amazonaws.com",
    // addRefererApiEndpoint: "https://j7ghhovz2g.execute-api.us-west-2.amazonaws.com",
    // addUpdateQuestApiEndpoint: "https://eb7fhg3vae.execute-api.us-west-2.amazonaws.com",
    // checkUserRefereeApiEndpoint: "https://cmgc48jrnl.execute-api.us-west-2.amazonaws.com",
    // degenWeekPointsApiEndpoint: "https://9pw6aaad8i.execute-api.us-west-2.amazonaws.com",
    // depositApiEndpoint: "https://i0mek5c0b9.execute-api.us-west-2.amazonaws.com",
    // depositWithdrawPoolEndpoint: "https://dh2ojlvv11.execute-api.us-west-2.amazonaws.com",
    // distributeWithdrawFeesEndpoint: "https://kwebewre45.execute-api.us-west-2.amazonaws.com",
    // dynamicShareApiEndpoint: "https://6aw58g6zc2.execute-api.us-west-2.amazonaws.com",
    // extendTimeEndpoint: "https://gvl7fsqi1e.execute-api.us-west-2.amazonaws.com",
    // getAutoSpinDataApiEndpoint: "https://j1ftd7c82j.execute-api.us-west-2.amazonaws.com",
    // getBetConstraintsEndpoint: "https://wtf9pvit2b.execute-api.us-west-2.amazonaws.com",
    // getBetEndpoint: "https://mp7vgqq86c.execute-api.us-west-2.amazonaws.com",
    // getBetQuestsApiEndpoint: "https://nvy5vqj6lk.execute-api.us-west-2.amazonaws.com",
    // getMyReferrerApiEndpoint: "https://jd9r3d1syf.execute-api.us-west-2.amazonaws.com",
    // getQuestV2ApiEndpoint: "https://q9h9rf308d.execute-api.us-west-2.amazonaws.com",
    // getReferStatsApiEndpoint: "https://ngsp6mstm9.execute-api.us-west-2.amazonaws.com",
    // getReferralCodeEndpoint: "https://p4qgtn53t1.execute-api.us-west-2.amazonaws.com",
    // getUserBalanceEndpoint: "https://242iaw7ft9.execute-api.us-west-2.amazonaws.com",
    // getUserHistoryEndpoint: "https://cnwrshzurb.execute-api.us-west-2.amazonaws.com",
    // leaderBoardDataApiEndpoint: "https://f2mmvx9a3h.execute-api.us-west-2.amazonaws.com",
    // pepeSessionCheckApiEndpoint: "https://9ftjcmwd00.execute-api.us-west-2.amazonaws.com",
    // pepeSessionNonceApiEndpoint: "https://jndw0bpu1b.execute-api.us-west-2.amazonaws.com",
    // pepeSessionsApiEndpoint: "https://l647i5ouxb.execute-api.us-west-2.amazonaws.com",
    // placeBetApiEndpoint: "https://4znyx9o3xc.execute-api.us-west-2.amazonaws.com",
    // placebetErrorEmailAlertsApiEndpoint: "https://0hitl7pw52.execute-api.us-west-2.amazonaws.com",
    // userDegenWeekPointsApiEndpoint: "https://gtvflgmub3.execute-api.us-west-2.amazonaws.com",
    // withdrawApiEndpoint: "https://xmpk8ffvac.execute-api.us-west-2.amazonaws.com",
  },
};

// urls pointing to pepe tokens
const pepeAPI = {
  prod: {
    history_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/history',
    prod_API: 'https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
    trade_API: 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/trade',
    SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
    leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
    degen_API: 'https://0wdy2peh64.execute-api.us-west-2.amazonaws.com/scores',
  },
  dev: {
    history_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/history',
    prod_API: ' https://g96447jye5.execute-api.us-west-2.amazonaws.com/Prod',
    trade_API: ' https://kngkl4unwa.execute-api.us-west-2.amazonaws.com/Prod/trade',
    SESSION_API: 'https://6ipqpvh6wi.execute-api.us-west-2.amazonaws.com/Prod',
    leader_API: 'https://5yzowlm75a.execute-api.us-west-2.amazonaws.com/getleaderboarddata',
    degen_API: 'https://0wdy2peh64.execute-api.us-west-2.amazonaws.com/scores',
  },
};

const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

//music imports
const LEVEL1 = 'https://roulette-audio.s3.us-west-2.amazonaws.com/music/level1.mp3';
const LEVEL2 = 'https://roulette-audio.s3.us-west-2.amazonaws.com/music/level2.mp3';
const LEVEL3 = 'https://roulette-audio.s3.us-west-2.amazonaws.com/music/level3.mp3';
const LEVEL4 = 'https://roulette-audio.s3.us-west-2.amazonaws.com/music/level4.mp3';
const WIN_LONG = 'https://roulette-audio.s3.us-west-2.amazonaws.com/music/Jackpot-Long.mp3';
const WIN_SHORT = 'https://roulette-audio.s3.us-west-2.amazonaws.com/music/Jackpot-Short.mp3';

const BET1 = 'https://roulette-audio.s3.us-west-2.amazonaws.com/sfx/Bet+1.wav';
const BET2 = 'https://roulette-audio.s3.us-west-2.amazonaws.com/sfx/Bet+2.wav';
const BET3 = 'https://roulette-audio.s3.us-west-2.amazonaws.com/sfx/Bet+3.wav';
const SPIN = 'https://roulette-audio.s3.us-west-2.amazonaws.com/sfx/Press+SPIN+-+Rollers+starting.wav';
const ROCKET = 'https://roulette-audio.s3.us-west-2.amazonaws.com/sfx/Rocket+starting.wav';
const WIN_TRADE = 'https://roulette-audio.s3.us-west-2.amazonaws.com/sfx/Win+Screen+message.wav';
const LOSE_TRADE = 'https://roulette-audio.s3.us-west-2.amazonaws.com/sfx/Pepes_Game_FX_-_Losing_Vibe_2.mp3';

// sentry io
const sentryUrl = '@o939166.ingest.sentry.io/4505198682439680';

const metamaskChains = [
  {
    chainId: ethers.utils.hexlify(42161),
    chainName: 'arbitrium',
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  {
    chainId: ethers.utils.hexlify(8453),
    chainName: 'base',
    rpcUrls: ['https://mainnet.base.org'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://basescan.org'],
  },
];

export {
  pepeBetContractAddress,
  approvedAssets,
  network,
  USDC_ADDRESS,
  pUSD_ADDRESS,
  erc20_abi,
  // esPegTokenAddress,
  // pepeStakingContractAddress,
  // pepeLockingContractAddress,
  // tokenDistributorContractAddress,
  // pegContractAddress,
  // feeDistributor,
  // pepePool,
  poolId,
  WETH,
  balancer_pool_tokens,
  coingecko_api,
  history_API,
  prod_API,
  trade_API,
  SESSION_API,
  permission_API,
  api,
  unvestAddresses,
  unvestAddressesWithNames,
  top100ContractAddress,
  pegOuro,
  addreferURL,
  LEVEL1,
  LEVEL2,
  LEVEL3,
  LEVEL4,
  WIN_LONG,
  WIN_SHORT,
  BET1,
  BET2,
  BET3,
  SPIN,
  ROCKET,
  WIN_TRADE,
  LOSE_TRADE,
  PEPE_ADDRESS,
  pepeAPI,
  pepeBetTokenContractAddress,
  tokens,
  round2Address,
  unvestPlsAddresses,
  unvestPlsWithNames,
  sentryUrl,
  referralContract,
  checkrefreeURL,
  twitter_api,
  // esPegStakingContractAddress,
  // degenTokenDistributorContractAddress,
  // pepeEsPegLockUpContractAddress,
  // pepeEsPegStakingContractAddress,
  // pepeProxyLPTokenAddress,
  balancerVaultAddress,
  balancerLPTokenAddress,
  // pepeLockupExtensionContractAddress,
  apr_tooltip_message,
  unvestAllRoundsWithNames,
  unvestPlsAllRoundsWithNames,
  plsAutoCompounderContractAddress,
  plsTokenAddress,
  referral_API,
  offChainBetContractAddress,
  pepeFeeDistributorV2Address,
  getQuest_API,
  autoSpin_API,
  emailAlert_API,
  months,
  chain_ids,
  baseOffchainBetContract,
  pepeChains,
  metamaskChains,
  arbitriumContracts,
  baseContracts
};
