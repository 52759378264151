import React, { useCallback, useContext, useEffect, useState } from 'react';
import { blueToken } from '../../assets';
import up from '../../assets/images/dropDownArrow.svg';
import { ethers } from 'ethers';
import { StoreContext } from '../../utils/store';
import { useMediaQuery } from 'usehooks-ts';
import Tooltip from '@mui/material/Tooltip';
import { apr_tooltip_message } from '../../constants';
import { observer } from 'mobx-react-lite';

const StakeCard = ({ isPEG, isActive, stake, stakeID, totalSupply, stakeAmount, rewards, handleClaimEsPegRewards }) => {
  const store = useContext(StoreContext);
  const { setStakeModal, setCurrentStakeTab, setIsEsPeg, setEsPegStakeID } = store;
  const [isStaticActive, setIsStaticActive] = useState(false);
  const [totalStaked, setTotalStaked] = useState(0.0);
  const [totalSupplyV, setTotalSupply] = useState(0.0);
  const [myStake, setMyStake] = useState(0.0);
  const [pegVested, setPegVested] = useState(0.0);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [currentDays, setCurrentDays] = useState(0);
  const [claimablePeg, setClaimablePeg] = useState('')
  const [refresh, refreshData] = useState(false);

  // const [example, setExample] = useState(0)
  // var example = 3


  const initiateStakeData = useCallback(async () => {
    setTotalStaked(parseFloat(parseFloat(ethers.utils.formatUnits(stake.totalStaked, 18)).toFixed(2)).toLocaleString());
    console.log('supply', totalSupply);
    if (totalSupply) {
      setTotalSupply(parseFloat(parseFloat(ethers.utils.formatUnits(totalSupply, 18)).toFixed(2)).toLocaleString());
    }
    setMyStake(parseFloat(ethers.utils.formatUnits(stake.stakeData.amount, 18)).toFixed(2));
    // setPegVested(!isPEG && parseFloat(ethers.utils.formatUnits(stake.stakeData.amountClaimed, 18)).toFixed(2));
    setNumberOfDays(dateConversion(stake.stakeData.startTime, stake.stakeData.fullVestingTime));
    setCurrentDays(getDaysFromStart(stake.stakeData.startTime));
  }, [isPEG]);

  const handleClaimablePeg = () => {
    const currentTime = new Date();
    const currentTime_Unix = Math.floor(currentTime.getTime() / 1000);
    //  const timeCal = currentTime_Unix - stake.stakeData.lastClaimTime / 1000;
    const timeCal = currentTime_Unix - stake?.stakeData?.lastClaimTime;
    const initCal = timeCal * ethers.utils.formatUnits(stake?.stakeData?.pegPerSecond, 18);

    //  return initCal;
    // console.log({ isPEG, initCal, timeCal, perPegSec: ethers.utils.formatUnits(stake.stakeData.pegPerSecond, 18) });

    setClaimablePeg(parseFloat(initCal).toFixed(2));
  }

  useEffect(() => {
    if (stake) {
      initiateStakeData();
      handleClaimablePeg();
      if (!isPEG) handleVestedPeg();
    }
  }, [stake, initiateStakeData]);

  useEffect(() => {
    if(stake){
      handleClaimablePeg()
    }
    
    console.log('refreshed')
  }, [refresh])

  const dateConversion = (start, endDate) => {
    const timestampMs = endDate * 1000;
    const startDate = new Date(start * 1000);
    const timeDifferenceMs = timestampMs - startDate;
    const days = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
    return days;
  };

  const getDaysFromStart = (startTimestamp) => {
    const startTimestampMs = startTimestamp * 1000;
    const currentTimestampMs = Date.now();
    const timeDifferenceMs = currentTimestampMs - startTimestampMs;
    const days = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
    return days;
  };

  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getVestedDate = (timestamp) => {
    console.log('vested date::', timestamp)
    const millisecondsIn90Days = 90 * 24 * 60 * 60
    return timestamp + millisecondsIn90Days
  }

  const isMobile = useMediaQuery('(max-width: 640px)');

  useEffect(() => {
    console.log({ stake, isPEG, isEsPeg: !isPEG })
  }, [isPEG, stake])

  const handleVestedPeg = () => {
    console.log('Called handleVestedPeg');
    const currentTime = new Date();
    const currentTime_Unix = Math.floor(currentTime.getTime() / 1000);
    const timeCal = currentTime_Unix - stake.stakeData.startTime;
    const initCal = timeCal * ethers.utils.formatUnits(stake.stakeData.pegPerSecond, 18);
    const vestedPeg = parseFloat(initCal).toFixed(2);
    setPegVested(vestedPeg);
  }

  return (
    <>
      {isPEG ? (
        isMobile ? (
          <div
            className={`${isStaticActive && (stakeAmount || rewards) && 'border-border-blue border-[0.5px] rounded-md'
              } text-center`}
          >
            <tbody className="text-center text-[#FFFFFF66]">
              <tr className={`bg-[#09031D3D] cursor-pointer `} onClick={() => setIsStaticActive(!isStaticActive)}>
                <td className="py-5 w-[150px] text-white/40 barlow font-normal text-base leading-4">
                  {stakeAmount || '0'}
                </td>
                <td className="py-5 w-[150px] text-white/40 barlow font-normal text-base leading-4">
                  {rewards || '0'}
                </td>
              </tr>
              {/* active state */}
              {isStaticActive && (stakeAmount || rewards) && (
                <tr className="bg-[#FFFFFF0D]" style={{ borderTop: 'none' }}>
                  <td colSpan={7}>
                    <div className="pt-2.5 w-full">
                      <div className=" mx-[3.75%]">
                        <table className="justify-between w-full p-2 bg-transparent ">
                          <tbody className="text-[rgba(255, 255, 255, 0.4)] text-sm leading-[18px]">
                            <tr>
                              <th className="pb-5 text-sm font-normal text-left barlow">PEG Staked</th>
                              <th className="pb-5 text-sm font-normal text-right barlow">{stakeAmount}</th>
                            </tr>
                            {/* <tr >
                              <th className="pb-5 text-sm font-normal text-left barlow">Start Date</th>
                              <th className="pb-5 text-sm font-normal text-right barlow">n/a</th>
                            </tr>
                            <tr>
                              <th className="pb-5 text-sm font-normal text-left barlow">Last added</th>
                              <th className="pb-5 text-sm font-normal text-right barlow">n/a</th>
                            </tr>
                            <tr>
                              <th className="pb-5 text-sm font-normal text-left barlow">USDC claimed</th>
                              <th className="pb-5 text-sm font-normal text-right barlow">n/a</th>
                            </tr> */}
                            <tr>
                              <th className="text-sm font-normal text-left text-white barlow">Claimable USDC</th>
                              <th className="text-sm font-normal text-right text-white barlow">{rewards}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        className="flex flex-nowrap justify-evenly w-full mx-auto mt-5 mb-[12px] pt-[12px] px-[7px] text-sm text-white barlow gap-[12px] "
                        style={{ borderTop: 'solid 0.5px #18ADFA' }}
                      >
                        <button
                          className="bg-[#FFFFFF1A] py-[5px] w-[62px] text-white rounded-md"
                          onClick={() => {
                            setIsEsPeg(false);
                            setStakeModal(true);
                            setCurrentStakeTab('stake');
                          }}
                        >
                          Stake
                        </button>
                        <button
                          className="bg-[#FFFFFF1A] py-[5px] w-[62px] text-white rounded-md"
                          onClick={() => {
                            setIsEsPeg(false);
                            setStakeModal(true);
                            setCurrentStakeTab('unstake');
                          }}
                        >
                          Unstake
                        </button>
                        <button
                          className="bg-[#FFFFFF1A] py-[5px] w-[62px] text-white rounded-md"
                          onClick={() => {
                            // setIsEsPeg(false);
                            // setStakeModal(true);
                            // setCurrentStakeTab('claim');
                          }}
                        >
                          Claim
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </div>
        ) : (
          <tbody className="text-center text-[#FFFFFF66]">
            <tr className="bg-[#09031D3D] cursor-pointer" onClick={() => setIsStaticActive(!isStaticActive)}>
              <td className="w-1/5 py-5 text-base font-normal leading-4 text-white/40 barlow">
                <img className="inline-block w-4 h-4 mr-[7px]" src={blueToken} alt="pepe token icon" />
                PEG
              </td>
              <td className="w-1/5 py-5 text-base font-normal leading-4 text-white/40 barlow">
                <Tooltip title={apr_tooltip_message} placement="bottom" arrow>
                  <span>TBD</span>
                </Tooltip>
              </td>
              <td className="w-1/5 py-5 text-base font-normal leading-4 text-white/40 barlow">{stakeAmount}</td>
              <td className="w-1/5 py-5 text-base font-normal leading-4 text-white/40 barlow">{rewards}</td>

              <td className="py-5 pr-4 w-[10%] text-white barlow font-normal text-base leading-4">
                <img
                  src={up}
                  className={`${isStaticActive && (stakeAmount || rewards) ? 'rotate-0' : 'rotate-180'
                    } transition-all mx-auto`}
                  alt="arrow icon"
                />
              </td>
            </tr>
            {/* active state */}
            {isStaticActive && (stakeAmount || rewards) ? (
              <tr className="bg-[#09031D3D]">
                <td colSpan={7}>
                  <div className="pt-2.5 w-full mb-[30px]">
                    <div className="stake-box-shadow mx-[3.75%] border-border-blue bg-white/[0.05] border-[0.5px] h-auto rounded-md p-5">
                      <table className="justify-between w-full p-2 bg-transparent ">
                        <tbody className="text-[rgba(255, 255, 255, 0.4)] text-sm leading-[18px]">
                          <tr>
                            <th className="pb-5 text-sm font-normal text-left barlow">PEG Staked</th>
                            <th className="pb-5 text-sm font-normal text-right barlow">{stakeAmount}</th>
                          </tr>
                          {/* <tr>
                            <th className="pb-5 text-sm font-normal text-left barlow">Start Date</th>
                            <th className="pb-5 text-sm font-normal text-right barlow">n/a</th>
                          </tr> */}
                          {/* <tr>
                            <th className="pb-5 text-sm font-normal text-left barlow">Last added</th>
                            <th className="pb-5 text-sm font-normal text-right barlow">n/a</th>
                          </tr> */}
                          {/* <tr>
                            <th className="pb-5 text-sm font-normal text-left barlow">USDC Rewards claimed</th>
                            <th className="pb-5 text-sm font-normal text-right barlow">n/a</th>
                          </tr> */}
                          <tr>
                            <th className="text-sm font-normal text-left text-white barlow">Claimable USDC Rewards</th>
                            <th className="text-sm font-normal text-right text-white barlow">{rewards}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              <></>
            )}
          </tbody>
        )
      ) : isMobile ? (
        <>
          <div className={`${isStaticActive && 'border-border-blue border-[0.5px] rounded-md'}`}>
            <tr
              className={`bg-[#09031D3D] cursor-pointer`}
              style={{ borderBottom: 'none' }}
              onClick={() => setIsStaticActive(!isStaticActive)}
            >
              <td className="py-5 w-[150px] text-white/40 barlow font-normal text-base leading-4">{myStake}</td>
              <td className="py-5 w-[150px] text-white/40 barlow font-normal text-base leading-4">
                {/* {currentDays}/{numberOfDays} */}
                {numberOfDays - currentDays < 0 ? '0' : numberOfDays - currentDays}
              </td>
              <td className="py-5 w-[150px] text-white/40 barlow font-normal text-base leading-4">{pegVested}</td>
            </tr>
            {/* active state */} 
            {isStaticActive && (
              <tr className=" bg-[#FFFFFF0D]">
                <td colSpan={7}>
                  <div className="pt-2.5 w-full">
                    <div className="mx-[3.75%]">
                      <table className="justify-between w-full p-2 bg-transparent ">
                        <tbody className="text-[rgba(255, 255, 255, 0.4)] text-sm leading-[18px]">
                          <tr>
                            <th className="pb-5 text-sm font-normal text-left barlow">esPEG Staked</th>
                            <th className="pb-5 text-sm font-normal text-right barlow">{myStake}</th>
                          </tr>
                          <tr>
                            <th className="pb-5 text-sm font-normal text-left barlow">Start Date</th>
                            <th className="pb-5 text-sm font-normal text-right barlow">
                              {convertTimestampToDate(stake.stakeData.startTime)}
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-5 text-sm font-normal text-left barlow">Vested Date</th>
                            <th className="pb-5 text-sm font-normal text-right barlow">
                              {convertTimestampToDate(getVestedDate(stake.stakeData.startTime))}
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-5 text-sm font-normal text-left barlow">PEG Claimed</th>
                            <th className="pb-5 text-sm font-normal text-right barlow">
                              {parseFloat(ethers.utils.formatUnits(stake.stakeData.amountClaimed, 18)).toFixed(2)}
                            </th>
                          </tr>
                          <tr>
                            <th className="text-sm font-normal text-left text-white barlow">Claimable PEG</th>
                            <th className="text-sm font-normal text-right text-white barlow">
                              {/* {parseFloat(ethers.utils.formatUnits(stake.stakeData.amountClaimable, 18)).toFixed(2)} */}
                              {claimablePeg}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div
                      className="flex flex-nowrap justify-between w-full mx-auto mt-5 mb-[12px] pt-[12px] px-[7px] font-normal text-sm barlow gap-[12px] "
                      style={{ borderTop: 'solid 0.5px #18ADFA' }}
                    >
                      {/* <button
                        className="bg-[#FFFFFF1A] py-[5px] w-[62px] text-white rounded-md"
                        onClick={() => {
                          setIsEsPeg(true);
                          setStakeModal(true);
                          setCurrentStakeTab('stake');
                          setEsPegStakeID(stakeID);
                        }}
                      >
                        Stake
                      </button>
                      <button
                        className="bg-[#FFFFFF1A] py-[5px] w-[62px] text-white rounded-md"
                        onClick={() => {
                          setIsEsPeg(true);
                          setStakeModal(true);
                          setCurrentStakeTab('unstake');
                          setEsPegStakeID(stakeID);
                        }}
                      >
                        Unstake
                      </button> */}
                      <button
                        className="bg-[#FFFFFF1A] py-[5px] w-[62px] text-white rounded-md"
                        onClick={async() => {
                          // setIsEsPeg(true);
                          // setStakeModal(true);
                          // setCurrentStakeTab('claim');
                          // setEsPegStakeID(stakeID);
                          await handleClaimEsPegRewards(stakeID);
                          refreshData(!refresh)
                        }}
                      >
                        Claim
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </div>
        </>
      ) : (
        <tbody className="text-center text-[#FFFFFF66]">
          <tr
            className="bg-[#09031D3D] border-b-2 border-transparent cursor-pointer"
            onClick={() => setIsStaticActive(!isStaticActive)}
          >
            <td className="py-5 text-base font-normal leading-4 text-white/40 barlow">
              <img className="inline-block w-4 h-4 mr-[7px]" src={blueToken} alt="pepe token icon" />
              esPEG
            </td>
            <td className="py-5 text-base font-normal leading-4 text-white/40 barlow">{totalStaked}</td>
            <td className="py-5 text-base font-normal leading-4 text-white/40 barlow">{totalSupplyV}</td>
            <td className="py-5 text-base font-normal leading-4 text-white/40 barlow">
              {/* {currentDays}/{numberOfDays} */}
              {numberOfDays - currentDays < 0 ? '0' : numberOfDays - currentDays}
            </td>
            <td className="py-5 text-base font-normal leading-4 text-white/40 barlow">{myStake}</td>
            <td className="py-5 text-base font-normal leading-4 text-white/40 barlow">{pegVested}</td>
            <td className="py-5 pr-2 text-base font-normal leading-4 text-white barlow">
              <img
                src={up}
                className={`${isStaticActive ? 'rotate-0' : 'rotate-180'} transition-all mx-auto`}
                alt="arrow icon"
              />
            </td>
          </tr>
          {/* active state */}
          {isStaticActive && (
            <tr className="bg-[#09031D3D]">
              <td colSpan={7}>
                <div className="pt-2.5 w-full">
                  <div className="stake-box-shadow mx-[3.75%] border-border-blue bg-white/[0.05] border-[0.5px] h-[224px] rounded-md p-5">
                    <table className="justify-between w-full p-2 bg-transparent ">
                      <tbody className="text-[rgba(255, 255, 255, 0.4)] text-sm leading-[18px]">
                        <tr>
                          <th className="pb-5 text-sm font-normal text-left barlow">esPEG Staked</th>
                          <th className="pb-5 text-sm font-normal text-right barlow">{myStake}</th>
                        </tr>
                        <tr>
                          <th className="pb-5 text-sm font-normal text-left barlow">Start Date</th>
                          <th className="pb-5 text-sm font-normal text-right barlow">
                            {convertTimestampToDate(stake.stakeData.startTime)}
                          </th>
                        </tr>
                        <tr>
                          <th className="pb-5 text-sm font-normal text-left barlow">Vested Date</th>
                          <th className="pb-5 text-sm font-normal text-right barlow">
                            {convertTimestampToDate(getVestedDate(stake.stakeData.startTime))}
                          </th>
                        </tr>
                        <tr>
                          <th className="pb-5 text-sm font-normal text-left barlow">PEG Rewards Claimed</th>
                          <th className="pb-5 text-sm font-normal text-right barlow">
                            {parseFloat(ethers.utils.formatUnits(stake.stakeData.amountClaimed, 18)).toFixed(2)}
                          </th>
                        </tr>
                        <tr>
                          <th className="text-sm font-normal text-left text-white barlow">Claimable PEG Rewards</th>
                          <th className="text-sm font-normal text-right text-white barlow">
                            {/* {parseFloat(ethers.utils.formatUnits(stake.stakeData.amountClaimable, 18)).toFixed(2)} */}
                            {claimablePeg}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="space-x-[5px] float-right mr-8 mx-auto mt-5 pb-[30px] flex">
                    <button
                      className="flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:py-[3px] sm:px-[50px] flex items-center justify-center bg-white/[0.05] text-white"
                      onClick={ async()=> {
                        // setIsEsPeg(true);
                        // setStakeModal(true);
                        // setCurrentStakeTab('claim');
                        // console.log('claim ID::', stakeID)
                        await handleClaimEsPegRewards(stakeID);
                        refreshData(!refresh)
                      }}
                    >
                      Claim
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      )}
    </>
  );
};

export default (observer(StakeCard));
