import React from 'react';

// variants = inactive | ongoing | warning
const NewQuestStateIcon = ({ variant = 'inactive' }) => {
  const inactive = (
    <>
      <path
        d="M16.939 17.5864C16.898 17.5187 16.8387 17.4625 16.7669 17.4233C16.6952 17.3842 16.6136 17.3635 16.5306 17.3635H10.3816L11.4125 9.98817C11.4236 9.88946 11.3989 9.79014 11.3423 9.7062C11.2858 9.62226 11.2007 9.55857 11.1008 9.52541C11.0009 9.49224 10.892 9.49153 10.7916 9.52337C10.6912 9.55521 10.6051 9.61776 10.5473 9.70095L3.06953 21.968C3.0258 22.0346 3.00183 22.1109 3.0001 22.1891C2.99837 22.2672 3.01893 22.3444 3.05967 22.4126C3.1004 22.4809 3.15984 22.5377 3.23185 22.5773C3.30386 22.6168 3.38584 22.6377 3.46933 22.6377H9.52642L8.70945 30.0242C8.70142 30.1225 8.72876 30.2206 8.78711 30.3027C8.84546 30.3849 8.93147 30.4464 9.03146 30.4775C9.13145 30.5086 9.23968 30.5074 9.33891 30.4743C9.43815 30.4412 9.5227 30.3779 9.5791 30.2946L16.9333 18.0289C16.976 17.9622 16.999 17.8861 17 17.8083C17.001 17.7306 16.9799 17.654 16.939 17.5864Z"
        fill="url(#paint0_linear_287_10411)"
      />
    </>
  );

  const warning = (
    <>
      <path
        d="M6.26317 22.5792C6.14517 22.5792 6.04403 22.5455 5.95974 22.478C5.89231 22.4106 5.8586 22.3179 5.8586 22.1999L5.42874 11.5293C5.42874 11.2765 5.54674 11.15 5.78274 11.15H9.77788C10.0139 11.15 10.1319 11.2765 10.1319 11.5293L9.67674 22.1999C9.67674 22.3179 9.6346 22.4106 9.55031 22.478C9.48288 22.5455 9.39017 22.5792 9.27217 22.5792H6.26317ZM7.57803 28.85C6.88689 28.85 6.31374 28.6309 5.8586 28.1926C5.42031 27.7375 5.20117 27.1643 5.20117 26.4732C5.20117 25.7652 5.42031 25.192 5.8586 24.7537C6.31374 24.3155 6.88689 24.0963 7.57803 24.0963C8.26917 24.0963 8.83388 24.3155 9.27217 24.7537C9.72731 25.192 9.95488 25.7652 9.95488 26.4732C9.95488 27.1475 9.72731 27.7122 9.27217 28.1673C8.83388 28.6225 8.26917 28.85 7.57803 28.85Z"
        fill="url(#paint0_linear_287_10416)"
      />
    </>
  );

  const ongoing = (
    <>
      <path
        d="M8.58422 29.1459C8.38724 29.3314 8.11864 29.4349 7.8395 29.4349C7.56036 29.4349 7.29175 29.3314 7.09478 29.1459L1.14752 23.5762C0.530253 22.9982 0.530253 22.0612 1.14752 21.4842L1.89224 20.7869C2.50951 20.2089 3.50915 20.2089 4.12641 20.7869L7.8395 24.2637L17.8727 14.869C18.49 14.291 19.4907 14.291 20.1069 14.869L20.8516 15.5663C21.4689 16.1443 21.4689 17.0813 20.8516 17.6583L8.58422 29.1459Z"
        fill="url(#paint0_linear_287_10413)"
      />
      <defs></defs>
    </>
  );
  return (
    <svg width="20" height="auto" viewBox="0 0 20 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g className={variant === 'inactive' ? '' : 'hidden'}>{inactive}</g>
      <g className={variant === 'ongoing' ? '' : 'hidden'}>{ongoing}</g>
      <g className={variant === 'warning' ? '' : 'hidden'}>{warning}</g>

      <defs>
        <linearGradient
          id="paint0_linear_287_10413"
          x1="11.0453"
          y1="15.834"
          x2="6.27123"
          y2="23.9132"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.166667" stop-color="#FFC73E" />
          <stop offset="1" stop-color="#643093" />
        </linearGradient>

        <linearGradient
          id="paint0_linear_287_10416"
          x1="7.67745"
          y1="12.8004"
          x2="3.73214"
          y2="14.1527"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.166667" stop-color="#FFC73E" />
          <stop offset="1" stop-color="#643093" />
        </linearGradient>

        <linearGradient
          id="paint0_linear_287_10411"
          x1="10.031"
          y1="11.458"
          x2="2.54793"
          y2="17.5963"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.166667" stop-color="#FFC73E" />
          <stop offset="1" stop-color="#643093" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NewQuestStateIcon;
