import React from 'react';

const DiscordIcon = () => {
  return (
    <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M20.7324 1.82527C20.7324 1.82527 20.7324 1.82527 20.6922 1.82527C19.1621 1.10188 17.5514 0.619616 15.9005 0.338296C15.8602 0.298108 15.82 0.338296 15.82 0.338296C15.5784 0.740182 15.3771 1.18226 15.216 1.58414C13.404 1.30282 11.592 1.30282 9.78006 1.58414C9.61899 1.18226 9.41766 0.740182 9.17607 0.338296C9.17607 0.338296 9.1358 0.298108 9.09553 0.338296C7.44462 0.619616 5.83398 1.10188 4.30387 1.82527C4.2636 1.82527 4.2636 1.82527 4.2636 1.82527C1.20338 6.40677 0.357788 10.8677 0.76045 15.2884C0.76045 15.2884 0.760448 15.3286 0.800715 15.3286C2.57242 16.6548 4.58573 17.6596 6.71983 18.3026C6.71983 18.3428 6.7601 18.3026 6.80037 18.3026C7.2433 17.6596 7.64596 17.0165 8.00835 16.3333C8.00835 16.2931 8.00835 16.253 7.96808 16.2128C7.32383 15.9716 6.67957 15.6903 6.11584 15.3286C6.07558 15.3286 6.03531 15.2482 6.11584 15.2081C6.23664 15.1277 6.35744 15.0473 6.47824 14.9267C6.47824 14.9267 6.5185 14.9267 6.55877 14.9267C10.4243 16.695 14.612 16.695 18.4373 14.9267C18.4775 14.9267 18.4775 14.9267 18.5178 14.9267C18.6386 15.0473 18.7594 15.1277 18.8802 15.2081C18.9205 15.2482 18.9205 15.3286 18.8802 15.3286C18.2762 15.6903 17.6722 15.9716 17.028 16.2128C16.9877 16.253 16.9877 16.2931 16.9877 16.3333C17.3501 17.0165 17.7528 17.6596 18.1957 18.3026C18.2359 18.3026 18.2359 18.3428 18.2762 18.3026C20.4103 17.6596 22.4236 16.6548 24.1953 15.3286C24.2356 15.3286 24.2356 15.2884 24.2356 15.2884C24.7188 10.1845 23.39 5.76375 20.7324 1.82527ZM8.57208 12.5958C7.40436 12.5958 6.43797 11.5107 6.43797 10.2247C6.43797 8.89845 7.40436 7.85355 8.57208 7.85355C9.78006 7.85355 10.7062 8.89845 10.7062 10.2247C10.7062 11.5107 9.7398 12.5958 8.57208 12.5958ZM16.424 12.5958C15.2563 12.5958 14.3301 11.5107 14.3301 10.2247C14.3301 8.89845 15.2563 7.85355 16.424 7.85355C17.632 7.85355 18.5983 8.89845 18.5581 10.2247C18.5581 11.5107 17.632 12.5958 16.424 12.5958Z"
          fill="url(#paint0_linear_331_118)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_331_118"
          x1="2.88566"
          y1="2.76296"
          x2="26.8925"
          y2="4.18935"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00B7F0" />
          <stop offset="1" stop-color="#4819BE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DiscordIcon;
