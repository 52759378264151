import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WagmiConfig, createClient, configureChains, chain } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { RouterProvider, BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/index';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { StoreProvider } from './utils/store';
import walletConnect from './assets/walletConnect-icon 1.svg';
import coinBase from './assets/Coinbase-icon-symbol-1.svg'
import Rabby from './assets/Rabby.svg'
import Brave from './assets/brave.png'
import injected from './assets/injected.svg';
import metamask from './assets/metamask.svg';
import { styled } from "@mui/material";
import bulbFrame_winning from './assets/images/bulbFrame_winning.png' 
import bulbFrame_losing from './assets/images/bulbFrame_losing.png';
import MainBG from './assets/images/MainBG.svg';
import rocket_icon_mini from './assets/images/rocket-move-equal-mini.png';
import pepe_wearing_crown from './assets/images/pepe_wearing_crown.svg';
import leftCoinStack from './assets/images/pepeCoinStackLeft.svg';
import rightCoinStack from './assets/images/pepeCoinStackRight.svg';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import { SocketContextProvider } from './utils/SocketProvider';
import CreditsContextProvider from './utils/creditsContext';
import StorageContextProvider from './utils/StorageContext';
import * as Sentry from '@sentry/react';
import { sentryUrl } from './constants';
import { AudioPlayerProvider } from 'react-use-audio-player';
import buffer from 'buffer';


////////////////////////////////////////////////////////
//RAINBOWKIT WALLET CONFIGURATION

import { WalletConnectLegacyConnector } from '@wagmi/core/connectors/walletConnectLegacy'
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
  Theme
} from '@rainbow-me/rainbowkit';
import { createConfig } from 'wagmi';
import {
  arbitrum,
  mainnet
} from '@wagmi/core/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  rabbyWallet,
  braveWallet
} from '@rainbow-me/rainbowkit/wallets';

// import { publicProvider } from 'wagmi/providers/public';

//for testing
// const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID
const projectId='2f3c28b9e9ea83116510698302b4cd85'

const myCustomTheme = {
  blurs: {
    modalOverlay: '...',
  },
  colors: {
    accentColor: '#7b3fe4',
    accentColorForeground: '#7b3fe4',
    actionButtonBorder: '...',
    actionButtonBorderMobile: '...',
    actionButtonSecondaryBackground: '...',
    closeButton: '...',
    closeButtonBackground: '...',
    connectButtonBackground: '...',
    connectButtonBackgroundError: '...',
    connectButtonInnerBackground: '...',
    connectButtonText: '...',
    connectButtonTextError: '...',
    connectionIndicator: '...',
    downloadBottomCardBackground: '...',
    downloadTopCardBackground: '...',
    error: '...',
    generalBorder: '...',
    generalBorderDim: '...',
    menuItemBackground: '...',
    modalBackdrop: 'slate-900',
    modalBackground: '...',
    modalBorder: '...',
    modalText: '...',
    modalTextDim: '...',
    modalTextSecondary: '...',
    profileAction: '...',
    profileActionHover: '...',
    profileForeground: '...',
    selectedOptionBorder: '...',
    standby: '...',
  },
  fonts: {
    body: 'barlow',
  },
  radii: {
    actionButton: '...',
    connectButton: '...',
    menuButton: '...',
    modal: '...',
    modalMobile: '...',
  },
  shadows: {
    connectButton: '...',
    dialog: '...',
    profileDetailsAction: '...',
    selectedOption: '...',
    selectedWallet: '...',
    walletLogo: '...',
  },
};


const base = {
  id: 8453,
  name: 'Base Mainnet',
  network: 'base',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://mainnet.base.org/rpc'] },
    default: { http: ['https://mainnet.base.org/rpc'] },
  },
  blockExplorers: {
    etherscan: { name: 'BaseScan', url: 'https://basescan.org' },
    default: { name: 'BaseScan', url: 'https://basescan.org' },
  },
  contracts: {
    
  },
}


// const { chains, provider, webSocketProvider } = configureChains([arbitrum, base], [

//   alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
//   publicProvider({ weight: 1 }),
// ])

const { chains, provider, webSocketProvider } = configureChains([mainnet, arbitrum, base], [
  publicProvider()
]);

// const { chains, publicClient } = configureChains(
//   [ arbitrum ],
//   [
//     alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
//     publicProvider()
//   ]
// );

// const { connectors } = getDefaultWallets({
//   appName: 'roulette',
//   projectId: projectId,
//   chains,
// })

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      // injectedWallet({ chains }),
      // rainbowWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains , logo: metamask}),
      walletConnectWallet({ projectId, chains }),
      coinbaseWallet({ chains, appName: 'defi roulette', logo:coinBase }),
      rabbyWallet({ projectId, chains, logo:Rabby }),
      braveWallet({ projectId, chains, logo:Brave}),
    ],
  },
]);

const MMConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: true,
    UNSTABLE_shimOnConnectSelectAccount: true,
  },
})

const mergedConnectors = [MMConnector, ...connectors()]
const mergeConnectors = () => {
  return mergedConnectors
}

const wagmiClient = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
  connectors: mergeConnectors(),
})


// const { connectors } = getDefaultWallets({
//   appName: 'defi roulette',
//   projectId: projectId,
//   chains
// });

// const wagmiConfig = createClient({
//   autoConnect: true,
//   connectors,
//   publicClient
// })


///////////////////////////////////////////////////////////








const { Buffer } = buffer;
const connector = new InjectedConnector()

Sentry.init({
  dsn: `https://${process.env.REACT_APP_SENTRY_KEY}${sentryUrl}`,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});




if (!window.Buffer) window.Buffer = Buffer;

// const { chains, provider, webSocketProvider } = configureChains([chain.mainnet, chain.arbitrum, base], [
//   publicProvider()
// ]);

// const client = createClient({
//   autoConnect: true,
//   connectors: [
//     new MetaMaskConnector({
//       chains,
//       options: {
//         logo: metamask,
//       },
//     }),
//     new WalletConnectConnector({
//       chains,
//       options: {
//         projectId: '...',
//         logo: walletConnect,
//       },
//     }),
//     new CoinbaseWalletConnector({
//       chains,
//       options: {
//         appName: 'pepes game',
//         logo: coinBase,
//       },
//     }),
//     // new InjectedConnector({
//     //   chains,
//     //   options: {
//     //     name: 'Rabby Wallet',
//     //     getProvider: () =>
//     //       typeof window !== 'undefined' ? window.myInjectedWallet : undefined,
//     //     logo: Rabby,
//     //   },
//     // }),
//     // new InjectedConnector({
//     //   chains,
//     //   options: {
//     //     name: 'Brave Wallet',
//     //     getProvider: () =>
//     //       typeof window !== 'undefined' ? window.myInjectedWallet : undefined,
//     //     logo: Brave,
//     //   },
//     // }),
    

//   ],
//   provider,
//   webSocketProvider,
// });

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#1369A2',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#46298F',
  },
}));

const preloadImages = (srcs) => {
  if (!preloadImages.cache) {
    preloadImages.cache = [];
  }
  var img;
  for (var i = 0; i < srcs.length; i++) {
    img = new Image();
    img.src = srcs[i];
    preloadImages.cache.push(img);
  }
}

const imageSrcs = [bulbFrame_winning, bulbFrame_losing, MainBG, rocket_icon_mini, pepe_wearing_crown, leftCoinStack, rightCoinStack];

preloadImages(imageSrcs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  // <WagmiConfig client={client}>
  <WagmiConfig client={wagmiClient}>
    <StoreProvider>
      <StorageContextProvider>
 
          <SnackbarProvider
            maxSnack={5}
            Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
            }}
          >
            {/* <App />
        <RouterProvider router={router} /> */}
            <Provider store={store}>
            <RainbowKitProvider chains={chains} 
            theme={darkTheme({
            accentColor: '#7b3fe4',
            accentColorForeground: 'white',
            borderRadius: 'small',
            })}

            // theme={myCustomTheme}
      
      >
              <PersistGate persistor={persistor} loading={null}>
                <CreditsContextProvider>
                  <Router>
                    <AudioPlayerProvider>
                      <App />
                      <AppRoutes />
                    </AudioPlayerProvider>
                  </Router>
                </CreditsContextProvider>
              </PersistGate>
              </RainbowKitProvider>
            </Provider>
          </SnackbarProvider>
      
      </StorageContextProvider>
    </StoreProvider>
  </WagmiConfig>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
