import React, { useState, useEffect, useCallback, useMemo, useContext, Fragment } from 'react';
import './DepositWithdrawFunds.css';
import { cancel_icon_w_gradient } from '../../assets';
import { BigNumber, ethers } from 'ethers';
import { useAccount, useSigner, erc20ABI, useNetwork } from 'wagmi';
import { abi } from '../../utils/pepebet-abi';
import { useSnackbar } from 'notistack';
import { useStorage } from '../../utils/StorageContext';
import { StoreContext } from '../../utils/store';
import { CreditsContext } from '../../utils/creditsContext';
import GradientBox from '../GradientBox/GradientBox';
import { Dialog, Transition } from '@headlessui/react';
import { getChainAddress, getSelectedTokenData, convertToDollarValue } from '../../services/service';
import { api, chain_ids } from '../../constants';

export default function DepositWithdrawFunds({ close, isOpen }) {
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const Store = useContext(StoreContext);
  let { depositWithdrawToken, depositWithdrawOperation, setDepositWithdrawOperation, setPulloutModal } = Store;
  const { setYourCredits, userPepeBalance, setUserPepeBalance } = useContext(CreditsContext);
  const [isDepositing, setIsDepositing] = useState(true);
  const [transactionInProcess, setTransactionInProcess] = useState(false);
  const [amount, setAmount] = useState('0');
  const [allowance, setAllowance] = useState(BigNumber.from(0));
  const [formattedAmount, setFormattedAmount] = useState('0');
  const [userWalletBalance, setUserWalletBalance] = useState(BigNumber.from(0));
  const [formattedUserWalletBalance, setFormattedUserWalletBalance] = useState('0');
  // const [userPepeBalance, setUserPepeBalance] = useState(BigNumber.from(0));
  const [formattedUserPepeBalance, setFormattedUserPepeBalance] = useState('0');
  const [isApproved, setIsApproved] = useState(false);
  const floatRegexp = useMemo(() => /^[+-]?\d*(?:[.,]\d*)?$/, []);
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useAccount();
  const { data: signer } = useSigner();

  const { offChainBet } = api;

  const tokenData =
    depositWithdrawToken === undefined ? getSelectedTokenData('USDC') : getSelectedTokenData(depositWithdrawToken);
  /**
   * @dev chain_ids contains array of chain ids used at the moment
   * @dev index 0 is arbitrium and index 1 is base
   */
  const inAppContract = useMemo(() => {
    const chain_id = getChainAddress(chain?.id || chain_ids[0]);
    console.log(chain_id);
    return new ethers.Contract(chain_id, abi, signer);
  }, [signer]);

  const walletContract = useMemo(() => {
    return new ethers.Contract(tokenData.tokenAddres, erc20ABI, signer);
  }, [tokenData.tokenAddres, signer]);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      const valid = floatRegexp.test(value.replace(/,/g, ''));
      if (!valid) e.preventDefault();

      if (valid) {
        const formatted = value.replace(/,/g, '');

        setAmount(value.replace(/,/g, '') || '');
        setFormattedAmount(formatted);
      }
      if (value === '') {
        setAmount('');
        setFormattedAmount('');
      }
    },
    [floatRegexp]
  );

  // const estimateGasUnits = async (entryFunction, amount) => {
  //   //type string && BigNumber resp.
  //   let estimatedCost;
  //   try {
  //     estimatedCost = await inAppContract.estimateGas[entryFunction](amount);
  //   } catch (e) {
  //     estimatedCost = BigNumber.from(0);
  //   }
  //   return estimatedCost;
  //};

  const getUserWalletBalance = useCallback(async () => {
    try {
      const balance = await walletContract.balanceOf(address);
      setUserWalletBalance(balance);
      setFormattedUserWalletBalance(ethers.utils.formatUnits(balance, tokenData.formatUnits));
    } catch (e) {}
  }, [walletContract, address, tokenData.formatUnits]);

  const getUserPepeBalance = useCallback(async () => {
    try {
      //const userBalance = await inAppContract.balances(address);
      const response = await fetch(offChainBet.getUserBalanceEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userAddress: ethers.utils.getAddress(address),
          tokenAddress: ethers.utils.getAddress(tokenData.tokenAddres),
          chainId: chain?.id,
        }),
      });
      /**
       * @dev - Notice, best to use checksum addresses; i.e. ethers.utils.getAddress(address)
       */
      const balance = await response.json();
      console.log('balance response from lambda: ', balance);
      const userBalance = BigNumber.from(balance.body.balance);
      setUserPepeBalance(userBalance);
      let formattedUserBalance = ethers.utils.formatUnits(userBalance, tokenData.formatUnits);
      console.log('formatted user balance: ', formattedUserBalance);
      setFormattedUserPepeBalance(formattedUserBalance);

      // if (depositWithdrawToken == 'USDbC') {
      //   formattedUserBalance = await convertToDollarValue('weth', formattedUserBalance)
      // }

      setYourCredits(formattedUserBalance);
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, inAppContract, depositWithdrawToken]);

  const getUserAllowance = useCallback(async () => {
    try {
      const userAllowance = await walletContract.allowance(address, getChainAddress(chain.id));
      setAllowance(userAllowance);
      console.log('user allowance: ', ethers.utils.formatUnits(userAllowance, tokenData.formatUnits));
      if (amount !== '') {
        const parsedAmount = ethers.utils.parseUnits(amount, tokenData.formatUnits);
        if (userAllowance.lt(parsedAmount) || parsedAmount.isZero()) {
          setIsApproved(false);
        } else {
          setIsApproved(true);
        }
      } else {
        setIsApproved(false);
      }
    } catch (error) {
      console.error('get allowance error:', error);
    }
  }, [walletContract, address, tokenData.formatUnits, amount]);

  useEffect(() => {
    getUserAllowance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const approval = async () => {
    let parsedAmount;

    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(
        parseFloat(realAmount).toFixed(4),
        getSelectedTokenData(depositWithdrawToken).formatUnits
      );
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(allowance)) {
      try {
        const approvalTransaction = await walletContract.approve(
          getChainAddress(chain.id),
          ethers.constants.MaxUint256
        );
        await approvalTransaction.wait();
        const newAllowance = await walletContract.allowance(address, getChainAddress(chain.id));
        // console.log('amount::', amount, ethers.utils.parseUnits(amount, 6))
        // console.log('new allowance::', newAllowance, newAllowance.lt(ethers.utils.parseUnits(amount, 6)))
        setAllowance(newAllowance);
        setIsApproved(true);
      } catch (e) {
        enqueueSnackbar('Approval failed.', {
          variant: 'error',
        });
        setIsApproved(false);
        return;
      }
    } else {
      console.log('still have balance');
    }
  };

  const deposit = async () => {
    let parsedAmount;
    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(realAmount, tokenData.formatUnits);
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(userWalletBalance)) {
      enqueueSnackbar('Insufficient balance', {
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar('Processing deposit, please wait', {
      variant: 'info',
    });
    //const estimatedCost = await estimateGasUnits('deposit', parsedAmount);

    try {
      //console.log('estimated costs: ', estimatedCost.toNumber());
      // const gasUnits = estimatedCost.eq(0) ? 0 : estimatedCost.toNumber() + 1e4; ///@dev keep 1e4 as buffer

      // const tx = await inAppContract.deposit(parsedAmount, { gasLimit: gasUnits === 0 ? 1e6 : gasUnits });

      // const receipt = await tx.wait();
      const response = await fetch(offChainBet.depositApiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userAddress: ethers.utils.getAddress(address),
          tokenAddress: ethers.utils.getAddress(tokenData.tokenAddres),
          amount: parseFloat(amount),
          chainId: chain.id,
        }),
      });
      const data = await response.json();
      // console.log('deposit response from lambda: ', data);
      // console.log('tx hash: ', data.data?.txHash);

      if (data.status === 'success') {
        enqueueSnackbar('Deposit successful', {
          variant: 'success',
        });

        const amountRemainging = userWalletBalance.sub(parsedAmount);
        // await getUserWalletBalance();
        await getUserPepeBalance();
        const updatedCredits = userPepeBalance.add(parsedAmount);
        setFormattedUserWalletBalance(ethers.utils.formatUnits(amountRemainging, tokenData.formatUnits));

        let formattedUserBalance = ethers.utils.formatUnits(updatedCredits, tokenData.formatUnits);

        // if (depositWithdrawToken == 'WETH') {
        //   formattedUserBalance = await convertToDollarValue('weth', formattedUserBalance)
        // }

        setYourCredits(formattedUserBalance);
        //window.location.reload();
        enqueueSnackbar(`Deposit successful, tx hash: ${data.data?.txHash}`, {
          variant: 'success',
        });
        close();
      } else {
        throw new Error('Deposit failed');
      }
    } catch (e) {
      enqueueSnackbar('Unable to complete deposit.', {
        variant: 'error',
      });
      return;
    }
  };

  const withdraw = async () => {
    let parsedAmount;
    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(realAmount, tokenData.formatUnits);
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(userPepeBalance)) {
      enqueueSnackbar('Insufficient balance', {
        variant: 'error',
      });
      return;
    }
    if (parsedAmount.eq(0)) {
      enqueueSnackbar('Invalid amount', {
        variant: 'error',
      });
      return;
    }

    enqueueSnackbar('Processing withdrawal, please wait', {
      variant: 'info',
    });
    //const estimatedCost = await estimateGasUnits('withdraw', parsedAmount);

    try {
      const response = await fetch(offChainBet.withdrawApiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userAddress: ethers.utils.getAddress(address),
          tokenAddress: ethers.utils.getAddress(tokenData.tokenAddres),
          amount: parseFloat(amount),
          chainId: chain.id,
        }),
      });

      const data = await response.json();
      // console.log('withdraw response from lambda: ', data);
      // console.log('tx hash: ', data.data?.txHash);

      if (data.status === 'success') {
        await getUserWalletBalance();
        await getUserPepeBalance();
        const updatedCredits = userPepeBalance.sub(parsedAmount);

        let formattedUserBalance = ethers.utils.formatUnits(updatedCredits, tokenData.formatUnits);

        if (depositWithdrawToken == 'WETH') {
          formattedUserBalance = await convertToDollarValue('weth', formattedUserBalance);
        }

        setYourCredits(formattedUserBalance);

        enqueueSnackbar(`Withdrawal successful, tx hash: ${data.data?.txHash}`, {
          variant: 'success',
        });
        close();
        //window.location.reload();
      } else {
        throw new Error('Withdrawal failed');
      }

      //console.log('estimated costs: ', estimatedCost.toNumber());
      // const gasUnits = estimatedCost.eq(0) ? 0 : estimatedCost.toNumber() + 1e4; ///@dev keep 1e4 as buffer

      // const tx = await inAppContract.withdraw(parsedAmount, { gasLimit: gasUnits === 0 ? 1e6 : gasUnits });

      // const receipt = await tx.wait();
    } catch (e) {
      console.error('Withdrawal error: ', e);
      enqueueSnackbar('Withdrawal failed', { variant: 'error' });
      return;
    }
  };

  const handleCustomAmountChange = async (percentage, what) => {
    if (what === 'Deposit') {
      const newAmount = userWalletBalance.mul(percentage).div(1e4);
      const formattedAmount = ethers.utils.formatUnits(newAmount, tokenData.formatUnits);
      setAmount(formattedAmount);
      setFormattedAmount(formattedAmount);
    } else {
      const newAmount = userPepeBalance.mul(percentage).div(1e4);
      const formattedAmount = ethers.utils.formatUnits(newAmount, tokenData.formatUnits);
      setAmount(formattedAmount);
      setFormattedAmount(formattedAmount);
    }
  };

  // useEffect(() => {
  //   console.log('allowance::', amount, ethers.utils.parseUnits(amount, 6))
  //   console.log('allowance::', allowance.lt(ethers.utils.parseUnits(amount, 6)))
  // }, [allowance])

  useEffect(() => {
    getUserWalletBalance();
    getUserPepeBalance();
    getUserAllowance();

    //Setting wehen depositing is done using state from depositWithdrawOperation
    if (depositWithdrawOperation === 'deposit') {
      setIsDepositing(true);
    } else {
      setIsDepositing(false);
    }
  }, [getUserAllowance, getUserPepeBalance, getUserWalletBalance, isDepositing, depositWithdrawOperation]);

  const toggleDepositTabOn = () => {
    setIsDepositing(true);
    setDepositWithdrawOperation('deposit');
  };

  const toggleWithdrawTabOn = () => {
    setIsDepositing(false);
    setDepositWithdrawOperation('withdraw');
  };

  const handleWithdraw = async () => {
    setTransactionInProcess(true);
    await withdraw();
    setTransactionInProcess(false);
  };

  const handleDeposit = async () => {
    setTransactionInProcess(true);
    await deposit();
    setTransactionInProcess(false);
  };

  const handleApprove = async () => {
    setTransactionInProcess(true);
    await approval();
    setTransactionInProcess(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-90" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full transition-all transform">
                <div className="relative">
                  <div>
                    <GradientBox
                      classes="relative w-[87%] sm:w-1/2 sm:min-w-[37rem] max-w-[40rem] mx-auto py-12 barlow"
                      roundedClass="rounded-[0.625rem]"
                    >
                      <div className="text-white py-[1.875rem] sm:pt-[1.375rem] sm:pb-[3.5rem] px-5 sm:px-[3.5rem]">
                        <div className="flex justify-end mb-5 sm:mb-[0.688rem]">
                          <button onClick={close}>
                            <img src={cancel_icon_w_gradient} alt="cancel" className="w-4 h-auto sm:w-auto" />
                          </button>
                        </div>

                        <h2 className='gradient-text font-["Tourney"] font-bold text-[1.75rem] sm:text-[4.188rem] leading-[1.925rem] sm:leading-[4.606rem] tracking-[0.03em] text-center mb-5 sm:mb-[2.861rem]'>
                          FUNDS
                        </h2>

                        <div className="dwf-inner-box min-h-[200px]">
                          <div className="flex justify-center gap-[1.875rem] sm:gap-4 barlow font-bold text-[1.125rem] leading-[1.375rem] sm:text-[18px] sm:leading-5 mb-[1.875rem] sm:mb-8">
                            <span
                              onClick={toggleDepositTabOn}
                              className={isDepositing ? 'dwf-active-tab' : 'dwf-inactive-tab'}
                            >
                              Deposit
                            </span>
                            <span
                              onClick={toggleWithdrawTabOn}
                              className={isDepositing ? 'dwf-inactive-tab' : 'dwf-active-tab'}
                            >
                              Withdraw
                            </span>
                          </div>
                          <div className="flex justify-between text-xs leading-[0.625rem] sm:text-base mb-[0.438rem] sm:mb-2">
                            <div className="">
                              <span className="text-white/70">Wallet: </span>
                              {formattedUserWalletBalance} <span className="">{depositWithdrawToken}</span>
                            </div>
                            <div className="">
                              <span className="text-white/70">In-App: </span>
                              {formattedUserPepeBalance} <span className="">{depositWithdrawToken}</span>
                            </div>
                          </div>
                          <div className="relative flex mb-[1.875rem] sm:mb-6 sm:manrope">
                            <input className="dwf-funds-input" onChange={handleChange} value={formattedAmount} />
                            <div className="dwf-funds-percentages">
                              <div
                                onClick={() => {
                                  handleCustomAmountChange(3600, isDepositing ? 'Deposit' : 'Withdraw');
                                }}
                                className="text-[0.688rem] text-sm cursor-pointer pointer-events-auto font-light bg-[#18adfa80] rounded-[4px] flex w-[2.438rem] py-1 justify-center"
                              >
                                36%
                              </div>
                              <div
                                onClick={() => {
                                  handleCustomAmountChange(6900, isDepositing ? 'Deposit' : 'Withdraw');
                                }}
                                className="text-[0.688rem] text-sm cursor-pointer pointer-events-auto font-light bg-[#18adfa80] rounded-[4px] flex w-[2.438rem] py-1 justify-center"
                              >
                                69%
                              </div>
                              <div
                                onClick={() => {
                                  handleCustomAmountChange(1e4, isDepositing ? 'Deposit' : 'Withdraw');
                                }}
                                className="text-[0.688rem] text-sm cursor-pointer pointer-events-auto font-light bg-[#18adfa80] rounded-[4px] flex w-[2.438rem] py-1 justify-center"
                              >
                                Max
                              </div>
                            </div>
                          </div>
                          {/* {isDepositing &&
                            allowance.lt(
                              amount != ''
                                ? typeof amount === 'string'
                                  ? ethers.utils.parseUnits(parseFloat(amount).toFixed(4), 6)
                                  : amount
                                : ethers.utils.parseUnits('5', 6)
                            ) && (
                              <div className="flex justify-center mb-3 -mt-3 text-xs font-medium barlow">
                                Minimum deposit amount is 5 <span className="uppercase ">{depositWithdrawToken}</span>
                              </div>
                            )} */}

                          <div className={`flex barlow justify-center`}>
                            {isDepositing ? (
                              <>
                                {/* {allowance.lt(amount != '' ? typeof amount === "string" ? ethers.utils.parseUnits(parseFloat(amount).toFixed(4), 6) : amount : ethers.utils.parseUnits('5', 6)) ? ( */}
                                <div className={`basis-[48%]`}>
                                  <div className="relative">
                                    <div className={`${isApproved && 'opacity-40'} z-10 relative`}>
                                      <div className="items-center justify-center sm:flex barlow">
                                        <button
                                          onClick={handleApprove}
                                          disabled={isApproved | transactionInProcess}
                                          className={`disabled:cursor-not-allowed relative connect-wallet-gradient-shadow flex justify-center min-w-[8rem] sm:min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[0.875rem] sm:mb-0`}
                                        >
                                          <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                                            Approve
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* ) : ( */}
                                <div className={` basis-[48%]`}>
                                  <div className="relative">
                                    <div className={`${!isApproved && 'opacity-40'} z-10 relative`}>
                                      <div className="items-center justify-center sm:flex barlow">
                                        <button
                                          onClick={handleDeposit}
                                          disabled={!isApproved | transactionInProcess}
                                          className={`disabled:cursor-not-allowed relative connect-wallet-gradient-shadow flex justify-center min-w-[8rem] sm:min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[0.875rem] sm:mb-0`}
                                        >
                                          <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                                            Deposit
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* )} */}
                              </>
                            ) : (
                              <div className="basis-[48%]">
                                <div className="relative">
                                  <div className="relative z-10">
                                    <div className="items-center justify-center sm:flex barlow">
                                      <button
                                        onClick={handleWithdraw}
                                        className={`disabled:cursor-not-allowed relative connect-wallet-gradient-shadow flex justify-center min-w-[8rem] sm:min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[0.875rem] sm:mb-0`}
                                        disabled={transactionInProcess}
                                      >
                                        <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                                          Withdraw
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          {arbsOrBase !== 'base' && (
                            <p className="mt-4">
                              <a
                                href="#"
                                onClick={() => {
                                  setPulloutModal(true);
                                }}
                              >
                                Miss your funds?
                              </a>
                            </p>
                          )}
                        </div>

                        {/* <div className="relative">
                              <div className="relative z-10">
                                <div className="items-center justify-center sm:flex barlow">
                                  <button
                                    className="relative connect-wallet-gradient-shadow flex justify-center min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[1.875rem] sm:mb-0 disabled:opacity-40"
                                  >
                                    <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                                      Button
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div> */}
                      </div>
                    </GradientBox>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
