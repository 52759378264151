export const BitCoinIcon = ({ selected = false }) => {
  return (
    <svg width="auto" height="80%" viewBox="0 0 86 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_5688_151694)">
        {selected ? (
          <path
            d="M76.953 51.4725C72.2786 70.224 53.2836 81.6365 34.5299 76.9621C15.7827 72.2855 4.37021 53.2905 9.04894 34.5411C13.7212 15.7853 32.714 4.37281 51.4634 9.04724C70.2171 13.7217 81.6274 32.7188 76.953 51.4725Z"
            fill="url(#paint0_linear_5688_151694)"
          />
        ) : (
          <path
            d="M76.953 51.4725C72.2786 70.224 53.2836 81.6365 34.5299 76.9621C15.7827 72.2855 4.37021 53.2905 9.04894 34.5411C13.7212 15.7853 32.714 4.37281 51.4634 9.04724C70.2171 13.7217 81.6274 32.7188 76.953 51.4725Z"
            fill="url(#selectedFilter)"
          />
        )}
      </g>
      <path
        d="M56.8276 34.0022C56.3408 29.317 52.2824 27.7768 47.1707 27.3675L47.1212 20.8643L43.1619 20.8944L43.2093 27.2253C42.1689 27.234 41.1047 27.262 40.0492 27.29L40.0018 20.916L36.0447 20.9461L36.0921 27.4472C35.2348 27.4709 34.3947 27.4946 33.574 27.5011V27.4817L28.1133 27.5205L28.1456 31.749C28.1456 31.749 31.0687 31.6714 31.0192 31.7253C32.6218 31.7145 33.1517 32.6408 33.309 33.4421L33.365 40.8501C33.4749 40.8501 33.6192 40.8523 33.7829 40.8738C33.6515 40.8738 33.5093 40.8738 33.365 40.8781L33.4426 51.2566C33.3758 51.7607 33.085 52.5685 31.9648 52.5792C32.0165 52.6245 29.0869 52.6008 29.0869 52.6008L28.3352 57.3334L33.4856 57.2946C34.4442 57.2881 35.3877 57.2989 36.314 57.2946L36.3657 63.8711L40.3206 63.8409L40.2711 57.3334C41.3568 57.3484 42.408 57.3484 43.4333 57.3398L43.4807 63.8172L47.44 63.7871L47.3904 57.2214C54.0423 56.7884 58.6887 55.078 59.2186 48.8246C59.6473 43.7883 57.2627 41.561 53.4801 40.6821C55.7656 39.4952 57.1852 37.4251 56.8233 33.9979L56.8276 34.0022ZM51.3949 48.1073C51.4337 53.023 43.0133 52.5275 40.3314 52.5491L40.2668 43.8335C42.9508 43.8142 51.3561 42.9784 51.3949 48.1051V48.1073ZM49.4605 35.8267C49.4928 40.2986 42.4704 39.8291 40.2366 39.8463L40.1763 31.9429C42.4101 31.9256 49.4239 31.1631 49.4605 35.8267Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_5688_151694"
          x="0"
          y="0"
          width="86"
          height="86.0098"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5688_151694" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5688_151694" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_5688_151694"
          x1="1.60403"
          y1="19.5557"
          x2="96.2694"
          y2="70.9538"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0714569" stop-color="#00B7F0" />
          <stop offset="1" stop-color="#643EC6" />
        </linearGradient>
        <linearGradient
          id="selectedFilter"
          x1="1.60403"
          y1="19.5557"
          x2="96.2694"
          y2="70.9538"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0714569" stop-color="#BEB7C9" />
          <stop offset="1" stop-color="#7A787C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
