import React, { useContext, useMemo, useState, useRef, useEffect } from 'react';
import {
  cancel_icon_w_gradient,
  finalmobilelogo,
  pepe_top_base,
  pepe_throwing_coins_desktop,
  pepe_throwing_coins_mobile,
  pepe_top,
  pepePills,
  pepe_pills,
  information,
  twitterIcon,
} from '../assets';
import XIcon from '../assets/icons/XIcon.svg';
import '../styles/results.css';
import { useSigner, useAccount, useNetwork } from 'wagmi';
import { ethers, BigNumber } from 'ethers';
import { pepeBetContractAddress, approvedAssets, trade_API, api, twitter_api, getQuest_API } from '../constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { abi } from '../utils/pepebet-abi';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import usdcoin from '../assets/images/usd-coin-usdc-logo 2.svg';
import { TwitterShareButton } from 'react-share';
import { StoreContext } from '../utils/store';
import Settings from '../components/Settings/Settings';
import BetAmountButton from '../components/BetAmountButton';
import PlayButton from '../components/PlayButton/PlayButton';
import { CreditsContext } from '../utils/creditsContext';
import {
  getLeverage,
  uploadShareImage,
  calculatePoints,
  getDollarValue,
  convertToDollarValue,
} from '../services/service';
import { useStorage } from '../utils/StorageContext';
import { getSelectedTokenData } from '../services/service';
import ShareResult from '../components/ShareResult';
import LoaderIcon from '../components/LoaderIcon';
import domtoimage from 'dom-to-image';
import { useMediaQuery } from 'usehooks-ts';
import ShareModal from '../components/modal/shareModal';
import { Tooltip } from '@mui/material';
import { parse } from 'postcss';

const Result = () => {
  const { state, search } = useLocation();
  const { betCurrency } = useStorage();
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const [won, setWon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState();
  const [lasttrade, setLastT] = useState({});
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const [retryCount, setRetryCount] = useState(0);
  const isMobileDevice = useMediaQuery('(max-width: 640px)');
  const {
    tradeVars,
    setLastTrade,
    lasttradeOpen,
    lasttradeClose,
    lasttradeWin,
    lambdaResponseData,
    setStoreState,
    setSFX,
    sfxAudio,
    setSrc,
    music,
    setTradeVars,
    setGoodLuckModal,
    activeAmountButton: activeButton,
    setActiveAmountButton: setActiveButton,
    setWillRespin,
    referralCode,
    depositWithdrawToken,
  } = store;

  const {
    yourCredits,
    setYourCredits,
    chipsAndDays,
    questStreakCompleted,
    pillMessage,
    setPillMessage,
    showPills,
    setShowPills,
    userPepeBalance,
    setUserPepeBalance,
  } = useContext(CreditsContext);

  const { data: signer } = useSigner();
  const { address } = useAccount();
  const pepeBetContract = useMemo(
    () => new ethers.Contract(getSelectedTokenData(betCurrency)?.contractAddress, abi, signer),
    [signer]
  );

  const [leverage, setLeverage] = useState(7100); //80 percent in bps
  const [outcome, setOutcome] = useState(0);
  const [inputAmount, setInputAmount] = useState(tradeVars.amount);
  const [hideButton, setHideButton] = useState(false);
  // const [userPepeBalance, setUserPepeBalance] = useState(BigNumber.from(0));
  const [gottenAmount, setGottenAmount] = useState(false);
  const [referralLink, setReferralLink] = useState('');
  const [showShareResult, setShowShareResult] = useState(false);
  const [shareLoading, setShareLoading] = useState(true);
  const [userPoints, setUserPoints] = useState(0);
  const [betPills, setBetPills] = useState();

  const [shareScreenShotCalled, setShareScreenShotCalled] = useState(false);
  const [shareResultImagesLoaded, setShareResultImagesLoaded] = useState(false);

  const [respinButtonClicked, setRespinButtonClicked] = useState(false);

  // const handleShareResultImageLoad = (loadingState) => {
  //   setShareResultImagesLoaded(loadingState);
  //   if (loadingState && !shareScreenShotCalled) {
  //     shareScreenShot();
  //     setShareScreenShotCalled(true);
  //   }
  // };

  const isMobile = useMediaQuery('(max-width: 640px)');
  const betRef = useRef(null);

  const INSUFFICIENT_FUNDS_MSG = 'Insufficient funds';

  const showPillMessage = () => {
    if (pillMessage) {
      enqueueSnackbar(
        `Congrats, you acquired your daily dose of +${
          chipsAndDays
            .filter((item) =>
              questStreakCompleted === 0 ? item.day === questStreakCompleted + 1 : item.day === questStreakCompleted
            )
            .map((item) => item.chips)[0]
        } Pepe’s Pills.`,
        { variant: 'success' }
      );
      setPillMessage(false);
    }
  };

  const getResult = async (betid = '') => {
    // console.log({'yourCreditResult': yourCredits})
    try {
      setLoading(true);
      //console.log("time up..........................",state)
      console.log(state.betid, betid);
      const betStatus = await axios.post(api.offChainBet.getBetEndpoint, {
        betId: state !== undefined ? state.betid : betid,
      });
      const betData = betStatus?.data?.message;
      console.log('betData: ', betData);
      if (betData !== undefined) {
        if (!betData.is_active) {
          //check for a win or lost
          let { open_price, close_price, payout, asset_ticker, is_long, duration, amount } = betData;

          setUserPoints(calculatePoints(Number(amount), parseFloat(payout)));
          /**
           * @dev - pick the leverage from the database now, table bet_constraints.
           */
          let contractLeverage;
          if (won) {
            contractLeverage = await pepeBetContract.leverage();
          } else {
            contractLeverage = 10000;
          }
          const leverage =
            contractLeverage /
            10000 /
            ((Math.abs(parseFloat(open_price) - parseFloat(close_price)) / parseFloat(open_price)) * 100);

          getBetQuest(state !== undefined ? state.betid : betid);

          if (isFinite(leverage)) {
            setLeverage(leverage.toFixed(3));
          } else {
            setLeverage(0.0);
          }
          // console.log({ contractLeverage, leverage, lasttrade }, typeof leverage);
          //let op = bodydata.open_price.toString().slice(0, -6) + '.' + bodydata.open_price.toString().slice(-6);
          setLastT({ open_price, close_price, asset_ticker, is_long, duration, amount });
          setShowShareResult(true);
          setShareLoading(false);
          try {
            //const userBalance = await pepeBetContract.balances(address);
            const { offChainBet } = api;
            const tokenData = getSelectedTokenData(betCurrency);

            const response = await fetch(offChainBet.getUserBalanceEndpoint, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                userAddress: ethers.utils.getAddress(address),
                tokenAddress: ethers.utils.getAddress(tokenData.tokenAddres),
                chainId: chain.id,
              }),
            });

            const balance = await response.json();
            //console.log('balance response from lambda 1: ', balance);
            const userBalance = BigNumber.from(balance.body.balance);
            setUserPepeBalance(userBalance);
            let formattedUserBalance = ethers.utils.formatUnits(userBalance, tokenData.formatUnits);

            setYourCredits(formattedUserBalance);

            setGottenAmount(true);
          } catch (e) {}
          if (parseFloat(payout) > 0) {
            setWon(true);

            setLastTrade({ open_price, close_price, win: payout });
            if (!state?.prevlocation) {
              enqueueSnackbar('Transaction Successful. You got $ ' + payout + '.', {
                variant: 'success',
              });
              showPillMessage();
            }
          } else {
            setWon(false);
            setLastTrade({ open_price, close_price, win: payout });
            if (!state?.prevlocation) {
              enqueueSnackbar("Transaction Successful, Pepe's sorry you lost.", { variant: 'warning' });
              showPillMessage();
            }
          }

          setLoading(false);
        } else if (betData.is_active) {
          if (retryCount < 5) {
            //lets retry
            setRetryCount(retryCount + 1);
            getResult();
          } else {
            navigate('/slot');
            enqueueSnackbar('Sorry unable to get bet results at the moment. Kindly check bet history for bet status.', {
              variant: 'warning',
            });
          }

          setLoading(false);
        }
      } else {
        // console.log('Response came with no data:', betStatus);
        setLoading(false);
        navigate('/slot');
        enqueueSnackbar('Transaction not made successfully. ', { variant: 'error' });
      }

      // setLoading(false);
    } catch (err) {
      if (!shareLoading) {
        enqueueSnackbar('Error getting bet results. Please check bet history. ', { variant: 'error' });
      }
      setLoading(false);
    }
  };

  //Method to get bet quest data(PILLS)
  const getBetQuest = async (betId) => {
    try {
      // const { data } = await axios.get(
      //   `${getQuest_API}/getBetQuests?betId=${betId}`
      // );
      const { data } = await axios.get(`${api.offChainBet.getBetQuestsApiEndpoint}?betId=${betId}`);
      setBetPills(data.data.chips);
    } catch (err) {
      setBetPills(null);
    }
  };

  const sentTweet = async (code) => {
    setShareLoading(true);
    try {
      let data;
      if (!referralCode) {
        const response = await axios.get(`${api.offChainBet.getReferralCodeEndpoint}?address=${address}`);
        data = response.data.refcode[0];
        setStoreState('referralCode', data?.refcode);
      }

      const img = await localStorage.getItem('share_image');
      const tweet = await axios.post(twitter_api + '?code=' + code, {
        image: img,
        reflink: window.location.origin + '?ref=' + encodeURIComponent(data.refcode),
      });
      if (tweet.data?.status === 'success') {
        enqueueSnackbar('Successfully shared to Twitter. ', { variant: 'success' });

        setTimeout(() => window.open('https://www.app.pepesgame.com/slot'), 2000);
      }
      // console.log('share response', tweet);
      setShareLoading(false);
      setShowShareResult(false);
    } catch (error) {
      setShareLoading(false);
      console.log(error);
      enqueueSnackbar('Error sharing to Twitter. ', { variant: 'error' });
      window.open('https://www.app.pepesgame.com/slot');
      setShowShareResult(false);
      setTimeout(() => window.close(), 2000);
    }
  };

  React.useEffect(() => {
    // console.log('referral code::', referralCode)
    //get code then make request to end point (Lambda)
    const auth_code = search?.split('&')[1];
    const code = auth_code?.split('=')[1];
    // console.log('response:', code);

    const urlParams = new URLSearchParams(window.location.search);
    const betid = urlParams.get('state');

    if (code) {
      getResult(betid);
      setShowShareResult(true);
      sentTweet(code);
      return;
    }

    getLeverage().then((res) => {
      setOutcome(res);
    });
    //set loading to true
    setLoading(true);
    let timer = null;
    // if(tradeVars.time >= 30){
    timer = setTimeout(() => {
      getResult();
      //get leverage from contract
      //getResultFromContract();
    }, 1000); //make request after 5 secs
    // }else{
    //     getResult()
    // }
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // React.useEffect(() => {
  //   if (showShareResult) {
  //     shareScreenShot()
  //   }
  // }, [showShareResult])

  const handleClick = (button, amount) => {
    setActiveButton(button);
    setInputAmount(amount);
  };

  const handleNewBet = () => {
    // console.log(state);
    navigate(-1);
    // if (state.prevlocation === 'history') {
    //   navigate('/recent-plays');
    // } else {
    //   setHideButton(true);
    navigate('/slot');
    // }
  };

  const runBet = () => {
    if (inputAmount === 0) {
      return;
    }

    setYourCredits(yourCredits - inputAmount);

    setTradeVars(inputAmount, tradeVars.currency, tradeVars.time, tradeVars.longShort);

    setGoodLuckModal(true);
    navigate('/chart');
  };

  const shareScreenShot = async () => {
    const snackbarId = enqueueSnackbar('Preparing to share on Twitter ...', { variant: 'info', persist: true });
    const element = document.getElementById('result-panel-share'); // Result share component's ID
    domtoimage
      .toJpeg(element)
      .then(async function (dataUrl) {
        let share_image = dataUrl;
        // localStorage.setItem('share_image', dataUrl);

        const base64Data = share_image.split(';base64,')[1];
        const buffer = Buffer.from(base64Data, 'base64');
        const dt = new Date().getTime();
        const file_obj = { file: buffer, name: referralCode + '_' + dt + '.jpeg' };
        try {
          let share_url;
          const share_upload_data = await uploadShareImage(file_obj, referralCode);
          closeSnackbar(snackbarId);
          if (share_upload_data.status === 'success') {
            share_url = share_upload_data.url;
          }
          // console.log(share_url);
          const contentUrl = share_url;
          const tweetText =
            'Experience that unique thrill of trading in one single click with \n @PepesGame 🐸🎲🎶Ready? LFG 👇 !';
          const hashtags = 'PepesGame';

          const encodedUrl = encodeURIComponent(contentUrl);
          const encodedText = encodeURIComponent(tweetText);
          // const encodedHashtags = encodeURIComponent(hashtags);

          const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}`;

          const newTab = await window.open(twitterShareUrl, '_blank');
          if (newTab) {
            newTab.focus();
          } else {
            window.location.href = twitterShareUrl;
            enqueueSnackbar('didnt open new tab', { variant: 'warning' });
            //// console.error('Failed to open new tab');
          }
        } catch (error) {
          closeSnackbar(snackbarId);
          console.log({ twitterError: error.message });
          enqueueSnackbar('Unable to process your request at the moment.', { variant: 'warning' });
        }

        // twitter_authorization();
      })
      .catch(function (error) {
        closeSnackbar(snackbarId);
        console.error('oops, something went wrong!', error);
      });
  };

  const respin = async () => {
    if (respinButtonClicked) {
      return;
    }
    setRespinButtonClicked(true);

    try {
      // Simulate a delay (remove this in your actual implementation)
      await new Promise((resolve) => setTimeout(resolve, 3000));

      setWillRespin(true);
      // console.log({"credit": yourCredits})
      navigate('/slot');
    } catch (error) {
      // Handle any errors that occurred during respin
      console.error('Respin failed:', error);
    }

    setRespinButtonClicked(true);
  };

  const closeShare = () => {
    setShowShareResult(false);
  };

  React.useEffect(() => {
    if (won) {
      if (tradeVars.longShort === 'Long') {
        setSrc(music[4]);
      } else {
        setSrc(music[5]);
      }
    }
  }, [won]);

  const handleClose = () => {
    if (state?.prevlocation) {
      navigate(-1);
    } else {
      navigate('/slot');
    }
  };

  useEffect(() => {
    return () => {
      setShowPills(false);
    };
  }, []);

  return (
    <div className="bg-blackish-on-desktop">
      <div className="relative">
        <div className="items-center justify-center min-h-screen text-white sm:pb-12 sm:flex">
          <div className="flex items-center justify-center h-[44vw] sm:hidden overflow-hidden">
            <img
              src={arbsOrBase === 'base' ? pepe_top_base : finalmobilelogo}
              alt=""
              className="block mx-auto pointer-events-none"
            />
          </div>
          <button className="block p-4 ml-auto hidden" onClick={handleClose}>
            <img src={cancel_icon_w_gradient} alt="" />
          </button>
          <div>
            {shareLoading ? (
              <div className="flex items-center justify-center h-12">
                <div role="status" className="pt-1">
                  <LoaderIcon />
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <ShareResult
                  referralCode={referralCode}
                  amount={tradeVars.amount ? tradeVars.amount : lasttrade.amount}
                  currency={tradeVars.amount ? tradeVars.currency : lasttrade.asset_ticker}
                  longShort={tradeVars.amount ? tradeVars.longShort : lasttrade.is_long}
                  // longShort={tradeVars.longShort}
                  time={tradeVars.amount ? tradeVars.time : lasttrade.duration}
                  won={won}
                  openPrice={lasttrade?.open_price}
                  closePrice={lasttrade?.close_price}
                  leverage={leverage}
                  closeShare={closeShare}
                  userPoints={userPoints}
                  betPills={betPills}
                  show={showShareResult}
                />

                {/* <img src="" id="test-share-image" /> */}

                <div
                  className={`flex ${showShareResult ? 'justify-center' : 'justify-evenly'} ${
                    isMobile ? 'gap-3' : 'gap-8'
                  } items-center pb-8 sm:pb-0`}
                >
                  {/* <button onClick={() => navigate('/leaderboard')}
                    className="btn-blueish-shadow relative connect-wallet-gradient-shadow flex justify-center px-4 sm:px-0 sm:min-w-[9rem] border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem]"
                  >
                    <span className="button-gradient-text font-bold text-[1.063rem] leading-5">Leaderboard</span>
                  </button> */}

                  <button
                    onClick={handleClose}
                    className={`btn-blueish-shadow relative connect-wallet-gradient-shadow flex justify-center px-4 sm:px-4 sm:min-w-[7rem] border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] ${
                      isMobile ? '!ml-0' : '!ml-[41px]'
                    } ${showShareResult && 'ml-[79px]'}`}
                  >
                    <span className="button-gradient-text font-bold text-[1.063rem] leading-5">Close</span>
                  </button>

                  <button
                    onClick={() => shareScreenShot()}
                    className={`btn-blueish-shadow relative connect-wallet-gradient-shadow flex justify-center items-center px-2 sm:px-8 sm:min-w-[6rem] border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/60 p-2 sm:py-[0.688rem]`}
                  >
                    <img
                      src={XIcon}
                      alt="twiiter icon"
                      className={`${isMobile ? 'w-[18px] h-[20.24px] mr-[7px]' : 'w-[22px] h-[16.24px] mr-[10px]'}`}
                    />
                    <span className="button-gradient-text font-bold text-[1.063rem] leading-5">Share</span>
                  </button>

                  <button
                    onClick={() => (respinButtonClicked ? '' : respin())}
                    className={`${
                      tradeVars?.amount ? '' : 'hidden'
                    } btn-blueish-shadow border-[1px] border-solid border-[#18ADFA] block text-center rounded-md px-4 sm:px-4 sm:min-w-[12rem] p-2 sm:py-[0.688rem] text-white drop-shadow-[0px_4px_4px_rgba(0,0,0,0.95)] barlow text-[17px] leading-[20px] font-bold py-2 bg-gradient-to-r from-[rgba(0,183,240,0.56)] to-[rgba(72,25,190,0.56)]`}
                    disabled={respinButtonClicked}
                  >
                    Respin
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Settings classes="hidden" />
    </div>
  );
};

export default Result;
