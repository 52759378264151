import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import './Settings.css';
import '../Audio/AudioPlayer.js';
import musicIcon from '../../assets/images/Vector-2.svg'
import sfxIcon from '../../assets/images/Group (1).svg'
import menuIcon from '../../assets/images/Menu.svg'
import mute from '../../assets/images/mute.svg'
import zeroVol from '../../assets/images/zeroVol.svg'

import { StoreContext } from '../../utils/store';
import AudioPlayer from '../Audio/AudioPlayer.js';
import { useMediaQuery } from 'usehooks-ts';
import { useStorage } from '../../utils/StorageContext';

const Settings = ({ classes }) => {
  const store = useContext(StoreContext);
  const query = useMediaQuery('(max-width: 640px)');
  const { sfx, SFXtoggle} = store;
  const [settingsSFX, setSettingsSFX] = useState(sfx);
  const [showVolume, setShowVolume] = useState(false)
  const [musicLogo, setMusicLogo] = useState('')
  const navigate = useNavigate()
  const {autoplay, setAutoplay, vol, setVol} = useStorage()

  useEffect(() => {
      if(parseFloat(vol) === 0 && autoplay === 'on') {
        setAutoplay('off')
      }
  }, [vol])

  useEffect(() => {
    const slider = document.getElementById('slider')
    slider.style.backgroundSize = (vol) * 100 + '%'
  }, []);

  useEffect(()=>{
    const handleMusicLogo = () => {
      if(parseFloat(vol) === 0){
        return zeroVol
      } else if(parseFloat(vol) > 0 && autoplay === 'off'){
        return mute
      } else{
        return musicIcon
      }
    }

    setMusicLogo(handleMusicLogo())
  },[vol, autoplay])


  const toggleMusic = () => {
    if (autoplay === 'on') {
      setAutoplay('off');
    } else if (autoplay === 'off') {
      setAutoplay('on');
    }
  };

  const toggleSFX = () => {
    SFXtoggle(!sfx);
    setSettingsSFX(!settingsSFX);
  };

  const handleVolume = (event) => {
    const newVolume = event.target.value;
    let size;
    if(autoplay === 'off'){
      setAutoplay('on');
    }
    
    setVol(newVolume)
    size = (newVolume) * 100 + '%'

    event.target.style.backgroundSize = size
  }

  return (
    <>
      <div className={`absolute self-end flex flex-row sm:flex-col mr-4 sm:mb-0 sm:mr-0 right-0 bottom-0 items-end settings ${classes}`}>
        <div
          id="music"
          className={`relative mx-2.5 sm:mx-0 my-2.5 pr-1 flex items-center justify-end ${showVolume && !query ? 'w-[5.75rem]' : 'w-8'} h-6 settings-items rounded-md sm:rounded-l-3xl appearance-none border-none`}
          onMouseEnter={() => setShowVolume(true)}
          onMouseLeave={() => setShowVolume(false)}
        >
          <img 
          src={musicLogo} 
          className='h-4 w-5 cursor-pointer'
          onClick={() => toggleMusic()}/>
              <input
              id = 'slider'
              className={`${showVolume && !query ? 'block' : 'hidden'}`}
              type="range"
              min={0}
              max={1}
              step={0.02}
              value={vol}
              onChange={handleVolume}
            />
        </div>
        <button
          id="sfx"
          className={`relative mx-2.5 sm:mx-0 my-2.5 flex h-6 w-8 settings-items rounded-md sm:rounded-l-3xl appearance-none border-none justify-center items-center ${
            !settingsSFX ? 'opacity-50' : ''
          }`}
          onClick={toggleSFX}
        >
          <img src={sfxIcon} className='h-4 w-5' />
        </button>
        <button
          id="plays"
          className="relative mx-2.5 sm:mx-0 my-2.5 flex h-6 w-8 settings-items rounded-md sm:rounded-l-3xl appearance-none border-none justify-center items-center"
          onClick={() => navigate('/recent-plays')}
        > 
          <img src={menuIcon} className='h-4 w-5'/>
        </button>
      </div>
      {/* <AudioPlayer /> */}
  </>
  );
};

export default Settings;
