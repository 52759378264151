import axios from 'axios';
import { api, coingecko_api, pepeChains, poolId } from '../constants';
import { BalancerSDK, BalancerSdkConfig, Network } from '@balancer-labs/sdk';
import { tokens, balancerLPTokenAddress, balancerVaultAddress } from '../constants';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { BigNumber, ethers } from 'ethers';

const balancerconfig = {
  network: Network.ARBITRUM,
  rpcUrl: `https://arb-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API}`,
};
const balancer = new BalancerSDK(balancerconfig);

// const base_url = 'https://3uk9ahutsg.execute-api.us-west-2.amazonaws.com/default';

const base_url = ' https://wi6e5ppi8l.execute-api.us-west-2.amazonaws.com';
const binance_url = 'https://api.binance.com/api/v3/ticker/price?symbol=';
//BTCUSDT

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

export const getNewPrice = async (url, dataObject) => {
  // const { data } = await axios.post(`${base_url}/${url}`, dataObject, config);
  const { data } = await axios.get(`${binance_url + dataObject?.currency}`);

  return data;
};

export const getBinancePrice = async (url) => {
  const { data } = await axios.get(url);

  return data;
};

export const getBinancePriceURL = async (url) => {
  const { data } = await axios.get(url);

  return data;
};

export const getLeverage = async () => {
  const { data } = await axios.get(api.offChainBet.getBetConstraintsEndpoint);
  const leverage = data.data[0].payout_bps
  let leverage_in_decimals = leverage / 10000;
  return (1 * leverage_in_decimals + leverage_in_decimals - 1.02) * 100;
};

export const getDollarValue = async (ids, lockingAmounts) => {
  try {
    // const pool = await balancer.pools.find(poolId);
    //   let spot_price = await pool.calcSpotPrice(
    //     balancer_pool_tokens[0], // WETH
    //     balancer_pool_tokens[1] //PEG
    //   );
    //     console.log("SPOT PRICE::",spot_price)

    const pool = await balancer.pools.find(poolId);
    const pegprice = pool.tokens[0]?.token?.latestUSDPrice;
    //const amount = await pool.calcSpotPrice(balancer_pool_tokens[0],balancer_pool_tokens[1])
    //console.log("SPOT PRICE:",amount.toString())

    const data = await axios.get(`${coingecko_api}?ids=${ids}&vs_currencies=usd`);
    // console.log('AXIOS CALL:', data);
    let output_data = data?.data;
    //return prices as array
    return [
      parseFloat(pegprice * lockingAmounts?.peg).toFixed(2),
      (parseFloat(output_data?.weth?.usd) * lockingAmounts?.weth).toFixed(3),
    ];
  } catch (error) {
    console.log(error);
  }
};

// Method to convert formatted an amount to dollar using the id
export const convertToDollarValue = async (ids, amount) => {
  try {

    const data = await axios.get(`${coingecko_api}?ids=${ids}&vs_currencies=usd`);
    // console.log('AXIOS CALL:', data);
    let output_data = data?.data;
    //return prices as array
    return (parseFloat(output_data?.weth?.usd) * amount).toFixed(3)
  } catch (error) {
    console.log(error);
  }
}


export const convertDollarValueToWeth = async (amount) => {
  try {

    const data = await axios.get(`${coingecko_api}?ids=weth&vs_currencies=usd`);
    // console.log('AXIOS CALL:', data);
    let output_data = data?.data;

    let wethValue = (amount / parseFloat(output_data?.weth?.usd)).toFixed(4)

    return ethers.utils.parseUnits(wethValue, 18);
  } catch (error) {
    console.log(error);
  }
}

export const getPegPrice = async () => {
  try {
    const pool = await balancer.pools.find(poolId);
    const pegPrice = pool.tokens[0]?.token?.latestUSDPrice;
    return pegPrice;
  } catch (error) {
    console.error('get peg price error:', error);
    return 0;
  }
};

export const getBalancerLpTokenPrice = async () => {
  const pool = await balancer.pools.find(poolId);

  const amountOfPegInPool = parseFloat(pool.tokens[0]?.balance);
  const amountOfWethInPool = parseFloat(pool.tokens[1]?.balance);

  const pegPrice = parseFloat(pool.tokens[0]?.token?.latestUSDPrice);
  const wethPrice = parseFloat(pool.tokens[1]?.token?.latestUSDPrice);
  const poolDollarValue = amountOfPegInPool * pegPrice + amountOfWethInPool * wethPrice;
  const lpTokenSupply = parseFloat(pool.totalLiquidity);
  const lpTokenPrice = poolDollarValue / lpTokenSupply;

  console.log('LP TOKEN PRICE:', lpTokenPrice);
  return lpTokenPrice;
};

export const getSelectedTokenData = (token) => {
  let data = tokens.find((item) => item.name == token);
  return data;
};


export const uploadShareImage = async (selectedFile, refcode) => {
  try {
    const s3Client = new S3Client({
      region: process.env.REACT_APP_AWS_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRETE,
      },
    });

    const file = selectedFile;

    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      Key: "share/" + file.name,
      Body: file.file,
    };

    const command = new PutObjectCommand(params);

    const output = await s3Client.send(command);

    //make api call with the new image url and referral code as params
    const url = "http://" + process.env.REACT_APP_AWS_S3_BUCKET + "/share/" + file.name
    //console.log("uploaded file URL:",url)
    const share_url = await axios.post(
      api.prod.share_api,
      { image: url, ref_code: refcode }
    );

    return share_url?.data
  } catch (error) {
    console.error("Error uploading file:", error);
    throw (error);
  }
}

export const calculatePoints = (amount, winlose) => {
  if (winlose === 0) {
    switch (amount) {
      case 5:
        return 2;
      case 10:
        return 6;
      case 20:
        return 16;
      case 50:
        return 50;
      case 75:
        return 90;
      case 100:
        return 160;
      default:
        return 0;
    }
  } else {
    switch (amount) {
      case 5:
        return 1;
      case 10:
        return 3;
      case 20:
        return 8;
      case 50:
        return 25;
      case 75:
        return 45;
      case 100:
        return 80;
      default:
        return 0;
    }
  }
};

export const getPlsDollarValue = async (ids, convertingPrice) => {
  try {
    const data = await axios.get(`${coingecko_api}?ids=${ids}&vs_currencies=usd`);
    let output_data = data?.data;
    return (parseFloat(output_data?.plutusdao?.usd) * convertingPrice?.plutus).toFixed(2);
  } catch (error) {
    console.log(error);
    return 0.00;
  }
};

export const getChainAddress = (chainId) => {
  const chainAddress = pepeChains.find((item) => item.chainId === chainId)

  return chainAddress.betContract;
}