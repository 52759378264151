import React, { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import '../styles/stake.css';
// import Wrapper from '../components/Wrapper';
// import TopBar from '../components/top-nav/top-bar';
import {
  blueETH,
  blueToken,
  bolt,
  crown,
  lock,
  main,
  pepe_top,
  pepe_top_base,
  finalmobilelogo,
  PEG_ETH3_,
  airdrop,
} from '../assets';
import { useMediaQuery, useDebounce } from 'usehooks-ts';
import { StoreContext } from '../utils/store';
import { useAccount, useSigner, useProvider, useNetwork, erc20ABI } from 'wagmi';
import { ethers } from 'ethers';
import { getPegPrice, getBalancerLpTokenPrice, getDollarValue } from '../services/service';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClaimAirdrop } from '../components/Claim';
import PepeMobileFooter from '../components/PepeMobileFooter';
import {
  baseContracts,
  arbitriumContracts,
  WETH,
  balancer_pool_tokens,
  referralContract,
  chain_ids,
} from '../constants';
import { pepeLockUpExtensionABI } from '../utils/pepe-lockup-extension-abi';
import { pepeStaking } from '../utils/pepeStaking';
import { pepeLock } from '../utils/pepeLock';
import { pepeTokenABI } from '../utils/pepeTokenABI';
import { feeDistributorABI } from '../utils/feeDistributorABI';
import { useSnackbar } from 'notistack';
import Navbar from '../components/Navbar/Navbar';
import { BalancerSDK, BalancerSdkConfig, Network } from '@balancer-labs/sdk';
import Settings from '../components/Settings/Settings';
import Lock from '../components/Lock';
import StakeTab from '../components/StakeTab';
import UPMobile from '../components/UPMobile';
import { pepeEsPegLockABI } from '../utils/pepeEsPegLockABI';
import { pepeEsPegStakingABI } from '../utils/pepeEsPegStakingABI';
import { referralABI } from '../utils/referralABI';
import { tokenDistributorABI } from '../utils/tokenDistributorABI';
//import { setIn } from 'formik';
import { cancel_icon_w_gradient } from '../assets';
import { dollarFormatter } from '../utils/dollarFormatter';
import { useStorage } from '../utils/StorageContext';
import { observer } from 'mobx-react-lite';

const config = {
  network: Network.ARBITRUM,
  rpcUrl: `https://arb-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API}`,
};
const balancer = new BalancerSDK(config);

const INITIAL_DEPOSIT = 10000;

const Stake = ({ tab }) => {
  const navigate = useNavigate();
  //const location = useLocation();
  const { data: signer } = useSigner();
  const { chain } = useNetwork();

  const {
    pepeLockingContractAddress,
    pepePool,
    pegContractAddress,
    pepeStakingContractAddress,
    feeDistributor,
    poolId,
    pepeEsPegLockUpContractAddress,
    pepeEsPegStakingContractAddress,
    tokenDistributorContractAddress,
    pepeProxyLPTokenAddress,
    pepeLockupExtensionContractAddress,
    pepeFeeDistributorV2Address,
  } = chain?.id == chain_ids[0] ? arbitriumContracts : baseContracts; //// @dev chain Id index 0 is arbitrium
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';

  const [currentTab, setCurrentTab] = useState();
  const [isOngoing, setIsOngoing] = useState(false);
  const query = useMediaQuery('(max-width: 640px)');
  const Store = useContext(StoreContext);

  useEffect(() => {
    if (chain?.id == chain_ids[0]) {
      setCurrentTab(tab);
    } else if (chain?.id == chain_ids[1]) {
      setCurrentTab('stake');
    }
  }, [tab]);

  const {
    // setStakeModal,
    // setLockModal,
    setClaimModal,
    setPromptType,
    setClaimTx,
    setClaimingAmounts,
    stakeModal,
    setIsEsPeg,
    setLockPPLPModal,
    //setLockingAmounts,
    lockModal,
    claimModal,
    setStoreState,
    reloadData,
    //claimableAmount,
    setPepePPLPReward,
  } = Store;
  const { address } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const provider = useProvider();

  //const { data: signer } = useSigner();
  const floatRegexp = useMemo(() => /^[+-]?\d*(?:[.,]\d*)?$/, []);

  const stakingContract = useMemo(
    () => new ethers.Contract(pepeStakingContractAddress, pepeStaking, provider),
    [provider]
  );
  const lockingContract = useMemo(
    () => new ethers.Contract(pepeLockingContractAddress, pepeLock, provider),
    [provider]
  );

  const pepeFeeDistributorV2Contract = useMemo(
    () =>
      new ethers.Contract(
        pepeFeeDistributorV2Address,
        [
          'function pepeStakingPendingRewards(address) public view returns (uint256)',
          'function pepeLockUpPendingRewards(address) public view returns (uint256)',
        ],
        provider
      ),
    [provider]
  );

  const esPegLockingContract = useMemo(
    () => new ethers.Contract(pepeEsPegLockUpContractAddress, pepeEsPegLockABI, provider),
    [provider]
  );

  const esPegStakingContract = useMemo(
    () => new ethers.Contract(pepeEsPegStakingContractAddress, pepeEsPegStakingABI, provider),
    [provider]
  );

  const userReferralContract = useMemo(() => new ethers.Contract(referralContract, referralABI, provider), [provider]);

  const pepePegWethContract = useMemo(
    () => new ethers.Contract(tokenDistributorContractAddress, tokenDistributorABI, provider),
    [provider]
  );

  const pegContract = useMemo(() => new ethers.Contract(pegContractAddress, pepeTokenABI, provider), [provider]);

  const feeDistributor_contract = useMemo(
    () => new ethers.Contract(feeDistributor, feeDistributorABI, provider),
    [provider]
  );

  const pplpTokenContract = useMemo(() => new ethers.Contract(pepeProxyLPTokenAddress, erc20ABI, provider), [provider]);

  const pepeLockUpExtensionContract = useMemo(
    () => new ethers.Contract(pepeLockupExtensionContractAddress, pepeLockUpExtensionABI, provider),
    [provider]
  );

  // const [totalReward, setTotalReward] = useState(0.0);
  // const [locking_, setLockingReward] = useState(0.0);
  // const [staking_, setStakingReward] = useState(0.0);
  const [totalStaked, setTotalStaked] = useState(0.0);
  const [mytotalStaked, setMyTotalStaked] = useState(0.0);
  const [peg_input_event, setPEGInputEvent] = useState(null);
  const [weth_input_event, setWETHInputEvent] = useState(null);
  const [formattedInput, setFormattedAmount] = useState('');
  const [peg_amount, setAmount] = useState(0);
  // const [poolValue, setPoolValue] = useState('TBD');
  // const [volume_24h, setVolume_24h] = useState('TBD');
  // const [fees_24h, setFees_24h] = useState('TBD');
  const [apr, setAPR] = useState('TBD');
  const [poolBalance, setPoolBalance] = useState(0.0);
  const [protocolRev, setProtocolRev] = useState(0.0);
  const [peg_balance, setPegBalance] = useState(0.0);
  const [weth_balance, setWethBalance] = useState(0.0);
  const [weth_amount, setWETHAmount] = useState(0);
  const [calculating, setCalculating] = useState(false);
  const [refresh, refreshData] = useState(false);
  const [userLockDetails, setUserLockDetails] = useState(null);
  const [timetillunlock, setTimeUntilUnlock] = useState('');
  const [timeLocked, setDateLocked] = useState('');
  const [totalUsdcReward, setTotalUsdcReward] = useState(0.0);
  const [totalPegReward, setTotalPegReward] = useState(0.0);
  const [totalPPLPReward, setTotalPPLReward] = useState(0.0);
  const [priceOfPeg, setPriceOfPeg] = useState(0.0);
  const [priceOfPPLP, setPriceOfPPLP] = useState(0.0); ///@notice 1 PPLP = 1 balancer PEG-WETH pool token
  const [pplpPromptType, setPPLPPromptType] = useState(false);
  //const [refresh, setRefresh] = useState(false);

  const debouncedValue_peg = useDebounce(peg_input_event, 2000);
  const debouncedValue_weth = useDebounce(weth_input_event, 2000);

  useEffect(() => {
    handleChange(peg_input_event, 'PEG');
  }, [debouncedValue_peg]);

  useEffect(() => {
    handleChange(weth_input_event, 'WETH');
  }, [debouncedValue_weth]);

  //const [pendingStakeRewards, setPendingStakeRewards] = useState(0.0);

  const formatter = new Intl.NumberFormat('en-US');

  const calculateBalance = async (amount_, token) => {
    try {
      setCalculating(true);
      const pool = await balancer.pools.find(poolId);
      let amount_of_token = ethers.utils.parseUnits(amount_, 18);
      const { tokens, amounts } = await pool.calcProportionalAmounts(token, amount_of_token);
      console.log(tokens, amounts);
      //index 0 is peg and index1 is weth
      setCalculating(false);
      setAmount(ethers.utils.formatUnits(amounts[0]), 18);
      setWETHAmount(ethers.utils.formatUnits(amounts[1]), 18);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = useCallback(
    async (e, selected_token) => {
      if (e?.target?.value) {
        const value = e?.target?.value;
        const valid = floatRegexp.test(value.replace(/,/g, ''));
        if (!valid) e.preventDefault();

        if (valid) {
          const formatted = value.replace(/,/g, '');
          if (selected_token === 'PEG') {
            setAmount(value.replace(/,/g, '') || '');
            setFormattedAmount(formatted);
          } else {
            setWETHAmount(value.replace(/,/g, '') || '');
          }

          console.log(value.replace(/,/g, '') || '', formatted);

          if (formatted > 0) {
            const tkn_address = selected_token == 'WETH' ? balancer_pool_tokens[0] : balancer_pool_tokens[1]; //index 0=weth,index 1=peg
            //make calculation for the amount of weth/peg needed
            console.log(formatted, tkn_address);
            await calculateBalance(formatted, tkn_address);
          }
        }
        if (value === '') {
          setAmount('');
          setFormattedAmount('');
        }
      }
    },
    [floatRegexp]
  );

  const getUserPEGBalance = async () => {
    try {
      const peg_balance = await pegContract.balanceOf(address);
      setPegBalance(ethers.utils.formatUnits(peg_balance, 18).toLocaleString());
    } catch (error) {
      console.log('get peg balance', error);
    }
  };

  const getUserWETHBalance = async () => {
    try {
      const weth_contract = new ethers.Contract(WETH, erc20ABI, provider);
      const peg_balance = await weth_contract.balanceOf(address);
      setWethBalance(ethers.utils.formatUnits(peg_balance, 18).toLocaleString());
    } catch (error) {
      console.log('get weth balance', error);
    }
  };

  const pegPrice = useCallback(async () => {
    try {
      const price = await getPegPrice();
      if (price) {
        setPriceOfPeg(parseFloat(price));
        return;
      }
      setPriceOfPeg(0.0);
    } catch (error) {
      setPriceOfPeg(0.0);
      console.log('get peg price', error);
    }
  }, []);

  const pplpPrice = useCallback(async () => {
    try {
      const price = await getBalancerLpTokenPrice();
      if (price) {
        setPriceOfPPLP(parseFloat(price));
        return;
      }
      setPriceOfPPLP(0.0);
    } catch (error) {
      setPriceOfPPLP(0.0);
      console.log('get pplp price', error);
    }
  }, []);

  const init = async () => {
    try {
      const { stakingReward, lockingReward, esPegLockingReward, esPegStackingReward, referralReward, pepePPLPReward } =
        await pendingRewards();

      setTotalUsdcReward(
        parseFloat(
          ethers.utils.formatUnits(stakingReward.add(lockingReward).add(esPegLockingReward).add(referralReward), 6)
        ).toFixed(4)
      );

      setTotalPegReward(parseFloat(ethers.utils.formatUnits(esPegStackingReward, 18)).toFixed(4));

      setTotalPPLReward(parseFloat(ethers.utils.formatUnits(pepePPLPReward, 18)).toFixed(5));

      console.log({
        PEG: parseFloat(ethers.utils.formatUnits(esPegStackingReward, 18)),
        PPL: parseFloat(ethers.utils.formatUnits(pepePPLPReward, 18)).toFixed(5),
      });

      //get total staked
      const total_staked = await stakingContract.totalStaked();
      // console.log({ total_staked, format: ethers.utils.formatUnits(total_staked, 18).toLocaleString() });
      setTotalStaked(ethers.utils.formatUnits(total_staked, 18).toLocaleString());
      //get my total staked
      const my_total_staked = await stakingContract.getUserStake(address);
      setMyTotalStaked(ethers.utils.formatUnits(my_total_staked, 18).toLocaleString());
      //protocal revenue
      //get pepepool balance
      const poolBln = await pegContract.balanceOf(pepePool);

      //get pepe fee wallet balance
      const feeWalletBln = await feeDistributor_contract.getLastBalance();
      const protocol_revenue = poolBln.add(feeWalletBln).sub(ethers.utils.parseUnits(`${INITIAL_DEPOSIT}`, 18));
      setProtocolRev(formatter.format(ethers.utils.formatUnits(protocol_revenue, 18)));
      setPoolBalance(ethers.utils.formatUnits(poolBln, 18).toLocaleString());
      refreshData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const pendingRewards = useCallback(async () => {
    try {
      let stakingReward;
      if (chain?.id == chain_ids[0]) {
        stakingReward = await pepeFeeDistributorV2Contract.pepeStakingPendingRewards(address); // USDC
      } else {
        stakingReward = await stakingContract.pendingRewards(address); // USDC
      }
      const lockingReward = await pepeFeeDistributorV2Contract.pepeLockUpPendingRewards(address); // USDC

      const esPegLockingReward = await esPegLockingContract.getTotalPendingUsdcRewards(address);
      const esPegStackingReward = await esPegStakingContract.pendingRewards(address); // PEG
      const referralReward = await userReferralContract['getClaimableUsdc(address)'](address); // USDC
      const pepePPLPReward = await pepePegWethContract.claimableAmount(pepeProxyLPTokenAddress, address); //

      return {
        stakingReward,
        lockingReward,
        esPegLockingReward,
        esPegStackingReward,
        referralReward,
        pepePPLPReward,
      };
    } catch (error) {
      console.log('ERROR:', error);
    }
  }, [
    address,
    esPegLockingContract,
    esPegStakingContract,
    pepeFeeDistributorV2Contract,
    pepePegWethContract,
    userReferralContract,
  ]);

  const formatDate = (unixtimetamp) => {
    const date = new Date(unixtimetamp * 1000);
    // Define an array of month names
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    // Get the day, month, and year from the Date object
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    // Add the appropriate suffix to the day
    let daySuffix;
    if (day === 1 || day === 21 || day === 31) {
      daySuffix = 'st';
    } else if (day === 2 || day === 22) {
      daySuffix = 'nd';
    } else if (day === 3 || day === 23) {
      daySuffix = 'rd';
    } else {
      daySuffix = 'th';
    }

    // Return the formatted string
    return `${day}${daySuffix} ${month}, ${year}`;
  };

  const getUserLockDetails = useCallback(async () => {
    let userLockDetails_ = await lockingContract.lockDetails(address);
    if (!userLockDetails_.totalLpShare.isZero()) {
      let usdValues = await getDollarValue('weth', {
        weth: ethers.utils.formatUnits(userLockDetails_.wethLocked, 18),
        peg: ethers.utils.formatUnits(userLockDetails_.pegLocked, 18),
      });
      let modifiedData = {};
      modifiedData = {
        ...modifiedData,
        ...userLockDetails_,
        pegUsd: usdValues ? usdValues[0] : '0',
        wethUsd: usdValues ? usdValues[1] : '0',
      };
      setUserLockDetails(modifiedData);
      setIsOngoing(true);
      setStoreState('userLockingState', modifiedData);

      // calculate time until unlock
      const dateObj = new Date(modifiedData.unlockTimestamp * 1000);
      const month = dateObj.getUTCMonth() - new Date(modifiedData.lockedAt * 1000).getUTCMonth();
      const days = Math.floor(modifiedData.lockedAt / (1000 * 60 * 60 * 24));
      const hours = dateObj.getUTCHours();
      const minutes = dateObj.getUTCMinutes();

      let fd = formatDate(modifiedData.lockedAt);

      setDateLocked(fd);
      setTimeUntilUnlock(`${month}mn ${days}d ${hours}h ${minutes}m`);
    }
  }, [address, lockingContract]);

  useEffect(() => {
    pegPrice();
    pplpPrice();
    getUserPPLPLockDetails();
    init();
    getUserPEGBalance();
    getUserWETHBalance();
    getUserLockDetails();
    console.log('refreshed');
  }, [stakingContract, lockingContract, stakeModal, lockModal, currentTab, reloadData, refresh, claimModal]);
  const isMobile = useMediaQuery('(max-width: 640px)');

  const claimStakingReward = async () => {
    enqueueSnackbar('Claiming PEG Staking Rewards...', {
      variant: 'success',
    });
    const { stakingReward } = await pendingRewards();
    const stakingContract_ = new ethers.Contract(pepeStakingContractAddress, pepeStaking, signer);

    if (!stakingReward.isZero()) {
      let estimateGas;
      try {
        estimateGas = await stakingContract_.estimateGas.claimRewards();
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (error) {
        estimateGas = 3e6;
      }
      try {
        const tx = await stakingContract_.claimRewards({ gasLimit: estimateGas });
        const receipt = await tx.wait();

        enqueueSnackbar(`Successfully claimed PEG Staking Rewards. Tx hash: ${receipt.transactionHash}`, {
          variant: 'success',
        });

        // return;
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed in claiming PEG Staking Rewards', {
          variant: 'error',
        });
        // return;
      } finally {
        refreshData(!true);
      }
    } else {
      enqueueSnackbar('Rewards too low for claim...', {
        variant: 'success',
      });
    }
  };

  const claimLockingReward = async () => {
    enqueueSnackbar('Claiming PEG Locking Rewards...', {
      variant: 'success',
    });
    const { lockingReward } = await pendingRewards();
    const pepeLockUp_ = new ethers.Contract(pepeLockingContractAddress, pepeLock, signer);
    if (!lockingReward.isZero()) {
      let estimateGas;
      try {
        estimateGas = await pepeLockUp_.estimateGas.claimUsdcRewards();
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (error) {
        estimateGas = 3e6;
      }
      try {
        const tx = await pepeLockUp_.claimUsdcRewards({ gasLimit: estimateGas });
        const receipt = await tx.wait();
        enqueueSnackbar(`Successfully claimed PEG Locking Rewards. Tx hash: ${receipt.transactionHash}`, {
          variant: 'success',
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed in claiming PEG Locking Rewards', {
          variant: 'error',
        });
      }
    }
  };

  const claimReferralReward = async () => {
    enqueueSnackbar('Claiming referral USDC rewards...', {
      variant: 'success',
    });

    const { referralReward } = await pendingRewards();
    if (!referralReward.isZero()) {
      let estimateGas;
      const referralContract_ = new ethers.Contract(referralContract, referralABI, signer);
      try {
        estimateGas = await referralContract_.estimateGas.claimAll();
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (error) {
        estimateGas = 3e6;
      }
      try {
        const tx = await referralContract_.claimAll({ gasLimit: estimateGas });
        const receipt = await tx.wait();
        enqueueSnackbar(`Successfully claimed referral USDC rewards, Tx hash: ${receipt.transactionHash}`, {
          variant: 'success',
        });
        console.log(receipt);
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed to claim referral USDC rewards', {
          variant: 'error',
        });
      }
    }
  };

  const claimEsPegLockingReward = async () => {
    enqueueSnackbar('Claiming esPeg Locking Rewards...', {
      variant: 'success',
    });
    const { esPegLockingReward } = await pendingRewards();
    if (!esPegLockingReward.isZero()) {
      const esPegLocking_ = new ethers.Contract(pepeEsPegLockUpContractAddress, pepeEsPegLockABI, signer);
      let estimateGas;
      try {
        estimateGas = await esPegLocking_.estimateGas.claimAllUsdcRewards();
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (error) {
        estimateGas = 3e6;
      }
      try {
        const tx = await esPegLocking_.claimAllUsdcRewards({ gasLimit: estimateGas });
        const receipt = await tx.wait();
        enqueueSnackbar(`Successfully claimed esPeg Locking Rewards, Tx hash: ${receipt.transactionHash}`, {
          variant: 'success',
        });
        // setClaimTx(receipt.transactionHash);
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed to claim esPeg Locking Rewards', {
          variant: 'error',
        });
      }
    }
  };

  const handleAllUsdcClaims = async () => {
    await claimStakingReward();
    await claimLockingReward();
    await claimEsPegLockingReward();
    await claimReferralReward();
    refreshData(!true);
    setClaimingAmounts(totalUsdcReward);
    // setClaimModal(true);
  };

  const handleAllPegClaims = async () => {
    enqueueSnackbar('Claiming esPeg Staking Rewards...', {
      variant: 'success',
    });
    const { esPegStackingReward } = await pendingRewards();
    if (!esPegStackingReward.isZero()) {
      const esPegStaking_ = new ethers.Contract(pepeEsPegStakingContractAddress, pepeEsPegStakingABI, signer);
      let estimateGas;
      try {
        estimateGas = await esPegStaking_.estimateGas.claimAll();
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (error) {
        estimateGas = 3e6;
      }
      try {
        const tx = await esPegStaking_.claimAll({ gasLimit: estimateGas });
        const receipt = await tx.wait();
        setClaimingAmounts(totalPegReward);
        setClaimTx(receipt.transactionHash);
        setClaimModal(true);
        refreshData(!refresh);
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed in claiming PEG Staking Rewards', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('Rewards too low for claim...', {
        variant: 'success',
      });
    }
  };

  const getUserPPLPLockDetails = useCallback(async () => {
    const userPPLPLockDetails = await pepeLockUpExtensionContract.extensionLockDetails(address);
    if (userPPLPLockDetails.amount.isZero()) return;
    setPPLPPromptType(true);
  }, [address, pepeLockUpExtensionContract]);

  const handleAllPPLPClaims = async () => {
    enqueueSnackbar('Claiming and locking PPLP rewards...', {
      variant: 'success',
    });
    const { pepePPLPReward } = await pendingRewards();
    setPepePPLPReward(pepePPLPReward);

    if (!pepePPLPReward.isZero()) {
      ///@dev notice, uses the universal token distributor contract
      const pplpDistributorContract_ = new ethers.Contract(
        tokenDistributorContractAddress,
        tokenDistributorABI,
        signer
      );

      let estimateGas;
      try {
        estimateGas = await pplpDistributorContract_.estimateGas.claim(pepeProxyLPTokenAddress);
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (error) {
        estimateGas = 3e6;
      }

      try {
        const tx = await pplpDistributorContract_.claim(pepeProxyLPTokenAddress, { gasLimit: estimateGas });
        const receipt = await tx.wait();
        setClaimingAmounts(totalPPLPReward);
        setClaimTx(receipt.transactionHash);

        enqueueSnackbar(`Successfully claimed PPLP rewards, Tx hash: ${receipt.transactionHash}`, {
          variant: 'success',
        });
        setIsEsPeg(false);
        setLockPPLPModal(true);
        setPromptType(pplpPromptType);
      } catch (error) {
        console.log('pplp claim error: ', error);
        enqueueSnackbar('Failed to claim PPLP rewards', {
          variant: 'error',
        });

        refreshData(!refresh);
      }
    }
  };

  return (
    <>
      <div
        className="items-center md:container md:mx-auto"
        style={{
          background: query ? '#09031D' : `url(${main}) no-repeat fixed`,
          backgroundSize: 'cover',
          display: 'flex',
          maxWidth: '1550',
          minWidth: '1330',
          maxHeight: '980',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Navbar />
        {isMobile && <img src={arbsOrBase === 'base' ? pepe_top_base : finalmobilelogo} className="my-5 w-[95%] pointer-events-none" alt="Logo" />}
        <div className="stake-bg m-auto w-[90%] relative sm:mt-32 mb-4">
          {!isMobile && (
            <img
              src={arbsOrBase === 'base' ? pepe_top_base : pepe_top}
              className="top-[-6rem] w-[270px] sm:w-auto sm:top-[-14rem] left-0 right-0 block mx-auto absolute pointer-events-none"
              alt="Logo"
            />
          )}
          <span
            className="w-[14px] sm:w-auto absolute top-4 right-4 text-white cursor-pointer"
            onClick={() => navigate('/slot')}
          >
            <img src={cancel_icon_w_gradient} alt="" />
          </span>
          <p className='flex flex-col sm:flex-row sm:gap-1 items-center justify-center pb-5 px-5 text-3xl text-center sm:text-left sm:text-5xl font-["Tourney"] font-extrabold gradient-text uppercase mt-24'>
            {currentTab === 'claim' ? (
              <>
                <span>Claim What is Yours!</span>
              </>
            ) : (
              <>
                {/* <span>Grow a PEG,</span>
                <span>will ya?</span> */}

                <span className="text-center">MAXIMIZE THE VALUE OF YOUR PEG!</span>
              </>
            )}
          </p>
          <p className="px-10 pb-5 text-base text-center text-white barlow">
            {currentTab === 'claim' ? (
              <>
                <span>All your Airdrops in one Place</span>
              </>
            ) : (
              <>
                <span>Stake PEG to earn 10% Protocol Fees. Lock PEG for 24 weeks to earn 30% Protocol Fees.</span>
              </>
            )}
          </p>
          <div className="flex flex-wrap w-[90%] sm:w-3/4 mx-auto mb-5 sm:font-bold text-white barlow justify-between flex-row gap-4 sm:gap-0">
            <button
              className={`flex-1 rounded-lg mr-0 sm:mr-1 py-2 sm:py-2 sm:px-[30px] flex items-center justify-center sm:w-[200px] ${
                currentTab === 'stake'
                  ? 'bg-white/[0.05]'
                  : 'bg-[rgba(9,3,29,0.01)] border-[rgba(255,255,255,0.05)] border-2 border-solid shadow-lg'
              }`}
              onClick={() => {
                setCurrentTab('stake');
                navigate('/stake');
              }}
            >
              <img src={bolt} className="self-center inline-block mr-2" alt="" />
              Stake
            </button>
            <button
              className={`${
                chain?.id == chain_ids[1] ? 'cursor-not-allowed' : 'cursor-auto'
              } flex-1 rounded-lg mx-0 sm:mx-1 py-2 sm:py-2 sm:px-[30px] flex items-center justify-center sm:w-[200px] ${
                currentTab === 'lock'
                  ? 'bg-white/[0.05]'
                  : 'bg-[rgba(9,3,29,0.01)] border-[rgba(255,255,255,0.05)] border-2 border-solid shadow-lg'
              }`}
              onClick={() => {
                chain?.id == chain_ids[0] ? setCurrentTab('lock') : setCurrentTab('stake');
                chain?.id == chain_ids[0] ? navigate('/lock') : navigate('/stake');
              }}
            >
              <img src={crown} className="self-center inline-block mr-2" alt="" />
              Lock<span className="hidden sm:inline-block">&nbsp;/ LP</span>
            </button>
            <button
              className={` ${
                chain?.id == chain_ids[1] ? 'cursor-not-allowed' : 'cursor-auto'
              } flex-1 rounded-lg ml-0 sm:ml-1 py-2 sm:py-2 sm:px-[30px] flex items-center justify-center sm:w-[200px] ${
                currentTab === 'claim'
                  ? 'bg-white/[0.05]'
                  : 'bg-[rgba(9,3,29,0.01)] border-[rgba(255,255,255,0.05)] border-2 border-solid shadow-lg'
              }`}
              onClick={() => {
                chain?.id == chain_ids[0] ? setCurrentTab('claim') : setCurrentTab('stake');
                chain?.id == chain_ids[0] ? navigate('/claim') : navigate('/stake');
              }}
            >
              <img src={airdrop} className="self-center inline-block mr-2" alt="" />
              Claim <span className="hidden sm:inline-block">&nbsp;Airdrops</span>
            </button>
          </div>
          {currentTab !== 'claim' ? (
            <div className="flex flex-col sm:w-[580px] lg:w-[642px] mx-5 sm:mx-auto mb-[30px] mt-[10px] items-center sm:mt-5 sm:mb-10 p-5 sm:py-5 sm:px-[95px] bg-deep-blue/40 rounded-2xl gap-y-5">
              <div className="barlow font-normal text-base leading-4 tracking-[0.02em] uppercase text-center text-[#E0DDFF]">
                MY Claimable Rewards
              </div>

              <div className="flex flex-col gap-y-[32px] sm:flex-row sm:gap-y-0 sm:gap-5 w-full sm:w-auto">
                <div className="flex sm:flex-col justify-between items-center gap-[10px] text-left sm:text-center">
                  <div className="flex flex-col gap-2">
                    <div className="font-[Tourney] font-bold text-xl leading-[18px] gradient-text-c">USDC</div>
                    <div className="flex flex-row gap-[10px] items-center barlow text-[#E0DDFF80]/50 text-[13px] leading-[13px] ">
                      <div className="font-light uppercase">Rewards</div>
                      <div className="font-medium">${totalUsdcReward}</div>
                    </div>
                  </div>
                  <button
                    onClick={() => handleAllUsdcClaims()}
                    className={`max-w-[130px] text-xs sm:text-base w-3/4 sm:min-w-[130px] rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center bg-white/[0.05] text-white
                  ${totalUsdcReward <= 0 ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}
                `}
                    disabled={totalUsdcReward <= 0 ? true : false}
                  >
                    Claim All
                  </button>
                </div>

                <div className="flex sm:flex-col justify-between items-center gap-[21px] text-left sm:text-center">
                  <div className="flex flex-col gap-2">
                    <div className="font-[Tourney] font-bold text-xl leading-[18px] gradient-text-c">PEG</div>
                    <div className="flex flex-row gap-[10px] items-center barlow text-[#E0DDFF80]/50 text-[13px] leading-[13px] ">
                      <div className="font-light uppercase">Vested</div>
                      <div className="flex flex-row gap-[6px]">
                        <div className="font-medium">{totalPegReward}</div>
                        <div className="hidden font-light sm:block">{dollarFormatter(totalPegReward * priceOfPeg)}</div>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => handleAllPegClaims()}
                    className={`max-w-[130px] text-xs sm:text-base w-3/4 sm:min-w-[130px] rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center bg-white/[0.05] text-white
                  ${totalPegReward <= 0 ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}
                `}
                    disabled={totalPegReward <= 0 ? true : false}
                  >
                    Claim All
                  </button>
                </div>

                <div className="flex sm:flex-col justify-between items-center gap-[10px] text-left sm:text-center">
                  <div className="flex flex-col gap-2">
                    <div className="font-[Tourney] font-bold text-xl leading-[18px] gradient-text-c">PEG-wETH</div>
                    <div className="flex flex-row gap-[10px] items-center barlow text-[#E0DDFF80]/50 text-[13px] leading-[13px] ">
                      <div className="font-light uppercase">Rewards</div>
                      <div className="flex flex-row gap-[6px]">
                        <div className="font-medium">{totalPPLPReward}</div>
                        <div className="hidden font-light sm:block">
                          {dollarFormatter(totalPPLPReward * priceOfPPLP)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      handleAllPPLPClaims();
                    }}
                    className={`max-w-[130px] text-xs sm:text-base w-3/4 sm:min-w-[130px] rounded-lg mx-0 sm:mx-1 py-2 sm:p-2 flex items-center justify-center bg-white/[0.05] text-white
                  ${totalPPLPReward <= 0 ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}
                `}
                    disabled={totalPPLPReward <= 0 ? true : false}
                  >
                    Claim & Lock
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          {currentTab === 'stake' ? (
            <StakeTab handleUsdcClaims={claimStakingReward} />
          ) : currentTab === 'lock' ? (
            <Lock />
          ) : (
            <ClaimAirdrop />
          )}
        </div>
        {isMobile ? (
          <>
            <div className=" flex justify-end w-[92%]">
              <div className="pt-5 mx-2 ">
                <UPMobile />
              </div>
            </div>

            <div className="w-full ">
              <PepeMobileFooter classes="pt-5 w-full" />
            </div>
          </>
        ) : (
          <Settings />
        )}
      </div>
    </>
  );
};

// export default Stake;
export default observer(Stake);
