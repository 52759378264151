export const EthereumIcon = ({ selected = false }) => {
  return (
    <svg width="auto" height="80%" viewBox="0 0 67 99" fill="none" xmlns="http://www.w3.org/2000/svg">
      {selected ? (
        <g filter="url(#filter0_d_5688_151708)">
          <path
            d="M33.3486 8.71191L32.8027 10.5662V64.3697L33.3486 64.9143L58.3231 50.1517L33.3486 8.71191Z"
            fill="url(#paint0_linear_5688_151708)"
          />
          <path
            d="M33.3481 8.71191L8.37305 50.1517L33.3481 64.9143V38.7996V8.71191Z"
            fill="url(#paint1_linear_5688_151708)"
          />
          <path
            d="M33.3487 69.6427L33.041 70.0179V89.1835L33.3487 90.0816L58.3384 54.8877L33.3487 69.6427Z"
            fill="url(#paint2_linear_5688_151708)"
          />
          <path d="M33.3481 90.0816V69.6427L8.37305 54.8877L33.3481 90.0816Z" fill="url(#paint3_linear_5688_151708)" />
          <path d="M33.3496 64.9145L58.3241 50.1519L33.3496 38.7998V64.9145Z" fill="url(#paint4_linear_5688_151708)" />
          <path d="M8.37305 50.1519L33.3481 64.9145V38.7998L8.37305 50.1519Z" fill="url(#paint5_linear_5688_151708)" />
        </g>
      ) : (
        <g filter="url(#filter1_di_5688_151710)">
          <path
            d="M33.0947 8.49414L32.5488 10.3485V64.1519L33.0947 64.6965L58.0692 49.9339L33.0947 8.49414Z"
            fill="#343434"
            fill-opacity="0.58"
          />
          <path
            d="M33.0942 8.49414L8.11914 49.9339L33.0942 64.6965V38.5818V8.49414Z"
            fill="#8C8C8C"
            fill-opacity="0.8"
          />
          <path
            d="M33.0948 69.4249L32.7871 69.8001V88.9657L33.0948 89.8639L58.0845 54.6699L33.0948 69.4249Z"
            fill="#8C8C8C"
            fill-opacity="0.88"
          />
          <path d="M33.0942 89.8639V69.4249L8.11914 54.6699L33.0942 89.8639Z" fill="#8C8C8C" fill-opacity="0.65" />
          <path d="M33.0957 64.6967L58.0702 49.9341L33.0957 38.582V64.6967Z" fill="#393939" fill-opacity="0.5" />
          <path d="M8.11914 49.9341L33.0942 64.6967V38.582L8.11914 49.9341Z" fill="#393939" fill-opacity="0.18" />
        </g>
      )}

      <defs>
        <filter
          id="filter0_d_5688_151708"
          x="0.373047"
          y="0.711914"
          width="65.9648"
          height="97.3701"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5688_151708" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5688_151708" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_5688_151708"
          x1="15.9243"
          y1="22.5002"
          x2="39.0578"
          y2="9.655"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00B7F0" />
          <stop offset="1" stop-color="#4819BE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5688_151708"
          x1="10.7146"
          y1="16.3381"
          x2="45.8785"
          y2="9.69883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00B7F0" />
          <stop offset="1" stop-color="#4819BE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5688_151708"
          x1="17.8911"
          y1="58.0529"
          x2="56.3074"
          y2="50.9398"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00B7F0" />
          <stop offset="1" stop-color="#4819BE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5688_151708"
          x1="10.7146"
          y1="59.6631"
          x2="76.937"
          y2="62.5563"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00B7F0" />
          <stop offset="1" stop-color="#4819BE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5688_151708"
          x1="13.2012"
          y1="36.2675"
          x2="54.6776"
          y2="29.8145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00B7F0" />
          <stop offset="1" stop-color="#4819BE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5688_151708"
          x1="10.7146"
          y1="42.3436"
          x2="54.3257"
          y2="43.3862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00B7F0" />
          <stop offset="1" stop-color="#4819BE" />
        </linearGradient>

        <filter
          id="filter1_di_5688_151710"
          x="4.11914"
          y="8.49414"
          width="57.9648"
          height="89.3701"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5688_151710" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5688_151710" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="3" dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5688_151710" />
        </filter>
      </defs>
    </svg>
  );
};
