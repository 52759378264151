import React, { useState, useContext } from 'react';
import { StoreContext } from '../../utils/store';
import { CreditsContext } from '../../utils/creditsContext';
import { api } from '../../constants';
import { useSnackbar } from 'notistack';
import { pill } from '../../assets/';
import pill_base from '../../assets/images/pill_base.svg';
import { useNetwork } from 'wagmi';
import './style.css';

const ExtendTimeButton = ({ setDuration, setHasExtendedBefore, disabled, duration, seconds, pills, setSyncing, syncing }) => {
	const Credits = useContext(CreditsContext);
	const Store = useContext(StoreContext);
	const { chain } = useNetwork();
	const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
	const { questChips, setQuestChips } = Credits;
	const { tradeVars, setTradeVars, lambdaResponseData } = Store;
	const [isClicked, setIsClicked] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	// const handleMouseLeave = () => {
	// 	setIsClicked(false);
	// };

	const handleOnClick = async () => {
		setSyncing(true);
		setHasExtendedBefore(true)
		if (tradeVars.time < 5) {
			enqueueSnackbar('Time cannot be extended', {
				variant: 'error',
			});
			return;
		}
		try {
			if (questChips < pills) {
				enqueueSnackbar('You do not have enough chips to extend time', {
					variant: 'error',
				});
				return;
			}
			setIsClicked(true);
			setTimeout(() => {
				setIsClicked(false);

			}, 1000)

			console.log('bet id: ', lambdaResponseData?.betId);
			console.log('extended time: ', seconds);
			console.log('quest chips: ', questChips);
			console.log('seconds type: ', typeof seconds);
			const response = await fetch(api.offChainBet.extendTimeEndpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					betId: lambdaResponseData?.betId,
					extendedTime: seconds,
				}),
			});

			const data = await response.json();
			console.log('extend time data: ', data);
			if (data?.extendedBy !== seconds) throw new Error(JSON.stringify(data));

			setQuestChips(questChips - pills);
			setDuration(duration + seconds);

			setTradeVars(tradeVars.amount, tradeVars.currency, tradeVars.time + seconds, tradeVars.longShort);

			enqueueSnackbar(`Time extended by ${seconds}s`, {
				variant: 'success',
			});
		} catch (err) {
			enqueueSnackbar('Something went wrong', {
				variant: 'error',
			});
			console.error('extend time error: ', err);
		} finally {
			setSyncing(false);
		}
	};

	return (
		<button
			disabled={disabled | syncing}
			onClick={() => {
				handleOnClick();
			}}
			// onMouseLeave={handleMouseLeave}
			className={`group py-1.5 px-4 xs:px-1 flex items-center justify-center lg:gap-x-2 gap-x-1.5 text-md lg:text-xl font-bold font-barlow w-[85px] lg:w-[114px] rounded-md border ${disabled ? 'border-t-[#545454] border-b-[#545454] border-l-[#545454] border-r-[#545454]' : 'border-t-[#00B7F0] border-b-[#4819BE] border-l-[#00B7F0] border-r-[#4819BE]'} hover:shadow-[0px_0px_4px_0px_#00B7F0_,_0px_0px_11px_0px_#00B7F0]`}
			style={buttonStyle}
		>
			<span className={`${disabled ? 'text-slate-600' : 'text-white'} ${isClicked ? 'transition duration-500 ease-in-out scale-150 bounceIn' : ''} `}>
				+{seconds}s
			</span>
			<div className={`flex text-[#FFB807] gap-[5px] w-fit ${isClicked ? 'hidden' : ''}`}>
				<p className="flex items-center">
					{/* <span className="hidden group-hover:block group-hover:transition group-hover:duration-500 group-hover:ease-in-out">-</span> */}
					{pills}
				</p>
				<img src={arbsOrBase === 'base' ? pill_base : pill} alt="" className="h-[16px] lg:h-[18px] my-auto" />
			</div>
		</button>
	);
};

export default ExtendTimeButton;

const buttonStyle = {
	background: 'linear-gradient(92.59deg, rgba(0, 183, 240, 0.15) 9.56%, rgba(72, 25, 190, 0.15) 106.95%)',
};
