import React from "react";
import "./Jackpot.css"

function Jackpot({ amount }) {

  const pepe = require("../../assets/images/PEPE_GAME_BODY.png")

  return (
    <>
  <div className="w-24 z-100 h-9 mx-auto relative -top-7 jackpot barlow">
    <div className="relative w-full text-center shadow-blue-dim z-20 rounded-lg p-1 border-bg">
        <span className="text-white font-bold text-xs bg-gradient-to-t from-[#29ABE2] to-[#090731] rounded-lg px-4 pt-2 pb-[0.4rem]">JACKPOT</span>
    </div>
    <div className="relative w-[160%] text-center bg-deep-blue rounded-xl chart box-border border-solid border-2 border-border-blue font-bold -top-2 -left-7 z-10 py-2.5">
        <span id="cash-prize" className="text-2xl">{amount}</span>
    </div>
    <img className="absolute top-2 -left-5 h-5 w-5 z-0" src={pepe} alt="pepe"/>
    <hr id='first-line' className='purple-line'/>
    <hr id='second-line' className='purple-line'/>
  </div>
  </>);
}

export default Jackpot;
