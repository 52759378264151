import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useContext } from 'react';
import { useConnect, useAccount, useDisconnect } from 'wagmi';
import { useNavigate, useLocation } from 'react-router-dom';
import { cancel_icon_w_gradient } from '../../assets';
import GradientBox from '../../../src/components/GradientBox/GradientBox';
import LoaderIcon from '../LoaderIcon';
import { StoreContext } from '../../utils/store';
import { checkrefreeURL, addreferURL, permission_API, api } from '../../constants';
import axios from 'axios';
import { useStorage } from '../../utils/StorageContext';

export default function PulloutModal({ open, isOpen }) {
  const navigate = useNavigate();
  const { search: ref } = useLocation();
  const store = useContext(StoreContext);
  const { setReferralModal, setDisclaimerModal } = store;
  const { address, connector, isConnected } = useAccount();
  const { connect, connectors, isLoading, pendingConnector } = useConnect();
  const { disconnect } = useDisconnect();

  //Method to close connect Wallet Modal
  const closeModal = () => {
    open(false);
    localStorage.setItem("pullout-modal","hide")
    // navigate('/slot');
  };


  useEffect(() => {
    // if (isConnected) {
    //   //console.log(window.location);
    //   if (window.location.pathname === '/') {
    //     navigate('/slot');
    //   }
    //   open(false);
    // }
  }, [isConnected]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-90" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full  items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="transform transition-all relative w-full">
                <div className="relative">
                  <div>
                    <GradientBox
                      classes="relative w-[87%] sm:w-1/2 sm:min-w-[37rem] max-w-[40rem] mx-auto py-12"
                      roundedClass="rounded-[0.625rem]"
                    >
                      <div className="text-white py-[2rem] sm:pt-[1.375rem] sm:pb-[2rem] px-5 sm:px-[2.5rem]">
                        <div className="flex justify-end mb-5 sm:-[0.688rem]">
                          <button onClick={closeModal}>
                            <img src={cancel_icon_w_gradient} alt="cancel" className="w-4 h-auto sm:w-auto" />
                          </button>
                        </div>

                        <h5 className='gradient-text font-["Tourney"] font-bold text-[1.75rem] sm:text-[2.75rem] leading-[1.925rem] sm:leading-[4.606rem] tracking-[0.08em] text-center mb-5'>
                          MISS YOUR FUNDS?
                        </h5>

                        <div className='sm:px-8'>
                          <p className="text-[1.4rem] font-bold mb-5">Pepe's Game has upgraded and migrated now!</p>

                          <p className='mb-5 text-start font-["manrope"]'>
                            Rest assured that your staking and locking activities remain unaffected. Can't see your
                            funds? Check the old game page at{' '}
                            <a className="gradient-text-c" target="__blank" href="https://pullout.pepesgame.com">
                              pullout.pepesgame.com
                            </a>{' '}
                            to withdraw your funds and deposit them here (app.pepesgame.com) to enjoy the upgrades. See
                            you soon!
                          </p>
                        </div>

                        <div className={`flex flex-col-reverse sm:flex-row sm:flex barlow justify-center py-8 sm:gap-8`}>
                          <button
                           onClick={()=>{
                            open(false)
                            localStorage.setItem("pullout-modal","hide")
                           }}
                            className={`disabled:cursor-not-allowed relative connect-wallet-gradient-shadow flex justify-center min-w-[8rem] sm:min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[0.875rem] sm:mb-0`}
                          >
                            <span className='button-gradient-text font-bold text-[1.2rem] leading-5'>Close</span>
                          </button>

                            <div className="relative">
                              <div className={`z-[100] relative`}>
                                <div className="items-center justify-center sm:flex barlow"></div>
                                <button
                                  onClick={()=>{
                                    localStorage.setItem("pullout-modal","hide")
                                    window.open("https://pullout.pepesgame.com","__blank")
                                    open(false)
                                  }}
                                  className={`disabled:cursor-not-allowed relative connect-wallet-gradient-shadow flex justify-center min-w-[8rem] sm:min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[0.875rem] sm:mb-0`}
                                >
                                  <span className='button-gradient-text font-bold text-[1.2rem] leading-5'>Go to Withdrawal</span>
                                </button>
                              </div>
                            </div>
                          </div>
                 
                      </div>
                    </GradientBox>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
