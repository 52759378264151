import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useAudioPlayer, AudioPlayerProvider, useAudioPosition } from 'react-use-audio-player';
import { StoreContext } from '../../utils/store';
import { useStorage } from '../../utils/StorageContext';
import { WIN_LONG, WIN_SHORT } from '../../constants';

const AudioPlayer = () => {
  const Store = useContext(StoreContext);
  const { track, setTrackPosition, trackPosition } = Store;
  const [prevPosition, setPrevPosition] = useState(0);
  const {autoplay, vol} = useStorage()

  const { togglePlayPause, ready, loading, playing, error, play, volume, fade } = useAudioPlayer({
    src: track,
    format: 'mp3',
    autoplay: autoplay === 'on' ? true : false,
    html5: true,
    loop: ![WIN_LONG, WIN_SHORT].includes(track),
    onplay: (play) => {
      // console.log('playing........', play);
    },
    onerror: (error) => {
      // console.log('error..........', error);
    },
    onload: (load) => {
      setPrevPosition(trackPosition);
    }
  });

  document.addEventListener('visibilitychange', ()=>{
    if(document.visibilityState === 'hidden'){
      volume(0)
    } else{
      volume(vol)
    }
  })

  const { seek, position, duration, percentComplete } = useAudioPosition({ highRefreshRate: true });

  useEffect(() => {
    if (autoplay === 'off') {
      // pause if playing
      if (playing) {
        togglePlayPause();
      }
    } else if (autoplay === 'on') {
      if (!playing) {
        togglePlayPause();
      }
    }
  }, [autoplay]);

  useEffect(() => {
    if (autoplay === 'on') {
      if (prevPosition) {
        console.log({ prevPositionSought: prevPosition });
        seek(prevPosition);
      }
      volume(vol)
    }
  }, [autoplay, prevPosition]);

  useEffect(() => {
    if (autoplay === 'on') {
      setTrackPosition(duration * percentComplete * 0.01);
    }
  }, [autoplay, position]);

  useEffect(() => {
    if (error) {
      console.log({'MUSIC ERROR': error})
    }
  }, [error]);

  useEffect(() => {
    volume(vol)
  }, [vol])

  return ;
};

export default AudioPlayer;
