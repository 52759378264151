import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { cancel_icon_w_gradient, pepeFlex } from '../../assets';
import Disclaimer from '../Disclaimer';

const DisclaimerModal = ({ isOpen, open }) => {

    const [openDialogue, setOpenDialogue] = useState(null)

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1000]" onClose={()=>setOpenDialogue(true)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-80" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[rgba(0,0,0,0.75)] flex scrollable-modal">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className={`m-auto`}>
              {/* main container */}
                <Disclaimer />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DisclaimerModal;
