import React, { useContext, useMemo, useState, Fragment, useEffect, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { cancel_icon_w_gradient, smartPepe } from '../../assets';
import { StoreContext } from '../../utils/store';
import { enqueueSnackbar } from 'notistack';
import { BigNumber, ethers } from 'ethers';
import { erc20ABI, useAccount, useProvider, useSigner } from 'wagmi';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import { plsAutoCompounderContractAddress, plsTokenAddress } from '../../constants';
import { plsAutoCompounderABI } from '../../utils/plsAutoCompounderABI';
import { getPlsDollarValue } from '../../services/service';
import { useMediaQuery } from 'usehooks-ts';
import { isAddress } from 'ethers/lib/utils';

const VaultModal = ({ open, isOpen, isDeposit }) => {
  const store = useContext(StoreContext);
  const {
    vaultData,
    vaultModal,
  } = store;
  const { data: signer } = useSigner();
  const { address: userAddress } = useAccount();
  const provider = useProvider();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hash, setHash] = useState('');
  const [isApproved, setIsApproved] = useState(false);
  const [amount, setAmount] = useState(0);
  const [formattedInput, setFormattedAmount] = useState(0);
  const [allowance, setAllowance] = useState(BigNumber.from(0));
  const [amountDepWith, setAmountDepWith] = useState(0.0);
  const [amountDepWithBigNumber, setAmountDepWithBigNumber] = useState(BigNumber.from(0.0));
  const isMobile = useMediaQuery('(max-width: 640px)');
  const [isRestaking, setIsRestaking] = useState(false);

  const formatter = new Intl.NumberFormat('en-US');
  function addZeroes(num) {
    const dec = num.split('.')[1]
    const len = dec && dec.length > 2 ? dec.length : 2
    return Number(num).toFixed(len)
  }

  const floatRegexp = useMemo(() => /^[+-]?\d*(?:[.,]\d*)?$/, []);

  const plsAutoCompounderContract = useMemo(
    () => new ethers.Contract(plsAutoCompounderContractAddress, plsAutoCompounderABI, signer),
    [signer]
  );

  const plsTokenContract = useMemo(() => new ethers.Contract(plsTokenAddress, erc20ABI, signer), [signer]);

  useEffect(() => {
    const amount = vaultData.amount;
    const bigNum = vaultData.amountBigNumber;
    setFormattedAmount(amount);
    setAmountDepWithBigNumber(bigNum);
    handleCheckingConversion(amount);
  }, [vaultData.amount, vaultData.amountBigNumber, vaultModal]);

  useEffect(() => {
    getUserAllowance();
  }, [amount, refresh, vaultData]);

  const getUserAllowance = useCallback(async () => {
    try {
      const allowance = await plsTokenContract.allowance(userAddress, plsAutoCompounderContractAddress);
      setAllowance(allowance);
      if (!amount) {
        if (allowance.gt(ethers.utils.parseUnits('50', 18))) {
          setIsApproved(true);
          return;
        }
        setIsApproved(false);
      } else {
        if (allowance.gte(ethers.utils.parseUnits(amount.toString(), 18))) {
          setIsApproved(true);
          return;
        }
        setIsApproved(false);
      }
    } catch (error) {
      console.error('Vault Allowance Error', error);
    }
  }, [plsTokenContract, userAddress, amount]);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      const valid = floatRegexp.test(value.replace(/,/g, ''));
      if (!valid) e.preventDefault();
      if (valid) {
        const formatted = value.replace(/,/g, '');
        let newAmount = value.replace(/,/g, '') || '';

        if (newAmount.toString().length > 18) {
          newAmount = parseFloat(
            newAmount.toString().split('.')[0] + '.' + newAmount.toString().split('.')[1].slice(0, 18)
          );
        }
        setAmount(newAmount);
        setFormattedAmount(formatted);
        handleCheckingConversion(value);
      }
      if (value === '') {
        setAmount('');
        setFormattedAmount('');
        setAmountDepWith(0.0);
      }
    },
    [floatRegexp]
  );

  const handleCustomAmountChange = async (percentage) => {
    const value = amountDepWithBigNumber;
    if (value.gt(BigNumber.from(0))) {
      const percentageAmount = value.mul(percentage).div(1e4);
      const formatted = ethers.utils.formatUnits(percentageAmount, 18);
      setAmount(formatted);
      setFormattedAmount(formatted.toLocaleString());
      setAmountDepWith(await convertingPrice(formatted));
    }
  };

  const handleApprove = useCallback(async () => {
    let parsedAmount;
    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(realAmount.toString(), 18);
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(allowance)) {
      try {
        await plsTokenContract.approve(plsAutoCompounderContractAddress, ethers.constants.MaxUint256);
        const allowance = await plsTokenContract.allowance(userAddress, plsAutoCompounderContractAddress);
        setIsApproved(true);
        setAllowance(allowance);
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Approval failed.', {
          variant: 'error',
        });
        return;
      }
    }
  }, [amount, allowance, plsTokenContract, userAddress]);

  const handleDeposit = async () => {
    let parsedAmount;
    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(realAmount.toString(), 18);
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(BigNumber.from(0))) {
      let estimateGas;
      try {
        estimateGas = await plsAutoCompounderContract.estimateGas.stakePls(parsedAmount);
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (e) {
        estimateGas = 3e6;
      }

      try {
        let tx = await plsAutoCompounderContract.stakePls(parsedAmount, { gasLimit: estimateGas });
        const receipt = await tx?.wait();
        enqueueSnackbar('Deposit Successful.', {
          variant: 'success',
        });
        setHash(receipt.transactionHash);
        setIsSuccess(true);
        getUserAllowance();
        return;
      } catch (error) {
        console.log('errors:', error);
        enqueueSnackbar('Depositing PLS failed.', {
          variant: 'error',
        });
        return;
      }
    }
  };

  const convertingPrice = async (amount) => {
    const newAmount = {
      plutus: amount,
    };
    let prices = await getPlsDollarValue('plutusdao', newAmount);
    return prices;
  };

  const handleCheckingConversion = async (amount) => {
    const usdEquiv = await convertingPrice(amount);
    setAmountDepWith(usdEquiv);
  };

  const handleWithDrawalAndRestaking = async () => {
    const pendingRewards = await plsAutoCompounderContract.calculatePendingRewards();
    const plsStakedDetails = await plsAutoCompounderContract.plsStakeDetails(userAddress);
    const amountInContract = pendingRewards.add(plsStakedDetails?.plsStaked);
    await handleUnstake();
    const realAmount = amount === '' ? '0' : amount;
    const amountInputParsed = ethers.utils.parseUnits(realAmount.toString(), 18);
    const amountToRestake = amountInContract.sub(amountInputParsed);
    const displayAmount = ethers.utils.formatUnits(amountToRestake, 18);
    setAmount(displayAmount);
    enqueueSnackbar(`Withdrawal started, confirm withdrawal of ${displayAmount} in wallet`, {
      variant: 'info',
    });
    await handleApprove();
    await handleDeposit();
  }

  const handleUnstake = async() => {
    let estimateGas;
    try {
      estimateGas = await plsAutoCompounderContract.estimateGas.unStakePls();
      estimateGas = estimateGas.toNumber() + 1e4;
    } catch (e) {
      estimateGas = 3e6;
    }
    try {
        let tx = await plsAutoCompounderContract.unStakePls({ gasLimit: estimateGas });
        const receipt = await tx?.wait();
        return;
      } catch (error) {
        console.log('errors:', error);
        enqueueSnackbar('Unstaking PLS failed.', {
          variant: 'error',
        });
        return;
      }
  }

  return (
    <>
      {!isSuccess ? (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-[1000]" onClose={() => open(false)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto bg-deep-blue/[0.89] flex justify-center items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom={`${isSuccess ? 'opacity-100' : 'opacity-0'} scale-95`}
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`modal-dialog transition-all transform ${
                    isSuccess ? 'w-[739px] h-[600px]' : 'w-[628px] h-[450px] sm:h-[344px]'
                  } stake-modal flex max-[480px]:w-4/5 relative`}
                >
                  <div className="flex flex-col justify-around w-full p-4 sm:p-8 mx-auto">
                    <div className="flex w-full  items-end justify-end sm:mr-4 sm:mt-4">
                      <button
                        onClick={() => {
                          open(false);
                          setIsSuccess(false);
                        }}
                      >
                        <img src={cancel_icon_w_gradient} alt="cancel icon" width="15px" />
                      </button>
                    </div>
                    <>
                      <div className=' m-auto flex justify-center text-justify text-[43px] font-["Tourney"] font-extrabold gradient-text'>
                        {isDeposit === 'buy' ? (
                          'DEPOSIT PLS'
                        ) : isDeposit === 'deposit' ? (
                          'DEPOSIT PLS'
                        ) : (
                          <span className="flex w-full items- justify-center text-center">WITHDRAW YOUR PLS</span>
                        )}
                      </div>

                      <div className="">
                        <div className="bg-slate-900 border-[1px] border-border-blue/50 w-full h-[7rem] flex flex-col justify-between mt-4 p-2 rounded-xl shadow-lg">
                          <div className="flex h-full items-center justify-between p-2 sm:p-4">
                            <div className="flex flex-col justify-start">
                              {isDeposit === 'deposit' || isDeposit === 'buy' ? (
                                <>
                                  <span className="text-white/20 text-sm">Wallet Balance</span>
                                  <span className="text-white/70 ">
                                    {addZeroes(formatter.format(Number(vaultData?.amount).toFixed(2)))}
                                    {/* {vaultData?.amount} */}
                                  </span>
                                  <span className="text-white/20 text-sm">
                                    ${addZeroes(formatter.format(Number(vaultData?.amountUsd).toFixed(2)))}
                                    {/* ${vaultData?.amountUsd} */}
                                  </span></>
                              ) : (
                                <>
                                  <span className="text-white/70 ">{addZeroes(formatter.format(Number(vaultData?.amountUsd).toFixed(2)))}</span>
                                  <span className="text-white/20 text-sm">${addZeroes(formatter.format(Number(vaultData?.amountUsd).toFixed(2)))}</span>
                                </>
                              )}
                            </div>

                            <div className="flex flex-col sm:flex-row items-center gap-2  sm:gap- ml-2">
                              {/* <span className="text-white text-xl ">0</span> */}
                              <form>
                                <input
                                  type="number"
                                  id="vault_input"
                                  className="remove-number-arrows bg-slate-900 border-collapse border-0 w-[9rem] mr-4 p-0 text-3xl text-right text-white placeholder-white outline-none focus:outline-none focus:ring-0"
                                  placeholder={0.0}
                                  value={formattedInput}
                                  onChange={handleChange}
                                />
                              </form>
                              <div className="flex gap-2 sm:gap-4">
                                <button
                                  onClick={() => handleCustomAmountChange(6900)}
                                  className="bg-white/10 hover:bg-white/20 w-[4rem] h-[25px] text-white/50 rounded-md flex items-center justify-center px-2"
                                >
                                  69%
                                </button>
                                <button
                                  onClick={() => handleCustomAmountChange(1e4)}
                                  className="bg-white/10 hover:bg-white/20 w-[4rem] h-[25px] text-white/50 rounded-md flex items-center justify-center px-2"
                                >
                                  100%
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {isMobile ? (
                          <div className="flex items-center justify-center m-auto  gap-2 mt-5 mb-3  w-full flex-col">
                            <span className="text-white">
                              {isDeposit === 'deposit' ? 'You are depositing:' : 'You are withdrawing:'}
                            </span>
                            <div>
                              <div className="text-white flex justify-center ">
                              <span>
                              {addZeroes(formatter.format(Number(formattedInput).toFixed(2)))}
                              {/* {formattedInput} */}
                              </span> &nbsp;
                                <span>PLS</span>
                              </div>
                              <div className="flex items-center justify-center text-white/50 text-sm">
                                <span>
                                {''}
                                ${addZeroes(formatter.format(Number(amountDepWith).toFixed(2)))}
                                {/* (${amountDepWith}) */}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="flex items-center justify-center m-auto  gap-3 my-5 w-full">
                              <span className="text-white">
                                {isDeposit === 'deposit' ? 'You are depositing:' : 'You are withdrawing:'}
                              </span>
                              <div className="text-white flex ">
                                <span>{formattedInput}</span> &nbsp;
                                <span>PLS</span>
                              </div>
                              <div className="text-white/50 text-sm">
                                <span>
                                  {''}(${amountDepWith})
                                </span>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="my-4 flex justify-center gap-10">
                          {isDeposit === 'deposit' ? (
                            <>
                              <button
                                disabled={isApproved}
                                onClick={() => handleApprove()}
                                className={`bg-slate-900 border-[1px] border-border-blue shadow-[0px_0px_11px_#00B7F0] w-[9rem] rounded-md text-white p-[2px] ${
                                  isApproved ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                                }`}
                              >
                                Approve
                              </button>

                              <button
                                disabled={!isApproved}
                                onClick={() => handleDeposit()}
                                className={`bg-slate-900 border-[1px] border-border-blue w-[9rem] rounded-md text-white p-[2px] ${
                                  !isApproved ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                                }`}
                              >
                                Deposit
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => handleWithDrawalAndRestaking()}
                                className="bg-slate-900 border-[1px] border-border-blue w-[9rem] shadow-[0px_0px_8px_#00B7F0] rounded-md text-white p-[2px]"
                              >
                                Withdraw
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      ) : (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[1000]"
            onClose={() => {
              open(false);
              setIsSuccess(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto bg-deep-blue/[0.99] flex justify-center items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom={`${isSuccess ? 'opacity-100' : 'opacity-0'} scale-95`}
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`modal-dialog transition-all transform ${
                    isSuccess ? 'w-[739px] h-[600px]' : 'w-[628px] h-[344px]'
                  } stake-modal flex max-[480px]:w-4/5 relative`}
                >
                  <div className="flex flex-col justify-around w-full py-2 mx-auto">
                    <div className="flex items-end justify-end mr-4 -mt-2">
                      <button
                        onClick={() => {
                          open(false);
                          setIsSuccess(false);
                        }}
                      >
                        <img src={cancel_icon_w_gradient} alt="cancel icon" width="15px" />
                      </button>
                    </div>
                    <>
                      {isDeposit === 'withdraw' ? (
                        <>
                          <div className='flex items-center justify-center text-7xl font-["Tourney"] font-extrabold gradient-text text-center mt-2'>
                            YEAH!
                          </div>
                          <div className="mb-40 text-center text-white barlow">
                            <p className="text-3xl">You have successfully withdrawn</p>
                            <p className='text-[43px] font-["Tourney"] font-extrabold gradient-text'></p>
                            <p className='text-[43px] font-["Tourney"] font-extrabold gradient-text'>{amount} PLS</p>

                            <a className="text-xs underline" target="__blank" href={'https://arbiscan.io/tx/' + hash}>
                              See tx
                            </a>
                          </div>
                          <img src={smartPepe} alt="" className="absolute bottom-0 left-0" />
                        </>
                      ) : (
                        <>
                          <div className='flex my-8 items-center justify-center text-7xl font-["Tourney"] font-extrabold gradient-text text-center'>
                            YEAH!
                          </div>
                          <div className="mb-40 text-center text-white barlow flex flex-col gap-2">
                            <p className="text-3xl">You have successfully deposited</p>

                            <p className='text-[43px] font-["Tourney"] font-extrabold gradient-text'>{amount} PLS</p>

                            <p className="text-3xl">to your wallet</p>

                            <a
                              className="text-xs underline mt-4"
                              target="__blank"
                              href={'https://arbiscan.io/tx/' + hash}
                            >
                              See tx
                            </a>
                          </div>
                          <img src={smartPepe} alt="" className="absolute bottom-0 left-0" />
                        </>
                      )}
                    </>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default VaultModal;
