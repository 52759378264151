export const plsAutoCompounderABI = [
  {
    inputs: [
      { internalType: 'address', name: '_pls', type: 'address' },
      { internalType: 'address', name: '_plutusOneMonthEpochStaking', type: 'address' },
      { internalType: 'address', name: '_kyberSwapRouter', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'plsStaked', type: 'uint256' },
      { indexed: true, internalType: 'uint32', name: 'epoch', type: 'uint32' },
    ],
    name: 'AllPlsStaked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'plsUnstaked', type: 'uint256' },
      { indexed: true, internalType: 'uint32', name: 'epoch', type: 'uint32' },
    ],
    name: 'AllPlsUnstaked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'token', type: 'address' },
      { indexed: true, internalType: 'bytes', name: 'swapData', type: 'bytes' },
    ],
    name: 'KyberSwapFailed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'owner', type: 'address' }],
    name: 'PlsAssetsClaimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'owner', type: 'address' }],
    name: 'PlsBribesClaimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'accumulatedPlsPerPlsStaked', type: 'uint256' },
    ],
    name: 'PlsRewardsAccumulated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'plsRewardsWithdrawn', type: 'uint256' },
    ],
    name: 'PlsRewardsClaimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'plsRewards', type: 'uint256' },
    ],
    name: 'PlsRewardsUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: true, internalType: 'uint32', name: 'epoch', type: 'uint32' },
      { indexed: true, internalType: 'uint112', name: 'plsStaked', type: 'uint112' },
    ],
    name: 'PlsStaked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: true, internalType: 'uint32', name: 'epoch', type: 'uint32' },
    ],
    name: 'PlsUnstaked',
    type: 'event',
  },
  {
    inputs: [],
    name: 'EPOCH_REWARDS_PROXY',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'PLUTUS_BRIBE_PROXY',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes[]', name: 'swapData', type: 'bytes[]' },
      { internalType: 'address[]', name: 'tokens', type: 'address[]' },
    ],
    name: 'accumulatePls',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'accumulatedPlsPerPlsStaked',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'adminUnstakeAndClaimPlsAssetsAndBribes',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'staker', type: 'address' }],
    name: 'calculatePendingRewards',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'claimPlsAssets', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  { inputs: [], name: 'claimPlsBribes', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  { inputs: [], name: 'claimPlsRewards', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'isCompounding',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'kyberSwapRouter',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'plsRewardsBalance',
    outputs: [{ internalType: 'uint112', name: '', type: 'uint112' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'plsStakeDetails',
    outputs: [
      { internalType: 'int256', name: 'rewardDebt', type: 'int256' },
      { internalType: 'uint112', name: 'plsStaked', type: 'uint112' },
      { internalType: 'uint32', name: 'epoch', type: 'uint32' },
      { internalType: 'address', name: 'user', type: 'address' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'plsToken',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'plutusOneMonthEpochStaking',
    outputs: [{ internalType: 'contract IPlutusEpochStaking', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  { inputs: [], name: 'stakeAllPls', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'uint112', name: 'amount', type: 'uint112' }],
    name: 'stakePls',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalPlsStaked',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'unStakeAllPls', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  { inputs: [], name: 'unStakePls', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  { inputs: [], name: 'updatePlsRewards', outputs: [], stateMutability: 'nonpayable', type: 'function' },
];