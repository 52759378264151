import React, { useContext, useState, useCallback, useRef, useEffect, useMemo } from 'react';
import '../styles/slot-machine.css';
import {
  dollarsign_parentheses,
  mobileslotmachine,
  pepe_top,
  pepe_top_base,
  autospinIcon,
  quest_icon,
  addUsdbcCredit,
  pepe_game_body,
  pepe_game_body_base,
  buyUsdbc,
} from '../assets';
import { useAccount, useNetwork, useSignMessage, useSigner } from 'wagmi';
import { history_API, pepeBetContractAddress, api } from '../constants';
import PlayButton from '../components/PlayButton/PlayButton';
import { StoreContext } from '../utils/store';
import { betReducer, INITIAL_STATE } from '../reducers/betReducer'; // Importing betReducer and bet initial values
import pepeCredits from '../assets/images/B2 Attraction Button.svg';
import pepeCreditsBase from '../assets/images/pepeCreditBase.svg';
import USDC from '../assets/images/B2 Attraction Button (1).svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from 'usehooks-ts';
import axios from 'axios';
import { ReactComponent as DoNotClickHere } from '../assets/fonts/Do not click here.svg';
import doNotClickHere from '../assets/fonts/Do not click here.svg';
import { ReactComponent as DangerSign } from '../assets/images/dangerSign.svg';
import { UpArrow } from '../components/UpArrow';
import { DownArrow } from '../components/DownArrow';
import DepositWithdrawFunds from '../components/DepositWithdrawFunds/DepositWithdrawFunds';
import { SiweMessage } from 'siwe';
import { abi } from '../utils/pepebet-abi';
import { BigNumber, ethers } from 'ethers';
import AddCreditsButton from '../components/AddCreditsButton';
import { BitCoinIcon } from '../components/BitcoinIcon';
import { EthereumIcon } from '../components/EthereumIcon';
import { ARBIcon } from '../components/ARBIcon';
import BetSmallAmountSVG from '../components/BetAmountButtonSmall';
import { CreditsContext } from '../utils/creditsContext';
import LoaderIcon from '../components/LoaderIcon';
import SpinButtonIcon from '../components/SpinButtonIcon';
import TimeIcon from '../components/TimeIcon';
import Navbar from '../components/Navbar/Navbar';
import AutoSpinIndicator from '../components/AutoSpinIndicator';
import useLocalStorage from '../hooks/useLocalStorage';
import AutoSpin from '../components/AutoSpin/AutoSpin';
import { useStorage } from '../utils/StorageContext';
import AutoSpinResult from '../components/modal/AutoSpinResult';
import PepeMobileFooter from '../components/PepeMobileFooter';
import PepeDesktopFooter from '../components/PepeDesktopFooter';
import { getSelectedTokenData, convertToDollarValue, convertDollarValueToWeth } from '../services/service';
import AutospinIcon from '../components/AutospinIcon';
import { nanoid } from 'nanoid';
import { Tooltip } from '@mui/material';
import Speedometer from '../components/Speedometer';
const USER_VERIFICATION_ERROR_MSG = 'Unable to verify your account, Please refresh page';


const toolStyle = {
  color: 'white',
  backgroundColor: '#43334F',
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingLeft: '26px',
  paddingRight: '26px',
  borderRadius: '4px',
  fontSize: '14px',
  fontFamily: 'Barlow',
  fontStyle: 'normal',
  fontWeight: '400px',
  width: '229.025px',
  height: '139.793p',
};

function Slotmachine() {
  const { chain } = useNetwork();
  const arbsOrBase = chain?.id === 8453 ? 'base' : 'arbitrium';
  const [isAutoSpinning, setIsAutoSpinning] = useState(false);
  const navigate = useNavigate();
  const { signMessageAsync } = useSignMessage();
  const Store = useContext(StoreContext);
  const [DNCH, setDNCH] = useState(false);
  const {
    setTradeVars,
    currency,
    time,
    longShort,
    setSlotModal,
    setGoodLuckModal,
    sfxAudio,
    setSFX,
    music,
    setSrc,
    doRandomizeTradeVars,
    track,
    setStoreState,
    tradeVars: { amount },
    activeAmountButton: activeButton,
    setActiveAmountButton: setActiveButton,
    willRespin,
    setWillRespin,
    setDepositWithdrawModal,
    setWalletMenuPopup,
    // setAutoSpinModal,
    autoSpinAmount,
    // showAutoSpinModal,
    // respinSlots,
    // setRespinSlots
    autoSpinVars,
    setAutoSpinVars,
    setQuestChips,
    setQuestSpinDay,
    setDaysSpun,
    setQuestsModal,
    depositWithdrawToken,
    setCounterPepeModal,
    setPulloutModal,
  } = Store;
  const { address, connector, isConnected } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const INSUFFICIENT_FUNDS_MSG = 'Insufficient funds';

  const { yourCredits, setYourCredits, userPepeBalance, setUserPepeBalance } = useContext(CreditsContext);

  const [loadingCredits, setLoadingCredits] = useState(true);

  const [inputAmount, setInputAmount] = useState(amount);
  const [slots, setSlots] = useState([]);
  const [hasBeenRolled, setHasBeenRolled] = useState(false);
  const [totalBets, setTotalBets] = useState(0);
  const [totalLeverage, setTotalLeverage] = useState(0);
  const [itemCount] = useState(64);
  const [rolling, setRolling] = useState(false);
  const currencySlotRef = useRef(null);
  const timeSlotRef = useRef(null);
  const [slotContainerHeight, setSlotContainerHeight] = useState('42 %');
  const [profitLast24h, setProfitLast24h] = useState(0);
  const [profitAllTime, setProfitAllTime] = useState(0);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [gottenAmount, setGottenAmount] = useState(false);
  const [runRespin, setRunRespin] = useState(false); // Use to handle when to run respin after getting userPepeAccount Balance
  const [userVerified, setUserVerified] = useState(null);
  const { autoSpinData, setAutoSpinData, betCurrency, setIsSpinning, isSpinning, isRunning } = useStorage();
  const [localBalance, setLocalBalance] = useState(yourCredits);
  // const [userPepeBalance, setUserPepeBalance] = useState(BigNumber.from(0));
  const [showAutoSpinFinal, setShowAutoSpinFinal] = useState(false);
  const { data: signer } = useSigner();
  const [betRun, setBetRun] = useState(false);
  const [wethValue, setWethValue] = useState();

  const [speedometerHover, setSpeedometerHover] = useState(false);
  const userBalance = userPepeBalance;

  const pepeBetContract = useMemo(
    () => new ethers.Contract(getSelectedTokenData(betCurrency)?.contractAddress, abi, signer),
    [signer]
  );

  const openPaymentModal = () => setShowPaymentModal(true);
  const closePaymentModal = () => setShowPaymentModal(false);

  const [isArrowColored, setArrowColored] = useState(false);
  const isSmSize = useMediaQuery('(min-width: 640px)');
  const isLgSize = useMediaQuery('(min-width: 1024px)');
  const isMobileDevice = useMediaQuery('(max-width: 640px)');

  const formatter = new Intl.NumberFormat('en-US');
  const playButtonRef = useRef(null);
  const [openAutoModal, setOpenAutoModal] = useState(false);

  function addZeroes(num) {
    const dec = num.split('.')[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    console.log({ addZeroes: Number(num).toFixed(len) });
    return Number(num).toFixed(len);
  }

  //check if user's session is still valid
  const checkSession = async () => {
    setUserVerified(null);
    try {
      let session = await axios.post(
        // api.dev.SESSION_API + '/check',
        api.offChainBet.pepeSessionCheckApiEndpoint,
        JSON.stringify({ action: 'check', useraddress: address }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('SESSION:', session.data);
      if (!session.data.isActive) {
        //lets disable betting until user signs in
        setUserVerified(false);
        //continue with login prompt
        signIn();
      } else if (!session.data.verified && session.data.isActive) {
        //lets do refresh of token/session
        setUserVerified(false);
        signIn();
      } else if (session.data.isActive && session.data.verified) {
        setUserVerified(true);
      }
    } catch (err) {
      console.log('error validating user account:', err);
      enqueueSnackbar(USER_VERIFICATION_ERROR_MSG, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  //get unique nonce from lambda
  const getNonce = async () => {
    try {
      // const nonce = await axios.post(api.dev.SESSION_API + '/nonce', JSON.stringify({ useraddress: address }), {
      const nonce = await axios.post(
        api.offChainBet.pepeSessionNonceApiEndpoint,
        JSON.stringify({ useraddress: address }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return nonce?.data?.nonce;
    } catch (error) {}
  };

  //signin function. signs user message and verifies signature
  const signIn = async () => {
    const nonce = await getNonce();
    try {
      const chainId = chain?.id;
      if (!address || !chainId) return;
      // Create SIWE message with pre-fetched nonce and sign with wallet
      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement: 'Sign in with Ethereum to the app.',
        uri: window.location.origin,
        version: '1',
        chainId,
        nonce: nonce,
      });

      const signature = await signMessageAsync({
        message: message.prepareMessage(),
      });

      //console.log(signature,message)

      // Verify signature
      const verifyRes = await axios.post(
        // api.dev.SESSION_API + '/verify',
        api.offChainBet.pepeSessionsApiEndpoint,
        JSON.stringify({
          message,
          signature,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log(JSON.parse(verifyRes.data.body).isActive);
      let data = JSON.parse(verifyRes.data.body);
      if (!data.isActive) {
        throw new Error('Error verifying message');
      } else {
        setUserVerified(true);
      }

      //setState((x) => ({ ...x, loading: false }))
      //onSuccess({ address })
    } catch (error) {
      //setState((x) => ({ ...x, loading: false, nonce: undefined }))
      // onError({ error: error })
      console.log(error);
      // fetchNonce()
    }
  };

  // we want to generate slots once when component renders
  useEffect(() => {
    // console.log('checksession::', autoSpinData, autoSpinData.isAuto)
    // if (!autoSpinData.isAuto || autoSpinData.isAuto == undefined) {
    //   checkSession();
    // } else if (autoSpinData.isAuto && autoSpinData?.rounds - autoSpinData?.roundsLeft <= 1) {
    //   checkSession();
    // }

    generateSlots(itemCount);
    if (track !== music[0]) {
      setSrc(music[0]);
      console.log(itemCount);
    }

    if (willRespin) {
      let button = document.getElementById('js-playbutton-main');
      if (amount < yourCredits) {
        setTimeout(() => {
          button.click();
          runBet();
        }, 5000);
      } else {
        enqueueSnackbar(INSUFFICIENT_FUNDS_MSG, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
      setWillRespin(false);
    }
  }, []);

  // useEffect(()=>{
  //   if(loadingCredits){
  //     setSlotModal(false)
  //   } else{
  //     if(yourCredits === 0){
  //       setSlotModal(true)
  //     } else{
  //       setSlotModal(false)
  //     }
  //   }

  // }, [yourCredits, loadingCredits])

  useEffect(() => {
    if (isLgSize) {
      setSlotContainerHeight('45 %');
      return;
    }
    if (isSmSize) {
      setSlotContainerHeight('14.5 rem');
      return;
    }
    setSlotContainerHeight('42 %');
  }, [isSmSize, isLgSize]);

  let getRandomCurrencyStartIndex = Math.floor(Math.random() * currency.length);
  let getRandomLeverageIndex = Math.floor(Math.random() * longShort.length);
  let getRandomTimeIndex = Math.floor(Math.random() * time.length);

  const slotHeightValue = Number(slotContainerHeight.split(' ')[0]);
  const slotHeightUnits = slotContainerHeight.split(' ')[1];

  useEffect(() => {
    if (address) {
      // checkSession()
      getBalance(depositWithdrawToken);
      history();
    } else {
      setQuestChips(0);
      setQuestSpinDay(0);
      setDaysSpun(0);
      setGottenAmount(true);
      setLoadingCredits(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signer, pepeBetContract, address, depositWithdrawToken, chain?.id]);

  useEffect(() => {
    if (address) {
      checkSession();
      const pulloutState = localStorage.getItem('pullout-modal');
      if (pulloutState === 'hide') {
        setPulloutModal(false);
      } else {
        setPulloutModal(true);
      }
    }
  }, [address]);

  const getBalance = useCallback(
    async (currency) => {
      ///@notice signer hook is unavailable immediately when page mounts hence this signer will be empty
      ///using provider instead
      // const userToken = currency || betCurrency;
      // const provider = new ethers.providers.JsonRpcProvider('https://arb1.arbitrum.io/rpc');
      // const pepeBetContract = new ethers.Contract(
      //   userToken === 'PEPE' ? pepeBetTokenContractAddress : pepeBetContractAddress,
      //   abi,
      //   provider
      // );
      // try {
      //   const userBalance = await pepeBetContract.balances(address);
      //   setYourCredits(ethers.utils.formatUnits(userBalance, userToken === 'PEPE' ? 18 : 6));
      //   console.log({ credits: ethers.utils.formatUnits(userBalance, userToken === 'PEPE' ? 18 : 6) });
      //   setUserPepeBalance(userBalance);
      //   setRunRespin(true);
      // } catch (e) {
      // } finally {
      //   setGottenAmount(true);
      //   setLoadingCredits(false);
      // }

      //   // eslint-disable-next-line react-hooks/exhaustive-deps
      // },
      try {
        const userToken = currency || betCurrency;
        // const tokenData = getSelectedTokenData('USDC');
        const tokenData = getSelectedTokenData(userToken);
        const { offChainBet } = api;
        //const userBalance = await inAppContract.balances(address);
        const response = await fetch(offChainBet.getUserBalanceEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userAddress: ethers.utils.getAddress(address),
            tokenAddress: ethers.utils.getAddress(tokenData.tokenAddres),
            chainId: chain.id,
          }),
        });
        /**
         * @dev - Notice, best to use checksum addresses; i.e. ethers.utils.getAddress(address)
         */
        const balance = await response.json();
        //console.log('balance response from lambda 1: ', balance);
        const userBalance = BigNumber.from(balance.body.balance);
        setUserPepeBalance(userBalance);
        let formattedUserBalance = ethers.utils.formatUnits(userBalance, tokenData.formatUnits);
        //console.log('formatted user balance 1: ', formattedUserBalance);
        setUserPepeBalance(userBalance);
        setRunRespin(true);

        if (userToken == 'WETH') {
          setWethValue(formattedUserBalance); // Set the weth value before changing value to dollar
          formattedUserBalance = await convertToDollarValue('weth', formattedUserBalance);
          setYourCredits(formattedUserBalance);
        } else {
          setYourCredits(formattedUserBalance);
        }
      } catch (e) {
      } finally {
        setGottenAmount(true);
        setLoadingCredits(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [address]
  );

  /**
   * Randomizes currencies, long/short and times in the slot machine
   * @param itemCount The number of items in each slot. a higher number will give a faster spinning effect
   */

  const generateSlots = (itemCount) => {
    const generatedSlots = [];

    for (let slot = 0; slot < 3; slot++) {
      generatedSlots.push({
        y: '0rem',
        durationSeconds: 0,
        items: [],
      });

      for (let item = 0; item < itemCount; item++) {
        let randomItem = currency[getRandomCurrencyStartIndex % currency.length];
        getRandomCurrencyStartIndex += 1;
        if (slot === 1) {
          randomItem = longShort[getRandomLeverageIndex % longShort.length];
          getRandomLeverageIndex += 1;
        } else if (slot === 2) {
          randomItem = time[getRandomTimeIndex % time.length];
          getRandomTimeIndex += 1;
        }

        generatedSlots[slot].items.push({
          value: randomItem,
        });
      }
    }

    setSlots(generatedSlots);
    // setRespinSlots(generatedSlots)

    return generatedSlots;
  };

  // method to simulate spinning of the slot machine
  const spin = () => {
    setHasBeenRolled(true);
    let maxDuration = 0;

    if (isMobileDevice) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    setRolling(true);
    Store.setStoreState('amount', inputAmount);
    //doRandomizeTradeVars();

    // if(willRespin){
    //   respinSlots.forEach((row, index) => {
    //     row.y = '-' + ((itemCount * slotContainerHeight) / 3 - slotContainerHeight) + 'rem';
    //     console.log(row.y);
    //     row.durationSeconds = 2 + index;

    //     if (row.durationSeconds > maxDuration) {
    //       maxDuration = row.durationSeconds;
    //     }
    //   });
    // }else{
    slots.forEach((row, index) => {
      // if height is in percentage, slot height value is 100%
      let adjustedSlotValue = slotHeightValue;
      if (slotHeightUnits === '%') adjustedSlotValue = 100;
      row.y = '-' + ((itemCount * adjustedSlotValue) / 3 - adjustedSlotValue) + slotHeightUnits;
      row.durationSeconds = 2 + index;

      if (row.durationSeconds > maxDuration) {
        maxDuration = row.durationSeconds;
      }
    });
    // }

    setTimeout(() => {
      if (!currencySlotRef || !currencySlotRef.current || !timeSlotRef || !timeSlotRef.current) {
        return;
      }
      setArrowColored(true);
      timeSlotRef.current.children.item(itemCount - 2).classList.add('selectedSlot');
    }, maxDuration * 1000);
  };

  useEffect(() => {
    let button = document.getElementById('js-playbutton-main');
    if (button) {
      if (inputAmount === 0) {
        button.disabled = true;
      } else {
        button.disabled = false;
      }
    }
  }, [inputAmount]);

  const handleClick = async (button, amount) => {
    setActiveButton(button);
    let bet_amount = amount;
    // if(depositWithdrawToken==='WETH'){
    //   bet_amount = ethers.utils.formatUnits(await convertDollarValueToWeth(amount),18)
    // }
    setInputAmount(bet_amount);
  };

  //Method to run bet, to trigger rolling and maintain state
  const runBet = async () => {
    //  if (userVerified == null || userVerified) {
    if (inputAmount === 0) {
      return;
    }
    if (autoSpinData.isAuto) setIsSpinning(true);
    setSrc(music[2]);
    setSFX(sfxAudio[3]);
    spin();
    setYourCredits(+yourCredits - Number(inputAmount));

    // if(willRespin){
    //   setTradeVars(
    //     inputAmount,
    //     respinSlots[0].items[itemCount - 2].value,
    //     respinSlots[2].items[itemCount - 2].value,
    //     respinSlots[1].items[itemCount - 2].value
    //     );
    //   }else{
    setTradeVars(
      inputAmount,
      slots[0].items[itemCount - 2].value,
      slots[2].items[itemCount - 2].value,
      slots[1].items[itemCount - 2].value
    );
    if (autoSpinData.isAuto) setIsSpinning(false);
    // }
    //wait for few seconds then navigate
    setTimeout(() => {
      // setGoodLuckModal(true);
      setCounterPepeModal(true);

      // Check if there is referral and then pass referral to chart page
      // if(referral !== '' & referral !== undefined){
      //   navigate(`/chart/${referral}`);
      // }else{
      // navigate(`/chart`);
      // }
    }, 5000);
    // } else {
    console.log('user not verified');
    // enqueueSnackbar(USER_VERIFICATION_ERROR_MSG, {
    //   variant: 'error',
    //   preventDuplicate: true,
    // });
    //  }
    setBetRun(false);
  };

  const history = async () => {
    try {
      // const {
      //   data: { data: dataArray },
      // } = await axios.post(api.dev.history_API, {
      //   useraddress: address,
      // });
      const {
        data: { data: dataArray },
      } = await axios.post(api.offChainBet.getUserHistoryEndpoint, {
        useraddress: address,
      });
      const contractLeverageBps = await pepeBetContract.leverage(); // value is in bps and 1bps=0.01% so we divide by 10000 to get actual value
      const contractLeverage = contractLeverageBps / 10000;

      const hours = Date.now() - 24 * 60 * 60 * 1000;

      let accumLeverage = 0;
      let accumStakeLast24Hrs = 0;
      let accumProfitLast24Hrs = 0;
      let accumTotalStake = 0;
      let accumTotalProfit = 0;

      for (let i = 0; i < dataArray.length; i++) {
        const {
          openPrice: openPriceString,
          closePrice: closePriceString,
          winlose: winloseString,
          amount: amountString,
          endTime: endTimeString,
        } = dataArray[i];
        const openPrice = parseFloat(openPriceString);
        const closePrice = parseFloat(closePriceString);
        const winlose = parseFloat(winloseString);
        const amount = parseInt(amountString);
        const endTime = parseInt(endTimeString);

        if (endTime >= hours) {
          accumStakeLast24Hrs += amount;
        }
        accumTotalStake += amount;
        if (openPrice !== closePrice) {
          // leverage is 0 if open price and close price are the same so we should skip that
          const priceChange = Math.abs(openPrice - closePrice) / openPrice;
          if (winlose === 0) {
            // user lost the bet, leverage is 1/price change
            accumLeverage += 1 / priceChange;
            if (endTime > hours) {
              accumProfitLast24Hrs -= amount;
            }
            accumTotalProfit -= amount;
          } else {
            // user won the bet, leverage is (contract leverage/price change)
            accumLeverage += contractLeverage / priceChange;
            if (endTime > hours) {
              accumProfitLast24Hrs += contractLeverage * amount;
            }
            accumTotalProfit += contractLeverage * amount;
          }
        }
      }
      if (dataArray.length > 0) {
        setTotalBets(dataArray.length);
        let amount = (accumLeverage / dataArray.length).toFixed(2);
        let formattedData = formatter.format(parseInt(amount).toString());
        setTotalLeverage(formattedData);
      }
      if (accumStakeLast24Hrs !== 0) {
        // prevent divide by 0 error
        setProfitLast24h(((accumProfitLast24Hrs / accumStakeLast24Hrs) * 100).toFixed(2));
        setProfitAllTime(((accumTotalProfit / accumTotalStake) * 100).toFixed(2));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!loadingCredits) {
      if (autoSpinData.isAuto && autoSpinData.roundsLeft > 0) {
        if (!autoSpinData.isPlay) {
          setIsAutoSpinning(true);
          enqueueSnackbar('Autospin Paused', {
            variant: 'warning',
            preventDuplicate: true,
          });
        }
        if (autoSpinData.isStop && autoSpinData.isStopAt === autoSpinData.roundsLeft) {
          enqueueSnackbar('Autospin cancelled', {
            variant: 'warning',
            preventDuplicate: true,
          });
          setTimeout(() => {
            setShowAutoSpinFinal(true);
          }, 2500);
        }
        if (autoSpinData.isPlay && autoSpinData.isStopAt !== autoSpinData.roundsLeft) {
          handleAutoSpin(autoSpinData.amount);
        }
      }
    }
  }, [loadingCredits]);

  const handleCloseModal = (onClose, data) => {
    setOpenAutoModal(onClose);
    if (data) {
      handleAutoSpin(data);
    }
  };

  const handleAutoSpin = (amount) => {
    setInputAmount(amount);
    const activeButton = amount === 5 ? 'button1' : amount === 10 ? 'button2' : 'button3';
    setActiveButton(activeButton);
    let button = document.getElementById('js-playbutton-main');
    if (autoSpinData.isAuto) {
      if (autoSpinData.roundsLeft === 0) {
        enqueueSnackbar('Number of rounds depleted', {
          variant: 'info',
          preventDuplicate: true,
        });
      } else {
        setIsAutoSpinning(true);
        if (yourCredits >= amount) {
          if (isRunning === false) {
            const { isAuto, amount, rounds, roundsLeft, autospin_id, isPlay, isStop, isStopAt } = autoSpinData;
            const whatsLeft = roundsLeft - 1;
            var values = autospin_id.split('_').filter((item) => /^\d+$/.test(item));
            let id = autospin_id.replace(values.join('_'), '').slice(0, -1);

            setAutoSpinData({
              isAuto,
              amount,
              rounds,
              roundsLeft: whatsLeft,
              autospin_id: `${id}_${rounds - whatsLeft}_${rounds}`,
              isPlay,
              isStop,
              isStopAt,
            });
            setAutoSpinVars(autoSpinData.isAuto, autoSpinData.amount, autoSpinData.rounds, whatsLeft);

            setTimeout(() => {
              button.click();
              runBet();
              setIsAutoSpinning(true);
            }, 2500);
          }
        } else {
          enqueueSnackbar('Your are out of Credits. Auto spin cancelled', {
            variant: 'warning',
            preventDuplicate: true,
          });
          setTimeout(() => {
            setShowAutoSpinFinal(true);
          }, 2500);
        }
      }
    }
  };

  useEffect(() => {
    setLoadingCredits(true);
    getBalance(depositWithdrawToken);
  }, [depositWithdrawToken, getBalance, chain?.id]);

  const hasZeroCredits = isConnected && parseFloat(yourCredits) === 0 && !loadingCredits && isSmSize && !hasBeenRolled;

  const spinButtonAction = () => {
    if (!isConnected) {
      return;
    }
    if (hasZeroCredits) {
      setDepositWithdrawModal(true);
    } else if (!hasZeroCredits && activeButton !== '' && userPepeBalance.gt(0e6)) {
      runBet();
      setBetRun(true);
    }
  };

  return (
    <>
      <button
        className="hidden sm:flex justify-center items-center gap-[5px] quest-button-on-slot-glow fixed bottom-0 right-16 z-[2] bg-[#09031D] rounded-none rounded-t-2xl py-2 px-4"
        onClick={() => setQuestsModal(true)}
      >
        <span className="gradient-text text-[1.063rem] sm:uppercase font-semibold sm:font-bold">Pepe&apos;s Pills</span>
        <img
          src={arbsOrBase === 'base' ? pepe_game_body_base : pepe_game_body}
          alt=""
          className={`block w-8 h-auto grow-0 shrink-0 ${arbsOrBase === 'base' ? '' : 'scale-x-[-1] '}`}
        />
      </button>
      {showAutoSpinFinal && (
        <AutoSpinResult
          isOpen={showAutoSpinFinal}
          betToken={betCurrency}
          open={setShowAutoSpinFinal}
          numOfRounds={autoSpinData?.rounds}
          amountForAutoSpin={autoSpinData?.amount}
        />
      )}
      {openAutoModal && <AutoSpin address={address} isOpen={openAutoModal} open={handleCloseModal} />}
      <div className="bg-responsive">
        {showPaymentModal && <DepositWithdrawFunds close={closePaymentModal} />}
        <Navbar />

        <div className="absolute bg-sparkle bg-contain bg-no-repeat mix-blend-color-dodge w-[750px] h-3/4 top-0 z-[100] right-0 -scale-x-100 sparkle-1 pointer-events-none"></div>
        <div className="absolute bg-sparkle bg-contain bg-no-repeat mix-blend-color-dodge w-[750px] h-3/4 z-[200] left-0 top-0 sparkle-2 pointer-events-none"></div>

        <div
          className={`hidden xlmd:block absolute xlmd:w-[2.87rem] xlmd:h-[2.87rem] xlmd:left-[9.1rem] mb-0 top-[16.70rem] lg:left-[3.9rem] ${
            DNCH ? 'opacity-50' : 'opacity-0'
          } bottom-2 transform rotate-[-8deg] clickable`}
        >
          <DangerSign />
        </div>
        <div
          className={`hidden xlmd:block absolute xl:w-[16.24rem] xlmd:h-[6.73rem] xlmd:left-[3.745rem] sm:top-[18.71rem] transform rotate-[-7.95deg] ${
            DNCH ? 'opacity-40' : 'opacity-20'
          } z-10 clickable`}
          onMouseEnter={() => setDNCH(true)}
          onMouseLeave={() => setDNCH(false)}
        >
          <DoNotClickHere onClick={() => navigate('/degen-dashboard', { state: { openDefault: true } })} />
        </div>

        <div className="relative z-0 flex flex-col items-end justify-start w-full pt-4 sm:justify-end sm:pt-16 slot-cover">
          <div className="relative z-10 flex flex-col bg-slotmachine position-slotmachine">
            <div
              className={`block xlmd:hidden absolute xl:w-[16.24rem] xlmd:h-[6.73rem] xlmd:left-[3.745rem] bottom-0 transform rotate-[-7.95deg] ${
                DNCH ? 'opacity-40' : 'opacity-20'
              } z-10 clickable`}
              onMouseEnter={() => setDNCH(true)}
              onMouseLeave={() => setDNCH(false)}
            >
              <img
                src={doNotClickHere}
                className="w-[130px] h-[50px] sm:w-auto sm:h-[100px]"
                onClick={() => navigate('/degen-dashboard', { state: { openDefault: true } })}
              />
            </div>
            <img
              src={arbsOrBase === 'base' ? pepe_top_base : pepe_top}
              className={`h-auto w-[20rem] sm:w-[30rem] lg:w-[18rem] xl:w-[21rem] 2xl:w-[26rem] ${
                arbsOrBase === 'base' ? 'top-[-4rem]' : 'top-[-5rem]'
              } sm:top-[-10rem] lg:top-[-3.5rem] xl:top-[-7rem] 2xl:top-[-8rem] left-0 right-0 block mx-auto absolute pointer-events-none`}
              alt="Logo"
            />
            <img className="block w-full h-auto sm:hidden" src={mobileslotmachine} alt="" />
            {hasZeroCredits ? (
              <div
                className="z-20 flex flex-col justify-around rounded-sm slot-container zeroCredits"
                style={{ height: slotHeightValue + slotHeightUnits }}
              >
                <div className='flex items-center justify-center pt-5 text-4xl font-["Tourney"] font-extrabold gradient-text text-center'>
                  ZERO CREDITS
                </div>
                <p className="py-2 text-xl font-normal tracking-wide text-center text-white dark:text-gray-400 barlow">
                  Deposit {depositWithdrawToken} to play
                </p>
                <div className="flex justify-center w-full mb-5 text-white">
                  <button
                    className="min-w-[130px] w-1/4"
                    onClick={() => {
                      window.open(
                        `https://app.uniswap.org/#/tokens/arbitrum/${
                          getSelectedTokenData(depositWithdrawToken).tokenAddres
                        }`,
                        '_blank'
                      );
                    }}
                  >
                    <img src={depositWithdrawToken === 'USDbC' ? buyUsdbc : USDC} />
                  </button>
                  <button className="min-w-[135px] w-1/4" onClick={() => setDepositWithdrawModal(true)}>
                    <img src={arbsOrBase === 'base' ? pepeCreditsBase : pepeCredits} className="" alt="Add Credits" />
                  </button>
                  {/* <button className="border-none cursor-pointer appearance-none bg-[inherit] refresh-btn">
                      <img className="w-6 h-6" src={info} alt="Info" />
                  </button> */}
                </div>
              </div>
            ) : (
              ''
            )}
            <div
              className="flex overflow-hidden slot-container sm:text-5xl"
              style={{ height: slotHeightValue + slotHeightUnits }}
            >
              <div
                ref={currencySlotRef}
                className="flex-1 h-full text-center"
                style={
                  slots && slots[0]
                    ? {
                        transform: `translateY(${slots[0].y})`,
                        transitionDuration: `${slots[0].durationSeconds}s`,
                      }
                    : {}
                }
              >
                {slots.length > 0 &&
                  slots[0].items.map(({ value }, idx) => (
                    <div className="flex items-center justify-center h-1/3" key={idx}>
                      {idx === itemCount - 2 ? (
                        <>
                          {value === 'BTC' ? (
                            <BitCoinIcon selected={isArrowColored} />
                          ) : value === 'ETH' ? (
                            <EthereumIcon selected={isArrowColored} />
                          ) : (
                            <ARBIcon selected={isArrowColored} />
                          )}
                        </>
                      ) : (
                        <>{value === 'BTC' ? <BitCoinIcon /> : value === 'ETH' ? <EthereumIcon /> : <ARBIcon />}</>
                      )}
                    </div>
                  ))}
              </div>

              <div
                className="flex-1 h-full text-center"
                style={
                  slots && slots[1]
                    ? {
                        transform: `translateY(${slots[1].y})`,
                        transitionDuration: `${slots[1].durationSeconds}s`,
                      }
                    : {}
                }
              >
                {slots.length > 0 &&
                  slots[1].items.map(({ value }, idx) => (
                    <div key={idx} className="flex items-center justify-center h-1/3">
                      <div className="h-[45%] sm:h-3/5 w-full flex justify-center">
                        {idx === itemCount - 2 ? (
                          <>
                            {value === 'Long' ? (
                              <UpArrow selected={isArrowColored} />
                            ) : (
                              <DownArrow selected={isArrowColored} />
                            )}
                          </>
                        ) : (
                          <>{value === 'Long' ? <UpArrow /> : <DownArrow />}</>
                        )}
                      </div>
                    </div>
                  ))}
              </div>

              <div
                ref={timeSlotRef}
                className="flex-1 h-full text-center"
                style={
                  slots && slots[2]
                    ? {
                        transform: `translateY(${slots[2].y})`,
                        transitionDuration: `${slots[2].durationSeconds}s`,
                      }
                    : {}
                }
              >
                {slots.length > 0 &&
                  slots[2].items.map(({ value }, idx) => (
                    <div className="flex items-center justify-center h-1/3" key={idx}>
                      <div className="h-[35%] w-full flex justify-center">
                        {idx === itemCount - 2 ? (
                          <TimeIcon time={value} selected={isArrowColored} />
                        ) : (
                          <TimeIcon time={value} />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="relative z-10 h-2/5 sm:h-auto w-full sm:w-4/5 lg:w-[75vh] mx-auto pb-8 sm:pb-0">
            <div
              className={`w-full h-full ${
                isSmSize ? 'slotmachine-bottom-panel bg-[#09031de6]' : ''
              } pb-0 pl-0 pr-0 sm:py-4`}
            >
              <div className="w-full sm:w-[85%] sm:bg-opacity-5 sm:rounded-2xl flex flex-col sm:flex-row mx-auto sm:bg-[rgba(255,255,255,0.05)] sm:px-8">
                {/* <div
                    className={
                      isAutoSpinning && !isLgSize
                        ? 'hidden'
                        : 'flex text-white items-center'
                    }
                  > */}

                <div className="flex d-flex flex-[0.6] flex-col sm:mt-2">
                  <div className="w-full gap-4 justify-around sm:bg-[linear-gradient(360deg,rgba(9,3,29,0.2)_10.57%,_rgba(90,54,203,0.2)_89.02%)] sm:shadow-[0px_0px_4px_#18ADFA,0px_4px_24px_rgba(0,0,0,0.45),inset_10px_10px_24px_rgba(16,1,23,0.3)] p-2 sm:rounded-xl">
                    <div className="flex items-center justify-center w-full gap-4 sm:justify-evenly sm:gap-0">
                      <button
                        className="w-1/5 sm:w-1/4 slotmachine-amount-btn"
                        onClick={async () => {
                          if (!gottenAmount) {
                            return;
                          }

                          let compareDollarValue = 5e6;

                          // console.log('my bet currency::', depositWithdrawToken)
                          // if (depositWithdrawToken === 'WETH') {
                          //   compareDollarValue = await convertDollarValueToWeth(5)
                          // }

                          if (userBalance.lt(compareDollarValue)) {
                            enqueueSnackbar(INSUFFICIENT_FUNDS_MSG, {
                              variant: 'error',
                              preventDuplicate: true,
                            });
                            return;
                          }

                          handleClick('button1', 5);
                          setSFX(sfxAudio[0]);
                        }}
                      >
                        <BetSmallAmountSVG
                          amount={5}
                          disabled={
                            !gottenAmount
                              ? true
                              : activeButton === ''
                              ? false
                              : activeButton === 'button1'
                              ? false
                              : true
                          }
                        />
                      </button>

                      <button
                        className="w-1/5 sm:w-1/4 slotmachine-amount-btn"
                        onClick={async () => {
                          if (!gottenAmount) {
                            return;
                          }

                          let compareDollarValue = 10e6;

                          // if (betCurrency === 'WETH') {
                          //   compareDollarValue = await convertDollarValueToWeth(10)
                          // }

                          if (userBalance.lt(compareDollarValue)) {
                            enqueueSnackbar(INSUFFICIENT_FUNDS_MSG, {
                              variant: 'error',
                              preventDuplicate: true,
                            });
                            return;
                          }
                          handleClick('button2', 10);
                          setSFX(sfxAudio[1]);
                        }}
                      >
                        <BetSmallAmountSVG
                          amount={10}
                          disabled={
                            !gottenAmount
                              ? true
                              : activeButton === ''
                              ? false
                              : activeButton === 'button2'
                              ? false
                              : true
                          }
                        />
                      </button>

                      <button
                        className="w-1/5 sm:w-1/4 slotmachine-amount-btn"
                        onClick={async () => {
                          if (!gottenAmount) {
                            return;
                          }

                          let compareDollarValue = 20e6;

                          // if (depositWithdrawToken === 'WETH') {
                          //   compareDollarValue = await convertDollarValueToWeth(20)
                          // }

                          if (userBalance.lt(compareDollarValue)) {
                            enqueueSnackbar(INSUFFICIENT_FUNDS_MSG, {
                              variant: 'error',
                              preventDuplicate: true,
                            });
                            return;
                          }
                          handleClick('button3', 20);
                          setSFX(sfxAudio[2]);
                        }}
                      >
                        <BetSmallAmountSVG
                          amount={20}
                          disabled={
                            !gottenAmount
                              ? true
                              : activeButton === ''
                              ? false
                              : activeButton === 'button3'
                              ? false
                              : true
                          }
                        />
                      </button>
                    </div>
                    <div className="flex items-center justify-center w-full gap-4 sm:justify-evenly sm:gap-0">
                      <button
                        className="w-1/5 sm:w-1/4 slotmachine-amount-btn"
                        onClick={async () => {
                          if (!gottenAmount) {
                            return;
                          }

                          let compareDollarValue = 50e6;

                          // if (depositWithdrawToken === 'WETH') {
                          //   compareDollarValue = await convertDollarValueToWeth(50)
                          // }

                          if (userBalance.lt(compareDollarValue)) {
                            enqueueSnackbar(INSUFFICIENT_FUNDS_MSG, {
                              variant: 'error',
                              preventDuplicate: true,
                            });
                            return;
                          }
                          handleClick('button4', 50);
                          setSFX(sfxAudio[0]);
                        }}
                      >
                        <BetSmallAmountSVG
                          amount={50}
                          disabled={
                            !gottenAmount
                              ? true
                              : activeButton === ''
                              ? false
                              : activeButton === 'button4'
                              ? false
                              : true
                          }
                        />
                      </button>

                      <button
                        className="w-1/5 sm:w-1/4 slotmachine-amount-btn"
                        onClick={async () => {
                          if (!gottenAmount) {
                            return;
                          }

                          let compareDollarValue = 75e6;

                          // if (depositWithdrawToken === 'WETH') {
                          //   compareDollarValue = await convertDollarValueToWeth(75)
                          // }

                          if (userBalance.lt(compareDollarValue)) {
                            enqueueSnackbar(INSUFFICIENT_FUNDS_MSG, {
                              variant: 'error',
                              preventDuplicate: true,
                            });
                            return;
                          }
                          handleClick('button5', 75);
                          setSFX(sfxAudio[1]);
                        }}
                      >
                        <BetSmallAmountSVG
                          amount={75}
                          disabled={
                            !gottenAmount
                              ? true
                              : activeButton === ''
                              ? false
                              : activeButton === 'button5'
                              ? false
                              : true
                          }
                        />
                      </button>

                      <button
                        className="w-1/5 sm:w-1/4 slotmachine-amount-btn"
                        onClick={async () => {
                          if (!gottenAmount) {
                            return;
                          }

                          let compareDollarValue = 100e6;

                          // if (depositWithdrawToken === 'WETH') {
                          //   compareDollarValue = await convertDollarValueToWeth(75)
                          // }

                          if (userBalance.lt(compareDollarValue)) {
                            enqueueSnackbar(INSUFFICIENT_FUNDS_MSG, {
                              variant: 'error',
                              preventDuplicate: true,
                            });
                            return;
                          }
                          handleClick('button6', 100);
                          setSFX(sfxAudio[2]);
                        }}
                      >
                        <BetSmallAmountSVG
                          amount={100}
                          disabled={
                            !gottenAmount
                              ? true
                              : activeButton === ''
                              ? false
                              : activeButton === 'button6'
                              ? false
                              : true
                          }
                        />
                      </button>
                    </div>
                  </div>

                  {/* credit section */}
                  <div
                    className={
                      isAutoSpinning && !isLgSize
                        ? 'hidden'
                        : 'flex-wrap text-white panelShadow mt-4 sm:mt-0 hidden sm:flex self-center py-[0.938rem] sm:w-full text-center sm:text-left bg-[#0E0A23] sm:bg-transparent rounded-md'
                    }
                  >
                    <div className="w-full  sm:flex-1 sm:block flex items-start font-manrope sm:pr-0 sm:py-0 rounded-md shadow-panelShadow bg-[#0E0A23] sm:bg-inherit">
                      <div className="text-[11px] sm:text-[13px] leading-[15.03px] mt-2 sm:leading-[17.76px] text-[#E0DDFF] font-manrope font-medium mb-[2px]">
                        {!isSmSize ? 'Available Credits' : 'Your credits'}
                      </div>

                      <div className="flex items-center justify-center text-lg font-semibold flex-nowrap sm:justify-between">
                        <div className="flex sm:flex-1">
                          {loadingCredits ? (
                            <div role="status" className="pt-1">
                              <LoaderIcon />
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <p className=" font-['Tourney'] sm:bg-gradient-to-r bg-[linear-gradient(90.8deg,#00C5F0_0%,#398DDA_106.17%)] sm:from-blue-400 sm:to-cyan-600 text-transparent bg-clip-text text-[28.6px] sm:text-[20px] font-bold leading-[30px]">
                              {/* {yourCredits
                                ? !isNaN(yourCredits)
                                  ? '$' + addZeroes(formatter.format(Number(yourCredits).toFixed(2)))
                                  : '$0.00'
                                : '$0.00'} */}
                              {yourCredits
                                ? !isNaN(yourCredits)
                                  ? '$' + parseFloat(yourCredits).toFixed(2)
                                  : '$0.00'
                                : '$0.00'}

                              {/* {depositWithdrawToken == 'WETH' ? (
                                <>
                                  {yourCredits ? (!isNaN(yourCredits) ? '$' + parseFloat(yourCredits).toFixed(2) + `(${parseFloat(wethValue).toFixed(4)})` : '$0.00') : '$0.00'}
                                </>
                              ) : (
                                <>
                                  {yourCredits ? (!isNaN(yourCredits) ? '$' + parseFloat(yourCredits).toFixed(2) : '$0.00') : '$0.00'}
                                </>

                              )} */}
                            </p>
                          )}

                          {isSmSize && (
                            <button
                              onClick={() => setDepositWithdrawModal(true)}
                              className="sm:ml-8 flex flex-row justify-center items-center p-[1px] bg-gradient-to-r from-[rgb(0,183,240)] to-[rgba(72,25,190)]  bg-opacity-80 flex-none order-0 rounded-[6px]"
                            >
                              {/* <span class="absolute inset-0 border-gradient"></span> */}
                              <span className="text-[12px] rounded-[inherit] px-[19.5px] py-[5px] font-inter font-normal bg-black text-white text-xs leading-4 flex items-center justify-center">
                                Top Up
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end of credit section */}
                </div>
                {/* end of column 1 */}
                <div className="flex d-flex flex-[0.4] flex-col">
                  <div className="flex items-center justify-center flex-1 w-full gap-4 sm:gap-0 sm:w-auto sm:items-start">
                    <button
                      className="text-white sm:hidden font-manrope mobilePanelButtons"
                      onClick={() => setDepositWithdrawModal(true)}
                    >
                      Credits
                    </button>

                    <Tooltip
                       title={"Spinning/Trading is currently disabled"}
                       placement="bottom"
                       arrow
                       componentsProps={{
                         tooltip: {
                           sx: toolStyle,
                         },
                         arrow: {
                           sx: {
                             color: '#43334F',
                           },
                         },
                       }}
                    >
                    <button
                      id="js-playbutton-main"
                      className="w-[30%] sm:w-3/4 spinButton"
                      onClick={spinButtonAction}
                      disabled = {true}
                      //disabled={betRun}
                    >
                      <SpinButtonIcon
                        variant={
                          hasZeroCredits
                            ? 'zeroCredits'
                            : activeButton === '' && parseInt(inputAmount) === 0
                            ? 'setAmount'
                            : !hasZeroCredits && activeButton !== ''
                            ? 'spinNow'
                            : 'setAmount'
                        }
                        network={arbsOrBase}
                      />
                    </button>
                    </Tooltip>


                    <button
                      // className="w-[90px] h-[30px] flex justify-center gap-2 items-center overflow-hidden bg-[linear-gradient(145deg_,_#4A3B77_0%_,_#322852_100%)] border-[0.6px] border-[#886CDD] rounded-md mobilePanelButtons"
                      className="sm:hidden text-white font-manrope mobilePanelButtons"
                      onClick={() => window.open('https://dashboard.pepesgame.com/#summary', '_blank')}
                      onMouseOver={() => setSpeedometerHover(true)}
                      onMouseLeave={() => setSpeedometerHover(false)}
                    >
                      <p className="font-manrope text-white text-xs font-medium">How +EV?</p>
                    </button>

                    {/* <Tooltip title={"Coming Soon"} placement="bottom" arrow

                    <Tooltip
                      title={'Coming Soon'}
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: 'white',
                            backgroundColor: '#43334F',
                          },
                        },
                      }}
                    >
                      <button
                        className="text-white sm:hidden font-manrope mobilePanelButtons"
                        // onClick={() => setOpenAutoModal(true)}
                      >
                        Auto Spin
                      </button>
                    </Tooltip> */}
                  </div>
                  <div className="hidden sm:flex flex-1 w-full justify-end sm:justify-center">
                    <div className="w-fit h-fit p-[1.5px] rounded-md bg-gradient-to-r from-[#00B7F0] to-[#4819BE] shadow-[0px_0px_4px_0px_#00B7F0_,_0px_0px_11px_0px_rgba(24_,_173_,_250_,_0.40)]">
                      <button
                        className="w-[90px] h-[30px] flex justify-center gap-2 items-center group overflow-hidden bg-[#09031D] rounded-md"
                        onClick={() => window.open('https://dashboard.pepesgame.com/#summary', '_blank')}
                        onMouseOver={() => setSpeedometerHover(true)}
                        onMouseLeave={() => setSpeedometerHover(false)}
                      >
                        <Speedometer hovered={speedometerHover} />
                        <p className="font-barlow text-white text-xs font-medium group-hover:bg-[linear-gradient(131deg_,_#00B7F0_0%_,_#4819BE_100%)] group-hover:bg-clip-text group-hover:text-transparent group-hover:font-semibold">
                          How +EV?
                        </p>
                      </button>
                    </div>
                  </div>
                  {/* autospin button below */}
                  {/* <div className="justify-end flex-1 hidden w-full sm:flex sm:justify-center">
                    <Tooltip title={"Coming Soon"} placement="bottom" arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: "white",
                            backgroundColor: "#43334F",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#43334F",
                          },
                        }
                      }}
                    >
                      <button
                        className="w-[151px] h-[60px] flex justify-center items-center overflow-hidden"
                      // onClick={() => setOpenAutoModal(true)}
                      >
                        <AutospinIcon />
                      </button>
                    </Tooltip>
                  </div> */}
                  {/* end of autospin */}
                </div>
                {/* end of column 2 */}
              </div>

              {/* credit section for mobile */}
              <div
                className={
                  isAutoSpinning && !isLgSize
                    ? 'hidden'
                    : 'flex flex-col justify-center items-center flex-wrap text-white panelShadow mt-4 sm:mt-0 self-center w-full py-[0.938rem] sm:w-auto text-center sm:hidden bg-[#0E0A23] sm:bg-transparent rounded-md'
                }
              >
                <div className="w-full  sm:flex-1 flex-col justify-center items-center flex font-manrope sm:pr-0 sm:py-0 rounded-md shadow-panelShadow bg-[#0E0A23] sm:bg-inherit">
                  <div className="text-[11px] sm:text-[13px] leading-[15.03px] sm:leading-[17.76px] text-[#E0DDFF] font-manrope font-medium mb-[2px]">
                    {!isSmSize ? 'Available Credits' : 'Your credits'}
                  </div>

                  <div className="flex items-center justify-center text-lg font-semibold flex-nowrap sm:justify-between">
                    <div className="flex sm:flex-1">
                      {loadingCredits ? (
                        <div role="status" className="pt-1">
                          <LoaderIcon />
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <p className=" font-['Tourney'] sm:bg-gradient-to-r bg-[linear-gradient(90.8deg,#00C5F0_0%,#398DDA_106.17%)] sm:from-blue-400 sm:to-cyan-600 text-transparent bg-clip-text text-[22px] sm:text-[20px] font-bold leading-[30px]">
                          {/* {yourCredits
                            ? !isNaN(yourCredits)
                              ? '$' + addZeroes(formatter.format(Number(yourCredits).toFixed(2)))
                              : '$0.00'
                            : '$0.00'} */}
                          {yourCredits
                            ? !isNaN(yourCredits)
                              ? '$' + parseFloat(yourCredits).toFixed(2)
                              : '$0.00'
                            : '$0.00'}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* end of credit section for mobile */}

              {isAutoSpinning && (
                <div className={isLgSize ? 'hidden' : 'flex flex-1 justify-center items-start'}>
                  <AutoSpinIndicator classes="relative" show={!isLgSize} wider={isSmSize} />
                </div>
              )}

              <PepeMobileFooter classes="pt-[7.25rem] mb-4" />
            </div>
          </div>
        </div>

        <PepeDesktopFooter />
        <div className="relative w-full sm:hiddden">
          <button
            className="sm:hidden justify-center items-center gap-[5px] quest-button-on-slot-glow absolute bottom-0 right-4 z-[10] bg-[#09031D] rounded-none rounded-t-2xl py-2 px-4"
            onClick={() => setQuestsModal(true)}
          >
            <span className="text-sm font-semibold gradient-text">Pepe&apos;s Pills</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default Slotmachine;

{
  /* <p className=" font-['Tourney'] sm:bg-gradient-to-r bg-[linear-gradient(90.8deg,#00C5F0_0%,#398DDA_106.17%)] sm:from-blue-400 sm:to-cyan-600 text-transparent bg-clip-text text-[28.6px] sm:text-[20px] font-bold leading-[30px]">
{yourCredits
  ? !isNaN(yourCredits)
    ? { wholeNumber = Number.isInteger(yourCredits) ? 
      ('$' + formatter.format(Number(yourCredits).toFixed(2) + '00')) : 
      ('$' + formatter.format(Number(yourCredits).toFixed(2)))
     }
    
    : '$0.00'
  : '$0.00'}
</p>
)} */
}
