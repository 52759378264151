import React from 'react';

const MediumIcon = () => {
  return (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M13.6094 9.32043C13.6094 14.2919 10.6373 18.3204 6.97224 18.3204C3.30715 18.3204 0.333252 14.2919 0.333252 9.32043C0.333252 4.34898 3.30536 0.320435 6.97164 0.320435C10.6379 0.320435 13.6088 4.34898 13.6088 9.32043"
          fill="url(#paint0_linear_331_122)"
        />
        <path
          d="M20.9217 9.24691C20.9217 14.0244 19.4005 17.8979 17.5248 17.8979C15.649 17.8979 14.1278 14.0244 14.1278 9.24691C14.1278 4.46937 15.649 0.595953 17.5248 0.595953C19.4005 0.595953 20.9217 4.46937 20.9217 9.24691Z"
          fill="url(#paint1_linear_331_122)"
        />
        <path
          d="M23.9999 9.39154C23.9999 13.6487 23.3963 17.1004 22.6521 17.1004C21.9079 17.1004 21.3031 13.6487 21.3031 9.39154C21.3031 5.13433 21.9067 1.68268 22.6521 1.68268C23.3975 1.68268 23.9999 5.13433 23.9999 9.39154Z"
          fill="url(#paint2_linear_331_122)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_331_122"
          x1="6.76442"
          y1="-6.751"
          x2="13.9474"
          y2="23.6973"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.338542" stop-color="#00B7F0" />
          <stop offset="1" stop-color="#4819BE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_331_122"
          x1="6.76442"
          y1="-6.751"
          x2="13.9474"
          y2="23.6973"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.338542" stop-color="#00B7F0" />
          <stop offset="1" stop-color="#4819BE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_331_122"
          x1="6.76442"
          y1="-6.751"
          x2="13.9474"
          y2="23.6973"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.338542" stop-color="#00B7F0" />
          <stop offset="1" stop-color="#4819BE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MediumIcon;
